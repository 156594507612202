import i18next from 'i18next';
import services from 'services/services';
import * as types from 'store/actions/types';
import { openNotification } from 'components/utilities/notification/notification';
import { fetchContinueWatchingData } from 'store/actions';

export const fetchTvShowSeason = ({ uuid: id, isAuth }) => async (dispatch) => {
	try {
		dispatch({ type: types.TV_SHOW_SEASON_LOADING });

		const url = `/products/season/${id}?platform=BROWSER`;

		const { data } = await services.get(url);

		isAuth && (await fetchContinueWatchingData()(dispatch));

		dispatch({
			type: types.TV_SHOW_SEASON_SUCCESS,
			payload: data
		});
	} catch {
		dispatch({ type: types.TV_SHOW_SEASON_ERROR });

		openNotification({
			type: 'error',
			title: i18next.t('vod_movieDetails_error_title'),
			description: i18next.t('vod_movieDetails_error_description')
		});
	}
};
