import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	isPurchaseAvailable: false,
	isLoading: true,
	error: {
		isError: false,
		errorCode: '',
		message: ''
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.GEOLOCK_PURCHASE_LOADING:
				draft.isPurchaseAvailable = false;
				draft.isLoading = true;
				draft.error.isError = false;
				return;

			case types.GEOLOCK_PURCHASE_SUCCESS:
				draft.isPurchaseAvailable = true;
				draft.isLoading = false;
				return;

			case types.GEOLOCK_PURCHASE_ERROR:
				draft.isLoading = false;
				draft.error.isError = true;
				draft.error.errorCode = action.payload.errorCode;
				draft.error.message = action.payload.message;
				return;

			default:
				return state;
		}
	});
