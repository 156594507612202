import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { size, flex, customResponsive } from 'components/styles';

// Import components
import { Input } from 'components/elements';

const INPUT_MODIFIERS = {
	payment: () => css`
		width: 16.8rem;

		${customResponsive(
			size.medium,
			css`
				width: 100%;
				height: 4.6rem;
			`
		)}
	`
};

const labelTransformBaseStyle = css`
	transform: translate(0, -2.9rem) scale(0.8);
	padding: 0 0.8rem;
`;

export const Wrapper = styled.div`
	margin-bottom: 1.9rem;
	position: relative;

	${customResponsive(
		size.medium,
		css`
			width: 100%;
		`
	)}
`;

export const FormError = styled.span`
	display: ${({ isErrorVisible }) => (!isErrorVisible ? 'none' : 'block')};
	visibility: ${({ isErrorVisible }) =>
		!isErrorVisible ? 'hidden' : 'visible'};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.4)}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.error.color};
	text-align: right;
	margin-top: 0.1rem;
	margin-left: auto;
`;

export const InputWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	&:focus-within label {
		${labelTransformBaseStyle}

		color: ${({ theme, isErrorVisible }) =>
			isErrorVisible
				? theme.error.color
				: theme.authentication.input.topLabelColor};
	}
	input:-webkit-autofill + label {
		${labelTransformBaseStyle}
	}
`;

export const Label = styled.label`
	position: absolute;
	pointer-events: none;
	top: 50%;
	transform: translate(0, -50%) scale(1);
	transform-origin: top left;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	color: ${({ theme, isErrorVisible }) =>
		isErrorVisible ? theme.error.color : theme.authentication.input.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	line-height: 1;
	left: 2rem;
	z-index: 1;

	&.filled {
		${labelTransformBaseStyle}

		color: ${({ theme, isErrorVisible }) =>
			isErrorVisible
				? theme.error.color
				: theme.authentication.input.topLabelColor};
	}

	&:focus {
		color: ${({ theme, isErrorVisible }) =>
			isErrorVisible
				? theme.error.color
				: theme.authentication.input.topLabelColor};
	}

	background-color: white;
`;

export const ErrorWrapper = styled.div`
	${flex({ jc: 'flex-start' })}
`;

export const FieldInfo = styled.span`
	display: block;
	margin-top: 0.8rem;
	text-align: left;
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	color: ${({ theme }) => theme.authentication.input.color};
`;

export const InputStyled = styled(Input)`
	height: 4.4rem;
	border: 1px solid ${({ theme }) => theme.authentication.input.borderColor};
	color: ${({ theme }) => theme.authentication.input.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	padding: 1rem 2rem;

	&:focus,
	&:hover {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
		color: ${({ theme }) => theme.authentication.input.focusColor};
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.error.border};
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.authentication.input.color};
		transition: background-color 5000s ease-in-out 0s;
	}

	${applyStyleModifiers(INPUT_MODIFIERS)}
`;
