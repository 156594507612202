const fontWeight = {
	font: {
		light: 300,
		normal: 400,
		medium: 500,
		semiBold: 600,
		bold: 700
	}
};

export default fontWeight;
