import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Import components
import NavSearchForm from './NavSerachForm';

const NavSearch = ({ toggle }) => (
	<Search>
		<NavSearchForm toggle={toggle} />
	</Search>
);

const Search = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
`;

NavSearch.propTypes = {
	toggle: PropTypes.func.isRequired
};

export default NavSearch;
