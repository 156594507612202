import React from 'react';
import { useTranslation } from 'react-i18next';
import { number, oneOfType, any, object } from 'prop-types';

// Import helpers
import { convertPrice } from 'helpers';
import { isNumber } from 'helpers/prices';

// Import styles
import { Price, PromotionPrice } from './styles';

const PaymentOptionPrice = ({ price, promotion }) => {
	const { t } = useTranslation();

	const hasPrice = price !== null;

	if (!hasPrice) return null;

	const isPromotion = isNumber(promotion?.price);

	const regularPrice = convertPrice(price);
	const promotionPrice = convertPrice(promotion?.price);

	return (
		<Price>
			{isPromotion ? (
				<>
					<del>
						{regularPrice} {t('payment_currency')}
					</del>
					<PromotionPrice>
						{promotionPrice} {t('payment_currency')}
					</PromotionPrice>
				</>
			) : (
				<>
					{regularPrice} {t('payment_currency')}
				</>
			)}
		</Price>
	);
};

PaymentOptionPrice.propTypes = {
	price: oneOfType([number, any]),
	promotion: object
};

export default PaymentOptionPrice;
