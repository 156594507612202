import { combineReducers } from 'redux';

// Import helpers
import { getPersistState } from './helpers';

// IMPORT COMMON REDUCERS
import auth from './auth/auth_reducer';
import register from './register/register_reducer';
import reset_password from './reset_password/reset_password_reducer';
import email_confirmation from './email_confirmation/email_confirmation_reducer';
import portal from './portal/portal_reducer';
import player from './player/player_reducer';
import pip from './pip/pip_reducer';
import player_details from './player/player_details_reducer';
import player_vod from './player/player_vod_reducer';
import search from './search/search_reducer';
import devices from './devices/devices_reducer';
import parental_control from './parental_control/parental_control_reducer';
import user_products from './user_products/user_products_reducer';
import user_payments from './user_payments/user_payments_reducer';
import user_vod_accounts from './user_vod_accounts/user_vod_accounts_reducer';
import user_code_login from './user_code_login/user_code_login_reducer';
import previous_location from './prev_location';
import subscriptions from './subscriptions';
import menu from './menu/menu_reducer';
import product_gallery from './product_gallery/product_gallery_reducer';
import institutions from './institutions/institutions_reducer';
import wcag_accessibility from './wcag_accessibility/wcag_accessibility_reducer';

// IMPORT TV REDUCERS
import tvReducers from './tv';

// IMPORT VOD REDUCERS
import vodReducers from './vod';

// IMPORT TRANSACTION REDUCERS
import transactionReducers from './transaction';

const appReducer = combineReducers({
	auth,
	register,
	reset_password,
	portal,
	user_payments,
	player,
	player_details,
	player_vod,
	pip,
	parental_control,
	search,
	devices,
	user_products,
	user_vod_accounts,
	user_code_login,
	previous_location,
	email_confirmation,
	...tvReducers,
	...vodReducers,
	...transactionReducers,
	...subscriptions,
	menu,
	product_gallery,
	institutions,
	wcag_accessibility
});
/* eslint-disable no-param-reassign */
const rootReducer = (state, action) => {
	if (action.type === 'SIGN_OUT') {
		state = getPersistState(state);
	}

	return appReducer(state, action);
};
/* eslint-disable no-param-reassign */

export default rootReducer;
