import React from 'react';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import { Button, PlayerElements as Player } from 'components/elements';
import { VolumeStyled } from './styles';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const VolumeController = () => {
	const { t } = useTranslation();
	const { volume, setVolume, mute, isMuted } = usePlayerVodContext();

	const handleChangeVolume = (e) => {
		e.persist();
		// Get volume
		const value = e.target.value;
		// Set volume in player provider
		setVolume(value);
	};
	// Mute or unmute in player provider
	const handleMute = () => mute();

	const btnTitle = isMuted
		? t('common_player_volume_off_title')
		: t('common_player_volume_on_title');

	const renderIconVolume = () => {
		if (!isMuted) {
			return (
				<Player.IconVolume
					aria-hidden="true"
					title={t('common_player_volume_available_title')}
				/>
			);
		}
		return (
			<Player.IconVolumeOff
				aria-hidden="true"
				title={t('common_player_volume_not_available_title')}
			/>
		);
	};

	return (
		<Player.Wrapper>
			<Button
				onClick={handleMute}
				modifiers={['icon', 'playerIconHover']}
				title={btnTitle}
			>
				{renderIconVolume()}
			</Button>
			<Player.RangeWrapper>
				<VolumeStyled>
					<Player.Range
						value={volume}
						onChange={handleChangeVolume}
						type="range"
						name="volume"
						className="volume_range"
						id="volume"
						min="0"
						max="100"
						step="1"
					/>
					<Player.RangeValue modifiers={['primaryRange']} value={volume} />
				</VolumeStyled>
			</Player.RangeWrapper>
		</Player.Wrapper>
	);
};

export default VolumeController;
