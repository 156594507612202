import { useHistory } from 'react-router-dom';

// Import helpers
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import utilities
import { useAuth } from 'components/utilities';
import { useNoAuthPathSave } from 'components/utilities';

const useAuthModal = () => {
	const { isAuth } = useAuth();

	const history = useHistory();

	const { savePath } = useNoAuthPathSave();

	const handleRedirect = (toggle) => () => {
		toggle();
		savePath();
		history.push(`/${APP_CORE_PATHS.LOGIN}`);
	};

	const handleOnAuth = (cb) => (e) => {
		if (!isAuth) {
			e.preventDefault();
			cb();
		}
	};

	const handleOnOpen = (cb) => () => isAuth && cb?.();

	return {
		isAuth,
		redirect: handleRedirect,
		auth: handleOnAuth,
		open: handleOnOpen
	};
};

export default useAuthModal;
