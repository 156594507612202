import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { func, number } from 'prop-types';

// Import helpers
import { checkProductSubtype } from 'helpers';

// Import utilities
import { useViewportResize } from 'components/utilities';

const useGallery = ({
	onHeightChange = () => {},
	setSmallGalleryIndex = () => {},
	smallGalleryIndex,
	resolutionHeight = 0.565,
	descriptionHeight = 60,
	isFullscreen
}) => {
	// refs
	const galleryRef = useRef(null);

	// state
	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);
	const [currentIndex, setCurrentIndex] = useState(smallGalleryIndex);

	// redux state
	const { gallery, error, loading } = useSelector(
		({ product_gallery }) => product_gallery
	);
	const { subtype } = useSelector(({ movieDetails }) => movieDetails.data);

	// variables
	const galleryHeight = containerHeight - descriptionHeight;
	const smallGalleryHeight = containerWidth * resolutionHeight;

	const assetHeight = isFullscreen ? galleryHeight : smallGalleryHeight;

	const showGallery = gallery?.assets && !error && !loading;

	const { isArticle } = checkProductSubtype(subtype);

	// helpers
	const handleSetContainerWidth = () => {
		if (galleryRef.current) {
			const { offsetWidth, offsetHeight } = galleryRef.current;
			setContainerWidth(offsetWidth);
			setContainerHeight(offsetHeight);
		}
	};

	const handleSetCurrentIndex = (index) => {
		setCurrentIndex(index);
		setSmallGalleryIndex(index);
	};

	useViewportResize({
		callback: handleSetContainerWidth,
		throttleTime: 500
	});

	useEffect(() => {
		handleSetContainerWidth();
		// eslint-disable-next-line
	}, [galleryRef.current]);

	useEffect(() => {
		onHeightChange(assetHeight);
		// eslint-disable-next-line
	}, [assetHeight]);

	return {
		gallery,
		galleryRef,
		containerWidth,
		assetHeight,
		showGallery,
		currentIndex,
		isArticle,
		setCurrentIndex: handleSetCurrentIndex
	};
};

useGallery.propTypes = {
	onHeightChange: func,
	smallGalleryIndex: number,
	setSmallGalleryIndex: func,
	resolutionHeight: number
};

export default useGallery;
