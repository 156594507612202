import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
// Import utilties
import { customMinResponsive, size } from 'components/styles';

export const Nav = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	height: 100%;
	color: ${({ theme: { body } }) => body.footer.link};
	/* min-width: 100%; */
	${customMinResponsive(
		size.medium,
		css`
			flex-direction: row;
		`
	)}
`;

export const Item = styled.li`
	margin-bottom: 1.2rem;
	padding: 0 2rem;
	height: auto;
	position: relative;
	color: ${({ theme: { body } }) => body.footer.color};
	white-space: nowrap;
	${customMinResponsive(
		size.medium,
		css`
			padding: 0 1.8rem;
			margin-bottom: 0;
			margin-left: 0;

			&::after {
				position: absolute;
				top: 0;
				right: 0;
				content: '';
				display: block;
				height: 100%;
				width: 0.2rem;
				background-color: ${({ theme }) => theme.body.footer.link};
				opacity: 0.4;
			}

			&:first-child {
				padding-left: 0;
			}
		`
	)}

	&:last-child {
		padding-right: 0;

		&:after {
			display: none;
		}
	}
`;

export const linkStyle = css`
	&:link,
	&:visited {
		color: ${({ theme: { body } }) => body.footer.color};
		text-decoration: none;
		font-size: 1.2rem;
		font-weight: ${({ theme }) => theme.font.medium};
		line-height: 2.3rem;
		transition: 0.2s ease-in-out;
	}

	&:hover {
		color: ${({ theme: { body } }) => body.footer.hover};
	}

	&:active {
		color: ${({ theme: { body } }) => body.footer.hover};
	}

	&:focus-visible {
		color: ${({ theme: { body } }) => body.footer.hover};
	}
`;

export const StyledLink = styled(RouterLink)`
	${linkStyle}
`;
