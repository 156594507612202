export const getModalText = ({ isGeolockError, isEmailConfirmed }) => {
	let title = '';
	let description = '';

	if (isGeolockError) {
		title = 'common_geolock_purchase_modal_title';
		description = 'common_geolock_purchase_modal_description_required';
	} else if (!isEmailConfirmed) {
		title = 'payment_email_approve_title';
		description = 'payment_email_approve_description';
	}

	return { title, description };
};
