import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
	defaultLanguage,
	setUserLanguage,
	getUserLanguage
} from 'helpers/languages.js';

export const useLanguages = () => {
	const { i18n, t } = useTranslation();
	const currentLanguage = getUserLanguage();
	const history = useHistory();
	// refresh on language change

	const handleLanguageChange = (newLanguage) => {
		history.go(0);
		i18n.changeLanguage(newLanguage);
		setUserLanguage(newLanguage);
	};

	const mapCodeToLanguage = (code) =>
		new Intl.DisplayNames(code, { type: 'language' }).of(code).toUpperCase();

	return {
		defaultLanguage,
		currentLanguage,
		handleLanguageChange,
		mapCodeToLanguage,
		t
	};
};
