import * as types from 'store/actions/types';
import produce from 'immer';

import { STATUS_TYPES } from 'helpers/variables';

const { idle, loading, success, error } = STATUS_TYPES;

const INITIAL_STATE = {
	error: null,
	status: idle,
	isCodeExpired: false,
	isDevicesExceeded: false,
	isChangesExceeded: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Authorize login code *******************
			case types.AUTHORIZE_LOGIN_CODE_LOADING:
				return { ...INITIAL_STATE, status: loading };
			case types.AUTHORIZE_LOGIN_CODE_SUCCESS:
				draft.status = success;
				break;
			case types.AUTHORIZE_LOGIN_CODE_ERROR:
				draft.status = error;
				draft.isCodeExpired = action.payload.isCodeExpired;
				draft.isDevicesExceeded = action.payload.isDevicesExceeded;
				draft.isChangesExceeded = action.payload.isChangesExceeded;
				break;
			// ******************* Clear state *******************
			case types.CLEAR_USER_LOGIN_CODE_STATE:
				return { ...INITIAL_STATE };

			default:
				return state;
		}
	});
