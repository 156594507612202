import styled from 'styled-components';

export const OldPrice = styled.del`
	margin: 0 0.5rem;
	color: ${({ color }) => (color ? color : 'inherit')};
`;

export const RegularPrice = styled.span`
	margin: 0 0.5rem;
`;
