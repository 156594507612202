import { notification } from 'antd';

export const openNotification = ({
	type = 'success',
	title,
	description,
	body,
	duration = 7
}) => {
	notification[type]({
		message: title,
		description: description || body,
		duration,
		className: `notification-${type}`
	});
};
