import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const base = css`
	color: ${({ theme }) => theme.black};
	margin-bottom: 1.8rem;
	line-height: 1.4;
	font-weight: ${({ theme }) => theme.font.medium};
`;

// Heading First
const HEADING_FIRST_MODIFIERS = {
	primary: ({ theme: { heading } }) => css`
		color: ${heading.first.primary};
	`,

	details: ({ theme }) => css`
		margin-bottom: 2.2rem;
		color: ${theme.heading.first.details};
	`,

	normal: ({ theme }) => css`
		font-size: ${theme.fontSize.md}rem;
	`,

	big: ({ theme }) => css`
		font-size: ${theme.fontSize.lg}rem;
	`,
	hidden: () => css`
		position: absolute;
		clip: rect(1px, 1px, 1px, 1px);
		-webkit-clip-path: inset(0px 0px 99.9% 99.9%);
		clip-path: inset(0px 0px 99.9% 99.9%);
		overflow: hidden;
		height: 1px;
		width: 1px;
		padding: 0;
		border: 0;
	`
};

const First = styled.h1`
	${base}
	margin-top: 1.8rem;
	font-weight: ${({ theme }) => theme.font.medium};
	line-height: 1.2;
	${applyStyleModifiers(HEADING_FIRST_MODIFIERS)};
`;

// Heading Second
const HEADING_SECOND_MODIFIERS = {
	small: ({ theme }) => css`
		font-size: ${theme.fontSize.set('xs', -0.2)}rem;
		color: ${theme.primary};
	`
};

const Second = styled.h2`
	${base}

	${applyStyleModifiers(HEADING_SECOND_MODIFIERS)};
`;

// Heading Third
const HEADING_THIRD_MODIFIERS = {
	big: ({ theme }) => css`
		font-size: ${theme.fontSize.md}rem;
	`
};

const Third = styled.h3`
	${base}
	font-weight:${({ theme }) => theme.font.normal};
	${applyStyleModifiers(HEADING_THIRD_MODIFIERS)};
`;

export { Second, Third, First };
