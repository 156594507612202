import React from 'react';
import { func } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import utilities
import { useNoAuthRedirect } from 'components/utilities';

const ProtectedUserLogged = ({ component: Component }) => {
	const { isLogged, isRegisterCompleted, isFirebaseRegisterCompleted } =
		useSelector(({ auth }) => auth);

	const { redirectPath } = useNoAuthRedirect();

	return (
		<Route
			render={(props) =>
				isLogged && (isRegisterCompleted || isFirebaseRegisterCompleted) ? (
					<Redirect to={redirectPath} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

ProtectedUserLogged.propTypes = {
	component: func.isRequired
};

export default ProtectedUserLogged;
