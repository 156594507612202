import * as types from 'store/actions/types';

// ******************** SET PORTAL AVAILABILITY ********************
export const setPortalAvailability = () => (dispatch) => {
	dispatch({
		type: types.SET_PORTAL_AVAILABILITY
	});
};

// ******************** SET INTERNAL SERVER ERROR ********************
export const setInternalServerError = (isError) => (dispatch) => {
	dispatch({
		type: types.SET_INTERNAL_SERVER_ERROR,
		payload: isError
	});
};
