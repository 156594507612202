import * as types from 'store/actions/types';
import services from 'services/services';

// Import api
import { GET_INSTITUTIONS_LIST_API } from 'helpers/api';

// Import helpers
import { sortInstitutions } from 'helpers/institutions';

// import variables
import { SESSIONSTORAGE_INSTITUTION } from 'helpers/variables';
import { getInstitutionFromStorage } from 'helpers/institutions';

// ******************** FETCH INSTITUTIONS LIST ********************
export const fetchInstitutionsList = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_INSTITUTIONS_LIST_LOADING
		});

		const { data } = await services.get(GET_INSTITUTIONS_LIST_API);

		const institutions = sortInstitutions(data?.data);

		dispatch({
			type: types.FETCH_INSTITUTIONS_LIST_SUCCESS,
			payload: institutions
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_INSTITUTIONS_LIST_ERROR,
			payload: error.response
		});
	}
};

export const setInstitution = (institution) => (dispatch) => {
	// set institution to session storage
	const value = JSON.stringify(institution);
	sessionStorage.setItem(SESSIONSTORAGE_INSTITUTION, value);

	dispatch({
		type: types.SET_INSTITUTION,
		payload: institution
	});
};

export const setDefaultInstitution =
	({ override = false } = {}) =>
	async (dispatch) => {
		// set institution to redux if there is existing one in session storage
		const storageInstitution = getInstitutionFromStorage();

		if (storageInstitution && !override) {
			dispatch({
				type: types.SET_INSTITUTION,
				payload: storageInstitution
			});
			return;
		}
	};

export const clearInstitution = () => (dispatch) => {
	// remove institution from session storage
	sessionStorage.removeItem(SESSIONSTORAGE_INSTITUTION);

	dispatch({
		type: types.CLEAR_INSTITUTION
	});
};
