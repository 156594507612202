import * as types from 'store/actions/types';
import services from 'services/services';

export const fetchFreeVodPackets = () => async (dispatch) => {
	try {
		const { data } = await services.get('/products/free/uuids');

		// Dispatch an success action
		dispatch({
			type: types.FETCH_PACKETS_FREE_VOD_SUCCESS,
			payload: data
		});
	} catch {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_PACKETS_FREE_VOD_ERROR,
			payload: true
		});
	}
};
