import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearInstitution } from 'store/actions';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { HOME_PAGE } = APP_CORE_PATHS;

export const usePortalLogo = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	// go to homepage and clear institution
	const handleClearInstitution = () => {
		clearInstitution()(dispatch);
		history.push(HOME_PAGE);
	};

	return { clearInstitution: handleClearInstitution };
};

export default usePortalLogo;
