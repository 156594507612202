import { isPast as isPastFns, isFuture as isFutureFns } from 'date-fns';

export const getAvailableIn = ({ since, till, isAvailable }) => {
	if (isAvailable) {
		const isPast = isPastFns(new Date(since));
		const isFuture = isFutureFns(new Date(till));
		return isPast && isFuture;
	}

	return false;
};
