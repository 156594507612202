// ----------------- FETCH INSTITUTIONS LIST -----------------
export const FETCH_INSTITUTIONS_LIST_LOADING =
	'FETCH_INSTITUTIONS_LIST_LOADING';
export const FETCH_INSTITUTIONS_LIST_SUCCESS =
	'FETCH_INSTITUTIONS_LIST_SUCCESS';
export const FETCH_INSTITUTIONS_LIST_ERROR = 'FETCH_INSTITUTIONS_LIST_ERROR';

export const SET_INSTITUTION = 'SET_INSTITUTION';

// ----------------- CLEAR -----------------
export const CLEAR_INSTITUTIONS_LIST = 'CLEAR_INSTITUTIONS_LIST';
export const CLEAR_INSTITUTION = 'CLEAR_INSTITUTION';
