import styled, { css } from 'styled-components';

// Import styles
import {
	modalFadeInUp,
	flex,
	customMinResponsive,
	size
} from 'components/styles';

// Import icons
import { ReactComponent as IconClose } from 'assets/icons/ic_close_thin.svg';

// Import components
import { Button } from 'components/elements';

export const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999;
	${flex()}
`;

export const Box = styled.div`
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 95%;
	border: 1px solid ${({ theme }) => theme.product.packetsModal.border};
	border-radius: ${({ theme }) => theme.product.packetsModal.roundSize}rem;
	background-color: ${({ theme }) => theme.product.packetsModal.bg};
	margin: 3rem 0;
	overflow: hidden;
	animation: ${modalFadeInUp} 0.3s ease-in-out forwards;

	${customMinResponsive(
		size.medium,
		css`
				width: 76%;
		`
	)};
`;

export const ButtonClose = styled(Button)`
	position: absolute;
	top: 0.2rem;
	right: 0.2rem;
	z-index: 10;

	svg {
		path {
			fill: ${({ theme }) => theme.product.packetsModal.closeButton};
		}
	}

	:hover,
	:focus {
		svg {
			path {
				fill: ${({ theme }) => theme.product.packetsModal.closeButtonHover};
			}
		}
	}

	${customMinResponsive(
		size.medium,
		css`
			top: 2rem;
			right: 2rem;
		`
	)}
`;

export const IconCloseStyled = styled(IconClose)`
	width: 1.6rem;
	height: 1.6rem;
	position: relative;
	z-index: 10;
	padding: 0;

	${customMinResponsive(
		size.medium,
		css`
			width: 2.2rem;
			height: 2.2rem;
		`
	)}
`;

export const Content = styled.div`
	position: relative;
	height: 72vh;
	padding-top: 3rem;

	${customMinResponsive(
		size.medium,
		css`
			height: auto;
		`
	)};
`;

export const BlackTransparentBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	height: 100%;
	width: 100%;
	background: ${({ theme }) => theme.product.packetsModal.screenBg};
`;
