import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

// Import icons
import { ReactComponent as IconContentRatingP } from 'assets/icons/content_rating_p.svg';
import { ReactComponent as IconContentRatingS } from 'assets/icons/content_rating_s.svg';
import { ReactComponent as IconContentRatingN } from 'assets/icons/content_rating_n.svg';
import { ReactComponent as IconContentRatingW } from 'assets/icons/content_rating_w.svg';

const baseIconStyle = css`
	margin-left: 0.5rem;
	filter: ${({ isContrast }) => `grayscale(${isContrast ? 1 : 0})`};
	pointer-events: auto;
`;

export const Wrapper = styled.div`
	${flex()}
`;

export const StyledIconContentRatingP = styled(IconContentRatingP)`
	${baseIconStyle}
`;

export const StyledIconContentRatingS = styled(IconContentRatingS)`
	${baseIconStyle}
`;

export const StyledIconContentRatingN = styled(IconContentRatingN)`
	${baseIconStyle}
`;

export const StyledIconContentRatingW = styled(IconContentRatingW)`
	${baseIconStyle}
`;
