import React from 'react';
import { useSelector } from 'react-redux';
import { string, shape, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuLink as SubNavLink } from '@reach/menu-button';

// Import styles
import * as SubNav from './styles';

// Import helpers
import { getLinkDetails, getSubLinkDetails } from '../helpers';

const SubNavItem = ({ sublink, basePath, isMainItem }) => {
	const { t } = useTranslation();

	const genresList = useSelector(({ genresList }) => genresList.data);

	const { link, name, model_id, model_type } = getSubLinkDetails(sublink);

	const { path, isInternalLink } = getLinkDetails({
		modelId: model_id,
		modelType: model_type,
		name,
		basePath,
		genresList
	});

	if (isMainItem) {
		return (
			<li>
				{/*  Main item to show all */}
				<SubNavLink
					to={basePath}
					title={t('common_header_nav_mobile_show_all')}
					as={SubNav.StyledNavLink}
				>
					{t('common_header_nav_mobile_show_all')}
				</SubNavLink>
			</li>
		);
	}

	const linkProps = isInternalLink
		? { to: path, as: SubNav.StyledNavLink }
		: { href: link, as: SubNav.AnchorLink };

	return (
		<li>
			<SubNavLink title={name} {...linkProps}>
				{name}
			</SubNavLink>
		</li>
	);
};

SubNavItem.defaultProps = {
	isMainItem: false
};

SubNavItem.propTypes = {
	sublink: shape({
		name: string.isRequired,
		link: string.isRequired,
		model_id: string,
		model_type: string
	}),
	basePath: string.isRequired,
	isMainItem: bool
};

export default SubNavItem;
