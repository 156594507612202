import React from 'react';
import { object, bool, array } from 'prop-types';

// Import components
import SliderController from './SliderController';

// Import styled components
import { SliderStyled } from './styles';

// Import logic
import { useBannerSlider } from 'components/utilities';

const Slider = ({ settings, defaultBtn = true, children }) => {
	const { sliderRef } = useBannerSlider();
	const options = {
		...settings,
		nextArrow: <SliderController defaultBtn={defaultBtn} type="next" />,
		prevArrow: <SliderController defaultBtn={defaultBtn} type="prev" />
	};

	return (
		<SliderStyled {...options} ref={sliderRef}>
			{children}
		</SliderStyled>
	);
};

Slider.propTypes = {
	settings: object.isRequired,
	children: array.isRequired,
	defaultBtn: bool
};

export default Slider;
