import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { playerOnCancel } from 'store/actions';
import { PLAYER_SELECTORS } from 'helpers/variables';
import { fetchMovieResources } from './helpers';

// Import utilities
import { useAuth } from 'components/utilities';

const playerSelector = PLAYER_SELECTORS.VOD;

export function useLogic({
	uuid: id,
	type = 'vod',
	videoID,
	startStopCarousel
}) {
	const dispatch = useDispatch();

	const { isAuth } = useAuth();
	const { isReady, isLoaded } = useSelector((state) => state[playerSelector]);
	const { error, errorMessage } = useSelector(
		({ movieDetails }) => movieDetails
	);

	useEffect(() => {
		// Stop carousel
		startStopCarousel({ isOpened: true });
		// Fetch movie player resources
		fetchMovieResources({ id, type, dispatch, videoID, isAuth });

		return () => {
			// Start carousel
			startStopCarousel({ isOpened: false });
			// Clear player
			playerOnCancel(playerSelector)(dispatch);
		};
		// eslint-disable-next-line
	}, []);

	return {
		isReady,
		isLoaded,
		error,
		errorMessage,
		type: playerSelector
	};
}
