// import helpers
import { getSearchCategory } from 'helpers';

// Import variables
import { ROUTES_CORE_PATHS } from 'helpers/variables';

const { SEARCH } = ROUTES_CORE_PATHS;

const useNavItem = ({ path, slug }) => {
	let urlSlug = '';

	if (slug) {
		const isSearch = path.includes(SEARCH);
		urlSlug = `/${isSearch ? getSearchCategory(slug) : slug}`;
	}

	const url = `${path}${urlSlug}`;

	return { url };
};

export default useNavItem;
