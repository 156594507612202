import React from 'react';
import { number, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Import helpers
import { checkIsFavourite } from 'helpers/epg';
import { addFavourite, deleteFavourite } from 'store/actions';

// Import utilities
import { useAuth } from 'components/utilities';

// Import components
import { AuthModal, CommonButton } from 'components/elements';

// Import styles
import {
	IconFavorite,
	IconFavoriteFull
} from 'components/elements/molecules/common_button/styles';

const FavouriteButton = ({ uuid, width }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { data: favourites, isLoaded } = useSelector(
		({ favourites }) => favourites
	);

	const { isAuth } = useAuth();

	const isFavourite = checkIsFavourite(favourites, uuid);

	const click = () => {
		if (isFavourite) {
			return deleteFavourite(uuid)(dispatch);
		}
		return addFavourite(uuid)(dispatch);
	};

	const buttonText = isFavourite
		? t('vod_buttons_favourite_delete')
		: t('vod_buttons_favourite_add');

	return (
		<AuthModal>
			<CommonButton
				onClick={click}
				width={width}
				isLoading={!isLoaded && isAuth}
				loaderColor="secondary"
			>
				{isFavourite ? <IconFavoriteFull /> : <IconFavorite />}
				{buttonText}
			</CommonButton>
		</AuthModal>
	);
};

FavouriteButton.defaultProps = {
	width: 250
};

FavouriteButton.propTypes = {
	uuid: string.isRequired,
	width: number
};

export default FavouriteButton;
