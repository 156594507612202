/**
 * @param {Array} array - array of items
 * @param {number} count - how much items should be visible
 */
export function getProcessedItemsList(array, count, isCarousel) {
	let tempArr = array;
	const arrLength = array.length;

	if (!isCarousel && arrLength < count) {
		const missingNumber = count - arrLength;

		const blankElements = new Array(missingNumber).fill('extender');

		tempArr = array.concat(blankElements);
	}

	return tempArr;
}
