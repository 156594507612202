import React from 'react';
import { withTheme } from 'styled-components';
import {
	oneOfType,
	arrayOf,
	node,
	string,
	bool,
	func,
	number
} from 'prop-types';

// Import styled components
import { DrawerStyled } from './styles';

const DrawerRPC = ({ children, position, isOpen, ...rest }) => (
	<DrawerStyled visible={isOpen} placement={position} {...rest}>
		{children}
	</DrawerStyled>
);

DrawerRPC.defaultProps = {
	position: 'right',
	width: 356,
	destroyOnClose: true
};

DrawerRPC.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	title: string.isRequired,
	onClose: func.isRequired,
	isOpen: bool.isRequired,
	position: string,
	width: number,
	destroyOnClose: bool
};

export default withTheme(DrawerRPC);
