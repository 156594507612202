import React from 'react';
import { func, object } from 'prop-types';

// Import styles
import { Row } from 'components/elements/molecules/table/styles';

// Import components
import { TableCell } from '.';

const TableRow = ({ prepareRow, row }) => {
	prepareRow(row);
	return (
		<Row
			className="blueonline-table__body-row blueonline-table__row"
			{...row.getRowProps()}
		>
			{row.cells.map((cell) => (
				<TableCell key={cell.column.id} cell={cell} />
			))}
		</Row>
	);
};

TableRow.propTypes = {
	prepareRow: func.isRequired,
	row: object.isRequired
};

export default TableRow;
