import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { object } from 'prop-types';

// import helpers
import { INITIAL_PROMOTION, getPromotion } from 'helpers';
import { isNumber, getLowestPrices } from 'helpers/prices';

// Import utilities
import { useProductPrices } from 'components/utilities';

const useLowestPrice = ({ product }) => {
	const userPromotions = useSelector(({ promotions }) => promotions.data);

	const prices = useProductPrices({ product });

	return useMemo(() => {
		const pricesWithPromotions = Object.values(prices).map((regular) => {
			const { price, promotions } = regular;

			const promotion =
				isNumber(price) && promotions
					? getPromotion({ price, promotions, userPromotions })
					: INITIAL_PROMOTION;

			return { regular, promotion };
		});

		return getLowestPrices(pricesWithPromotions);
	}, [userPromotions, prices]);
};

useLowestPrice.propTypes = {
	product: object.isRequired
};

export default useLowestPrice;
