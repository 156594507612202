import * as types from 'store/actions/types';
import services from 'services/services';

export const fetchPackets = async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PACKETS_LOADING,
			payload: true
		});

		const { data } = await services.get(
			'/products/packet?subtype=main&order=order&orderDir=asc&platform=BROWSER'
		);

		// Dispatch an success action
		dispatch({
			type: types.FETCH_PACKETS_SUCCESS,
			payload: { ...data }
		});
	} catch {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_PACKETS_ERROR,
			payload: true
		});
	}
};
