import services from 'services/services';

// Import api
import { GET_AGREEMENTS_API } from 'helpers/api';

// Import helpers
import { replaceStringChar } from 'helpers';

export const fetchAgreementsByType = (type) => {
	const url = replaceStringChar({
		string: GET_AGREEMENTS_API,
		regex: /\b(?:type)\b/gi,
		object: { type }
	});

	return services.get(url);
};

export const getObligatoryAgreementsIDs = (agreements) =>
	agreements.filter(({ obligatory }) => obligatory).map(({ id }) => id);
