import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';

// Import components
import { CookieMessage } from 'components/elements';
import useContent from './useContent';

const Content = ({ children }) => {
	const { showCookieMessage } = useContent();

	return (
		<>
			{children}
			{showCookieMessage && <CookieMessage />}
		</>
	);
};

Content.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired
};

export default Content;
