import React from 'react';
import PropTypes from 'prop-types';

const CountdownRPC = ({ children, time, isPaused }) => {
	const [counter, setCounter] = React.useState(0);

	React.useEffect(() => {
		setCounter(time);
	}, [time]);

	React.useEffect(() => {
		let countdownTimeout = null;
		if (counter > 0) {
			countdownTimeout = setTimeout(() => setCounter(counter - 1), 1000);
		}

		if (isPaused) {
			clearTimeout(countdownTimeout);
		}

		return () => clearTimeout(countdownTimeout);
	}, [counter, isPaused]);

	return children({ counter });
};

CountdownRPC.propTypes = {
	children: PropTypes.func.isRequired,
	time: PropTypes.number.isRequired,
	isPaused: PropTypes.bool.isRequired
};

export default CountdownRPC;
