import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isLoaded: false,
	isError: false,
	errorMessage: ''
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Enable parental control *******************
			case types.PARENTAL_CONTROL_LOADING:
				return { ...INITIAL_STATE };

			case types.PARENTAL_CONTROL_SUCCESS:
				draft.isLoaded = true;
				return;

			case types.PARENTAL_CONTROL_ERROR:
				draft.isError = true;
				draft.errorMessage = action.payload.data.message || '';
				return;

			// ******************* Clear parental control *******************
			case types.CLEAR_PARENTAL_CONTROL_LOADING:
				return { ...INITIAL_STATE };

			default:
				return state;
		}
	});
