import React from 'react';
import { string, array, shape } from 'prop-types';

// Import components
import { NavButton } from './styles';
import { Nav } from 'components/elements';
import SubNavigation from './sub_nav/SubNavigation';

// Import helpers
import { getLinkDetails } from './helpers';
import { Menu as NavItem } from '@reach/menu-button';

const NavLink = ({ linkData }) => {
	const { submenu, model_id, model_type, link, name } = linkData;

	const { path, isInternalLink } = getLinkDetails({
		modelId: model_id,
		modelType: model_type,
		name
	});

	const isSubMenu = submenu.length;

	const renderSubMenu = (isSubMenu) => {
		if (!isSubMenu) {
			return (
				// Direct link to internal path
				<NavButton
					to={path}
					role="menuitem"
					tabIndex="0"
					title={name}
					activeClassName="active-nav"
					as={Nav.NavLink}
				>
					{name}
				</NavButton>
			);
		}

		return (
			// Button which open his submenu
			<NavButton role="menuitem" tabIndex="0" title={name}>
				{name}
			</NavButton>
		);
	};

	return (
		<NavItem>
			<SubNavigation submenu={submenu} basePath={path}>
				{isInternalLink ? (
					<>{renderSubMenu(isSubMenu)}</>
				) : (
					// Direct link to external path
					<NavButton
						href={link}
						role="menuitem"
						tabIndex="0"
						title={name}
						as={Nav.AnchorLink}
					>
						{name}
					</NavButton>
				)}
			</SubNavigation>
		</NavItem>
	);
};

NavLink.propTypes = {
	linkData: shape({
		name: string.isRequired,
		link: string.isRequired,
		submenu: array,
		model_id: string,
		model_type: string
	}).isRequired
};

export default NavLink;
