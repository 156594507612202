import { useParams, useHistory } from 'react-router-dom';
// import helpers
import { getSearchCategory } from 'helpers';

const useNavSelect = (path) => {
	const { category } = useParams();
	const history = useHistory();

	// translate from PL to ENG
	const categoryName = getSearchCategory(category);

	const handleOnChange = (category) => {
		// translate from ENG to PL
		const categoryName = getSearchCategory(category);
		const url = `${path}/${categoryName}`;
		history.push(url);
	};

	return {
		category: categoryName,
		onChange: handleOnChange,
	};
};

export default useNavSelect;
