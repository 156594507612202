import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isPortalAvailable: true,
	isInternalServerError: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************** SET PORTAL AVAILABILITY ********************
			case types.SET_PORTAL_AVAILABILITY:
				draft.isPortalAvailable = false;
				break;

			// ******************** SET INTERNAL SERVER ERROR ********************
			case types.SET_INTERNAL_SERVER_ERROR:
				draft.isInternalServerError = action.payload;
				break;

			default:
				return state;
		}
	});
