export const colors = {
	// Name approach proposal to the same color (gradation): veryLightColor -> lightColor -> semiLightColor -> color -> semiDarkColor -> darkColor -> veryDarkColor
	// Example: veryLightBrown -> lightBrown -> semiLightBrown -> brown -> semiDarkBrown -> darkBrown -> veryDarkBrown

	//========================
	black: '#000000',
	semiLightBlack: '#131313',
	lightBlack: '#222222',
	veryLightBlack: '#2C2B2B',

	//========================
	veryDarkGray: '#413F3F',
	darkGray: '#666666',
	gray: '#8A8A8A',
	veryLightGray: '#BDBABA',
	lightGray: '#E6E6E6',

	//========================
	darkWhite: '#D8D6D7',
	semiDarkWhite: '#EDEDED',
	white: '#FFFFFF',
	transparent: 'transparent',

	//========================
	semiDarkOrange: '#E26745',

	//========================
	semiDarkRed: '#DD2121',
	red: '#FF0000',

	semiLightBlue: '#384F9D',
	blue: '#052383',

	//======================== Old colors related to Tag
	blueDark: '#072740',
	blueOcean: '#66b5d6',
	turquoiseDark: '#12473f',
	blueGray: '#678398',
	orangeNormal: '#fb7b0e',
	pinkGray: '#926d8c',
	purpleDark: '#403448'
};
