import { useState } from 'react';

// Import helpers
import { getUserAgentInfo } from 'helpers';

const useProductGallery = () => {
	const [smallGalleryIndex, setSmallGalleryIndex] = useState(0);

	const { isMobile } = getUserAgentInfo();

	return {
		smallGalleryIndex,
		setSmallGalleryIndex,
		isMobile
	};
};

export default useProductGallery;
