import React from 'react';
import { useTranslation } from 'react-i18next';
import { array, func, bool } from 'prop-types';

// Import components
import { PlayerElements as Player } from 'components/elements';

// Import styles
import { PlayButton } from './styles';

const BigPlayButton = ({ modifiers, onClick, isFloatMode }) => {
	const { t } = useTranslation();

	return (
		<PlayButton
			isFloatMode={isFloatMode}
			modifiers={modifiers}
			onClick={onClick}
			title={t('common_player_play_title')}
		>
			<Player.IconPlayXl
				aria-hidden="true"
				title={t('common_player_play_title')}
			/>
		</PlayButton>
	);
};

BigPlayButton.defaultProps = {
	modifiers: [],
	onClick: () => {},
	isFloatMode: false
};

BigPlayButton.propTypes = {
	modifiers: array,
	onClick: func.isRequired,
	isFloatMode: bool
};

export default BigPlayButton;
