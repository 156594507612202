export const getVideoEndStatus = ({
	isEnded,
	isEpisode,
	isFloatMode,
	endMarginActionCancel,
	isEpisodeAvaiable
}) => {
	const isNextEpisode =
		isEnded &&
		isEpisode &&
		!isFloatMode &&
		!endMarginActionCancel &&
		isEpisodeAvaiable;

	const isVideoEnded =
		!isFloatMode &&
		isEnded &&
		isEpisode &&
		!endMarginActionCancel &&
		!isEpisodeAvaiable;

	return {
		isNextEpisode,
		isVideoEnded
	};
};
