import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styled components
import { SearchInputStyled, InputStyled } from './styles';

const SearchInput = ({ handleOnChange, query, ...props }) => {
	const { t } = useTranslation();
	return (
		<SearchInputStyled>
			<InputStyled
				name="query"
				type="text"
				placeholder={t('common_header_nav_search_placeholder')}
				onChange={handleOnChange}
				value={query}
				{...props}
			/>
		</SearchInputStyled>
	);
};

SearchInput.defaultProps = {
	autoFocus: true
};

SearchInput.propTypes = {
	handleOnChange: PropTypes.func.isRequired,
	query: PropTypes.string.isRequired
};

export default SearchInput;
