import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import assets
import NoImage from 'components/views/vod/images/placeholder.svg';

// Import styles
import { Figure, Image, Caption } from './styles';

const BannerImage = ({ coverImage, title, bannerHeight }) => {
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);
	const [isImageError, setImageError] = useState(false);

	const handleImageError = () => setImageError(true);

	const imageUrl = isImageError ? NoImage : `${coverImage}=w1920-h400-c`;
	const isPlaceholder = imageUrl === NoImage;

	return (
		<Figure bannerHeight={bannerHeight}>
			<Image
				src={imageUrl}
				alt={title}
				isPlaceholder={isPlaceholder}
				onError={handleImageError}
				isContrast={isContrast}
			/>
			<Caption>{title}</Caption>
		</Figure>
	);
};

BannerImage.propTypes = {
	coverImage: PropTypes.string,
	title: PropTypes.string,
	bannerHeight: PropTypes.number
};

export default BannerImage;
