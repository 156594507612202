import React from 'react';
import { func, array } from 'prop-types';

// Import styles
import { Body } from 'components/elements/molecules/table/styles';

// Import components
import { TableRow } from '.';

const TableBody = ({ getTableBodyProps, rows, prepareRow }) => (
	<Body className="blueonline-table__body" {...getTableBodyProps()}>
		{rows.map((row) => (
			<TableRow key={row.id} row={row} prepareRow={prepareRow} />
		))}
	</Body>
);

TableBody.propTypes = {
	getTableBodyProps: func.isRequired,
	prepareRow: func.isRequired,
	rows: array.isRequired
};

export default TableBody;
