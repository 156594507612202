import React from 'react';
import { func } from 'prop-types';
import { useSelector } from 'react-redux';

// Import styled components
import { StyledMenu } from './styles';
import LanguageDropDownMobile from '../LanguageDropDownMobile/LanguageDropDownMobile';
// Import icons
import { ReactComponent as Arrow } from 'assets/icons/icon_mobile_menu_arrow_down.svg';

// Import components
import UserMenu from '../UserMenu';
import NavMenu from '../NavMenu';
import AuthenticationButton from '../AuthenticationButton';

// Import utilities
import { useAuth } from 'components/utilities';

const MobileMenu = ({ toggle }) => {
	const { menuLinks } = useSelector(({ menu }) => menu);
	const { isAuth } = useAuth();

	return (
		<StyledMenu mode="inline" expandIcon={<Arrow />}>
			<NavMenu menuLinks={menuLinks} toggle={toggle} />
			{isAuth && <UserMenu toggle={toggle} />}
			<LanguageDropDownMobile toggle={toggle} />
			<AuthenticationButton toggle={toggle} />
		</StyledMenu>
	);
};

MobileMenu.propTypes = {
	toggle: func.isRequired
};

export default MobileMenu;
