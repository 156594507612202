import styled, { css } from 'styled-components';

// Import components
import { Heading as HeadingElement } from 'components/elements/atoms';

//Import styles
import { customMinResponsive, size } from 'components/styles';

const getContainerSpaces = ({ isUserProfile }) => (isUserProfile ? 0 : 5.8);

const Container = styled.div`
	flex: 1;
	padding: ${getContainerSpaces}rem 2rem 0 2rem;
	margin-bottom: ${getContainerSpaces}rem;

	${customMinResponsive(
		size.medium,
		css`
			padding: ${getContainerSpaces}rem 0 0 0;
		`
	)}
`;

const Content = styled.div`
	color: ${({ theme }) => theme.black};

	& > * {
		color: ${({ theme }) => theme.black} !important;
	}

	ul {
		padding-left: 4.2rem;
		margin-bottom: 2rem;
	}

	ol {
		padding-left: 4.2rem;
		margin-bottom: 0.9rem;
	}
`;

const Wrapper = styled.div`
	position: relative;
	min-height: 20rem;
`;

const Heading = styled(HeadingElement.First)`
	line-height: 1;
	margin-top: 0;
	margin-bottom: 2.4rem;
	color: ${({ theme }) => theme.black};
`;

const UserProfileHeading = styled(HeadingElement.First)`
	font-size: ${({ theme }) => theme.fontSize.set('s', 0.2)}rem;
	margin: 0 0 1.8rem 0;
	font-weight: ${({ theme }) => theme.font.medium};
	color: ${({ theme: { user } }) => user.topHeading};

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.md}rem;
			margin: 0;
		`
	)}
`;

export { Wrapper, Heading, Content, Container, UserProfileHeading };
