// Import helpers
import { PRODUCTS_TYPES } from 'helpers/variables';

const { SERIES, SEASON, EPISODE, ARTICLE, VOD, PASS, PACKET } = PRODUCTS_TYPES;

// ----- VOD SECTION BUTTON -----
// vod section button background when hover
export const backgroundHovered = ({
	vodSectionButton: { backgroundHovered }
}) => [
	{ offset: 0, color: backgroundHovered.color1 },
	{ offset: 100, color: backgroundHovered.color2 }
];

// vod section button border
export const borderGradient = ({ vodSectionButton: { borderGradient } }) => [
	{ offset: 0, color: borderGradient.color1 },
	{ offset: 50, color: borderGradient.color2 },
	{ offset: 100, color: borderGradient.color3 }
];

// ----- PAYMENT BUTTON -----
export const getPaymentButtonTranslation = ({
	subtype,
	isSubscriptionUpdate
}) => {
	switch (subtype) {
		case SERIES:
		case SEASON:
		case EPISODE:
		case PASS:
			return `vod_buttons_${subtype}_purchase`;

		case PACKET:
			return isSubscriptionUpdate
				? 'vod_buttons_subscription_resume'
				: 'vod_buttons_subscription';

		default:
			return 'payment_buy';
	}
};

export const getDetailsButtonTranslation = ({ type, is_audio }) => {
	switch (type) {
		case ARTICLE:
			return 'vod_buttons_read';

		case VOD:
			return is_audio ? 'vod_buttons_listen' : 'vod_buttons_play';

		case PASS:
			return 'vod_buttons_pass';

		default:
			return 'vod_buttons_play';
	}
};
