import React from 'react';

// Import components
import ShakaPlayerFocus from './ShakaPlayerFocus';
import TheoPlayerFocus from './TheoPlayerFocus';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

const PlayerFocus = (props) => {
	switch (DEFAULT_PLAYER) {
		case THEO_PLAYER:
			return <TheoPlayerFocus {...props} />;

		default:
			return <ShakaPlayerFocus {...props} />;
	}
};

export default PlayerFocus;
