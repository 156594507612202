// -----------------  FETCH PROMOTIONS -----------------
export const FETCH_PROMOTIONS_LOADING = 'FETCH_PROMOTIONS_LOADING';
export const FETCH_PROMOTIONS_SUCCESS = 'FETCH_PROMOTIONS_SUCCESS';
export const FETCH_PROMOTIONS_ERROR = 'FETCH_PROMOTIONS_ERROR';

// -----------------  FETCH VOUCHER PROMOTIONS -----------------
export const FETCH_VOUCHER_PROMOTIONS_LOADING =
	'FETCH_VOUCHER_PROMOTIONS_LOADING';
export const FETCH_VOUCHER_PROMOTIONS_SUCCESS =
	'FETCH_VOUCHER_PROMOTIONS_SUCCESS';
export const FETCH_VOUCHER_PROMOTIONS_ERROR = 'FETCH_VOUCHER_PROMOTIONS_ERROR';

// -----------------  CLEAR VOUCHER PROMOTIONS -----------------
export const CLEAR_VOUCHER_DATA = 'CLEAR_VOUCHER_DATA';

// -----------------  FINAL PROMOTION -----------------
export const SET_FINAL_PROMOTION = 'SET_FINAL_PROMOTION';
export const CLEAR_FINAL_PROMOTION = 'CLEAR_FINAL_PROMOTION';
