import Cookie from 'js-cookie';

// Import variables
import {
	COOKIES_AFFILIATE_CLIENT,
	COOKIES_AFFILIATE_CLIENT_EXPIRES
} from 'helpers/variables';

export const saveAffiliateClientData = (affiliateID) => {
	Cookie.set(COOKIES_AFFILIATE_CLIENT, affiliateID, {
		expires: COOKIES_AFFILIATE_CLIENT_EXPIRES
	});
};

export const getAffiliateClientQuery = () => {
	const affiliateClientID = Cookie.get(COOKIES_AFFILIATE_CLIENT) || '';
	const query = `?aff_code=${affiliateClientID}`;

	return query;
};
