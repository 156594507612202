import { useContext } from 'react';
import { PlayerContext } from './PlayerProvider';

const usePlayerContext = () => {
	const context = useContext(PlayerContext);

	return context;
};

export default usePlayerContext;
