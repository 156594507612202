import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { customMinResponsive, size, focusBasic } from 'components/styles';

// Import icons
import { ReactComponent as IconInfo } from 'assets/icons/ic_info.svg';

// Import modifiers
import {
	BANNER_WRAPPER_CONFIG,
	BANNER_CONTENT_CONFIG,
	BANNER_ARROWS_CONFIG
} from './modifiers';

// Import variables
import { DESCRIPTION_LINE_HEIGHT_LIMIT } from 'helpers/variables';
import { WCAG_PROPORTION_MULTIPLIER } from '../helpers';

const { LINE_HEIGHT_DESKTOP } = DESCRIPTION_LINE_HEIGHT_LIMIT;

const dotHover = css`
	&:focus,
	&:hover {
		background-color: ${({ theme }) =>
			theme.isContrast
				? theme.contrast.secondaryBgFocus
				: theme.primaryBlueLight};
		border-color: ${({ theme }) =>
			theme.isContrast
				? theme.contrast.secondaryBgFocus
				: theme.primaryBlueLight};
	}

	&:focus-visible {
		${focusBasic({ isWhiteBg: true })}
	}
`;

export const Wrapper = styled.section`
	position: relative;
	background-color: ${({ theme }) => theme.primaryBgBlack};
	margin-bottom: ${({ isLowBanner }) => (isLowBanner ? 6 : 0)}rem;

	width: 100%;
	aspect-ratio: ${({ theme, mobileRatio }) =>
		theme.isWCAG
			? mobileRatio * (1 / WCAG_PROPORTION_MULTIPLIER)
			: mobileRatio};

	${customMinResponsive(
		size.medium,
		css`
			aspect-ratio: ${({ theme, desktopRatio }) =>
				theme.isWCAG
					? desktopRatio * (1 / WCAG_PROPORTION_MULTIPLIER)
					: desktopRatio};
		`
	)};

	.slick-slider,
	.slick-list,
	.slick-track,
	.slick-slide > div {
		height: 100%;
	}

	.slick-dots {
		width: auto;
		display: grid;
		gap: 0.8rem;
		padding: 0.8rem;
		grid-auto-flow: column;
		justify-content: center;
		align-items: center;

		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		bottom: ${({ isLowBanner }) => (isLowBanner ? 0 : 5)}%;

		${customMinResponsive(
			size.medium,
			css`
				bottom: ${({ isLowBanner }) => (isLowBanner ? 2 : 10)}%;
			`
		)}

		background-color: ${({ theme, isContrast }) =>
			isContrast ? theme.white : `${theme.blackBg}B3`}; // B3 -> 70% opacity
		border-radius: 4rem;
		backdrop-filter: blur(0.5rem);

		li {
			padding: 0;
			margin: 0;
			width: auto;
			height: auto;
		}

		.slider_dot_btn {
			height: 1rem;
			width: 1rem;
			border-radius: 50%;
			border: 1px solid
				${({ theme, isContrast }) =>
					isContrast ? `${theme.blackBg}B3` : theme.graySoft};

			${customMinResponsive(
				size.medium,
				css`
					height: 1.4rem;
					width: 1.4rem;
					border-width: 1.5px;
				`
			)}
			${dotHover}
		}

		.slick-active > .slider_dot_btn {
			background-color: ${({ theme, isContrast }) =>
				isContrast ? `${theme.blackBg}B3` : theme.graySoft};
			${dotHover}
		}
	}
`;

export const SliderDots = styled.ul`
	list-style: none;
	z-index: 2;
`;

export const BannerWrapper = styled.div`
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 1.7rem;
	z-index: 2;

	background: ${({ theme, cover, isContrast, hasContent }) =>
		isContrast && hasContent
			? theme.primaryBgBlack
			: `${theme.primaryBgBlack} ${cover} no-repeat scroll center top/cover`};

	filter: ${({ hasContent, isContrast }) =>
		isContrast && !hasContent ? 'grayscale()' : 'none'};

	&:before,
	&:after {
		display: block;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		content: '';
	}

	:is(a) {
		height: 100% !important;
	}

	:is(a):hover,
	:is(a):focus {
		&:before {
			z-index: 3;
			outline: 0.3rem solid ${({ theme }) => theme.primaryBlueLight};
			outline-offset: -0.3rem;
		}
	}

	${applyStyleModifiers(BANNER_ARROWS_CONFIG)};
	${applyStyleModifiers(BANNER_WRAPPER_CONFIG)};
`;

export const BannerContent = styled.div`
	z-index: 2;
	position: relative;
	width: 100%;

	${applyStyleModifiers(BANNER_CONTENT_CONFIG)}
`;

export const Title = styled.h2`
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.set('md', 0.2)}rem;
	line-height: 2.5rem;
	margin-bottom: ${({ theme }) => (theme.isWCAG ? 0 : '1.9rem')} !important;

	${customMinResponsive(
		size.large,
		css`
			font-size: ${({ theme }) => theme.fontSize.xl}rem;
			line-height: 5.7rem;
			margin-bottom: ${({ theme }) => (theme.isWCAG ? 0 : '3rem')} !important;
		`
	)}
`;

export const Description = styled.p`
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	overflow: hidden;
	line-height: 19px;
	max-height: ${19 * 6}px;
	margin-bottom: 1.9rem;

	${customMinResponsive(
		size.large,
		css`
			font-size: ${({ theme }) => theme.fontSize.md}rem;
			line-height: ${LINE_HEIGHT_DESKTOP}px;
			max-height: ${LINE_HEIGHT_DESKTOP * 10}rem;
			margin-bottom: 3rem;

			${({ theme: { isWCAG } }) =>
				isWCAG &&
				css`
					max-height: 30rem !important;
					overflow-y: auto;
					margin-top: 2em;
				`}
		`
	)}
`;

export const InfoIcon = styled(IconInfo)`
	margin-right: 0.8rem;
	height: 100%;
`;

export const Dot = styled.button`
	background-color: transparent;

	&::before,
	&::after {
		display: none;
	}
`;

export const Gradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: ${({ zIndex }) => zIndex};

	&:before,
	&:after {
		display: block;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
	}

	${({ hasRadialGradient, theme }) =>
		hasRadialGradient &&
		css`
			&:before {
				background: radial-gradient(
					circle at top left,
					${theme.primaryBgBlack},
					${theme.transparent} 50%
				);
			}
		`}

	${({ hasBottomGradient, theme }) =>
		hasBottomGradient &&
		css`
			&:after {
				background: linear-gradient(
					0deg,
					${theme.primaryBgBlack} 0%,
					${theme.primaryBgBlack} 5%,
					${theme.transparent} 30%,
					${theme.transparent} 100%
				);
			}
		`}
`;
