import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useInfoTabButtons from '../../../views/ProductDetails/Layout/Info/buttons/useInfoTabButtons';

// Import helpers
import {
	PRODUCT_TYPES_MAPPED,
	checkProductSubtype,
	urlTranslationTypes,
	checkIsWithinTimePeriod
} from 'helpers';
// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import utilities
import { useProductLimits } from 'components/utilities';

// Import helpers
import { PRODUCT_LIMIT_TYPES, getRenderAsLink } from 'helpers';
import useLiveEventInfo from 'components/views/vod/views/ProductDetails/Layout/Info/ProductInfo/components/LiveEventInfo/useLiveEventInfo.js';

const { VIEWS } = PRODUCT_LIMIT_TYPES;
const { series, movie } = urlTranslationTypes;

const getTranslationKey = ({ isAudio, isLiveEvent, hasEventEnded }) => {
	if (isAudio) return 'vod_buttons_listen';
	if (isLiveEvent && hasEventEnded) return 'live_buttons_broadcast_ended';
	return 'vod_buttons_play';
};

const usePlayButton = ({ uuid, product, disabled }) => {
	const { t } = useTranslation();
	const { type: productType } = useParams();

	const {
		is_available: isAvailable,
		since,
		is_audio: isAudio,
		till,
		uuid: productUuid
	} = product;

	const { isLimitExceeded, limitMessage, limitCounter } = useProductLimits({
		product,
		limitType: VIEWS
	});
	const { isSeries } = checkProductSubtype(PRODUCT_TYPES_MAPPED[productType]);
	const { isLiveEvent } = useInfoTabButtons();
	const { hasEventEnded } = useLiveEventInfo();
	const type = isSeries ? series : movie;

	const linkRedirect = `${APP_CORE_PATHS.WATCH}/${type}/${uuid || productUuid}`;
	const translationKey = getTranslationKey({
		isAudio,
		isLiveEvent,
		hasEventEnded
	});
	const buttonText = isLimitExceeded
		? limitMessage
		: `${t(translationKey)}${limitCounter}`;

	const isWithinTimePeriod = checkIsWithinTimePeriod({ since, till });

	const isAvailableToPlay =
		!isLimitExceeded && (isWithinTimePeriod || isAvailable);

	const renderAs = disabled ? 'button' : getRenderAsLink(isAvailableToPlay);

	return {
		linkRedirect,
		buttonText,
		isAvailableToPlay,
		renderAs
	};
};

export default usePlayButton;
