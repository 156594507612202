import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, object } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// Import components
import { CommonButton } from 'components/elements';

// Import styles
import {
	Wrapper,
	ButtonClose,
	IconCloseStyled,
	Header,
	Description,
	ButtonsWrapper
} from './styles';

// Import helpers
import { COOKIES_MODAL_TYPES } from 'helpers/cookies';

const { BANNER } = COOKIES_MODAL_TYPES;

const CookieBanner = ({
	messageToggle,
	toggleCookies,
	acceptAllCookies,
	document
}) => {
	const { t } = useTranslation();

	const title = document?.title;
	const content = document?.content;

	return (
		<Wrapper>
			<ButtonClose onClick={messageToggle}>
				<IconCloseStyled aria-hidden="true" />
			</ButtonClose>
			<Header>{title}</Header>
			<Description>{ReactHtmlParser(content)}</Description>
			<ButtonsWrapper>
				<CommonButton onClick={toggleCookies}>
					{t('common_cookie_btn_customize')}
				</CommonButton>

				<CommonButton isPrime onClick={acceptAllCookies(BANNER)}>
					{t('common_cookie_btn_accept')}
				</CommonButton>
			</ButtonsWrapper>
		</Wrapper>
	);
};

CookieBanner.propTypes = {
	messageToggle: func.isRequired,
	toggleCookies: func.isRequired,
	acceptAllCookies: func.isRequired,
	document: object
};

export default CookieBanner;
