import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFavourite, addFavourite } from 'store/actions';

// Import helpers
import { checkIsFavourite } from 'helpers/epg';

// Import icons
import { ReactComponent as FavoriteIconFull } from 'assets/icons/ic_favorite_full.svg';
import { ReactComponent as FavoriteIcon } from 'assets/icons/ic_favorite.svg';

// Import components
import { Button } from 'components/elements';

const FavouriteButton = ({ channelID }) => {
	const dispatch = useDispatch();
	const favourites = useSelector((store) => store.favourites.data);

	const isFavourite = checkIsFavourite(favourites, channelID);

	return isFavourite ? (
		<ButtonStyled onClick={() => deleteFavourite(channelID)(dispatch)}>
			<FavIconFullStyled />
		</ButtonStyled>
	) : (
		<ButtonStyled onClick={() => addFavourite(channelID)(dispatch)}>
			<FavIconStyled />
		</ButtonStyled>
	);
};

const ButtonStyled = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
`;

const FavIconStyled = styled(FavoriteIcon)`
	height: 2.2rem;
	width: 2.2rem;
`;

const FavIconFullStyled = styled(FavoriteIconFull)`
	height: 1.9rem;
	width: 1.9rem;
	path {
		fill: ${({ theme }) => theme.primary};
	}
`;

FavouriteButton.propTypes = {
	channelID: PropTypes.string.isRequired
};

export default FavouriteButton;
