import React from 'react';
import { object, string } from 'prop-types';

// Import providers
import PlayerProvider from '../PlayerProvider';

// Import styles
import { Wrapper, Fog } from '../styles';

// Import components
import { Loader } from 'components/elements';
import PlayerContent from '../PlayerContent';
import { LiveBadge } from './LiveBadge';
import PlayerError from '../PlayerError';

// Import helpers
import { SHAKA_PLAYER_ERRORS } from 'helpers/player';

const { SHAKA_PLAYER_ERROR_MESSAGE } = SHAKA_PLAYER_ERRORS;

const VideoBannerLive = ({ vidoBanners, name }) => {
	const {
		isLoading,
		isError,
		data,
		currentProduct,
		isContrast,
		isMuted,
		isActive,
		nextProduct,
		previousProduct,
		deleteVideoSession,
		updateVideoSession,
		toggleSound,
		onFocus,
		onBlur,
		onMouseOver,
		onMouseLeave,
		hasNextProduct,
		hasPreviousProduct
	} = vidoBanners;

	return (
		<Wrapper
			onFocus={onFocus}
			onBlur={onBlur}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
		>
			{isContrast && <Fog />}
			<LiveBadge />

			{isLoading ? (
				<Loader zIndex={900} position="absolute" background={false} />
			) : isError ? (
				<PlayerError message={SHAKA_PLAYER_ERROR_MESSAGE} />
			) : (
				<PlayerProvider
					data={data}
					nextProduct={nextProduct}
					isMuted={isMuted}
					isActive={isActive}
					deleteVideoSession={deleteVideoSession}
					updateVideoSession={updateVideoSession}
					toggleSound={toggleSound}
				>
					<PlayerContent
						currentProduct={currentProduct}
						nextProduct={nextProduct}
						previousProduct={previousProduct}
						hasNextProduct={hasNextProduct}
						hasPreviousProduct={hasPreviousProduct}
						name={name}
						showArrows={false}
					/>
				</PlayerProvider>
			)}
		</Wrapper>
	);
};

VideoBannerLive.propTypes = {
	vidoBanners: object.isRequired,
	name: string.isRequired
};

export default VideoBannerLive;
