import styled, { css } from 'styled-components';

// Import styles
import { size, customResponsive } from 'components/styles';

export const Wrapper = styled.div`
	position: absolute;
	z-index: 9999;

	${({ isFloatMode }) =>
		isFloatMode
			? css`
					right: 1rem;
					bottom: 1rem;
			  `
			: css`
					left: 3rem;
					bottom: 11rem;

					${customResponsive(
						size.small,
						css`
							left: 1rem;
							bottom: 7rem;
						`
					)}
			  `}
`;
