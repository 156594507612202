import styled, { css } from 'styled-components';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

// Import icons
import { ReactComponent as IconFacebookSVG } from 'assets/icons/ic_facebook.svg';
import { ReactComponent as IconTwitterSVG } from 'assets/icons/ic_twitter.svg';

// Import styles
import {
	flex,
	customMinResponsive,
	productTextColor,
	size,
	setIconColor
} from 'components/styles';

const shareButtonStyles = css`
	height: 2.2rem;
	width: 2.2rem;
	margin-right: 1rem;
	border-radius: 50%;

	${({ theme: { product }, light }) =>
		css`
			${light === 'true' && setIconColor(product.lightBackground.color)}

			&:focus-visible {
				${light === 'true'
					? setIconColor(product.lightBackground.linkHover)
					: setIconColor(product.coverBackground.linkHover)}
			}

			&:hover {
				outline: none;

				${light === 'true'
					? setIconColor(product.lightBackground.linkHover)
					: setIconColor(product.coverBackground.linkHover)}
			}
		`}

	${customMinResponsive(
		size.medium,
		css`
			height: 3.1rem;
			width: 3.1rem;
		`
	)};
`;

const commonSVGStyles = css`
	height: inherit;
	width: inherit;

	g {
		g {
			fill: ${({ theme }) => theme.product.share.iconColor}
			transition: fill 0.1s ease-in-out;
		}
	}
`;

export const HrLine = styled.hr`
	width: calc(100% - 1.7rem);
	position: relative;
	z-index: 2;

	${({ theme, isLightBackground }) =>
		isLightBackground &&
		css`
			background-color: ${theme.product.lightBackground.color};
		`}

	${customMinResponsive(
		size.medium,
		css`
			width: ${({ isLightBackground }) =>
				isLightBackground ? 'calc(100% - 8.5vw)' : '45%'};
		`
	)};
`;

export const ShareSection = styled.section`
	${flex({ jc: 'flex-start' })}
	margin: 0.5rem 1.7rem 5.2rem 0;
	position: relative;
	z-index: 2;

	${customMinResponsive(
		size.medium,
		css`
			margin: 0.5rem 4rem 10.5rem 0;
		`
	)};
`;

export const ShareText = styled.p`
	margin-right: 0.6rem;
	font-size: ${({ theme }) => theme.fontSize.share.mobile}rem;
	line-height: 2.1rem;
	${productTextColor}
	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.share.desktop}rem;
			margin-right: 1.8rem;
		`
	)};
`;

export const StyledFacebookShareButton = styled(FacebookShareButton)`
	${shareButtonStyles}
`;

export const StyledTwitterShareButton = styled(TwitterShareButton)`
	${shareButtonStyles}
`;

export const IconFacebook = styled(IconFacebookSVG)`
	${commonSVGStyles}
`;

export const IconTwitter = styled(IconTwitterSVG)`
	${commonSVGStyles}
`;
