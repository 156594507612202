import _ from 'lodash';

export const callAllFns =
	(...fns) =>
	(...args) =>
		fns.forEach((fn) => fn && fn(...args));

export const createQueryParams = (obj) => {
	const searchParams = new URLSearchParams(_.omitBy(obj, (value) => !value));
	const query = searchParams.toString();
	return query ? `?${query}` : '';
};

export const importIcon = (name) => {
	try {
		// eslint-disable-next-line no-undef
		return require(`../assets/icons/${name}.svg`);
	} catch (e) {
		return '';
	}
};
