import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPipAvailabe } from 'store/actions';

const usePip = ({ isReady = false } = {}) => {
	const dispatch = useDispatch();
	const { isPipOn } = useSelector((state) => state.pip);

	const videSelector = `video`;
	const video = () => document.querySelector(videSelector);

	React.useEffect(() => {
		let intervalPip = null;
		const IntervalTime = 1000;
		if (isReady) {
			// check if isReady and browser support picture-in-picture API
			const isPipAvailable = isReady && document.pictureInPictureEnabled;
			setPipAvailabe(isPipAvailable)(dispatch);

			// If pip is on,replace video and do not exit the pip window
			intervalPip = setInterval(() => {
				if (document.pictureInPictureElement && video().readyState > 0) {
					video().requestPictureInPicture();

					clearInterval(intervalPip);
				}
			}, IntervalTime);

			!isPipOn && clearInterval(intervalPip);
		}

		return () => {
			clearInterval(intervalPip);
			setPipAvailabe()(dispatch);
			exitPip();
		};
		/*eslint-disable-next-line  */
	}, [isReady]);

	const exitPip = () => {
		if (document.pictureInPictureElement) {
			document.exitPictureInPicture();
		}
	};

	const handleRequestPip = () => {
		if (!document.pictureInPictureElement) {
			video()
				.requestPictureInPicture()
				.catch(() => exitPip());
		} else {
			exitPip();
		}
	};

	return { requestPip: handleRequestPip, exitPip };
};

export default usePip;
