import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers
import { PAGE_META_TAGS } from 'helpers/meta_tags';

// Import images
import { WATERMARK_URL as COMMON_LOGO } from 'helpers/variables';

const { COMMON } = PAGE_META_TAGS;

const useHelemtMetaTags = ({
	title,
	description,
	keywords,
	image,
	isTranslationKey
}) => {
	const { t } = useTranslation();

	const setTranslation = (str) => (isTranslationKey ? t(str) : str);

	const titleMeta = title || COMMON.title;
	const nameMeta = title || COMMON.title;
	const descriptionMeta = description || COMMON.description;
	const keywordsMeta = keywords || COMMON.keywords;
	const imageMeta = image || COMMON_LOGO;

	return {
		titleMeta: setTranslation(titleMeta),
		nameMeta: setTranslation(nameMeta),
		descriptionMeta: setTranslation(descriptionMeta),
		keywordsMeta: setTranslation(keywordsMeta),
		imageMeta
	};
};
useHelemtMetaTags.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	keywords: PropTypes.string,
	image: PropTypes.string,
	isTranslationKey: PropTypes.bool
};

export default useHelemtMetaTags;
