import React from 'react';
import { array } from 'prop-types';
import { Link } from 'react-router-dom';

// Import utilities
import { Slider } from 'components/utilities';

// Import logic
import useBannerLogic from './useBannerLogic';

// Import styles
import { BannersSliderWrap, Item } from './styles';

// Import components
import BannerImage from './BannerImage';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';
import { sliderSettings, checkBannerUrl } from './helpers';
import { checkProductSubtype } from 'helpers';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

const { COVER } = PRODUCT_IMAGES_TYPES;

const Banners = ({ content }) => {
	const { containerRef, bannerHeight } = useBannerLogic();

	const renderBannerImage = ({ coverImage, title }) => (
		<BannerImage
			coverImage={coverImage}
			title={title}
			bannerHeight={bannerHeight}
		/>
	);

	const renderBanners = ({
		uuid,
		images,
		title,
		context: { url_pc },
		subtype
	}) => {
		const coverImage = getImage({ images, type: COVER });

		const { isBanner } = checkProductSubtype(subtype);

		if (!coverImage || !isBanner) return null;

		const { isExternalPath, urlPc } = checkBannerUrl(url_pc);

		return (
			<Item key={uuid}>
				{url_pc ? (
					<>
						{isExternalPath ? (
							<Link to={`/${urlPc.splice(1).join('/')}`}>
								{renderBannerImage({ coverImage, title })}
							</Link>
						) : (
							<a href={url_pc} target="_blank" rel="noopener noreferrer">
								{renderBannerImage({ coverImage, title })}
							</a>
						)}
					</>
				) : (
					renderBannerImage({ coverImage, title })
				)}
			</Item>
		);
	};

	return (
		<BannersSliderWrap ref={containerRef}>
			{bannerHeight && (
				<Slider defaultBtn={false} settings={sliderSettings}>
					{content.map(renderBanners)}
				</Slider>
			)}
		</BannersSliderWrap>
	);
};

Banners.propTypes = {
	content: array.isRequired
};

export default Banners;
