import React from 'react';
import { object, string, shape, bool, func } from 'prop-types';
import NumberFormat from 'react-number-format';

// Import components
import { Tooltip } from 'antd';

// Import styled components
import {
	Wrapper,
	FormError,
	InputWrapper,
	Label,
	ErrorWrapper,
	FieldInfo,
	InputStyled
} from '../styles';

const RegisterFormField = ({
	field,
	form: { touched, errors },
	placeholder,
	tooltipHintText,
	fieldInfo,
	styles,
	componentInfo: ComponentInfo,
	maskSymbol = null,
	inputFormat = null,
	required,
	...rest
}) => {
	const { name, value } = field;
	const isErrorVisible = touched[name] && errors[name];
	const errorText = errors[name] || '';

	const isInfo = !!ComponentInfo;
	const isFieldInfoVisible = !!fieldInfo;

	return (
		<Wrapper>
			<Tooltip
				title={tooltipHintText}
				visible={tooltipHintText}
				placement="right"
				overlayClassName="antd-customRegisterTooltip"
			>
				<InputWrapper isErrorVisible={isErrorVisible}>
					{maskSymbol ? (
						<NumberFormat
							mask={maskSymbol}
							format={inputFormat}
							className={`${
								touched[field.name] && errors[field.name] ? 'errors' : ''
							}`}
							name={name}
							placeholder={placeholder}
							customInput={InputStyled}
							{...field}
							{...rest}
						/>
					) : (
						<InputStyled
							id={name}
							aria-required="true"
							aria-invalid={isErrorVisible}
							aria-describedby={`${name}-alert`}
							className={`${isErrorVisible ? 'errors' : ''}`}
							{...field}
							{...rest}
						/>
					)}

					<Label
						backgroundColor={styles.label?.background}
						className={value && 'filled'}
						htmlFor={name}
						isErrorVisible={isErrorVisible}
					>
						{placeholder} {required && '*'}
					</Label>
				</InputWrapper>
			</Tooltip>
			<ErrorWrapper>
				{isInfo && <ComponentInfo />}
				<FormError
					isErrorVisible={isErrorVisible}
					role="alert"
					aria-atomic="true"
					id={`${name}-alert`}
				>
					{errorText}
				</FormError>
			</ErrorWrapper>
			{isFieldInfoVisible && <FieldInfo>{fieldInfo}</FieldInfo>}
		</Wrapper>
	);
};

RegisterFormField.defaultProps = {
	type: 'text',
	autoComplete: 'true',
	styles: {},
	isInfoTip: false,
	required: false
};

RegisterFormField.propTypes = {
	type: string.isRequired,
	field: object.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	placeholder: string.isRequired,
	autoComplete: string,
	tooltipHintText: string,
	fieldInfo: string,
	styles: object,
	isPasswordReminder: bool,
	componentInfo: func,
	maskSymbol: string,
	inputFormat: string,
	required: bool
};

export default RegisterFormField;
