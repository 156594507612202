// style for button hover

import styled, { css } from 'styled-components';

export const baseRootStyle = css`
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	text-decoration: none;
	position: relative;
	cursor: pointer;
	color: white;

	&:hover {
		color: white;
	}
`;

export const Content = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 15px;
	justify-items: center;
	align-items: center;
`;

export const ButtonContainer = styled.div.attrs(({ show }) => ({
	style: {
		opacity: show ? '1' : '0'
	}
}))`
	position: absolute;
	height: 100%;
	width: 100%;
	transition: opacity ease 150ms;
`;

export const backgroundDefault = ({
	gradientButton: { backgroundDefault }
}) => [
	{ offset: 0, color: backgroundDefault.color1 },
	{ offset: 39.63, color: backgroundDefault.color2 },
	{ offset: 67.28, color: backgroundDefault.color3 },
	{ offset: 100, color: backgroundDefault.color4 }
];

export const backgroundHovered = ({
	gradientButton: { backgroundHovered }
}) => [
	{ offset: 0, color: backgroundHovered.color1 },
	{ offset: 39.63, color: backgroundHovered.color2 },
	{ offset: 67.4, color: backgroundHovered.color3 },
	{ offset: 100, color: backgroundHovered.color4 }
];

export const borderDefault = ({ gradientButton: { borderDefault } }) => [
	{ offset: 0, color: borderDefault.color1 },
	{ offset: 34.37, color: borderDefault.color2 },
	{ offset: 100, color: borderDefault.color3 }
];

export const borderHovered = ({ gradientButton: { borderHovered } }) => [
	{ offset: 0, color: borderHovered.color1 },
	{ offset: 34.37, color: borderHovered.color2 },
	{ offset: 100, color: borderHovered.color2 }
];

// tv smart
export const smartBackgroundHovered = ({
	gradientButton: { smartBackgroundHovered }
}) => [
	{ offset: 0, color: smartBackgroundHovered.color1 },
	{ offset: 39.63, color: smartBackgroundHovered.color2 },
	{ offset: 67.4, color: smartBackgroundHovered.color3 },
	{ offset: 100, color: smartBackgroundHovered.color4 }
];

export const smartBorderHovered = ({
	gradientButton: { smartBorderHovered }
}) => [
	{ offset: 0, color: smartBorderHovered.color1 },
	{ offset: 34.37, color: smartBorderHovered.color2 },
	{ offset: 100, color: smartBorderHovered.color3 }
];
