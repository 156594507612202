import React from 'react';

// Import helpers
import { getTimeLineFormat } from 'helpers/index';

// Import utilities
import { useSafeMountDispatch } from 'components/utilities';

// Import components
import { Timer } from './styles';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const PlayerTimer = () => {
	const [time, setTime] = React.useState('00:00:00');
	const { currentTimeProgress } = usePlayerVodContext();
	const safeSetTime = useSafeMountDispatch(setTime);

	React.useEffect(() => {
		currentTimeProgress((currentTime) =>
			safeSetTime(getTimeLineFormat(currentTime))
		);

		// eslint-disable-next-line
	}, []);
	return <Timer>{time}</Timer>;
};

export default PlayerTimer;
