import * as types from 'store/actions/types';
import produce from 'immer';

// Import helpers
import { clearChannelsEpg } from 'store/reducers/epg/helpers';

const INITIAL_STATE = {
	data: { lives: [] },
	date: { isToday: true, since: '', till: '' },
	isLoaded: true,
	isError: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Set Epg channels *******************
			case types.SET_EPG_CHANNELS:
				draft.data = action.payload;
				return;
			// ******************* Fetch tv programs *******************
			case types.FETCH_EPG_PROGRAMS_LOADING:
				draft.isLoaded = false;
				draft.isError = false;
				draft.date.since = '';
				draft.date.till = '';
				draft.date.isToday = true;
				return;

			case types.FETCH_EPG_PROGRAMS_SUCCESS:
				draft.data = action.payload.data;
				draft.date.since = action.payload.since;
				draft.date.till = action.payload.till;
				draft.date.isToday = action.payload.isToday;
				draft.isLoaded = true;
				return;

			case types.FETCH_EPG_PROGRAMS_ERROR:
				draft.isError = true;
				return;

			// ******************* Clear tv programs *******************
			case types.CLEAR_EPG_PROGRAMS:
				draft.data.lives = clearChannelsEpg(draft.data.lives);
				draft.date.since = '';
				draft.date.till = '';
				draft.date.isToday = true;
				draft.isLoaded = true;
				draft.isError = false;
				return;

			default:
				return state;
		}
	});
