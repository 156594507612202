import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { BANNER_WRAPPER_CONFIG } from 'components/elements/molecules/slider_banners/styles/modifiers';
import { customMinResponsive, size } from 'components/styles';

export const BannerWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	padding: 0 2rem;

	${customMinResponsive(
		size.medium,
		css`
			padding: 0 4rem;
		`
	)}

	${applyStyleModifiers(BANNER_WRAPPER_CONFIG)}
`;
