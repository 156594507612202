import { useState, useEffect } from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';

const useProductTranslations = ({ data }) => {
	const [product, setProduct] = useState(data);

	const {
		i18n: { language }
	} = useTranslation();

	const handleGetTranslations = () => {
		const productTranslations = data?.translations[language] || {};
		setProduct({ ...data, ...productTranslations });
	};

	useEffect(() => {
		data?.translations ? handleGetTranslations() : setProduct(data);
		// eslint-disable-next-line
	}, [data?.uuid, language]);

	return { product };
};

useProductTranslations.propTypes = {
	data: object.isRequired
};

export default useProductTranslations;
