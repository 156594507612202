import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch, useStore } from 'react-redux';
import {
	fetchPlayerConfig,
	fetchMovieDetails,
	fetchProgramBroadcasts,
	fetchProgramDetails
} from 'store/actions';

// Import variables
import { PLAYER_SELECTORS, PRODUCT_TYPES } from 'helpers/variables';

// Import helpers
import { checkPlayerType, getSearchCategory, containsUUID } from 'helpers';

const { DETAILS, VOD } = PLAYER_SELECTORS;

/**
 * Custom hook used to disable parental control in player and error placeholders across app.
 * Used in header parental control button.
 */
const useParentalControlDisable = () => {
	// STATE  HOOKS
	const [playerType, setPlayerType] = useState(null);
	const [shouldFetchConfig, setShouldFetchConfig] = useState(false);

	// ROUTER HOOKS
	const { pathname } = useLocation();

	// REDUX HOOKS
	const { channelID: selectedProgramChannelID } = useSelector(
		({ selected_program }) => selected_program
	);
	const movieDetails = useSelector(({ movieDetails }) => movieDetails.data);
	const playerState = useSelector((state) => state[playerType]);
	const programDetailsChannelID = useSelector(
		({ program_details }) => program_details.data?.channel_uuid
	);
	const dispatch = useDispatch();
	const { getState } = useStore();

	// determine player error
	const isPlayerError = playerState?.error.isError;

	// get proper product id based on pathname
	const pathnameArray = pathname.split('/');
	const programID = pathnameArray[pathnameArray.length - 1];

	// VOD location bools
	const isVodLocation = pathnameArray[1] === PRODUCT_TYPES[1];
	const isVodDetailsLocation = isVodLocation && containsUUID(pathname);

	// determine player types
	const isVodPlayer = playerType === VOD;
	const isProgramDetailsPlayer = playerType === DETAILS;
	const isMovieDetailsData = movieDetails && movieDetails.uuid;

	// determine channel id based on player type (player location)
	// channel id is stored in Redux in two different places based on in which location user is
	const channelID = isProgramDetailsPlayer
		? programDetailsChannelID
		: selectedProgramChannelID;

	const handleFetchProgramDetails = (id) => {
		fetchProgramBroadcasts(id)(dispatch);
		fetchProgramDetails(id)(dispatch);
	};

	const handleFetchMovieDetails = async (
		productID,
		productType = PRODUCT_TYPES[1]
	) => {
		await fetchMovieDetails(productID, productType, dispatch);
	};

	const handleVodPlayerConfig = useCallback(async (productID) => {
		await fetchPlayerConfig({
			productID,
			type: VOD,
			videoType: PRODUCT_TYPES[1]
		})(dispatch, getState);
		// eslint-disable-next-line
	}, []);

	const getPlayerConfiguration = async () => {
		// when PC error on player
		if (playerType && isPlayerError) {
			if (isVodPlayer && isMovieDetailsData) {
				handleVodPlayerConfig();
			}
			// vod player and no movies details (fetch config once)
			else if (isVodPlayer && !isMovieDetailsData) {
				handleFetchMovieDetails(programID);
				setShouldFetchConfig(true);
			}
			// fetch player or player_details config
			else {
				fetchPlayerConfig({
					productID: channelID,
					type: playerType
				})(dispatch, getState);
			}
		}
		// when PC error on screen (error placeholder on screen and no error on player in Redux)
		else if (isProgramDetailsPlayer && !isPlayerError && programID) {
			handleFetchProgramDetails(programID);
		}
		// when PC error on screen in vod location without player (error placeholder on screen and no error on player in Redux)
		// condition added to prevent fetching config on main vod, series and for kids page
		// when toggling parental control
		else if (isVodDetailsLocation) {
			// get proper vod data for fetching action
			const vodID = pathnameArray[3];
			const pathnameType = pathnameArray[2];
			const vodSubtype = getSearchCategory(pathnameType);

			handleFetchMovieDetails(vodID, vodSubtype);
		}
	};

	useEffect(() => {
		const type = checkPlayerType(pathname);
		setPlayerType(type);
	}, [pathname]);

	useEffect(() => {
		if (shouldFetchConfig && isVodPlayer && isMovieDetailsData) {
			handleVodPlayerConfig(movieDetails.uuid);
			setShouldFetchConfig(false);
		}
		// eslint-disable-next-line
	}, [isMovieDetailsData, isVodPlayer, shouldFetchConfig]);

	return { getPlayerConfiguration };
};

export default useParentalControlDisable;
