// Import helpers

import { APP_CORE_PATHS } from './app';
import { DOCUMENTS_ALIASES } from 'helpers/variables.js';

const { USER } = APP_CORE_PATHS;
const { REGULATIONS, COOKIES_POLICY, PRIVACY_POLICY } = DOCUMENTS_ALIASES;
const USER_PROFILE_SUBPATHS = {
	PROFILE: 'profile',
	LANGUAGE: 'language',
	PARENTAL_CONTROL: 'parental-control',
	ACTIVE_SHOPPING: 'active-shopping',
	PURCHASE_HISTORY: 'purchase-history',
	SUBSCRIPTIONS: 'subscriptions',
	FAVOURITES: 'favourites',
	REMINDERS: 'reminders',
	REGULATION: `document/${REGULATIONS}`,
	POLICY: `document/${PRIVACY_POLICY}`,
	COOKIES_POLICY: `document/${COOKIES_POLICY}`,
	DOCUMENT: 'document/:alias'
};

export const USER_PROFILE_PATHS = {
	GENERAL: `/${USER}/${USER_PROFILE_SUBPATHS.PROFILE}`,
	LANGUAGE: `/${USER}/${USER_PROFILE_SUBPATHS.LANGUAGE}`,
	PARENTAL_CONTROL: `/${USER}/${USER_PROFILE_SUBPATHS.PARENTAL_CONTROL}`,
	PRODUCTS: `/${USER}/${USER_PROFILE_SUBPATHS.ACTIVE_SHOPPING}`,
	PURCHASE_HISTORY: `/${USER}/${USER_PROFILE_SUBPATHS.PURCHASE_HISTORY}`,
	SUBSCRIPTIONS: `/${USER}/${USER_PROFILE_SUBPATHS.SUBSCRIPTIONS}`,
	FAVOURITES: `/${USER}/${USER_PROFILE_SUBPATHS.FAVOURITES}`,
	REMINDERS: `/${USER}/${USER_PROFILE_SUBPATHS.REMINDERS}`,
	REGULATION: `/${USER}/${USER_PROFILE_SUBPATHS.REGULATION}`,
	POLICY: `/${USER}/${USER_PROFILE_SUBPATHS.POLICY}`,
	COOKIES_POLICY: `/${USER}/${USER_PROFILE_SUBPATHS.COOKIES_POLICY}`,
	DOCUMENT: `/${USER}/${USER_PROFILE_SUBPATHS.DOCUMENT}`
};
