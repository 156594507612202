// FETCH REMINDERS
export const FETCH_REMINDERS_LOADING = 'FETCH_REMINDERS_LOADING';
export const FETCH_REMINDERS_SUCCESS = 'FETCH_REMINDERS_SUCCESS';
export const FETCH_REMINDERS_ERROR = 'FETCH_REMINDERS_ERROR';
// ADD REMINDER
export const ADD_REMINDER_LOADING = 'ADD_REMINDER_LOADING';
export const ADD_REMINDER_ERROR = 'ADD_REMINDER_ERROR';
// DELETE REMINDER
export const DELETE_REMINDER_LOADING = 'DELETE_REMINDER_LOADING';
export const DELETE_REMINDER_ERROR = 'DELETE_REMINDER_ERROR';
