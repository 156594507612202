import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import { convertErrors, addCustomTranslationToErrors } from 'services/helpers';
import { checkForInvalidDataError } from 'store/helpers/error_status';

// ******************** SEND RESET EMAIL ********************
export const sendResetEmail = (email, setErrors) => async (dispatch) => {
	try {
		dispatch({
			type: types.SEND_RESET_EMAIL_LOADING
		});

		const url = '/resetPassword/init';
		await services.post(url, email);

		dispatch({
			type: types.SEND_RESET_EMAIL_SUCCESS
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Check if it's not invalid data error
		checkForInvalidDataError(error);

		dispatch({
			type: types.SEND_RESET_EMAIL_ERROR,
			payload: error.response
		});

		if (
			error.response?.data?.validator?.errors &&
			error.response.status === 422
		) {
			// convert errors from request to format that is accepted by Formik
			const errors = convertErrors(error.response.data.validator.errors);

			// In this case translate all data from request validator to custom text
			addCustomTranslationToErrors(errors, 'Nieprawidłowy format danych');

			//set errors in proper fields in Formik
			setErrors(errors);
		}
	}
};

// ******************** CHECK RESET PASSWORD HASH ********************
export const checkResetPasswordHash = (hash) => async (dispatch) => {
	try {
		dispatch({
			type: types.CHECK_RESET_PASSWORD_HASH_LOADING
		});

		const url = `/resetPassword/${hash}/checkStatus`;
		await services.post(url);

		dispatch({
			type: types.CHECK_RESET_PASSWORD_HASH_SUCCESS
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Check if it's not invalid data error
		checkForInvalidDataError(error);

		dispatch({
			type: types.CHECK_RESET_PASSWORD_HASH_ERROR,
			payload: error.response
		});
	}
};

// ******************** SEND NEW PASSWORDS ********************
export const sendNewPasswords = (passwords, hash, setErrors) => async (
	dispatch
) => {
	try {
		dispatch({
			type: types.SEND_NEW_PASSWORDS_LOADING
		});

		const url = `/resetPassword/${hash}`;
		await services.post(url, passwords);

		dispatch({
			type: types.SEND_NEW_PASSWORDS_SUCCESS
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// One exeption becouse error must be visible but there is
		// no translation to this message
		checkForInvalidDataError(error);

		dispatch({
			type: types.SEND_NEW_PASSWORDS_ERROR,
			payload: error.response
		});

		if (
			error.response?.data?.validator?.errors &&
			error.response.status === 422
		) {
			// convert errors from request to format that is accepted by Formik
			const errors = convertErrors(error.response.data.validator.errors);

			// In this case translate all data from request validator to custom text
			addCustomTranslationToErrors(errors, 'Nieprawidłowy format danych');

			//set errors in proper fields in Formik
			setErrors(errors);
		}
	}
};

// ------------ Clear state ------------
export const clearResetPasswordState = () => (dispatch) => {
	dispatch({
		type: types.CLEAR_RESET_PASSWORD_STATE
	});
};
