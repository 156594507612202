import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const StyledNav = styled.nav`
	display: none
		${customMinResponsive(
			size.medium,
			css`
				display: block;
				position: fixed;
				top: 0;
				left: -10001px;
				z-index: 99999;
				a {
					color: ${({ theme }) => theme.body.tabNav.color};
					text-align: center;
					&:focus-visible {
						top: 1.75rem;
						left: 10016px;
						position: absolute;
						display: block;
						width: 25rem;
						padding: 1.5rem;
						border-radius: 0.6rem;
						border: 3px solid ${({ theme }) => theme.body.tabNav.borderColor};
						box-shadow: 0 0 4px ${({ theme }) => theme.body.tabNav.shadowColor};
						background-color: ${({ theme }) => theme.body.tabNav.bg};
					}
				}
			`
		)};
`;
