import React from 'react';
import { number, array, func } from 'prop-types';

// Import styles
import { Dot } from './styles';

const SliderDot = ({ index, content, changeSlide }) => {
	const { title } = content[index];

	const handleClick = () => changeSlide(index);

	return (
		<Dot
			title={title}
			ariaLabel={title}
			onClick={handleClick}
			className="slider_dot_btn"
		/>
	);
};

SliderDot.propTypes = {
	index: number.isRequired,
	content: array.isRequired,
	changeSlide: func.isRequired
};

export default SliderDot;
