import React from 'react';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import { PlayerElements as Player, Button } from 'components/elements';
import {
	Box,
	WrapperStyled,
	Bg,
	Content,
	HeadingStyled,
	Setting,
	IconSettingsStyled,
	IconTriangleStyled,
	ProfileStyled
} from './styles';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const SettingsController = () => {
	const {
		profiles,
		selectedProfile,
		setProfile,
		subtitles,
		selectedSubtitle,
		setSubtitle,
		languages,
		selectedLanguage,
		setLanguage,
		isSafari,
		floatMode
	} = usePlayerVodContext();

	const { t } = useTranslation();

	const { isFloatMode } = floatMode;

	// Set profile in player provider
	const handleSetProfile = (values) => () => setProfile(values);
	const handleSetSubtitle = (id) => () => setSubtitle(id);
	const handleSetLanguage = (id) => () => setLanguage(id);

	/* eslint-disable react/prop-types */
	const renderProfile = ({ id, height, bandwidth }) => (
		<ProfileStyled
			onClick={handleSetProfile({ id, bandwidth })}
			profile={id}
			selected={selectedProfile}
			key={id}
		>
			<Player.IconTick /> <span>{`${height}p`}</span>
		</ProfileStyled>
	);
	/* eslint-disable react/prop-types */

	/* eslint-disable react/prop-types */
	const renderSubtitle = ({ id, language }) => (
		<ProfileStyled
			onClick={handleSetSubtitle(language)}
			profile={language}
			selected={selectedSubtitle}
			key={id}
		>
			<Player.IconTick /> <span>{language.toUpperCase()}</span>
		</ProfileStyled>
	);
	/* eslint-disable react/prop-types */

	const renderLanguage = (language, index) => (
		<ProfileStyled
			onClick={handleSetLanguage(language)}
			profile={language}
			selected={selectedLanguage}
			key={index}
		>
			<Player.IconTick /> <span>{language.toUpperCase()}</span>
		</ProfileStyled>
	);

	const hasVideoProfile = profiles && selectedLanguage;

	return (
		<WrapperStyled>
			<Button
				modifiers={['icon', 'playerIconHover']}
				title={t('common_player_settings_title')}
			>
				<IconSettingsStyled
					aria-hidden="true"
					title={t('common_player_settings_title')}
				/>
			</Button>

			<Box isFloatMode={isFloatMode}>
				<Bg />
				<IconTriangleStyled />
				<Content isFloatMode={isFloatMode}>
					{languages.length > 1 && (
						<Setting>
							<HeadingStyled isFloatMode={isFloatMode}>
								{t('player_settings_language')}
							</HeadingStyled>
							<Player.Profiles>{languages.map(renderLanguage)}</Player.Profiles>
						</Setting>
					)}
					{subtitles.length > 0 && (
						<Setting>
							<HeadingStyled isFloatMode={isFloatMode}>
								{t('player_settings_subtitles')}
							</HeadingStyled>
							<Player.Profiles>
								<ProfileStyled
									onClick={handleSetSubtitle('disabled')}
									profile="disabled"
									selected={selectedSubtitle}
								>
									<Player.IconTick />{' '}
									<span>{t('player_settings_subtitles_disabled')}</span>
								</ProfileStyled>

								{subtitles.map(renderSubtitle)}
							</Player.Profiles>
						</Setting>
					)}

					<Setting>
						<HeadingStyled isFloatMode={isFloatMode}>
							{t('player_settings_profiles')}
						</HeadingStyled>
						<Player.Profiles>
							<ProfileStyled
								onClick={handleSetProfile({ id: 'auto' })}
								profile="auto"
								selected={selectedProfile}
							>
								<Player.IconTick /> <span>auto</span>
							</ProfileStyled>

							{hasVideoProfile &&
								!isSafari &&
								profiles[selectedLanguage].map(renderProfile)}
						</Player.Profiles>
					</Setting>
				</Content>
			</Box>
		</WrapperStyled>
	);
};

export default SettingsController;
