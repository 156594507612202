import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isAvailable: false,
	isPipOn: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************** SET IS PIP AVAILABLE ********************
			case types.SET_IS_PIP_AVAILABLE:
				draft.isAvailable = action.payload;
				return;

			// ******************** SET IS PIP ON ********************
			case types.SET_IS_PIP_ON:
				draft.isPipOn = action.payload;
				return;

			default:
				return state;
		}
	});
