import styled, { css } from 'styled-components';
import { size, flex, customMinResponsive } from 'components/styles';
import NavTemplate from '../nav_template';
// Import styles

export const Wrapper = styled.div`
	flex: 1;
	${flex({ jc: 'space-between', ai: 'flex' })};
`;

export const Container = styled.div`
	${flex()};
`;

export const MenusContainer = styled.div`
	background: ${({ theme }) => theme.body.nav.menuMobileBgColor};
	width: 100%;
	height: 100vh;
	z-index: 999;
	overflow: hidden;
	position: absolute;

	${customMinResponsive(
		size.medium,

		css`
			background: rgba(0, 0, 0, 0.6);
		`
	)}
`;

const transitionIn = css`
	transition: opacity 0.4s;
`;

const transitionOut = css`
	transition: opacity 0.1s;
`;

export const MobileWrapper = styled.div`
	visibility: ${({ intersection }) => (intersection ? 'visible' : 'hidden')};
	opacity: ${({ intersection }) => (intersection ? 1 : 0)};
	position: absolute;
	top: 0;
	width: 100vw;
	margin-right: 24px;
	${({ intersection }) => (intersection ? transitionIn : transitionOut)}
`;

export const MobileNavTemplate = styled(NavTemplate)`
	border-bottom: 1px solid ${({ theme }) => theme.body.nav.borderColor};
`;
