import { useSelector, useDispatch } from 'react-redux';
import { clearInstitution } from 'store/actions';

export const useSearchInstitutions = (close) => {
	const institutionUuid = useSelector(
		({ institutions }) => institutions.institution?.uuid
	);
	const dispatch = useDispatch();

	const handleClearInstitution = () => {
		if (institutionUuid) {
			clearInstitution()(dispatch);
		}
		close();
	};

	return {
		clearInstitution: handleClearInstitution
	};
};
