import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { replaceStringChar } from 'helpers';

// Import api
import { GET_SECTIONS_LIST_API, QUERY_SECTIONS_LIST } from 'helpers/api';

const useSectionsQuery = ({ label = '' }) => {
	const queryKey = `${QUERY_SECTIONS_LIST}_${label}`;

	const queryFn = () => {
		const url = replaceStringChar({
			string: GET_SECTIONS_LIST_API,
			regex: /\b(?:label)\b/gi,
			object: { label }
		});

		return services.get(url);
	};

	const { data, isLoading, isError } = useQuery({
		queryKey,
		queryFn
	});

	const sections = data?.data || [];

	const isSections = !!sections.length;

	return {
		isLoading,
		sections,
		isError,
		isSections
	};
};

export default useSectionsQuery;
