import { useSelector } from 'react-redux';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

const { COVER } = PRODUCT_IMAGES_TYPES;

const useBanner = ({ images, context, isCurrent }) => {
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	const coverImage = getImage({ images, type: COVER });

	const url = context?.url_pc;

	const tabIndex = isCurrent ? 0 : -1;

	return { isContrast, coverImage, url, tabIndex };
};

export default useBanner;
