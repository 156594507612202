import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { UPDATE_LIVE_PROGRAM_INTERVAL } from 'helpers/variables';

const useProgramTimeProgress = (
	{ since, till },
	channelId,
	setTvProgramsLive,
	isScrolling = false,
	isVisible = true
) => {
	const isMounted = React.useRef(false);
	const [progress, setProgress] = React.useState(30);

	React.useEffect(() => {
		isMounted.current = true;
		const intervalTime = UPDATE_LIVE_PROGRAM_INTERVAL;
		let intervalID = null;

		const isLiveVisible = isMounted.current && isVisible && !isScrolling;

		// Calculate initial progress
		isLiveVisible && calculateProgress();

		// Calculate update progress
		if (isLiveVisible) {
			intervalID = setInterval(() => {
				isMounted.current && calculateProgress();
			}, intervalTime);
		}

		return () => {
			isMounted.current = false;
			clearInterval(intervalID);
		};
		// eslint-disable-next-line
	}, [since, till, isScrolling]);

	const calculateProgress = () => {
		const dateNow = new Date();
		const sinceDate = new Date(since.replace(/-/g, '/'));
		const tillDate = new Date(till.replace(/-/g, '/'));

		// Calculate diff and diff now
		const calculateDiff = (date) =>
			(tillDate.getTime() - date.getTime()) / 1000 / 60;

		let diff = calculateDiff(sinceDate);
		let diffNow = calculateDiff(dateNow);

		if (diffNow <= 0 && isMounted.current) {
			// Dispatch an action
			setTvProgramsLive(channelId);
		} else {
			// Calculate progress bard width in %
			const timeProgress = ((diff - diffNow) * 100) / diff;
			// Update state progressbar
			isMounted.current && setProgress(Math.ceil(timeProgress));
		}
	};

	return {
		progress
	};
};

useProgramTimeProgress.propTypes = {
	programs: PropTypes.shape({
		since: PropTypes.string.isRequired,
		till: PropTypes.string.isRequired
	}).isRequired,
	channelId: PropTypes.string.isRequired,
	setTvProgramsLive: PropTypes.func.isRequired,
	isScrolling: PropTypes.bool.isRequired,
	isVisible: PropTypes.bool.isRequired
};

export default useProgramTimeProgress;
