// Import variables
import { FOOTER_PATHS, DOCUMENTS_PATHS } from 'components/routes/paths';
import i18next from 'i18next';
import { injectIf } from 'helpers/injectIf';

const { REGULATIONS, PRIVACY_POLICY, DCB_PAYMENT } = DOCUMENTS_PATHS;

const { CONTACT, SUBSCRIPTION } = FOOTER_PATHS;

const polishOnly = i18next.language === 'pl';

export const FOOTER_NAV_LINKS = [
	{
		path: REGULATIONS,
		text: 'common_footer_terms_conditions',
		ariaLabel: 'aria_footer_nav_regulations',
		auth: false
	},
	{
		path: PRIVACY_POLICY,
		text: 'common_footer_privacy_policy',
		ariaLabel: 'aria_footer_nav_private_policy',
		auth: false
	},
	{
		path: CONTACT,
		text: 'common_footer_contact',
		ariaLabel: 'aria_footer_nav_private_contact',
		auth: false
	},
	{
		path: SUBSCRIPTION,
		text: 'common_footer_unsubscribe',
		ariaLabel: 'aria_footer_nav_footer_unsubscribe',
		auth: true
	},
	{
		...injectIf(polishOnly, {
			path: DCB_PAYMENT,
			text: 'common_footer_dcb_payment_terms',
			ariaLabel: 'aria_footer_nav_dcb_payment_terms',
			auth: false
		})
	}
];
