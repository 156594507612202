import * as types from './types';
import produce from 'immer';

// Import helpers
import { INITIAL_PROMOTION } from 'helpers';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_SELECTED_PRICE_ID:
				draft.selectedPriceId = action.payload;
				break;

			case types.SET_PAYMENT_METHODS:
				draft.paymentMethods = action.payload;
				break;

			case types.SET_TRANSACTION_TYPE:
				draft.transactionType = action.payload;
				break;

			case types.SET_USER_PROMOTIONS:
				draft.userPromotions = action.payload;
				break;

			case types.SET_PROMOTION:
				draft.promotion = action.payload;
				break;

			case types.CLEAR_PROMOTION:
				draft.promotion = INITIAL_PROMOTION;
				break;

			case types.SET_INVOICE:
				draft.need_invoice = !draft.need_invoice;
				break;

			default:
				return state;
		}
	});

export default reducer;
