// Import variables
import {
	BASE_APPS_URLS,
	ANDROID_APPS_IDS,
	APPLE_APPS_IDS
} from 'helpers/variables';

// get url for apple and android app
export const getAppStoreUrls = () => {
	const { ANDROID_BASE_URL, APPLE_BASE_URL } = BASE_APPS_URLS;
	const { ANDROID_TV_ONLINE_ID } = ANDROID_APPS_IDS;
	const { APPLE_TV_ONLINE_ID } = APPLE_APPS_IDS;

	const androidUrl = `${ANDROID_BASE_URL}${ANDROID_TV_ONLINE_ID}`;
	const appleUrl = `${APPLE_BASE_URL}${APPLE_TV_ONLINE_ID}`;

	return { androidUrl, appleUrl };
};
