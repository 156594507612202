import React from 'react';
import { func, string, node } from 'prop-types';

// Import styles
import { Content } from './styles';

// Import components
import { AriaBaseModal } from 'components/elements';

const AriaModalContent = ({
	title,
	description,
	children,
	childrenDirection
}) => (
	<Content>
		<AriaBaseModal.Text>{title}</AriaBaseModal.Text>
		{description && (
			<AriaBaseModal.Text description>{description}</AriaBaseModal.Text>
		)}
		{children && (
			<AriaBaseModal.Bottom flexDirection={childrenDirection}>
				{children}
			</AriaBaseModal.Bottom>
		)}
	</Content>
);

AriaModalContent.propTypes = {
	toggle: func,
	renderBottom: func,
	title: string.isRequired,
	description: string,
	children: node,
	childrenDirection: string
};

export default AriaModalContent;
