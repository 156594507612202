import React from 'react';
import { func, string, node } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { AriaModal } from 'components/elements';

// Import styles
import { ButtonClose, IconClose } from './styles';

const FullscreenModal = ({ titleText, onExit, children, ...restProps }) => {
	const { t } = useTranslation();
	return (
		<AriaModal titleText={titleText} onExit={onExit} {...restProps}>
			<ButtonClose
				onClick={onExit}
				title={t('common_modal_close_button_title')}
			>
				<IconClose
					aria-hidden={true}
					title={t('common_modal_close_button_title')}
				/>
			</ButtonClose>
			{children}
		</AriaModal>
	);
};

FullscreenModal.defaultProps = {
	onExit: () => {},
	titleText: 'Dialog'
};

FullscreenModal.propTypes = {
	onExit: func.isRequired,
	titleText: string.isRequired,
	children: node.isRequired
};

export default FullscreenModal;
