import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setTvProgramsLive,
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	playerOnCancel
} from 'store/actions';
import styled, { css } from 'styled-components';

// Import variables
import { PLAYER_SELECTORS } from 'helpers/variables';

// Import icons
import { ReactComponent as IconLock } from 'assets/icons/ic_lock.svg';

// Import styles
import { flex } from 'components/styles';

// Import helpers
import { setChannelLogo } from 'helpers/index';

// Import utilities
import { useProgramTimeProgress, ImageOnLoadRPC } from 'components/utilities';

// Import components
import { Progress } from 'components/elements';
import SidebarInfo from './SidebarInfo';
import FavouriteButton from './FavouriteButton';

const SidebarChannel = React.memo(
	({
		fetchPlayerConfig,
		deleteVideoSession,
		setSelectedProgram,
		setTvProgramsLive,
		style,
		videoSessionId,
		selectedChannelID,
		playerOnCancel,
		isScrolling,
		isVisible,
		images,
		channelID,
		channelName,
		avaiable,
		program,
		nextProgram
	}) => {
		// Check if channel is limited;
		const limited = !avaiable;

		const { title, since, till, uuid: id, pc_rating } = program;

		// Check if program is selected
		const isProgramSelected = channelID === selectedChannelID ? true : false;

		const updateCurrentLive = (channelId) => {
			// Dispatch an setTvProgramsLive action in channels folder
			!isProgramSelected && setTvProgramsLive(channelId);
		};

		// Calculate the program's time progress
		const { progress } = useProgramTimeProgress(
			program,
			channelID,
			updateCurrentLive,
			isScrolling,
			isVisible
		);

		// also change function in Favourite.js
		const handlePlaySelected = async () => {
			// Delete request
			// Dispatch an playerOnCancel action in player folder
			playerOnCancel(PLAYER_SELECTORS.PLAYER);
			// Delete video live session
			// Dispatch an deleteVideoSession action in player folder
			videoSessionId && (await deleteVideoSession());
			// Set title in player overlay content
			// Dispatch an setSelectedProgram action in channel folder
			setSelectedProgram({ title, id, channel: channelName, channelID });
			// Fetch new video session config
			// Dispatch an fetchPlayerConfig action in player folder
			fetchPlayerConfig({ productID: channelID });
		};

		const renderNextProgram = () => {
			const { title, since, till, pc_rating } = nextProgram;
			return (
				<SidebarInfo
					isUpcoming={true}
					since={since}
					till={till}
					title={title}
					pcRating={pc_rating}
				/>
			);
		};

		const progressbarStyle = isProgramSelected ? 'primary' : 'default';
		return (
			<Channel isSelected={isProgramSelected} style={style}>
				<FavouriteButton channelID={channelID} />
				<Content onClick={handlePlaySelected}>
					<LogoBox>
						<Figure>
							<ImageOnLoadRPC>
								{({ setError }) => (
									<>
										<Logo
											onError={setError}
											limited={limited}
											src={`${setChannelLogo(images)}=w70-h70`}
											alt="Logo"
										/>
									</>
								)}
							</ImageOnLoadRPC>

							{limited && <Lock />}
						</Figure>
					</LogoBox>
					<Description>
						<ChannelName>{channelName}</ChannelName>

						<SidebarInfo
							since={since}
							till={till}
							title={title}
							pcRating={pc_rating}
						/>
						<ProgressBarStyled>
							<Progress.Current
								modifiers={progressbarStyle}
								progress={progress}
							/>
						</ProgressBarStyled>
						{nextProgram && renderNextProgram()}
					</Description>
				</Content>
			</Channel>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

const Channel = styled.div`
	padding: 0.8rem 1.2rem 0 0.4rem;
	display: flex;
	height: 100%;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	background-color: transparent;

	&:hover {
		background-color: rgba(255, 255, 255, 0.2);
	}

	${({ isSelected }) =>
		isSelected &&
		css`
			background-color: rgba(255, 255, 255, 0.2);
		`}
`;

const Content = styled.div`
	display: flex;
	width: 100%;
`;

const LogoBox = styled.div`
	padding: 0.8rem 1.2rem 0 0.4rem;
	margin-bottom: 0.5rem;
	height: 100%;
	width: 8rem;
	float: left;
	margin-right: 0.8rem;
	${flex()}
`;

const Figure = styled.figure`
	position: relative;
	height: 100%;
	width: 100%;
`;

const Logo = styled.img`
	max-height: 60px;
	max-width: 60px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	${({ limited }) =>
		limited &&
		css`
			filter: grayscale(100%);
			opacity: 0.5;
		`}
`;

const Lock = styled(IconLock)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Description = styled.div`
	width: calc(100% - 9rem);
	color: ${({ theme }) => theme.white};
`;

const ChannelName = styled.p`
	font-size: 1.4rem;
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 0.8rem;
`;

const ProgressBarStyled = styled(Progress.Bar)`
	width: 100%;
	margin: 0.4rem 0;
`;

SidebarChannel.propTypes = {
	images: PropTypes.object.isRequired,
	channelName: PropTypes.string.isRequired,
	channelID: PropTypes.string.isRequired,
	nextPrograms: PropTypes.object,
	program: PropTypes.object,
	fetchPlayerConfig: PropTypes.func.isRequired,
	deleteVideoSession: PropTypes.func.isRequired,
	setSelectedProgram: PropTypes.func.isRequired,
	setTvProgramsLive: PropTypes.func.isRequired,
	videoSessionId: PropTypes.string,
	selectedChannelID: PropTypes.string,
	avaiable: PropTypes.bool,
	favourites: PropTypes.array,
	isVisible: PropTypes.bool.isRequired,
	isScrolling: PropTypes.bool.isRequired
};

const mapStateToProps = ({
	player: { videoSessionId },
	selected_program: { channelID }
}) => ({
	videoSessionId,
	selectedChannelID: channelID
});

export default connect(
	mapStateToProps,
	{
		fetchPlayerConfig,
		deleteVideoSession,
		setSelectedProgram,
		setTvProgramsLive,
		playerOnCancel
	}
)(SidebarChannel);
