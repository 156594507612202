import React from 'react';
import { arrayOf, oneOfType, func, node } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import heleprs
import { callAllFns } from 'helpers';

// Import components
import {
	AriaBaseModal,
	AriaModalContent,
	Loader,
	CommonButton
} from 'components/elements';
import EmailConfirmation from './EmailConfirmation';

// Import logic
import usePaymentProtect from './usePaymentProtect';

const PaymentProtect = ({ children, onClick }) => {
	const { t } = useTranslation();

	const isLoading = children?.props?.isLoading;
	const redirectUrl = children?.props?.to;
	const onChildrenClick = children?.props?.onClick;

	const {
		isOpened,
		modalToggle,
		title,
		description,
		isGeolockError,
		isEmailConfirmed,
		isUserDetailsLoading,
		protect
	} = usePaymentProtect({ redirectUrl });

	const renderContent = () => {
		if (isGeolockError) {
			return (
				<CommonButton
					onClick={modalToggle}
					title={t('common_modal_close_button_title')}
				>
					{t('common_modal_close_button_title')}
				</CommonButton>
			);
		}

		if (!isEmailConfirmed) {
			return <EmailConfirmation />;
		}

		return null;
	};

	return (
		<>
			{React.cloneElement(children, {
				onClick: callAllFns(onClick, protect(onChildrenClick)),
				isLoading: isLoading || isUserDetailsLoading
			})}

			{isUserDetailsLoading && (
				<Loader position="absolute" background={false} />
			)}

			{isOpened && (
				<AriaBaseModal
					titleText={t(title)}
					close={modalToggle}
					style={{ width: '47vw' }}
				>
					<AriaModalContent
						title={t(title)}
						description={t(description)}
						childrenDirection="column"
					>
						{renderContent()}
					</AriaModalContent>
				</AriaBaseModal>
			)}
		</>
	);
};

PaymentProtect.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	onClick: func
};

export default PaymentProtect;
