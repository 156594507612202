import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Import components
import { ProgramRecordings } from 'components/elements';
import Share from '../share/Share';
import ReminderButtons from '../reminder/ReminderButtons';
import { Box, ButtonStyled } from '../styles';

const RenderButtonType = ({
	showRemindersMenu,
	isUpcoming,
	isNotEnded,
	reminderId,
	programId,
	startDate,
	recordingConditions,
	showShareButton,
	linkDetails,
	isCatchup,
	buttonText
}) => {
	if (showRemindersMenu) {
		return (
			<ReminderButtons
				isUpcoming={isUpcoming}
				isNotEnded={isNotEnded}
				reminderId={reminderId}
				programId={programId}
				startDate={startDate}
			/>
		);
	}
	if (recordingConditions) {
		return (
			<Box>
				<ProgramRecordings programId={programId} />
			</Box>
		);
	}
	if (showShareButton) {
		return (
			<Box>
				<Share linkDetails={linkDetails} />
			</Box>
		);
	}
	return (
		<Box isCatchup={isCatchup}>
			<ButtonStyled as={Link} to={linkDetails} modifiers="default">
				{buttonText}
			</ButtonStyled>
		</Box>
	);
};

RenderButtonType.propTypes = {
	isUpcoming: PropTypes.bool.isRequired,
	programId: PropTypes.string.isRequired,
	linkDetails: PropTypes.shape({
		pathname: PropTypes.string,
		state: PropTypes.shape({ sCatchup: PropTypes.bool }),
		hash: PropTypes.string
	}),
	reminderId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
	isCatchup: PropTypes.bool,
	isNotEnded: PropTypes.bool,
	startDate: PropTypes.string,
	showRemindersMenu: PropTypes.bool,
	buttonText: PropTypes.string,
	recordingConditions: PropTypes.string,
	showShareButton: PropTypes.string
};

export default RenderButtonType;
