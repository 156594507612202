import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { TRANSACTION_TYPES } from 'helpers/prices';

const { RENT, PURCHASE } = TRANSACTION_TYPES;

const useEpisodeBuy = (props) => {
	const {
		purchasePrice,
		purchasePeriod,
		rentPrice,
		rentPeriod,
		productName,
		uuid,
		rentPromotion,
		purchasePromotion
	} = props;
	const isPurchaseRedirect = true;
	const [episode, setEpisode] = React.useState(null);

	React.useEffect(() => {
		if (purchasePrice) {
			const period = purchasePeriod || 0;
			setEpisode({
				price: purchasePrice,
				period,
				productName,
				uuid,
				isPurchaseRedirect,
				transactionType: PURCHASE,
				promotion: purchasePromotion
			});
		}

		if (!purchasePrice && rentPrice) {
			const period = rentPeriod || 0;
			setEpisode({
				price: rentPrice,
				period,
				productName,
				uuid,
				isPurchaseRedirect,
				transactionType: RENT,
				promotion: rentPromotion
			});
		}

		// eslint-disable-next-line
	}, [purchasePrice, rentPrice, rentPromotion, purchasePromotion]);

	return episode;
};

useEpisodeBuy.propTypes = {
	purchasePrice: PropTypes.any,
	purchasePeriod: PropTypes.any,
	rentPrice: PropTypes.number,
	rentPeriod: PropTypes.number,
	productName: PropTypes.string,
	uuid: PropTypes.string,
	rentPromotion: PropTypes.object,
	purchasePromotion: PropTypes.object
};

export default useEpisodeBuy;
