import React from 'react';
import { string } from 'prop-types';

// Import helpers
import { CONTAINER_SCREEN_PERCENT_DESKTOP } from './helpers';

// Import components
import { GradientBg, MoreInfoButton } from 'components/elements';

// Import logic
import { useBanner } from './hooks';

// Import styles
import { BannerWrapper, BannerContent, Title, Description } from './styles';

const Banner = ({ title, short_desc, ...restProps }) => {
	const { isContrast, coverImage, url, tabIndex } = useBanner(restProps);

	return (
		<BannerWrapper cover={coverImage} isContrast={isContrast}>
			<GradientBg containerScreenPercent={CONTAINER_SCREEN_PERCENT_DESKTOP} />
			<BannerContent>
				<Title>{title}</Title>
				<Description>{short_desc}</Description>
				<MoreInfoButton url={url} tabIndex={tabIndex} />
			</BannerContent>
		</BannerWrapper>
	);
};

Banner.propTypes = {
	title: string.isRequired,
	short_desc: string.isRequired
};

export default Banner;
