import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Import assets
import Bg from 'assets/images/mobile_bg.jpg';
import { ReactComponent as UnderLine } from 'assets/icons/underline.svg';
import AppStore from 'assets/icons/app_store.svg';
import PortalLogo from 'assets/icons/nexgentv.svg';

// Import components
import { Heading } from 'components/elements';

// Import helpers
import { getAppStoreUrls } from 'helpers/app_store_urls';
import { checkAppType } from 'helpers';

const Mobile = ({ mobileType }) => {
	const { t } = useTranslation();
	const { androidUrl, appleUrl } = getAppStoreUrls();
	const { appName } = checkAppType();

	return (
		<Wrapper>
			<Top>
				<LogoImage src={PortalLogo} alt="Logo" />
			</Top>

			<Content>
				<HeadingSecondStyled>
					{t('common_mobile_header')}{' '}
					<span>{t('common_mobile_header_span')}</span>
				</HeadingSecondStyled>
				<div>
					<UnderLineStyled />
				</div>
				<div>
					{mobileType === 'ios' && (
						<a href={appleUrl} target="blank">
							<AppStoreLogo src={AppStore} alt="App Store" />
						</a>
					)}
					{mobileType === 'android' && (
						<a href={androidUrl} target="blank">
							<GooglePlayLogo
								src="https://play.google.com/intl/en_us/badges/images/generic/pl_badge_web_generic.png"
								alt="Google Play"
							/>
						</a>
					)}
				</div>

				<Paragraph>{t(`common_mobile_paragraph_${appName}`)}</Paragraph>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	flex: 1;
	height: 100%;
	width: 100%;
	background-image: url(${Bg});
	background-size: cover;
	padding-bottom: 6rem;
`;

const Top = styled.div`
	margin: 4em 3em;
	margin-bottom: 3em;
	padding: 1em 0 3em;
	text-align: center;
	border-bottom: 1px solid #222;
`;

const LogoImage = styled.img`
	width: 50%;
	max-width: 250px;
	height: auto;
`;

const Content = styled.div`
	text-align: center;
`;

const HeadingSecondStyled = styled(Heading.Second)`
	font-size: 2.6rem;

	span {
		display: block;
		text-transform: uppercase;
	}
`;

const UnderLineStyled = styled(UnderLine)`
	width: 28rem;
`;

const Paragraph = styled.p`
	color: ${({ theme }) => theme.white};
	font-size: 2.1rem;
	margin: 0 6rem;
	text-align: center;
	line-height: 1.2;
`;

const AppStoreLogo = styled.img`
	width: 20rem;
	margin: 2.5rem 0;
`;

const GooglePlayLogo = styled.img`
	width: 22rem;
	margin: 2.5rem 0;
`;

Mobile.propTypes = {
	mobileType: string
};

export default Mobile;
