import styled, { css } from 'styled-components';
import { HEADER_HEIGHT_MOBILE, HEADER_HEIGHT_DESKTOP } from 'helpers/variables';
import { customMinResponsive, size } from 'components/styles';

export const Wrapper = styled.section`
	position: relative;
	background-color: ${({ theme }) => theme.videoBanner.bg};
	width: 100%;
	/* 16:9 aspect ratio */
	height: 56.25vw;
	max-height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
	margin-bottom: 3.2rem;
	overflow: hidden;

	${customMinResponsive(
		size.medium,
		css`
			max-height: calc(100vh - ${HEADER_HEIGHT_DESKTOP}px);
		`
	)}

	::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.5) 100%
			),
			linear-gradient(179deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 99.41%);

		opacity: 0.2;
	}
`;

export const PlaceholderWrapper = styled.section`
	position: relative;
	width: 100%;
	cursor: pointer;
	height: 56.25vw;
	max-height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
	margin-bottom: 3.2rem;
	overflow: hidden;

	${customMinResponsive(
		size.medium,
		css`
			max-height: calc(100vh - ${HEADER_HEIGHT_DESKTOP}px);
		`
	)}

	::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000000;
		opacity: 0.5;
	}
`;

export const Video = styled.video`
	width: 100%;
`;

export const Fog = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.videoBanner.fog.bg};
`;
