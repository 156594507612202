// Timer.js

import React from 'react';
import PropTypes from 'prop-types';
import { useTimer } from './useTimer';
import { TimeValue, Label, TimerContainer } from './styles';
import { TimeBlockWithFixedWidth } from './TimeBlockWithFixedWidth';
// Countdown Component

const Timer = ({ targetDate }) => {
	const { timeLeft } = useTimer(targetDate);

	const timeUnits = [
		{ value: timeLeft?.days || '0', label: 'D' },
		{ value: timeLeft?.hours || '0', label: 'H' },
		{ value: timeLeft?.minutes || '0', label: 'M' },
		{ value: timeLeft?.seconds || '0', label: 'S' }
	];

	return (
		<TimerContainer>
			{timeUnits.map((unit, index) => (
				<TimeBlockWithFixedWidth key={index}>
					<TimeValue>
						{unit.value}
						<Label>{unit.label}</Label>
					</TimeValue>
				</TimeBlockWithFixedWidth>
			))}
		</TimerContainer>
	);
};

Timer.propTypes = {
	targetDate: PropTypes.instanceOf(Date).isRequired
};

export default Timer;
