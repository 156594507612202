import React from 'react';
import { object, string, shape, bool } from 'prop-types';

// Import components
import { Tooltip } from 'antd';

// Import styled components
import { TextareaFieldStyled, TextareaStyled, LabelStyled } from './styles';
import { FormError, InputWrapper } from '../styles';

const TextareaField = ({
	type,
	field,
	name,
	autoComplete,
	form: { touched, errors },
	placeholder,
	tooltipHintText,
	styles,
	required,
	...rest
}) => {
	const isErrorVisible = touched[field.name] && errors[field.name];
	const errorText = errors[field.name] || '';

	return (
		<TextareaFieldStyled>
			<Tooltip
				title={tooltipHintText}
				trigger="focus"
				placement="bottomRight"
				overlayClassName="antd-customRegisterTooltip"
			>
				<InputWrapper>
					<TextareaStyled
						id={field.name}
						aria-required="true"
						aria-invalid={touched[field.name] && errors[field.name]}
						aria-describedby={`${field.name}Alert`}
						className={`${
							touched[field.name] && errors[field.name] ? 'errors' : ''
						}`}
						type={type}
						name={name}
						autoComplete={autoComplete}
						{...field}
						{...rest}
					/>
					<LabelStyled
						backgroundColor={styles.label?.background}
						className={field.value && 'filled'}
						htmlFor={field.name}
					>
						{placeholder} {required && '*'}
					</LabelStyled>
				</InputWrapper>
			</Tooltip>
			<FormError
				isErrorVisible={isErrorVisible}
				role="alert"
				aria-atomic="true"
				id={`${field.name}Alert`}
			>
				{errorText}
			</FormError>
		</TextareaFieldStyled>
	);
};

TextareaField.defaultProps = {
	type: 'text',
	name: 'pin',
	autoComplete: 'true',
	styles: {},
	required: false
};

TextareaField.propTypes = {
	name: string.isRequired,
	type: string.isRequired,
	field: object.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	placeholder: string.isRequired,
	autoComplete: string,
	tooltipHintText: string,
	styles: object,
	required: bool
};

export default TextareaField;
