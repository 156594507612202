import React from 'react';
import { string } from 'prop-types';

// Import components
import { Row, Loader, Static } from 'components/elements';
import StaticContent from './StaticContent';
import useStaticPage from './useStaticPage';

const StaticPage = (props) => {
	const { isUserProfile, showLoader } = useStaticPage(props);

	const StaticWrapper = (
		<Static.Wrapper>
			<StaticContent isUserProfile />
		</Static.Wrapper>
	);

	const StaticRow = (
		<Row>
			<StaticContent />
		</Row>
	);

	return (
		<Static.Container isUserProfile={isUserProfile}>
			{showLoader && <Loader zIndex={900} position="absolute" />}

			{isUserProfile ? StaticWrapper : StaticRow}
		</Static.Container>
	);
};

StaticPage.defaultProps = { alias: '' };
StaticPage.propTypes = { alias: string };

export default StaticPage;
