import React from 'react';
import Slider from 'react-slick';
import { array } from 'prop-types';

// Import components
import Banner from './Banner';
import SliderDot from './SliderDot';

// Import styles
import { Wrapper, SliderDots } from './styles';

// Import helpers
import { sliderSettings } from './helpers';

// Import logic
import { useHighBanners } from './hooks';

const HighBanners = ({ content }) => {
	const { sliderRef, currentIndex, changeCurrentIndex } = useHighBanners({
		content
	});

	const customPaging = (index) => (
		<SliderDot
			content={content}
			index={index}
			changeSlide={changeCurrentIndex}
		/>
	);

	const appendDots = (dots) => <SliderDots>{dots}</SliderDots>;

	return (
		<Wrapper>
			<Slider
				{...sliderSettings}
				customPaging={customPaging}
				appendDots={appendDots}
				ref={sliderRef}
			>
				{content.map((banner, index) => (
					<Banner
						key={banner.uuid}
						isCurrent={currentIndex === index}
						{...banner}
					/>
				))}
			</Slider>
		</Wrapper>
	);
};

HighBanners.propTypes = {
	content: array.isRequired
};

export default HighBanners;
