import React from 'react';

export default function useFullScreen({
	callback = () => {},
	isIphone = false
}) {
	const elementRef = React.createRef();
	const [isFullScreen, setIsFullScreen] = React.useState(
		document[getBrowserFullscreenElementProp()] != null
	);

	const setFullscreen = () => {
		if (elementRef.current === null || isIphone) return;

		if (isFullScreen) {
			document.exitFullscreen();
		} else if (elementRef.current && elementRef.current.requestFullscreen) {
			elementRef.current
				.requestFullscreen()
				.then(() => {
					setIsFullScreen(document[getBrowserFullscreenElementProp()] != null);
				})
				.catch(() => {
					setIsFullScreen(false);
				});
		}
	};

	React.useLayoutEffect(() => {
		if (!isIphone) {
			document.onfullscreenchange = () => {
				const isChangeToFullScreen =
					document[getBrowserFullscreenElementProp()] != null;
				setIsFullScreen(isChangeToFullScreen);
				!isFullScreen &&
					window.scroll({
						top: 0,
						behavior: 'smooth'
					});

				if (!isChangeToFullScreen) {
					callback();
				}
			};
		}

		return () => (document.onfullscreenchange = undefined);
	}, [callback, isFullScreen, isIphone]);

	return { setFullscreen, elementRef, isFullScreen };
}

function getBrowserFullscreenElementProp() {
	if (typeof document.fullscreenElement !== 'undefined') {
		return 'fullscreenElement';
	} else if (typeof document.mozFullScreenElement !== 'undefined') {
		return 'mozFullScreenElement';
	} else if (typeof document.msFullscreenElement !== 'undefined') {
		return 'msFullscreenElement';
	} else if (typeof document.webkitFullscreenElement !== 'undefined') {
		return 'webkitFullscreenElement';
	}

	return '';
}
