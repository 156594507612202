import styled, { css } from 'styled-components';

// Import utilties
import { customMinResponsive, size } from 'components/styles';
import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from 'helpers/variables';

export const StyledHeader = styled.header`
	width: 100%;
	position: relative;
	height: ${HEADER_HEIGHT_MOBILE}px;
	z-index: 1000;

	${customMinResponsive(
		size.medium,
		css`
			height: ${HEADER_HEIGHT_DESKTOP}px;
		`
	)}
`;

export const DisabilityNavWrapper = styled.div`
	height: ${({ theme }) => theme.body.disabilityNav.height}rem;
	color: ${({ theme }) => theme.body.disabilityNav.textColor};
	background: ${({ theme }) => theme.body.disabilityNav.bgColor};
	padding: 1.8rem;

	${customMinResponsive(
		size.medium,
		css`
			padding: 1.8rem 4rem;
		`
	)}
`;

export const Title = styled.h1`
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(0px 0px 99.9% 99.9%);
	clip-path: inset(0px 0px 99.9% 99.9%);
	overflow: hidden;
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0;
`;

export const InnerWrapper = styled.div`
	position: fixed;
`;
