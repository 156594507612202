import React from 'react';
import { string, func, number } from 'prop-types';

// Import utilities
import { ToggleRPC } from 'components/utilities';

// Import components
import { PreviewButton } from 'components/views/vod/components/buttons';
import PlayerPreview from './PlayerPreview';

const Preview = React.memo(
	(props) => {
		return (
			<ToggleRPC>
				{({ on, toggle }) =>
					!on ? (
						<PreviewButton watchPreview={toggle} />
					) : (
						<PlayerPreview toggle={toggle} {...props} />
					)
				}
			</ToggleRPC>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

Preview.propTypes = {
	uuid: string.isRequired,
	startStopCarousel: func.isRequired,
	videoID: number.isRequired
};

export default Preview;
