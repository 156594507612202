import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isError: false,
	isLoading: true,
	data: []
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* ACTIVE SUBSCRIPTIONS *******************
			case types.FETCH_ACTIVE_PAYMENT_METHODS_LOADING:
				draft.isError = false;
				draft.isLoading = true;
				return;

			case types.FETCH_ACTIVE_PAYMENT_METHODS_SUCCESS:
				draft.data = action.payload;
				draft.isError = false;
				draft.isLoading = false;
				return;

			case types.FETCH_ACTIVE_PAYMENT_METHODS_ERROR:
				draft.data = [];
				draft.isError = true;
				draft.isLoading = false;
				return;

			case types.CLEAR_ACTIVE_PAYMENT_METHODS:
				return INITIAL_STATE;

			default:
				return state;
		}
	});
