// Import helpers
import { COOKIES_TYPES, COOKIES_NAMES_MAPPED } from 'helpers/cookies';

const { FUNCTIONAL } = COOKIES_TYPES;

const { statistic: STATISTIC } = COOKIES_NAMES_MAPPED;

const checkFunctionalAgreements = (name) => {
	switch (name) {
		case FUNCTIONAL:
		case STATISTIC:
			return true;

		default:
			return false;
	}
};

export const getFunctionalAgreement = (agreements) =>
	agreements.find(({ name }) => {
		const nameLowerCase = name.toLowerCase();
		return checkFunctionalAgreements(nameLowerCase);
	});
