import React from 'react';

const useSafeMountDispatch = (dispatch) => {
	const mounted = React.useRef(false);

	React.useLayoutEffect(() => {
		mounted.current = true;
		return () => (mounted.current = false);
	}, []);

	return React.useCallback(
		(...args) => (mounted.current ? dispatch(...args) : null),
		[dispatch]
	);
};

export default useSafeMountDispatch;
