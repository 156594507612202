import React from 'react';
import PropTypes from 'prop-types';

class ToggleRPC extends React.PureComponent {
	static propTypes = {
		children: PropTypes.func.isRequired,
		initial: PropTypes.bool
	};

	state = {
		on: false
	};

	componentDidMount() {
		this.setState({ on: this.props.initial });
	}

	componentDidUpdate(prevProps) {
		if (prevProps.initial !== this.props.initial) {
			this.setState({ on: this.props.initial });
		}
	}

	handleToggle = () => this.setState((prev) => ({ on: !prev.on }));

	render() {
		const { on } = this.state;
		const { children } = this.props;
		return children({
			on,
			toggle: this.handleToggle
		});
	}
}

ToggleRPC.defaultProps = {
	initial: false
};

export default ToggleRPC;
