import React from 'react';
import { useSelector } from 'react-redux';
import { string, func } from 'prop-types';

// Import styles
import { Button, Image } from './styles';

const HeaderIcon = ({
	onClick,
	ariaLabel,
	title,
	src,
	alt,
	className,
	...props
}) => {
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	return (
		<Button
			className={className}
			onClick={onClick}
			aria-label={ariaLabel}
			title={title}
		>
			<Image
				src={src}
				alt={alt}
				aria-hidden="true"
				isContrast={isContrast}
				{...props}
			/>
		</Button>
	);
};

HeaderIcon.defaultProps = {
	onClick: () => {},
	ariaLabel: '',
	title: '',
	src: '',
	alt: ''
};

HeaderIcon.propTypes = {
	onClick: func.isRequired,
	ariaLabel: string.isRequired,
	title: string.isRequired,
	src: string.isRequired,
	alt: string.isRequired,
	className: string
};

export default HeaderIcon;
