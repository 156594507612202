import * as types from 'store/actions/types';

const INITIAL_STATE = {
	loading: true,
	error: false,
	data: [],
	total: false,
	sortingName: 'vod_section_details_filters_default'
};

export default function(state = INITIAL_STATE, action = {}) {
	switch (action.type) {
		case types.MOVIES_LIST_LOADING:
			return {
				...state,
				loading: true,
				error: false
			};
		case types.MOVIES_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				data: state.data.concat(action.payload),
				total: action.total
			};
		case types.MOVIES_LIST_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case types.MOVIES_LIST_CLEAR:
			return {
				...INITIAL_STATE,
				sortingName: state.sortingName
			};
		case types.MOVIES_LIST_CLEAR_DATA:
			return {
				...INITIAL_STATE
			};
		case types.UPDATE_SORTING_NAME:
			return {
				...state,
				sortingName: action.payload
			};
		default:
			return state;
	}
}
