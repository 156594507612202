import $ from 'jquery';
import { css } from 'styled-components';

// Import translation
import i18n from 'i18next';

// Import svg
import triangle from 'assets/icons/triangle-border.svg';

// Import helpers
import { getHeaders } from 'helpers/headers';
import { LOCALSTORAGE_PLAYER } from 'helpers/variables';

export const SHAKA_PLAYER_ERRORS = {
	BROWSER_NOT_SUPPORTED: i18n.t('player_error_browser_not_supported'),
	SHAKA_PLAYER_ERROR_MESSAGE: i18n.t('player_error_shaka_player_error_message')
};

export const PLAYER_ERROR_TYPES = {
	RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
	RESOURCE_NOT_ACTIVE: 'RESOURCE_NOT_ACTIVE',
	VIDEO_NOT_FOUND: 'VIDEO_NOT_FOUND',
	PROGRAM_NOT_FOUND: 'PROGRAM_NOT_FOUND',
	RESOURCE_NOT_AVAILABLE: 'RESOURCE_NOT_AVAILABLE',
	SUBSCRIBER_PARALLEL_STREAMS_LIMIT_EXCEEDED:
		'SUBSCRIBER_PARALLEL_STREAMS_LIMIT_EXCEEDED',
	SUBSCRIBER_GROUP_STREAMS_LIMIT_EXCEEDED:
		'SUBSCRIBER_GROUP_STREAMS_LIMIT_EXCEEDED',
	PROGRAM_BLACKOUT: 'PROGRAM_BLACKOUT',
	STARTOVER_NOT_ACTIVE_FOR_CHANNEL: 'STARTOVER_NOT_ACTIVE_FOR_CHANNEL',
	CATCHUP_NOT_ACTIVE_FOR_CHANNEL: 'CATCHUP_NOT_ACTIVE_FOR_CHANNEL',
	CATCHUP_NOT_AVAILABLE_FOR_PROGRAM: 'CATCHUP_NOT_AVAILABLE_FOR_PROGRAM',
	NPVR_NOT_ACTIVE_FOR_CHANNEL: 'NPVR_NOT_ACTIVE_FOR_CHANNEL',
	NPVR_NOT_AVAILABLE_FOR_PROGRAM: 'NPVR_NOT_AVAILABLE_FOR_PROGRAM',
	BLOCKED_BY_PARENTAL_CONTROL: 'BLOCKED_BY_PARENTAL_CONTROL',
	NOT_AVAILABLE_ON_PLATFORM: 'NOT_AVAILABLE_ON_PLATFORM',
	MUST_BE_IN_LOCAL: 'MUST_BE_IN_LOCAL',
	RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS: 'RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS',
	IP_BLACKLISTED: 'IP_BLACKLISTED',
	STB_CONNECTION_REFUSED: 'STB_CONNECTION_REFUSED',
	VIDEO_SESSION_INVALID: 'VIDEO_SESSION_INVALID',
	LIST_RECORDING_FAILURE_EXCEPTION: 'LIST_RECORDING_FAILURE_EXCEPTION',
	ADD_RECORDING_FAILURE_EXCEPTION: 'ADD_RECORDING_FAILURE_EXCEPTION',
	DELETE_RECORDING_EXCEPTION: 'DELETE_RECORDING_EXCEPTION',
	ACCOUNT_DETAILS_EXCEPTION: 'ACCOUNT_DETAILS_EXCEPTION',
	INVALID_API_DEVICE_HEADER: 'INVALID_API_DEVICE_HEADER',
	INVALID_PIN: 'INVALID_PIN',
	PIN_WRONG_OLD_PIN: 'PIN_WRONG_OLD_PIN',
	PIN_CANNOT_BE_EMPTY: 'PIN_CANNOT_BE_EMPTY',
	INVALID_PLATFORM: 'INVALID_PLATFORM',
	PLATFORM_MISSING: 'PLATFORM_MISSING',
	INVALID_UUID: 'INVALID_UUID',
	CANNOT_BUY_PRODUCT: 'CANNOT_BUY_PRODUCT',
	INVALID_PAYMENT_PIN: 'INVALID_PAYMENT_PIN',
	INVALID_PRICE_ENTRY: 'INVALID_PRICE_ENTRY',
	NOT_ENOUGH_CREDITS: 'NOT_ENOUGH_CREDITS',
	PRICE_ENTRY_OUT_OF_DATE: 'PRICE_ENTRY_OUT_OF_DATE',
	SGT_AUTHENTICATION_FAILED: 'SGT_AUTHENTICATION_FAILED',
	MISSING_EMAIL: 'MISSING_EMAIL',
	ESALES_SERVICE_UNAVAILABLE: 'ESALES_SERVICE_UNAVAILABLE',

	// DEVICES
	ADD_RECORDING_CONFLICTS: 'ADD_RECORDING_CONFLICTS',
	ADD_RECORDING_DISK_FULL: 'ADD_RECORDING_DISK_FULL',
	ADD_RECORDING_UNKNOWN_ERROR: 'ADD_RECORDING_UNKNOWN_ERROR',
	ACCOUNT_DETAILS_NOT_FOUND: 'ACCOUNT_DETAILS_NOT_FOUND',
	ACCOUNT_DETAILS_UNKNOWN_ERROR: 'ACCOUNT_DETAILS_UNKNOWN_ERROR',
	DELETE_RECORDING_NOT_FOUND: 'DELETE_RECORDING_NOT_FOUND',
	DELETE_RECORDING_WRONG_PIN: 'DELETE_RECORDING_WRONG_PIN',
	DELETE_RECORDING_UNKNOWN_ERROR: 'DELETE_RECORDING_UNKNOWN_ERROR',

	// LOGIN AUTH
	AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
	BEARER_TOKEN_REQUIRED: 'BEARER_TOKEN_REQUIRED',
	AUTHENTICATION_REQUIRED: 'AUTHENTICATION_REQUIRED',
	DEVICES_LIMIT_EXCEEDED: 'DEVICES_LIMIT_EXCEEDED',
	DEVICE_NOT_EXISTS: 'DEVICE_NOT_EXISTS',
	SUBSCRIBER_DEVICES_CHANGING_LIMIT_EXCEEDED:
		'SUBSCRIBER_DEVICES_CHANGING_LIMIT_EXCEEDED'
};

const styles = {
	black: '#000',
	white: '#fff',
	gray: '#aeaeae',
	linearGradient: {
		top: 'linear-gradient(to left, #2da7df 0, #21409a 100%)',
		bottom: 'linear-gradient(to bottom, #2da7df 0, #21409a 100%)'
	}
};

export const playerStyles = css`
	&& {
		.atdsplayer-controls-bar,
		.atdsplayer-spinner,
		.atdsplayer-btn-play-big {
			display: none !important;
		}
	}
`;

export const playerVodStyles = (isReady) => css`
	&& {
		.atdsplayer-spinner,
		.atdsplayer-btn-play-big,
		.atdsplayer-btn-play-pause,
		.atdsplayer-btn-stop,
		.atdsplayer-btn-fullscreen,
		.atdsplayer-volume-control,
		.atdsplayer-volume-control-horizontal,
		.atdsplayer-btn-profile,
		.atdsplayer-btn-subtitle,
		.atdsplayer-controls-time {
			display: none !important;
		}

		.atdsplayer-controls-bar {
			position: absolute;
			left: 0;
			bottom: 62px;
			background: transparent !important;
		}

		.atdsplayer-progress-bar {
			height: 4px;
		}

		.atdsplayer-progress-control {
			right: 233px !important;
			left: 208px !important;
			opacity: ${isReady ? 1 : '0 !important'};
		}

		.atdsplayer-progress-control-inner {
			top: 1px;
		}

		.atdsplayer-progress-video {
			background: ${styles.linearGradient.top};
		}

		.atdsplayer-progress-control-drag {
			height: 14px;
			width: 14px;
			top: 12.5px;
			border: 1px solid rgba(255, 255, 255, 0.6);
			background: ${styles.linearGradient.bottom};
		}

		.atdsplayer-sprite {
			border: none;
			top: -126px !important;

			&::before {
				content: '';
				display: block;
				position: absolute;
				top: -5px;
				left: -5px;
				height: 135%;
				width: 106%;
				border-radius: 6px;
				background-color: ${styles.black};
				z-index: -1;
			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				bottom: -41px;
				left: 50%;
				transform: translateX(-50%);
				background-image: url(${triangle});
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				height: 17px;
				width: 26px;
				border-radius: 6px;
				background-color: transparent;
			}
		}

		.atdsplayer-progress-tooltip {
			top: -35px;
			border: 1px solid transparent !important;
			background: transparent;
			box-shadow: none;
			color: ${styles.gray};
		}
	}
`;

export const playerCatchStyles = css`
	.atdsplayer-btn-stop,
	.atdsplayer-controls-time,
	.atdsplayer-btn-profile,
	.atdsplayer-volume-control,
	.atdsplayer-btn-subtitle,
	.atdsplayer-btn-fullscreen,
	.atdsplayer-spinner,
	.atdsplayer-btn-play-big,
	.atdsplayer-progress-control-drag {
		display: none !important;
		background: transparent;
		background-color: transparent;
		background-image: none;
	}

	.atdsplayer-progress-control,
	.atdsplayer-btn-play-pause {
		display: block !important;
	}

	.atdsplayer-controls-bar {
		background: transparent;
		background-color: transparent;
		background-image: none;
	}

	.atdsplayer-progress-control {
		left: 36px !important;
	}

	.atdsplayer-controls-bar {
		right: 28rem !important;
		bottom: 1rem;
	}

	.atdsplayer-progress-video {
		background: ${({ theme }) => theme.primary};
	}
`;

export const options = {
	width: '100%',
	height: '100%',
	autoplayBlockedRule: 'mute',
	volume: 100,
	liveDelay: 30,
	defaultBitrate: null,
	playlist: '',
	settingsLayout: 'simple',
	firefoxMseEnabled: true,
	dashIndexMode: true
};

export const createPlayerPlaylist = ({ id, videoSessionId }) =>
	`${process.env.REACT_APP_API_URL}/player/product/${id}/playlist?type=channel&videoSessionId=${videoSessionId}`;

// Sync request on browser reload
export const playerDeleteSyncRequest = (videoSession) => {
	$(document).ready(function () {
		const headers = getHeaders();

		const url = `${process.env.REACT_APP_API_URL}/player/videosession/${videoSession}?platform=BROWSER`;

		if (videoSession) {
			$.ajax({
				type: 'DELETE',
				headers,
				url: url,
				async: false
			});
		}
	});
};

// set video mute to local storage
export const setMuteToStorage = () => {
	const playerStorage = JSON.parse(localStorage.getItem(LOCALSTORAGE_PLAYER));
	localStorage.setItem(
		LOCALSTORAGE_PLAYER,
		JSON.stringify({ ...playerStorage, isMuted: true })
	);
};
