import styled, { css } from 'styled-components';
import { size, customMinResponsive } from 'components/styles';

export const TimerContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 0.8rem;
`;

export const TimeBlock = styled.div`
	background-color: white;
	color: black;
	font-size: 12px;
	font-weight: bold;
	padding: 0.35rem 0.8rem;
	max-height: 40px;
	transform: scale(0.9);
	min-width: 46px;
	${customMinResponsive(
		size.medium,
		css`
			font-size: 20px;
			padding: 0.35rem 0.8rem;
		`
	)}
`;

export const TimeValue = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Label = styled.span`
	font-weight: bold;
	margin-left: 5px;
`;
