import React from 'react';
import { string, number, func, shape, instanceOf } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styled components
import {
	Wrapper,
	StyledLink,
	ProductTitle,
	TrashIconStyled,
	ButtonStyled
} from '../../common_styles.js';
import { StyledProductType, Time } from './styles';

// Import logic
import useReminder from './useReminder';

const Reminder = ({ toggle, reminder }) => {
	const { t } = useTranslation();
	const { deleteReminder, programStartTime, link, productAlias } =
		useReminder(reminder);
	const { title } = reminder;

	return (
		<Wrapper>
			<StyledLink onClick={toggle} to={link}>
				{title && <ProductTitle>{title}</ProductTitle>}
				<StyledProductType>
					{productAlias} <Time>{programStartTime}</Time>
				</StyledProductType>
			</StyledLink>
			<ButtonStyled
				onClick={deleteReminder}
				aria-label={t('aria_product_remove')}
				title={t('aria_product_remove')}
			>
				<TrashIconStyled aria-hidden="true" />
			</ButtonStyled>
		</Wrapper>
	);
};

Reminder.propTypes = {
	toggle: func.isRequired,
	reminder: shape({
		title: string.isRequired,
		id: number.isRequired,
		program_start: instanceOf(Date).isRequired,
		product_uuid: string.isRequired,
		type: string.isRequired
	})
};

export default Reminder;
