import React from 'react';
import { useSelector } from 'react-redux';
import { string, array, number, oneOfType, bool } from 'prop-types';

// Import Components
import { Basic } from '../Basic';
import { SliderItem } from '../components/SliderItem';
import { RegularHeader } from '../components/RegularHeader';

// Import variables
import { VOD_SECTIONS_TYPES } from 'helpers/variables';

// Import helpers
import { checkProductAvailability } from '../helpers';

export function Regular({
	itemsToShow = 8,
	id,
	content,
	name,
	label,
	showAllMoviesButton,
	borderLine,
	backgroundImage,
	itemWithLink
}) {
	const renderHeader = () => (
		<RegularHeader
			showAllMoviesButton={showAllMoviesButton}
			sectionId={id}
			title={name}
			borderLine={borderLine}
		/>
	);

	// FILTER UNAVAILABLE ELEMENTS FROM CONTINUE WATCHING SECTION
	// list of products available to user
	const { products } = useSelector(({ auth }) => auth);

	// check if section is continue watching section
	const isContinueWatchingSection =
		label === VOD_SECTIONS_TYPES.WEB_MOVIES_CONTINUE_WATCHING;

	// array filtered from products that are in continue watching section and are not available
	const filteredContent = content.filter((item) => {
		const isAvailable = checkProductAvailability(
			item.uuid,
			item.available_in,
			products
		);
		return !(isContinueWatchingSection && !isAvailable);
	});

	// if continue watching section is empty do not render it
	if (isContinueWatchingSection && filteredContent.length === 0) return null;
	else
		return (
			<Basic
				key={id}
				itemResolutionHeight={12.9} //unusual value to fit tiles for full HD design
				itemResolutionWidth={9}
				itemsList={filteredContent}
				itemsToShow={itemsToShow}
				headerHeight={75}
				spaceBetweenItems={13}
				backgroundImage={backgroundImage}
				renderItemComponent={({ data, height, width }) => (
					<SliderItem
						sectionLabel={label}
						data={data}
						height={height}
						width={width}
						sliderId={id}
						itemWithLink={itemWithLink}
					/>
				)}
				renderHeader={renderHeader}
			/>
		);
}

Regular.defaultProps = {
	showAllMoviesButton: true,
	label: '',
	borderLine: false,
	backgroundImage: false
};

Regular.propTypes = {
	id: oneOfType([string, number]).isRequired,
	itemsToShow: number,
	content: array.isRequired,
	name: string.isRequired,
	label: string.isRequired,
	showAllMoviesButton: bool,
	borderLine: bool,
	backgroundImage: oneOfType([string, bool]),
	itemWithLink: bool
};
