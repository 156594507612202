import React from 'react';
import { func, string, number, oneOfType, bool } from 'prop-types';

// Import styles
import { Checkbox } from './styles';

const Toggler = ({ id, toggleAgreement, checked, disabled }) => {
	const handleToggleAgreement = () => toggleAgreement(id);

	return (
		<Checkbox
			id={id}
			type="checkbox"
			checked={checked}
			onChange={handleToggleAgreement}
			disabled={disabled}
		/>
	);
};

Toggler.defaultProps = {
	toggleAgreement: () => {},
	checked: false,
	disabled: false
};

Toggler.propTypes = {
	id: oneOfType([string, number]).isRequired,
	toggleAgreement: func.isRequired,
	checked: bool,
	disabled: bool
};

export default Toggler;
