import { ReactComponent as IconTriangle } from 'assets/icons/triangle.svg';
import styled, { css } from 'styled-components';

// Import components
import { Button, PlayerElements as Player, Heading } from 'components/elements';

export const StyledButton = styled(Button)`
	margin-right: 1.5rem;
`;

export const ControllersWrapper = styled.div`
	position: absolute;
	padding: 2rem 0 2rem 3rem;
	display: flex;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	display: ${({ isFocus }) => (isFocus ? 'flex' : 'none')};
	transition: all 0.3s ease-in-out;
	z-index: 100;
	left: 0;
	bottom: ${({ isCatchupControllers }) => (isCatchupControllers ? -1 : 0.4)}rem;
	width: 100%;

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			align-items: flex-end;
			padding: 0 0 2rem 2rem;
		`}
`;

export const Content = styled.div`
	position: absolute;
	bottom: 55px;
	left: 50%;
	transform: translateX(-50%);
	padding: 1rem 1.8rem;
	background-color: ${({ theme }) => theme.player.settingsBg};
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s ease-in-out;
	border: 1px solid ${({ theme }) => theme.player.settingsBorder};
	border-radius: 6px;
`;

export const SettingsWrapper = styled.div`
	position: relative;
	margin: 0 0.5rem 0 1rem;

	&:hover ${Content} {
		opacity: 1;
		visibility: visible;
	}
`;

export const Profiles = styled.ul`
	list-style: none;
	display: flex;
`;

export const Profile = styled.li`
	font-size: 1.4rem;
	margin-bottom: 0.4rem;
	color: ${({ theme }) => theme.gray};
	transition: all 0.3 ease-in-out;
	cursor: pointer;

	&:hover {
		color: ${({ theme }) => theme.white};
	}

	${({ profile, selected }) =>
		profile === selected &&
		css`
			color: ${({ theme }) => theme.white};
		`}
`;

export const ProfilesBox = styled.div`
	text-align: center;

	&:nth-child(odd) {
		margin-right: 0.9rem;
	}

	&:nth-child(even) {
		margin-left: 0.9rem;
	}
`;

export const ProfileName = styled(Heading.Third)`
	margin-left: 0;
	margin-bottom: 0.8rem;
	padding: 0;
	color: ${({ theme }) => theme.player.settingsTitle};
`;

export const IconTriangleStyled = styled(IconTriangle)`
	position: absolute;
	bottom: -1.3rem;
	right: 3.8rem;
`;

export const VolumeStyled = styled(Player.Volume)`
	position: absolute;
	height: 5px;
	bottom: 300%;
	left: -175%;
	transform: rotate(-90deg);
`;
