// Import variables
import { LOCAL_STORAGE_LANGUAGE, LANGUAGES_TYPES } from 'helpers/variables';

import { ReactComponent as DE_flag } from 'assets/flags/de.svg';
import { ReactComponent as EN_flag } from 'assets/flags/en.svg';
import { ReactComponent as PL_flag } from 'assets/flags/pl.svg';
import { ReactComponent as ES_flag } from 'assets/flags/es.svg';
import { ReactComponent as FR_flag } from 'assets/flags/fr.svg';

export const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;

export const projectlanguages = process.env.REACT_APP_LANGUAGES.split(',');

export const LANGUAGE_OPTIONS = [
	{ code: 'de', flag: DE_flag },
	{ code: 'en', flag: EN_flag },
	{ code: 'es', flag: ES_flag },
	{ code: 'fr', flag: FR_flag },
	{ code: 'pl', flag: PL_flag }
];

export const languagesOptions = projectlanguages.map((lang) => ({
	name: lang,
	value: lang
}));

export const getUserLanguage = () => {
	const localStorageLang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
	const defualtLang =
		process.env.REACT_APP_DEFAULT_LANGUAGE || LANGUAGES_TYPES.EN;
	return localStorageLang ?? defualtLang;
};

export const hasSeveralLanguages = projectlanguages.length > 1;

export const setUserLanguage = (lang) => {
	localStorage.setItem(LOCAL_STORAGE_LANGUAGE, lang);
};
