import * as types from 'store/actions/types';
import servicesVod from 'services/services';
import { openNotification } from 'components/utilities/notification/notification';
import i18next from 'i18next';

// Import helpers
import { checkErrorStatus } from '../../helpers/error_status';

export const fetchGenresList = () => async (dispatch) => {
	try {
		dispatch({ type: types.GENRES_LIST_LOADING });

		const url = 'products/genres/vod';

		const {
			data: { data: genresList }
		} = await servicesVod.get(url);

		dispatch({
			type: types.GENRES_LIST_SUCCESS,
			payload: genresList
		});
	} catch (error) {
		dispatch({ type: types.GENRES_LIST_ERROR });

		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18next.t('vod_root_error_title'),
				description: i18next.t('vod_root_error_description')
			});
	}
};
