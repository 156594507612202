import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const Container = styled.div`
	${customMinResponsive(
		size.medium,
		css`
			margin-top: 15rem;
		`
	)};
`;
