import { useSelector, useDispatch } from 'react-redux';
import { signOut } from 'store/actions';

export const useAuthenticationButton = (toggle) => {
	const { isLogged } = useSelector(({ auth }) => auth);
	const dispatch = useDispatch();

	const handleSignOut = () => {
		signOut()(dispatch);
		toggle();
	};
	return { isLogged, signOut: handleSignOut };
};
