import { useDispatch } from 'react-redux';
import { acceptFunctionalCookies } from 'store/actions';

// Import utilities
import { useAgreements, useToggle, useDocuments } from 'components/utilities';

// Import helpers
import { COOKIES_AGREEMENTS, QUERY_COOKIES_AGREEMENTS } from 'helpers';
import { setAcceptedCookies, COOKIES_MODAL_TYPES } from 'helpers/cookies';
import { getFunctionalAgreement } from './helpers';

// Import api
import { QUERY_COOKIES_DOCUMENTS } from 'helpers/api';

// Import variables
import { DOCUMENTS_ALIASES } from 'helpers/variables';

const { BANNER, MODAL } = COOKIES_MODAL_TYPES;

const { COOKIES_BANNER_ALIAS, COOKIES_MODAL_ALIAS } = DOCUMENTS_ALIASES;

const useCookiesAgreements = ({
	initial = BANNER,
	onAccept = () => {},
	onModalClose = () => {},
	initialAcceptedAgreements = []
}) => {
	const isInitialModal = initial === MODAL;
	const dispatch = useDispatch();

	// ---- state ----
	const [isMessageOpened, messageToggle] = useToggle(!isInitialModal);
	const [isModalOpened, modaltoggle] = useToggle(isInitialModal);

	const handleToggleCookies = () => {
		// toggle cookies banner
		messageToggle();
		// toggle cookies modal
		modaltoggle();
		// call props callback
		onModalClose();
	};

	const handleAcceptCheckedCookies = () => {
		const acceptedAgr = agreements.filter(({ id }) =>
			acceptedAgreements.includes(id)
		);

		const isFunctionalAgreement = getFunctionalAgreement(acceptedAgr);

		// set marketing agreement in order to trigger GA tracking
		if (isFunctionalAgreement) {
			acceptFunctionalCookies()(dispatch);
		}
		// set accepted cookies agreements to cookie
		setAcceptedCookies(acceptedAgr);
		// call props callback
		onAccept();
		// close cookies modal
		modaltoggle();
	};

	const handleCloseModal = (type) => {
		switch (type) {
			case MODAL:
				return modaltoggle();

			case BANNER:
				return messageToggle();

			default:
				throw new Error('Unknown close modal type');
		}
	};

	const handleAcceptAllCookies = (type) => () => {
		// set all cookies agreements to cookie
		setAcceptedCookies(agreements);
		// dispatch action to trigger GA tracking
		acceptFunctionalCookies()(dispatch);
		// call props callback
		onAccept();
		// close cookies message or cookies modal
		handleCloseModal(type);
	};

	const { agreements, acceptedAgreements, ...restAgreements } = useAgreements({
		queryKey: QUERY_COOKIES_AGREEMENTS,
		agreementsToFetch: COOKIES_AGREEMENTS,
		initialAcceptedAgreements
	});

	const { documents, isDocumentsLoading } = useDocuments({
		queryKey: QUERY_COOKIES_DOCUMENTS,
		documents: [COOKIES_BANNER_ALIAS, COOKIES_MODAL_ALIAS]
	});

	const bannerDocument = documents.find(
		({ alias }) => alias === COOKIES_BANNER_ALIAS
	);

	const modalDocument = documents.find(
		({ alias }) => alias === COOKIES_MODAL_ALIAS
	);

	return {
		isMessageOpened,
		messageToggle,
		isModalOpened,
		modaltoggle,
		agreements,
		acceptedAgreements,
		...restAgreements,
		toggleCookies: handleToggleCookies,
		acceptCheckedCookies: handleAcceptCheckedCookies,
		acceptAllCookies: handleAcceptAllCookies,
		isDocumentsLoading,
		bannerDocument,
		modalDocument
	};
};

export default useCookiesAgreements;
