import { ReactComponent as IconTriangle } from 'assets/icons/triangle.svg';
import styled, { css } from 'styled-components';
import { PlayerElements as Player, Heading } from 'components/elements';

export const Box = styled.div`
	position: absolute;
	bottom: 7rem;
	right: 0rem;
	border: 1px solid ${({ theme }) => theme.player.settingsBorder};
	border-radius: 6px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	text-align: center;

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			bottom: 5rem;
			right: -1rem;
		`}
`;

export const WrapperStyled = styled(Player.Wrapper)`
	&:hover ${Box} {
		opacity: 1;
		visibility: visible;
	}
`;

export const Bg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: ${({ theme }) => theme.player.settingsBg};
	border-radius: 6px;
	z-index: -1;
`;

export const Content = styled.div`
	height: 100%;
	width: 100%;
	border-radius: 6px;
	display: flex;
	padding: 2rem 0;

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			padding: 0.4rem 0;
		`}
`;

export const HeadingStyled = styled(Heading.Third)`
	margin-left: 0;
	color: ${({ theme }) => theme.player.settingsTitle};

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			margin-bottom: 0.6rem;
		`}
`;

export const Setting = styled.div`
	padding: 0 3rem;
	border-right: 1px solid ${({ theme }) => theme.player.settingsBorder};

	&:last-child {
		border-right: 1px solid transparent;
	}
`;

export const IconSettingsStyled = styled(Player.IconSettings)`
	cursor: pointer;
`;

export const IconTriangleStyled = styled(IconTriangle)`
	position: absolute;
	bottom: -1.3rem;
	right: 1.1rem;
`;

export const ProfileStyled = styled(Player.Profile)`
	margin-left: -1.7rem;
`;
