import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

const GridContainer = styled.div` 
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: 2rem;
	grid-row-gap: 5rem;

	${customMinResponsive(
		size.small,
		css`
			grid-template-columns: repeat(2, 1fr);
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			grid-template-columns: repeat(4, 1fr);
			grid-row-gap: 13rem;
		`
	)}
`;

export default GridContainer;
