import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	data: [],
	id: null,
	title: '',
	type: '',
	isLoaded: false,
	isError: false,
	isStatusError: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Fetch favourites *******************
			case types.FETCH_FAVOURITES_LOADING:
				return {
					...INITIAL_STATE,
					data: state.data
				};

			case types.FETCH_FAVOURITES_SUCCESS:
				draft.data = action.payload;
				draft.isLoaded = true;
				return;

			case types.FETCH_FAVOURITES_ERROR:
				draft.isError = true;
				return;

			// ******************* Delete favourite *******************
			case types.DELETE_FAVOURITE_LOADING:
				draft.isStatusError = false;
				return;

			case types.DELETE_FAVOURITE_SUCCESS:
				draft.data = draft.data.filter(
					({ item: { id } }) => id !== action.payload
				);
				return;

			case types.DELETE_FAVOURITE_ERROR:
				draft.isStatusError = true;
				return;

			// ******************* Add favourite *******************
			case types.ADD_FAVOURITE_LOADING:
				draft.isStatusError = false;
				return;

			case types.ADD_FAVOURITE_ERROR:
				draft.isStatusError = true;
				return;

			default:
				return state;
		}
	});
