import styled, { css } from 'styled-components';

// Import styles
import { size, customResponsive } from 'components/styles';

export const Wrapper = styled.div`
	${({ isFloatMode }) =>
		isFloatMode
			? css`
					position: fixed;
					bottom: 3rem;
					right: 3rem;
					width: 45rem;
					height: 25rem;
					z-index: 9999;

					${customResponsive(
						size.small,
						css`
							right: 0.5rem;
							width: 36rem;
							bottom: 1rem;
						`
					)}
			  `
			: css`
					position: absolute;
					left: ${({ coordinates }) => `${coordinates.left}px`};
					top: ${({ coordinates }) => `${coordinates.top}px`};
					width: ${({ coordinates }) => `${coordinates.width}px`};
					height: ${({ coordinates }) => `${coordinates.height}px`};
			  `}
`;
