import {
	fetchPlayerConfig,
	fetchMovieDetails,
	fetchContinueWatchingData
} from 'store/actions';
import { PLAYER_SELECTORS } from 'helpers/variables';

export const fetchMovieResources = async ({
	id,
	dispatch,
	videoID,
	isAuth
}) => {
	const playerSelector = PLAYER_SELECTORS.VOD;

	// Dispatch an fetchContinueWatchingData action in continue watching folder
	isAuth && (await fetchContinueWatchingData(id)(dispatch));

	// Dispatch an fetchMovieDetails action in movieDetails folder
	await fetchMovieDetails(id, 'vod', dispatch);

	// Dispatch an fetchPlayerConfig action in player folder
	await fetchPlayerConfig({
		productID: id,
		type: playerSelector,
		videoType: 'vod',
		videoID
	})(dispatch);
};
