export const APP_CORE_PATHS = {
	HOME_PAGE: '/home-page',
	PAGE: 'page',
	HOME: 'home-page',
	WATCH: '/page/watch',
	RECORDINGS: 'recordings',
	PACKETS: 'packages',
	PACKET: 'package',
	PAYMENTS: 'payment-history',
	FAVOURITES: 'favorites',
	DEFAULT_DOCUMENT: 'document',
	REGULATION: 'document/terms',
	CHANNELS: 'channels',
	CHANNELS_WATCH: 'channels/watch',
	PROGRAM_DETAILS: 'program-details',
	SEARCH: 'search',
	SECTION: 'section',
	REGISTER: 'registration',
	ADDITIONAL_INFO: 'additional-information',
	LOGIN: 'login',
	TRANSACTION: 'transaction',
	USER: 'user',
	PASSWORD_RESET: 'password-reset',
	EMAIL_CONFIRMATION: 'email-confirmation/:hash',
	DOCUMENT: 'document/:alias',
	CONTACT: 'contact',
	CATEGORIES: 'categories',
	PAYMENT: 'payment',
	PRODUCT: 'product',
	PRODUCTS: 'products',
	INSTITUTION: 'institution',
	COOKIES: 'cookies',
	TV: 'tv',
	EPG: 'epg',
	VOD: 'vod',
	MOVIES: 'movies',
	SERIES: 'series',
	KIDS: 'for-kids'
};
