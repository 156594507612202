import React from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import uuidv4 from 'uuid/v4';

// Import variables
import { GA_API } from 'helpers/api';

// Import helpers
import { getUserAgentInfo } from 'helpers';
import { COOKIE_EXPIRES, COOKIE_UID } from 'helpers/variables';
import { initializeFirebaseApp } from 'helpers/firebase';

// Import utilities
import { useTheme, useScript } from 'components/utilities';

export const useAppTemplate = () => {
	const { isPortalAvailable } = useSelector(({ portal }) => portal);
	const { isMobile } = getUserAgentInfo();
	const { functionalAgreementsPermission } = useSelector(({ auth }) => auth);

	const gaScriptUrl = `${GA_API}?id=${process.env.REACT_APP_GA_ID}`;
	useScript({
		url: gaScriptUrl,
		isActive: functionalAgreementsPermission,
		id: 'ga-tracking-js'
	});

	// get main theme with font size options
	const theme = useTheme();

	const checkUID = () => {
		// Get uid
		const cookies = Cookies.get(COOKIE_UID);
		// If uid is nod defined, create uid's cookie
		if (!cookies) {
			const createUID = uuidv4();
			Cookies.set(COOKIE_UID, createUID, { expires: COOKIE_EXPIRES });
		}
	};

	/* eslint-disable */
	React.useEffect(() => {
		// Initialize firebase
		initializeFirebaseApp();
		// Check uid
		checkUID();
	}, []);
	/* eslint-enable */

	React.useEffect(() => {
		const gaUuid = process.env.REACT_APP_GTM_ID;
		// Disable Google Analytics measurement
		window[`ga-disable-${gaUuid}`] = !functionalAgreementsPermission;

		if (!functionalAgreementsPermission) {
			const gaCodeId = gaUuid.replace('G-', '');
			const cookieName = '_ga';
			Cookies.remove(`${cookieName}_${gaCodeId}`);
			Cookies.remove(cookieName);
		}
	}, [functionalAgreementsPermission]);

	return {
		isPortalAvailable,
		isMobile,
		theme,
		isFunctionalCookieAccepted: functionalAgreementsPermission
	};
};
