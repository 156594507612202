import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styled components
import {
	Container,
	ContainerItem,
	PortalLogo,
	ErrorIcon,
	ErrorText,
} from './styles';

function PortalUnavailable() {
	const { t } = useTranslation();

	return (
		<Container>
			<ContainerItem>
				<PortalLogo />
				<ErrorText>{t('common_errors_service_unavailable')}</ErrorText>
				<ErrorIcon />
			</ContainerItem>
		</Container>
	);
}

export default PortalUnavailable;
