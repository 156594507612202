import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

//Import styles
import {
	customMinResponsive,
	customResponsive,
	size,
	flex
} from 'components/styles';

// Import assets
import { ReactComponent as IconArrowRight } from 'assets/icons/ic_arrow_r.svg';

// Import components
import { Button } from 'components/elements';

const SECTION_MODIFIERS = {
	sectionsList: () => css`
		padding: 0 0 0 1.8rem;

		&:not(:last-child) {
			margin-bottom: 3.4rem;
		}

		${customMinResponsive(
			size.medium,
			css`
				padding: 0 0 0 4rem;

				&:not(:last-child) {
					margin-top: 6rem;
				}
			`
		)}
	`,
	carousel: () => css`
		z-index: 3;
		padding-bottom: 3.3rem;
		${customMinResponsive(
			size.medium,
			css`
				padding-bottom: 6.4rem;
			`
		)}
	`,
	collection: () => css`
		padding-bottom: 3.3rem;
		${customMinResponsive(
			size.medium,
			css`
				padding-bottom: 6.4rem;
			`
		)}
	`
};

export const Wrapper = styled.section`
	position: relative;
	width: 100%;
	background-color: transparent;
	margin: 0 auto;
	background: ${({ backgroundImage }) =>
		backgroundImage
			? `url(${backgroundImage}) no-repeat center center`
			: 'none'};
	background-size: ${({ backgroundImage }) =>
		backgroundImage ? 'cover' : 'none'};

	${applyStyleModifiers(SECTION_MODIFIERS)};

	${({ addMarginTop }) =>
		addMarginTop &&
		css`
			margin-top: 4.5rem;
		`}
`;

export const Title = styled.h2`
	font-size: ${({ theme }) => theme.fontSize.slider.title.mobile}rem;
	font-weight: ${({ theme }) => theme.font.medium};
	line-height: 3.6rem;
	margin-bottom: 0.9rem;
	color: ${({ theme, type }) => theme.slider.title?.[type]};

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.slider.title.desktop}rem;
			font-weight: ${({ theme }) => theme.font.bold};
			line-height: 3.6rem;
			margin-bottom: 4.2rem;
		`
	)}
`;

export const SliderHeader = styled.div`
	${flex({ jc: 'space-between', ai: 'start' })};
	padding-right: 1rem;

	${customMinResponsive(
		size.medium,
		css`
			padding-right: 2rem;
		`
	)}
`;

export const ShowAllButton = styled(Button)`
	padding: 0.5rem 0;
	font-size: ${({ theme }) => theme.fontSize.slider.showAllButton.mobile}rem;

	${customMinResponsive(
		size.medium,
		css`
			display: inline-block;
			padding: 0.5rem 3.5rem;
			font-size: ${({ theme }) =>
				theme.fontSize.slider.showAllButton.desktop}rem;
		`
	)};

	${customResponsive(
		size.medium,
		css`
			padding: 0.5rem;
			font-weight: ${({ theme }) => theme.font.medium};
		`
	)}

	${customResponsive(
		size.lg,
		css`
			font-weight: ${({ theme }) => theme.font.bold};
		`
	)}

	${({ isDesktop }) =>
		!isDesktop &&
		css`
			padding: 0;
		`}
`;

export const IconArrowRightStyled = styled(IconArrowRight)`
	display: none;

	${customMinResponsive(
		size.medium,
		css`
			display: inline-block;
		`
	)};
`;
