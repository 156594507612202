import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useIntersectionObserver(
	elementRef,
	{ threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }
) {
	const [entry, setEntry] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const frozen = entry?.isIntersecting && freezeOnceVisible;

	const updateEntry = ([entry]) => {
		setEntry(entry);
		setIsLoading(false); // Set loading to false when intersection occurs
	};
	const location = useLocation();

	useEffect(() => {
		const node = elementRef?.current; // DOM Ref
		const hasIOSupport = !!window.IntersectionObserver;

		if (!hasIOSupport || frozen || !node) return;

		const observerParams = { threshold, root, rootMargin };
		const observer = new IntersectionObserver(updateEntry, observerParams);

		observer.observe(node);

		return () => observer.disconnect();
		// eslint-disable-next-line
	}, [
		// eslint-disable-next-line
		elementRef?.current,
		// eslint-disable-next-line
		JSON.stringify(threshold),
		root,
		rootMargin,
		frozen,
		location
	]);

	return { entry, isLoading };
}
