import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect = styled(Select)`
	background-color: ${({ theme }) => theme.body.nav.bgColor} !important;
	border: none;
	margin: 0;
	color: ${({ theme }) => theme.body.nav.color};

	& ::before {
		display: none;
	}

	svg {
		fill: ${({ theme }) => theme.body.nav.color};
	}

	.ant-select-selection {
		border: none;
	}

	& :hover,
	&:active {
		color: ${({ theme }) => theme.body.nav.hover} !important;
		svg {
			fill: ${({ theme }) => theme.body.nav.hover}!important;
		}
	}
`;

export const StyledLanguageText = styled.span`
	position: relative;
	top: -6px !important;
`;

export const StyledFlag = styled.span`
	font-size: 29px;
	margin-right: 8px;
	position: relative;
	bottom: 2px;

	svg {
		width: 29px;
		height: 20px;
	}
`;

export const StyledDropdownWrapper = styled.div`
	background: ${({ theme }) => theme.body.nav.bgColor};

	li {
		padding: 9px 11px !important;
		background-color: ${({ theme }) => theme.body.nav.bgColor} !important;
		color: ${({ theme }) => theme.body.nav.color} !important;
	}
`;
