import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

const GradientBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: ${({ containerScreenPercent }) => `${containerScreenPercent}`}vh;
	overflow: hidden;
	z-index: ${({ zIndex = 2 }) => zIndex};

	&:before,
	&:after {
		display: block;
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&:after {
		height: ${({ mobileHeight = '100%' }) => mobileHeight};
		background: ${({ theme }) => theme.coverBg.gradient};

		${customMinResponsive(
			size.medium,
			css`
				height: 100%;
			`
		)}
	}
`;

export default GradientBg;
