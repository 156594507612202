import * as types from 'store/actions/types';
import services from 'services/services';

// Import variables
import { PLATFORM_TYPES } from 'helpers/variables';

const { WEB } = PLATFORM_TYPES;

export const fetchMenuNavLinks = () => async (dispatch) => {
	try {
		dispatch({ type: types.FETCH_MENU_LINKS_LOADING });

		const {
			data: { data }
		} = await services.get('menus/list');

		const webMenuItems = data.filter(
			({ platform_group }) => platform_group === WEB
		);

		dispatch({
			type: types.FETCH_MENU_LINKS_SUCCESS,
			payload: webMenuItems
		});
	} catch (error) {
		if (error.response) {
			dispatch({
				type: types.FETCH_MENU_LINKS_ERROR
			});
		}
	}
};
