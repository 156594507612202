import { useEffect, useRef, useState } from 'react';

export function useLogic(parentWidth = null) {
	const ref = useRef(null);
	const [svgSize, setSvgSize] = useState(null);

	useEffect(() => {
		if (ref.current) {
			const { offsetHeight, offsetWidth } = ref.current;
			const width = parentWidth ? parentWidth : offsetWidth;

			setSvgSize({
				height: offsetHeight,
				width
			});
		}
	}, [parentWidth]);

	return { ref, svgSize };
}
