// Import heleprs
import { GOOGLE_TASK_MANAGER } from 'helpers/variables';

const { PAGE_VIEW } = GOOGLE_TASK_MANAGER;

function gtag() {
	window.dataLayer.push(arguments);
}

export const initGA = () => {
	window.dataLayer = window.dataLayer || [];

	gtag('js', new Date());
	gtag('config', process.env.REACT_APP_GA_ID);
};

export const logPageViewGA = (props) => {
	// GA
	const { pathname, search } = window.location;

	const locationPath = pathname + search;

	const eventOptions = {
		locationPath,
		js: new Date(),
		...props
	};
	gtag('event', PAGE_VIEW, eventOptions);
};
