import React from 'react';
import { string, number, object, array, shape, bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import styles
import { customMinResponsive } from 'components/styles';
import { ItemDetailsHoverStyle } from 'components/views/vod/components/ItemDetails/styles';
import { ContinueWatchingData, Strap } from '../styles/progressBar';

// Import utilities
import useSliderItemLogic from './useSliderItemLogic';
import { useProductTranslations } from 'components/utilities';

// Import components
import { Avaiable } from 'components/elements';
import ItemDetails from 'components/views/vod/components/ItemDetails/ItemDetails';

export function SliderItem(props) {
	const { data, width, height, itemWithLink = true } = props;

	const { product } = useProductTranslations({ data });

	const {
		isAvailable,
		isContinueWatchingSection,
		isCollectionPage,
		backgroundImage,
		linkParams,
		continuePercent,
		backgroundSize
	} = useSliderItemLogic(props);

	return (
		<Item
			image={backgroundImage}
			width={width}
			height={height}
			backgroundSize={backgroundSize}
			itemWithLink={itemWithLink}
		>
			{itemWithLink && <StyledLink to={linkParams} />}
			{isAvailable && !isCollectionPage && (
				<AvailableBoxStyled>
					<IconTickStyled />
					<Avaiable.BgGradient />
				</AvailableBoxStyled>
			)}
			{isContinueWatchingSection && isAvailable && !!continuePercent && (
				<ContinueWatchingData>
					<Strap percent={continuePercent} />
				</ContinueWatchingData>
			)}

			<ItemDetails data={product} />
		</Item>
	);
}

SliderItem.propTypes = {
	sectionLabel: string.isRequired,
	data: shape({
		uuid: string.isRequired,
		images: object,
		subtype: string.isRequired,
		context: object,
		title: string,
		year: number,
		genres: array,
		rating: number,
		country: string
	}),
	width: number.isRequired,
	height: number.isRequired,
	sliderId: string,
	itemWithLink: bool
};

const Item = styled.div.attrs(
	({ width, height, image, backgroundSize, itemWithLink }) => ({
		style: {
			width,
			height,
			background: `url(${image}) no-repeat center center`,
			backgroundSize,
			cursor: itemWithLink ? 'pointer' : 'default'
		}
	})
)`
	position: relative;
	text-decoration: none;
	color: initial;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		color: initial;
	}

	${ItemDetailsHoverStyle}
`;

const StyledLink = styled(Link)`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 6;
`;

const AvailableBoxStyled = styled(Avaiable.Box)`
	${customMinResponsive(
		1750,
		css`
			top: -2.4rem;
			right: -4.8rem;
			width: 9.5rem;
			height: 6rem;
		`
	)}
`;

const IconTickStyled = styled(Avaiable.Icon)`
	position: absolute;
	bottom: 0.6rem;
	left: 2.7rem;
	height: 0.7rem;
	transform: rotate(-41deg);

	${customMinResponsive(
		1750,
		css`
			bottom: 0.7rem;
			left: 3.7rem;
			height: 0.8rem;
		`
	)}
`;
