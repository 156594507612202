import React from 'react';
import { func, bool, string } from 'prop-types';

// Import  styles
import { ButtonStyled } from './styles';

const PaymentOptionButton = ({
	onClick = () => {},
	isDisabled = false,
	buttonText = '',
	labelledBy,
	type = 'button'
}) => (
	<ButtonStyled
		aria-labelledby={labelledBy}
		modifiers="default"
		onClick={onClick}
		disabled={isDisabled}
		type={type}
	>
		{buttonText}
	</ButtonStyled>
);

PaymentOptionButton.propTypes = {
	onClick: func,
	isDisabled: bool,
	buttonText: string.isRequired,
	labelledBy: string,
	type: string
};

export default PaymentOptionButton;
