import * as types from 'store/actions/types';

// Update prev location history
export const updatePreviousLocation = (previousPath) => (dispatch) =>
	dispatch({
		type: types.UPDATE_PREVIOUS_LOCATION,
		payload: { previousPath }
	});

export const saveLatestNoAuthPath = (path) => (dispatch) =>
	dispatch({
		type: types.SAVE_LATEST_NO_AUTH_PATH,
		payload: path
	});
