import styled, { css } from 'styled-components';

const Grid = styled.div`
	display: grid;
	grid-gap: ${({ gap = '2rem' }) => gap};
	grid-template-columns: ${({ columnWidth = 300, minMaxFr = '1fr' }) =>
		minMaxFr
			? css`repeat(auto-fit, minmax(${columnWidth}px, 1fr))`
			: css`repeat(auto-fill, minmax(${columnWidth}px, 1fr))`};
	grid-auto-rows: ${({ rowsHeight = 8 }) => rowsHeight}rem;
`;

export default Grid;
