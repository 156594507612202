import { useLocation } from 'react-router-dom';

// Import helpers
import { getUserAgentInfo } from 'helpers';

const useAppRoutesDesktop = () => {
	const { isMobile, mobileType } = getUserAgentInfo();
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const query = searchParams.get('app') || false;
	const isFromApp = query === 'true';

	return { isDesktop: !isMobile, mobileType, isFromApp };
};

export default useAppRoutesDesktop;
