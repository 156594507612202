import React from 'react';
import { useTranslation } from 'react-i18next';
import { oneOfType, string, number } from 'prop-types';

// Import styles
import {
	NoContentWrapper,
	InfoIcon,
	NoContentHeader,
	NoContentDescription
} from './styles';

const NoContentMessage = ({ paddingTopDesktop, heightOnMobile }) => {
	const { t } = useTranslation();

	return (
		<NoContentWrapper
			heightOnMobile={heightOnMobile}
			paddingTopDesktop={paddingTopDesktop}
		>
			<InfoIcon />
			<NoContentHeader>{t('common_no_content_message_header')}</NoContentHeader>
			<NoContentDescription>
				{t('common_no_content_message_description')}
			</NoContentDescription>
		</NoContentWrapper>
	);
};

NoContentMessage.propTypes = {
	paddingTopDesktop: oneOfType([string, number]),
	heightOnMobile: oneOfType([string, number])
};

export default NoContentMessage;
