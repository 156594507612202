// Import utilities
import { useGTM } from 'components/utilities';

const Analitics = () => {
	// Google analitics
	useGTM();
	return null;
};

export default Analitics;
