import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { PlayerElements as Player } from 'components/elements';
import { ButtonStyled } from './styles';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const PlayerSeekController = () => {
	const { t } = useTranslation();
	const { updateSeekTime, resetFocus } = usePlayerVodContext();

	const handleSeekTime =
		(value = true) =>
		() => {
			resetFocus();
			updateSeekTime(value);
		};

	return (
		<>
			<ButtonStyled
				modifiers={['icon', 'playerIconHover']}
				onClick={handleSeekTime(false)}
				title={t('common_player_seek_prev_title')}
			>
				<Player.IconSeekPrev
					aria-hidden="true"
					title={t('common_player_seek_prev_title')}
				/>
			</ButtonStyled>

			<ButtonStyled
				modifiers={['icon', 'playerIconHover']}
				onClick={handleSeekTime()}
				title={t('common_player_seek_next_title')}
			>
				<Player.IconSeekNext
					aria-hidden="true"
					title={t('common_player_seek_next_title')}
				/>
			</ButtonStyled>
		</>
	);
};

export default PlayerSeekController;
