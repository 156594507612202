// ------------ Send reset mail ------------
export const SEND_RESET_EMAIL_LOADING = 'SEND_RESET_EMAIL_LOADING';
export const SEND_RESET_EMAIL_SUCCESS = 'SEND_RESET_EMAIL_SUCCESS';
export const SEND_RESET_EMAIL_ERROR = 'SEND_RESET_EMAIL_ERROR';

// ------------ Check reset password hash ------------
export const CHECK_RESET_PASSWORD_HASH_LOADING =
	'CHECK_RESET_PASSWORD_HASH_LOADING';
export const CHECK_RESET_PASSWORD_HASH_SUCCESS =
	'CHECK_RESET_PASSWORD_HASH_SUCCESS';
export const CHECK_RESET_PASSWORD_HASH_ERROR =
	'CHECK_RESET_PASSWORD_HASH_ERROR';

// ------------ Send new passwords ------------
export const SEND_NEW_PASSWORDS_LOADING = 'SEND_NEW_PASSWORDS_LOADING';
export const SEND_NEW_PASSWORDS_SUCCESS = 'SEND_NEW_PASSWORDS_SUCCESS';
export const SEND_NEW_PASSWORDS_ERROR = 'SEND_NEW_PASSWORDS_ERROR';

// ------------ Clear state ------------
export const CLEAR_RESET_PASSWORD_STATE = 'CLEAR_RESET_PASSWORD_STATE';
