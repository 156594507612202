import { useState, useRef, useEffect } from 'react';

const useAgreementsModal = () => {
	const [centerModalContent, setCenterModalContent] = useState(false);

	// ---- refs ----
	const contentRef = useRef(null);

	const childrenCount = contentRef?.current?.children?.length;

	useEffect(() => {
		const { current } = contentRef;

		if (current) {
			// if modal content fits in viewport modal must use display flex to center content
			const contentFitsInViewport = current.offsetHeight < window.innerHeight;
			setCenterModalContent(contentFitsInViewport);
		}
		// eslint-disable-next-line
	}, [contentRef, childrenCount]);

	return { centerModalContent, contentRef };
};

export default useAgreementsModal;
