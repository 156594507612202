import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	fetchUserDetails,
	fetchFavourites,
	fetchReminders,
	fetchUserPackets,
	fetchUserDevices,
	fetchMenuNavLinks,
	clearInstitution,
	fetchGenresList,
	fetchFreeVodPackets,
	fetchActiveSubscriptionsList
} from 'store/actions';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import utilities
import { useAuth, useCookies } from 'components/utilities';

const { LOGIN, REGISTER, HOME } = APP_CORE_PATHS;

const useContent = () => {
	const dispatch = useDispatch();
	const { isAuth } = useAuth();
	const { pathname } = useLocation();

	const { showCookieMessage, checkCookiesAccepted } = useCookies();

	const isHome = pathname.includes(HOME);
	const isAuthRoutes = pathname.includes(LOGIN) || pathname.includes(REGISTER);

	const handleFetchResources = async () => {
		isHome && clearInstitution()(dispatch);
		// Dispatch an fetchMenuNavLinks action in menu folder
		await fetchMenuNavLinks()(dispatch);
		// Dispatch a fetchFreeVodPackets action in vod packets folder
		await fetchFreeVodPackets()(dispatch);
		// Dispatch a fetchGenresList action in vod genres folder
		await fetchGenresList()(dispatch);
	};

	const handleFetchAuthResources = async () => {
		// Dispatch an fetchFavourites action in favourites folder
		await fetchFavourites()(dispatch);
		// Dispatch an fetchReminders action
		await fetchReminders()(dispatch);
		// Dispatch a fetchDevices action in devices folder
		await fetchUserDevices()(dispatch);
		// Dispatch an fetchUserDetails action in auth folder
		await fetchUserDetails()(dispatch);
		// Dispatch an fetchUserPackets action in auth folder
		await fetchUserPackets()(dispatch);
		await fetchActiveSubscriptionsList()(dispatch);
	};

	useEffect(() => {
		handleFetchResources();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		checkCookiesAccepted();
		// eslint-disable-next-line
	}, [pathname]);

	useEffect(() => {
		isAuth && handleFetchAuthResources();
		// eslint-disable-next-line
	}, [isAuth]);

	return {
		showCookieMessage: showCookieMessage && !isAuthRoutes
	};
};

export default useContent;
