import { useRef, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import { getProcessedItemsList } from './helpers';

export function useLogic(props) {
	const {
		itemsList,
		itemResolutionWidth,
		itemResolutionHeight,
		spaceBetweenItems,
		horizontalPadding,
		verticalPadding,
		headerHeight,
		itemsToShow,
		headerSliderGap,
		renderHeader,
		isCarousel
	} = props;

	const containerRef = useRef(null);
	const [processedItemsList, setProcessedItemsList] = useState([]);
	const [containerWidth, setContainerWidth] = useState(0);

	// SLIDER WIDTH
	const sliderWidth = containerWidth - 2 * horizontalPadding;
	// if no header component header height is set to 0
	const checkedHeaderHeight = !!renderHeader ? headerHeight : 0;

	const sumItemsWidth = sliderWidth - spaceBetweenItems * (itemsToShow - 1);
	const itemWidth = sumItemsWidth / itemsToShow;
	const itemAndSliderHeight =
		(itemWidth / itemResolutionWidth) * itemResolutionHeight;
	const containerHeight =
		itemAndSliderHeight +
		2 * verticalPadding +
		checkedHeaderHeight +
		headerSliderGap;
	// number of items to scroll / show at once
	const slidesToScroll =
		itemsToShow > itemsList.length ? itemsList.length : itemsToShow;

	const handleSetContainerWidth = () => {
		if (containerRef.current) {
			setContainerWidth(containerRef.current.offsetWidth);
		}
	};

	const throttleHandleResize = throttle(handleSetContainerWidth, 500);

	useEffect(() => {
		handleSetContainerWidth();
	}, [containerRef]);

	useEffect(() => {
		window.addEventListener('resize', throttleHandleResize);

		return () => {
			window.removeEventListener('resize', throttleHandleResize);
		};
	});

	useEffect(() => {
		const processedItemsList = getProcessedItemsList(
			itemsList,
			itemsToShow,
			isCarousel
		);

		setProcessedItemsList(processedItemsList);
		// eslint-disable-next-line
	}, [itemsToShow, itemsList]);

	return {
		containerRef,
		containerHeight,
		checkedHeaderHeight,
		headerSliderGap,
		containerWidth,
		itemAndSliderHeight,
		sliderWidth,
		slidesToScroll,
		itemWidth,
		processedItemsList
	};
}
