import { useContext } from 'react';

// Import context
import { FloatWidgetContext } from './FloatWidgetProvider';

const useFloatWidgetContext = () => {
	const context = useContext(FloatWidgetContext);

	if (!context) {
		throw new Error(
			'useFloatWidgetContext must be used within a FloatWidgetProvider'
		);
	}
	return context;
};

export default useFloatWidgetContext;
