import React from 'react';

// Import styles
import { Wrapper } from './styles';

// Import components
import useFloatPlayer from './useFloatPlayer';
import PlayerWrapper from 'components/elements/organisms/player_vod/PlayerWrapper';

const FloatPlayer = () => {
	const { floatMode, playerProps, isAuth } = useFloatPlayer();
	if (!isAuth) return null;

	return (
		<Wrapper {...floatMode}>
			<PlayerWrapper floatMode={floatMode} {...playerProps} />
		</Wrapper>
	);
};

export default FloatPlayer;
