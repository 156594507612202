import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';

// ******************** SUBMIT PRODUCT PURCHASE ********************
export const checkTransactionStatus = (orderId) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.TRANSACTION_FINALIZATION_LOADING
		});

		let url = `/payments/payment/${orderId}`;

		const { data } = await services.get(url);

		// Dispatch an action
		dispatch({
			type: types.TRANSACTION_FINALIZATION_SUCCESS,
			// FIX NAMING WHEN API READY
			payload: data
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);

			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Dispatch an error action
		dispatch({
			type: types.TRANSACTION_FINALIZATION_ERROR,
			payload: error
		});
	}
};
