import React from 'react';
import { useSelector } from 'react-redux';

const useVodProgress = (id, duration) => {
	const [progress, setProgress] = React.useState(0);
	const { data } = useSelector(({ continue_watching }) => continue_watching);

	React.useEffect(() => {
		const movie = data.find(({ uuid }) => uuid === id);

		if (movie && movie.ended) {
			setProgress(100);
		} else if (movie && movie.time && movie.time >= 4) {
			const percent = Math.ceil((movie.time / duration) * 100);
			setProgress(percent);
		}
	}, [data, id, duration]);

	return progress;
};

export default useVodProgress;
