import * as types from './types';
import produce from 'immer';
import { INITIAL_STATE } from 'components/elements/organisms/float-widget/useFloatWidget/helpers';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_PLAYER_OPEN:
				draft.isPlayerOpen = action.payload;
				break;

			case types.SET_FLOAT_MODE:
				draft.isFloatMode = action.payload;
				break;

			case types.SET_PAGE_LOADED:
				draft.isPageLoaded = action.payload;
				break;

			case types.SET_INIT_PAGE_MODE:
				draft.isPageLoaded = false;
				draft.isFloatMode = false;
				break;

			case types.SET_COORDINATES:
				draft.coordinates = action.payload;
				break;

			case types.SET_ALL_TO_DEFAULT:
				return {
					...INITIAL_STATE
				};

			default:
				return state;
		}
	});

export default reducer;
