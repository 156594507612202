import React from 'react';
import { string } from 'prop-types';

// Import components
import { PlayerElements as Player } from 'components/elements';

const PlayerError = ({ message }) => (
	<Player.Error isVideoBanner>
		<Player.ErrorContent isVideoBanner>
			<Player.IconLock aria-hidden role="img" />
			<Player.ErrorHeading role="alert">{message}</Player.ErrorHeading>
		</Player.ErrorContent>
	</Player.Error>
);

PlayerError.propTypes = {
	message: string
};

export default PlayerError;
