import React from 'react';
import { array } from 'prop-types';
import BannerItem from './BannerItem';
import {
	BannersSliderWrap,
	BannerItemContent,
	ContentWrapper,
	InnerWrapper
} from './styles';
import { Slider } from 'components/utilities';
import { sliderSettings } from '../helpers';
import { useMediaQuery } from 'react-responsive';
import CountdownTimer from './Timer/Timer';
import { MOBILE_DEVICES } from 'helpers/variables';

export const ExtraLowBanner = ({ content }) => {
	const isMobile = useMediaQuery({ query: MOBILE_DEVICES });
	const bannerHeight = isMobile ? 57 : 66;
	// TODO : ADD Translation
	return (
		<BannersSliderWrap>
			<Slider defaultBtn={false} settings={sliderSettings}>
				{content?.map(({ images, title, context, uuid }) => {
					const targetDate = context?.parameters.count_down_date;
					const isCountdownActive = context?.parameters.count_down_active;

					return (
						<BannerItem
							key={uuid}
							uuid={uuid}
							images={images}
							title={title}
							url={context.url_pc}
							bannerHeight={bannerHeight}
						>
							<ContentWrapper>
								<InnerWrapper isCountdownActive={isCountdownActive}>
									<BannerItemContent>{title}</BannerItemContent>
									{isCountdownActive && (
										<CountdownTimer targetDate={targetDate} />
									)}
								</InnerWrapper>
							</ContentWrapper>
						</BannerItem>
					);
				})}
			</Slider>
		</BannersSliderWrap>
	);
};
ExtraLowBanner.propTypes = {
	content: array.isRequired
};

export default ExtraLowBanner;
