import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Import helpers
import { PLAYER_ERROR_TYPES } from 'helpers/player';
import { checkPlayerType } from 'helpers';

// Import components
import { PlayerElements as Player } from 'components/elements';
import ParentalControlButton from './ParentalControlButton';
import Packets from './Packets';

const { BLOCKED_BY_PARENTAL_CONTROL, RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS } =
	PLAYER_ERROR_TYPES;

const PlayerError = ({
	error: { message, errorCode = '' },
	playerType,
	isFloatMode
}) => {
	const { pathname } = useLocation();
	const type = playerType || checkPlayerType(pathname);

	return (
		<Player.Error isFloatMode={isFloatMode}>
			<Player.ErrorContent>
				<Player.IconLock />
				<Player.ErrorHeading isFloatMode={isFloatMode}>
					{message}
				</Player.ErrorHeading>
				{errorCode === BLOCKED_BY_PARENTAL_CONTROL && (
					<ParentalControlButton playerType={type} />
				)}
				{errorCode === RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS && <Packets />}
			</Player.ErrorContent>
		</Player.Error>
	);
};

PlayerError.propTypes = {
	error: PropTypes.shape({
		message: PropTypes.string,
		errorCode: PropTypes.string
	}).isRequired,
	playerType: PropTypes.string,
	isFloatMode: PropTypes.bool.isRequired
};

export default PlayerError;
