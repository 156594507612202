import firebase from 'firebase/compat/app';

export const initializeFirebaseApp = () => {
	const firebaseConfig = {
		apiKey: process.env.REACT_APP_API_KEY,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_PROJECT_ID,
		storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_APP_ID
	};

	firebase.initializeApp(firebaseConfig);
};

export const hasFirebaseAuthentication = !!process.env.REACT_APP_FIREBASE_AUTH;
