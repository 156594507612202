import React from 'react';
import { string, node, object } from 'prop-types';

// Import utilities
import { useToggle } from 'components/utilities';

// Import components
import { BigPlayButton } from 'components/elements';

// Import styles
import { Wrapper } from './styles';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';

// Import variables
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';

const { COVER } = PRODUCT_IMAGES_TYPES;

const PlayerToggle = ({ children, type, images }) => {
	const [isPlayerVisible, toggle] = useToggle();

	const coverImage = getImage({ images, type: COVER });

	return isPlayerVisible ? (
		children
	) : (
		<Wrapper modifiers={type} cover={coverImage}>
			<BigPlayButton
				modifiers={['icon', 'catchup', 'playerIconHover']}
				onClick={toggle}
			/>
		</Wrapper>
	);
};

PlayerToggle.propTypes = {
	children: node.isRequired,
	type: string.isRequired,
	images: object
};

export default PlayerToggle;
