import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

// Import utilities
import { DrawerRPC, ToggleRPC } from 'components/utilities';

// Import icons
import { ReactComponent as RemindersIcon } from 'assets/icons/ic_nav_reminder.svg';

// Import components
import { Button, Loader, AuthModal } from 'components/elements';
import Reminder from './Reminder';

// Import logic
import useReminders from './useReminders';

const Reminders = () => {
	const { t } = useTranslation();

	const { isLoaded, sortedReminderProducts } = useReminders();

	const navItemTitle = t('common_reminders_title');

	const renderReminders = (toggle) =>
		sortedReminderProducts.map((reminder) => (
			<Reminder key={reminder.id} toggle={toggle} reminder={reminder} />
		));

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<AuthModal>
						<Tooltip placement="bottom" onClick={toggle} title={navItemTitle}>
							<Button
								modifiers={['navIcon']}
								spaceLeft="1rem"
								title={navItemTitle}
							>
								<RemindersIcon aria-hidden="true" title={navItemTitle} />
							</Button>
						</Tooltip>
					</AuthModal>

					<DrawerRPC onClose={toggle} title={navItemTitle} isOpen={on}>
						{isLoaded ? (
							renderReminders(toggle)
						) : (
							<Loader
								zIndex={900}
								position="relative"
								background={false}
								width={8}
								height={8}
								top="2rem"
							/>
						)}
					</DrawerRPC>
				</>
			)}
		</ToggleRPC>
	);
};

export default Reminders;
