import React from 'react';
import { arrayOf, number, oneOfType, node, object, bool } from 'prop-types';

// Import styles
import { SliderWrapper, StyledSlickSlider } from './styles';

// Import components
import SliderArrow from './SliderArrow/SliderArrow';

// import helpers
import { defaultSliderSettings } from './helpers';
import { SLIDER_ARROW_TYPES } from 'helpers/variables';

const { PREV, NEXT } = SLIDER_ARROW_TYPES;

const Slider = ({
	children,
	slidesToShow,
	settings = defaultSliderSettings,
	slideHeight,
	isDesktop
}) => {
	return (
		<SliderWrapper>
			<StyledSlickSlider
				nextArrow={<SliderArrow type={NEXT} slideHeight={slideHeight} />}
				prevArrow={<SliderArrow type={PREV} slideHeight={slideHeight} />}
				slidesToShow={slidesToShow}
				slidesToScroll={parseInt(slidesToShow, 10)}
				arrows={isDesktop}
				{...settings}
			>
				{children}
			</StyledSlickSlider>
		</SliderWrapper>
	);
};

Slider.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	slidesToShow: number,
	settings: object,
	slideHeight: number.isRequired,
	isDesktop: bool.isRequired
};

export default Slider;
