import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

// Import helpers
import { convertTime, createLinkParams } from 'helpers/index';

// Import components
import { Catchup } from 'components/elements';

import {
	Content,
	Top,
	Clock,
	Time,
	Title,
	Genres,
	Description,
	LinkStyled
} from './styles';

const PopoverContent = ({
	since,
	till,
	title,
	description_short,
	category,
	t,
	uuid,
	isCatchup = false
}) => {
	const description = description_short
		? description_short.split('').splice(0, 90).join('')
		: '';

	const linkDetails = createLinkParams({ id: uuid });
	return (
		<Content>
			<Top>
				<Clock /> <Time>{convertTime(since, till)}</Time>
			</Top>
			<Title>{title || '---'}</Title>
			{category && <Genres>{category}</Genres>}
			{isCatchup && <Catchup>catchup</Catchup>}

			{description_short ? (
				<React.Fragment>
					<Description>
						{description}
						...
					</Description>
					{uuid && (
						<LinkStyled to={linkDetails}>
							{t('common_popover_more')} &gt;
						</LinkStyled>
					)}
				</React.Fragment>
			) : null}
		</Content>
	);
};

PopoverContent.propTypes = {
	title: PropTypes.string,
	since: PropTypes.string.isRequired,
	till: PropTypes.string.isRequired,
	uuid: PropTypes.string,
	category: PropTypes.string,
	description_short: PropTypes.string,
	isCatchup: PropTypes.bool,
	t: PropTypes.func.isRequired
};

export default withTranslation()(PopoverContent);
