import styled, { css } from 'styled-components';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

export const ButtonCloseFullScreen = styled(Button)`
	position: absolute;
	top: 5rem;
	right: 4rem;
	z-index: 11;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	visibility: ${({ isFocus }) => (isFocus ? 'visible' : 'hidden')};

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			top: 0.5rem;
			right: 1.5rem;
		`}
`;

export const IconPlayStyled = styled(Player.IconPlay)`
	width: 15rem;
	height: 15rem;

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			height: 2.2rem;
			width: 2.2rem;
		`}
`;
