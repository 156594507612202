export const PLAYER_INITIAL_STATE = {
	isReady: false,
	buffering: true,
	isError: false,
	errorMessage: '',
	isPaused: true,
	isEnded: false,
	sessionIntervalTime: null,
	playlistData: null
};
