import styled, { css } from 'styled-components';
// Import styles
import { customMinResponsive, size, flex } from 'components/styles';

// Import components
import { Input } from 'components/elements';

export const CodeFieldStyled = styled.div`
	${flex({ ai: 'flex-start', jc: 'space-between' })}
	flex-direction: column;
	width: 100%;
	margin-bottom: 1.5rem;
	${customMinResponsive(
		size.medium,
		css`
		width: 12.9rem
		flex-direction: row;
		margin: 0 1rem 0 0;
  	`
	)}
`;

export const StyledInput = styled(Input)`
	height: 5rem;
	color: ${({ theme, error }) =>
		error ? theme.error.color : theme.user.tableItemName};
	font-size: 2.8rem;
`;
