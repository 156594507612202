import styled, { css } from 'styled-components';

// Import icons
import { ReactComponent as IconAgeRating0 } from 'assets/icons/age_rating_0.svg';
import { ReactComponent as IconAgeRating7 } from 'assets/icons/age_rating_7.svg';
import { ReactComponent as IconAgeRating12 } from 'assets/icons/age_rating_12.svg';
import { ReactComponent as IconAgeRating16 } from 'assets/icons/age_rating_16.svg';
import { ReactComponent as IconAgeRating18 } from 'assets/icons/age_rating_18.svg';

const baseIconStyle = css`
	filter: ${({ iscontrast }) => (iscontrast ? 'grayscale(1)' : 'grayscale(0)')};
`;

export const StyledIconAgeRating0 = styled(IconAgeRating0)`
	${baseIconStyle}
`;
export const StyledIconAgeRating7 = styled(IconAgeRating7)`
	${baseIconStyle}
`;
export const StyledIconAgeRating12 = styled(IconAgeRating12)`
	${baseIconStyle}
`;
export const StyledIconAgeRating16 = styled(IconAgeRating16)`
	${baseIconStyle}
`;
export const StyledIconAgeRating18 = styled(IconAgeRating18)`
	${baseIconStyle}
`;
