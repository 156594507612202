import React from 'react';
import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';

// Import styles
import { Container } from './styles';

const Copyrights = () => {
	const { t } = useTranslation();

	const currentYear = getYear(new Date());
	return (
		<Container>
			<p>
				{currentYear} {t('common_footer_copyrights')}
			</p>
		</Container>
	);
};

export default Copyrights;
