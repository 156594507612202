import React from 'react';
import { useSelector } from 'react-redux';
import { number, string, func, object, bool } from 'prop-types';

// Import helpers
import { getAssetInfo } from '../helpers';

// Import styles
import { Figure, Image } from '../styles';

const GalleryItem = ({
	width,
	height,
	url,
	cursor,
	onItemClick,
	isFullscreen,
	...restProps
}) => {
	const { description } = getAssetInfo(restProps);
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	return (
		<Figure isFullscreen={isFullscreen} width={width} height={height}>
			<Image
				src={url}
				alt={description}
				cursor={cursor}
				onClick={onItemClick}
				isContrast={isContrast}
				isFullscreen={isFullscreen}
			/>
		</Figure>
	);
};

GalleryItem.propTypes = {
	width: number,
	height: number,
	url: string,
	cursor: string,
	onItemClick: func,
	galleryMetadata: object,
	metadata: object,
	isFullscreen: bool
};

export default GalleryItem;
