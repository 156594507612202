import * as types from 'store/actions/types';
import services from 'services/services';

// ******************** FETCH AVAILABLE CHANNELS ********************
export const fetchTvCategory = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_TV_CATEGORY_LOADING,
			payload: true
		});

		// Get tv categories
		const { data } = await services.get(`/products/genres/channel`);

		// Dispatch an action
		dispatch({
			type: types.FETCH_TV_CATEGORY_SUCCESS,
			payload: data.data
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_TV_CATEGORY_ERROR,
			payload: true
		});
	}
};
