import React from 'react';
import { arrayOf, number, string, shape, oneOfType, node } from 'prop-types';

// Import styles
import * as SubNav from './styles';

// Import components
import SubNavItem from './SubNavItem';
import { MenuList as SubNavList } from '@reach/menu-button';

const SubNavigation = ({ submenu, basePath, children }) => {
	return (
		<>
			{children}
			<SubNavList
				isSubmenu={!!submenu.length}
				role="menu-submenu"
				as={SubNav.List}
			>
				{/* Main item to show all */}
				<SubNavItem isMainItem basePath={basePath} />

				{submenu?.map((sublink) => (
					<SubNavItem key={sublink.id} sublink={sublink} basePath={basePath} />
				))}
			</SubNavList>
		</>
	);
};

SubNavigation.propTypes = {
	submenu: arrayOf(
		shape({
			id: number.isRequired,
			name: string.isRequired,
			link: string.isRequired,
			model_id: string,
			model_type: string
		})
	).isRequired,
	children: oneOfType([node, arrayOf(node)]).isRequired,
	basePath: string.isRequired
};

export default SubNavigation;
