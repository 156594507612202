import { useSelector } from 'react-redux';

// Import helpres
import { getFontSize } from 'helpers/wcag';
import { setFontSize } from 'components/theme/fonts';

// Import theme
import { mainTheme } from 'components/theme/mainTheme';

/*
  Custom hook that return main theme and font size options that depend on wcag settings
 */
const useTheme = () => {
	const { fontSize } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	const size = getFontSize(fontSize);
	const fontSizeOptions = setFontSize(size);

	return { ...mainTheme, ...fontSizeOptions };
};

export default useTheme;
