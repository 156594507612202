import React from 'react';
import { array, func } from 'prop-types';

// Import utilities
import { useCarousel } from 'components/utilities';

//Import components
import { CarouselBackground } from 'components/views/vod/components/sliders/Carousel/CarouselBackground';
import BasicInfo from './BasicInfo/BasicInfo';
import { GradientBg } from 'components/elements';

// Import styles
import { Root } from './styles';

const Carousel = ({ content, children }) => {
	const {
		currentIndex,
		currentItem,
		changeCurrentIndex,
		startStopCarousel,
		isContrast
	} = useCarousel({ content });

	return (
		<Root>
			{!isContrast && (
				<CarouselBackground content={content} currentIndex={currentIndex} />
			)}
			<BasicInfo
				startStopCarousel={startStopCarousel}
				kids={false}
				currentItem={currentItem}
			/>

			{children({ changeCurrentIndex })}
			<GradientBg mobileHeight="100%" />
		</Root>
	);
};

Carousel.propTypes = {
	content: array.isRequired,
	children: func.isRequired
};

export default Carousel;
