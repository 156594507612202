import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	configuration: {
		videoSession: { videoSessionId: null, now: null, till: null }
	},
	playlist: '',
	videoSessionId: null,
	isLoaded: false,
	isReady: false,
	error: { isError: false, name: '', message: '', errorCode: null }
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Fetch static content *******************
			case types.FETCH_PLAYER_DETAILS_CONFIG_LOADING:
				return { ...INITIAL_STATE };

			case types.FETCH_PLAYER_DETAILS_CONFIG_SUCCESS:
				draft.isLoaded = true;
				draft.error = { isError: false, name: '', message: '' };
				draft.configuration = action.payload.config;
				draft.playlist = action.payload.playlist;
				draft.videoSessionId = action.payload.videoSessionId;
				return;

			case types.FETCH_PLAYER_DETAILS_CONFIG_ERROR:
				const { code, message, errorCode } = action.payload;
				draft.isLoaded = true;
				draft.error = { isError: true, code, message, errorCode };
				return;

			// ******************* Delete session *******************
			case types.PLAYER_DETAILS_DELETE_VIDEO_SESSION_LOADING:
				draft.configuration = {
					videoSession: { videoSessionId: null, now: null, till: null }
				};
				draft.playlist = '';
				draft.isLoaded = false;
				draft.isReady = false;
				draft.error = { isError: false, name: '', message: '' };
				return;

			case types.PLAYER_DETAILS_DELETE_VIDEO_SESSION_SUCCESS:
				return { ...INITIAL_STATE };

			// ******************* Update video session id *******************
			case types.PLAYER_DETAILS_UPDATE_VIDEO_SESSION_SUCCESS:
				draft.videoSessionId = action.payload.data.videoSessionId;
				draft.configuration.videoSession = action.payload.data;
				draft.playlist = action.payload.playlist;
				return;

			// ******************* Player ready *******************
			case types.PLAYER_DETAILS_IS_READY:
				draft.isReady = action.payload;
				return;

			// ******************* Player playback error *******************
			case types.PLAYER_SET_PLAYBACK_ERROR:
				draft.isLoaded = true;
				draft.error = {
					isError: true,
					name: action.payload.code,
					message: action.payload.message
				};
				return;

			// ******************* Clear player playback error *******************
			case types.PLAYER_DETAILS_CLEAR_PLAYBACK_ERROR:
				draft.error = {
					isError: false,
					name: '',
					message: ''
				};
				return;

			default:
				return state;
		}
	});
