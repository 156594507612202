import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = { title: '', id: 0, channel: '', channelID: null };

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Set selected program *******************
			case types.SET_SELECTED_PROGRAM:
				draft.title = action.payload.title;
				draft.id = action.payload.id;
				draft.channel = action.payload.channel;
				draft.channelID = action.payload.channelID;
				return;

			// ******************* Clear selected program *******************
			case types.CLEAR_SELECTED_PROGRAM:
				return { ...INITIAL_STATE };

			default:
				return state;
		}
	});
