import {
	fetchPlayerConfig,
	fetchMovieDetails,
	fetchContinueWatchingData,
	fetchMovieEpisodesPrevNext,
	fetchRecommendations
} from 'store/actions';
import { PLAYER_SELECTORS, QUERY_SUBTYPES } from 'helpers/variables';

export const getIsEpisode = (type) => type === QUERY_SUBTYPES.series;

export const fetchMovieResources = async ({ id, type, dispatch }) => {
	const playerSelector = PLAYER_SELECTORS.VOD;
	const isEpisode = getIsEpisode(type);

	// Dispatch an fetchContinueWatchingData action in continue watching folder
	await fetchContinueWatchingData(id)(dispatch);
	// Dispatch an fetchMovieDetails action in movieDetails folder
	await fetchMovieDetails(id, 'vod', dispatch);

	// Dispatch an fetchMovieEpisodesPrevNext action in movieDetails folder
	isEpisode && (await fetchMovieEpisodesPrevNext(id, dispatch));
	// Dispatch an fetchRecommendations action in recommendations folder
	!isEpisode && (await fetchRecommendations(id, 'vod', dispatch));
	// Dispatch an fetchPlayerConfig action in player folder
	await fetchPlayerConfig({
		productID: id,
		type: playerSelector,
		videoType: 'vod'
	})(dispatch);
};
