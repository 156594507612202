import * as types from 'store/actions/types';

// ******************** SET PIP AVAILABE ********************
export const setPipAvailabe = (isPipAvailable = false) => (dispatch) => {
	dispatch({
		type: types.SET_IS_PIP_AVAILABLE,
		payload: isPipAvailable
	});
};
// ******************** SET IS PIP ON ********************
export const setPipOn = (isPipOn = false) => (dispatch) => {
	dispatch({
		type: types.SET_IS_PIP_ON,
		payload: isPipOn
	});
};
