import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from '@reach/menu-button';

// import helpers
import { menuItems } from '../helpers';

// Import styles
import { OptionsList } from './styles';
import {
	StyledMenuButton,
	IconReminder
} from 'components/elements/molecules/common_button/styles';

// Import components
import OptionsItem from './OptionsItem';

const Options = ({ timeDifference, startDate }) => {
	const { t } = useTranslation();
	const buttonRef = useRef(null);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		setWidth(buttonRef.current?.offsetWidth);
	}, [buttonRef]);

	return (
		<Menu>
			<StyledMenuButton ref={buttonRef}>
				<IconReminder aria-hidden="true" /> {t('vod_buttons_live_add_reminder')}
			</StyledMenuButton>
			<OptionsList width={width}>
				{menuItems.map((item) => (
					<OptionsItem
						key={item.id}
						startDate={startDate}
						timeDifference={timeDifference}
						{...item}
					/>
				))}
			</OptionsList>
		</Menu>
	);
};

Options.propTypes = {
	timeDifference: PropTypes.number.isRequired,
	startDate: PropTypes.object.isRequired
};

export default Options;
