import { useSelector } from 'react-redux';

// Import helpers
import { remindersSorting } from './helpers';

const useReminders = () => {
	const { data: reminders, isLoaded } = useSelector((store) => store.reminders);

	// sort reminders by program start time
	// if some reminders have the same program start time sort them by program title
	const sortedReminderProducts = remindersSorting({ reminders });

	return { isLoaded, sortedReminderProducts };
};

export default useReminders;
