import * as types from './types';

export const setSelectedPriceId = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SELECTED_PRICE_ID, payload });
};

export const setPaymentMethods = (payload) => (dispatch) => {
	dispatch({ type: types.SET_PAYMENT_METHODS, payload });
};

export const setTransactionType = (payload) => (dispatch) => {
	dispatch({ type: types.SET_TRANSACTION_TYPE, payload });
};

export const setUserPromotions = (payload) => (dispatch) => {
	dispatch({ type: types.SET_USER_PROMOTIONS, payload });
};

export const setPromotion = (payload) => (dispatch) => {
	dispatch({ type: types.SET_PROMOTION, payload });
};

export const setInvoiceAgreement = (payload) => (dispatch) => {
	dispatch({ type: types.SET_INVOICE, payload });
};
export const clearPromotion = () => (dispatch) => {
	dispatch({ type: types.CLEAR_PROMOTION });
};
