import { convertAvailabilityDates } from './time';

export const FAVOURITE_CATEGORY = 'ulubione';

// ===== EPG OPTIONS =====
export const EPG_WIDTH = 7200;
export const EPG_MOBILE_WIDTH = 1920;
export const EPG_ITEM_WIDTH = 300;
export const EPG_MOBILE_ITEM_WIDTH = 80;
export const EPG_ITEM_HEIGHT = 160;

export const checkIsFavourite = (favourites, productUuid) =>
	!!favourites.find(({ uuid }) => uuid === productUuid);

export const getChannelsWithPrograms = (lives) =>
	lives.filter((item) => item.noPrograms !== true);

export const getFavouriteChannels = (
	lives,
	favourites,
	withPrograms = true
) => {
	const channels = withPrograms ? getChannelsWithPrograms(lives) : lives;

	return channels.reduce((acc, next) => {
		const channel = favourites.find(({ uuid }) => uuid === next.uuid);
		if (channel) {
			acc.push(next);
		}
		return acc;
	}, []);
};
export const getFilteredChannels = (category, lives, withPrograms = true) => {
	const channels = withPrograms ? getChannelsWithPrograms(lives) : lives;
	return category
		? channels.filter(
				({ genres }) =>
					genres.length && genres.find(({ slug }) => slug === category)
		  )
		: channels;
};

export const getLivePrograms = (item) => {
	const { epgProgrammes, currentLiveIndex, noPrograms } = item;
	if (epgProgrammes[currentLiveIndex] && !noPrograms) return item;
};

export const flatten = (lives, hideUnavailable) =>
	lives.reduce((acc, next) => {
		const { subscriberLocalLimited, epgProgrammes, avaiable } = next;
		// Check if channel is limited;
		const limited = subscriberLocalLimited || !avaiable;
		const isAvailable = hideUnavailable && limited;
		// Return null if settings hideUnavailable, not paid and subscribeLocalLimited are turn on
		!isAvailable && acc.push(...epgProgrammes);
		return acc;
	}, []);

export const getChanelsResources = (lives, hideUnavailable) =>
	[].concat(lives).reduce((acc, live) => {
		const { subscriberLocalLimited, avaiable, noPrograms } = live;
		// Check if channel is limited;
		const limited = subscriberLocalLimited || !avaiable;
		const isAvailable = hideUnavailable && limited;

		// Return null if settings hideUnavailable, not paid and subscribeLocalLimited are turn on
		!isAvailable &&
			!noPrograms &&
			acc.push({
				images: live.images,
				subscriberLocalLimited: live.subscriberLocalLimited,
				paid: live.avaiable,
				catchupAvailable: live.catchupAvailable,
				id: live.id
			});
		return acc;
	}, []);

export const measureEpg = () => ({
	epgWidth: 7200,
	epgItemWidth: 300,
	epgItemHeight: 70,
	resize: false
});

export const getCategoryEpg = (pathname) => pathname.split('/')[3];

export const getProgramTimes = (program) => {
	const { sinceDate, tillDate } = convertAvailabilityDates({
		since: program.since,
		till: program.till
	});

	// Get timestamps
	const now = new Date().getTime();
	const since = new Date(sinceDate).getTime();
	const till = new Date(tillDate).getTime();

	return { now, since, till };
};

export const isProgramLive = (program) => {
	const { now, since, till } = getProgramTimes(program);

	return now >= since && now <= till;
};
