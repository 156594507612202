import React from 'react';
import { string } from 'prop-types';

// Import componens
import { SliderSection, Loader } from 'components/elements';
import { Container } from './styles';

// Import logic
import usePacketSlider from './usePacketSlider';

const PacketSlider = ({ sliderTitle }) => {
	const { isLoading, products } = usePacketSlider();

	if (isLoading) return <Loader position="static" background={false} />;

	if (!products.length) return null;

	return (
		<Container>
			<SliderSection
				showAll={false}
				name={sliderTitle}
				content={products}
				modifiers="collection"
				id="packet_slider"
				type="packet"
			/>
		</Container>
	);
};

PacketSlider.propTypes = {
	sliderTitle: string
};

export default PacketSlider;
