// Import translation
import i18n from 'i18next';

// Import helpers
import { checkPinFormat } from 'helpers';

export const disabledValidation = ({ pin }) => {
	const errors = {};

	const isPinValid = checkPinFormat(pin);

	// ------------------ PIN ------------------
	if (!pin) {
		errors.pin = i18n.t('parental_control_pin_required');
	} else if (!isPinValid) {
		errors.pin = i18n.t('parental_control_pin_format');
	}

	return errors;
};

export const enableValidation = ({ rating }) => {
	const errors = {};

	// ------------------ PRating ------------------
	if (rating < 0) {
		errors.rating = i18n.t('parental_control_age_required');
	}

	return errors;
};
