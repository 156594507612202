import styled, { css } from 'styled-components';
import { flex } from 'components/styles';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: space-between;
	column-gap: 1.2rem;
	padding: 0 2rem;
	left: 50%;
	transform: translateX(-50%);
	bottom: 5%;
	z-index: 11;
	> a {
		max-width: 10rem;
		width: 100%;
	}

	${customMinResponsive(
		size.medium,
		css`
			width: fit-content;
			padding: 0 4rem;
			transform: translateX(0);
			left: auto;
			right: 0;
		`
	)}
`;

export const FlexWrapper = styled.div`
	${flex({ jc: 'center' })};
	margin-left: auto;
`;
