import services from 'services/services';
import * as types from 'store/actions/types';

export const fetchProductGallery = (id) => async (dispatch) => {
	try {
		dispatch({ type: types.FETCH_PRODUCT_GALLERY_LOADING });

		let url = `/assets/groups/${id}/list`;

		const {
			data: { data }
		} = await services.get(url);

		const gallery = data?.length ? data[0] : null;

		dispatch({
			type: types.FETCH_PRODUCT_GALLERY_SUCCESS,
			payload: gallery
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_PRODUCT_GALLERY_ERROR
		});
	}
};

export const clearProductGallery = () => async (dispatch) => {
	dispatch({ type: types.CLEAR_PRODUCT_GALLERY });
};
