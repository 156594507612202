import React from 'react';
import { array } from 'prop-types';

// Import styles
import { Head } from 'components/elements/molecules/table/styles';

// Import components
import { TableHeaderRow } from '.';

const TableHead = ({ headerGroups }) => (
	<Head className="blueonline-table__head">
		{headerGroups.map((headerGroup, index) => (
			<TableHeaderRow
				key={headerGroup.headers[index].id}
				headerGroup={headerGroup}
			/>
		))}
	</Head>
);

TableHead.propTypes = {
	headerGroups: array.isRequired
};

export default TableHead;
