import * as types from 'store/actions/types';

const INITIAL_STATE = {
	loading: false,
	error: false,
	details: {}
};

export default function(state = INITIAL_STATE, action = {}) {
	switch (action.type) {
		case types.SECTION_DETAILS_LOADING:
			return {
				...state,
				loading: true,
				error: false
			};
		case types.SECTION_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				details: action.details
			};
		case types.SECTION_DETAILS_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};
		case types.SECTION_DETAILS_CLEAR:
			return INITIAL_STATE;
		default:
			return state;
	}
}
