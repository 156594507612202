import React from 'react';
import { func, bool } from 'prop-types';

// Import styled components
import { HamburgerBtn } from './styles';

const Hamburger = ({ isOpen, toggle }) => (
	<HamburgerBtn onClick={toggle} isOpen={isOpen}>
		<span />
		<span />
		<span />
	</HamburgerBtn>
);

Hamburger.propTypes = {
	isOpen: bool.isRequired,
	toggle: func.isRequired
};

export default Hamburger;
