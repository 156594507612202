import { isProgramLive } from "helpers/epg";


const getProgramIndex = (program) => {
	// Check if program is live now
	return isProgramLive(program);
};

export const setNextChannel = (channel, currentLiveIndex) => {
	if (channel.epgProgrammes.length - 1 !== currentLiveIndex) {
		const currentProgram = channel.epgProgrammes[currentLiveIndex];

		if (!currentProgram) return null;

		if (isProgramLive(currentProgram)) {
			return null;
		} else {
			const isProgramIndex = channel.epgProgrammes.findIndex(getProgramIndex);

			return isProgramIndex && isProgramIndex !== currentLiveIndex
				? isProgramIndex
				: null;
		}
	} else {
		return null;
	}
};
