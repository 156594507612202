import React from 'react';
import { func } from 'prop-types';

// Import components
import Gallery from './components/Gallery';
import { FullscreenModal } from 'components/elements';

// Import styles
import { FullscreenWrapper } from './styles';

// Import utilities
import { ToggleRPC } from 'components/utilities';

// Import logic
import { useProductGallery } from './hooks';

const ProductGallery = ({ onHeightChange }) => {
	const { smallGalleryIndex, setSmallGalleryIndex, isMobile } =
		useProductGallery();

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<Gallery
						onHeightChange={onHeightChange}
						onItemClick={toggle}
						cursor="zoom-in"
						smallGalleryIndex={smallGalleryIndex}
						setSmallGalleryIndex={setSmallGalleryIndex}
					/>
					{on && !isMobile && (
						<FullscreenModal onExit={toggle}>
							<FullscreenWrapper>
								<Gallery smallGalleryIndex={smallGalleryIndex} isFullscreen />
							</FullscreenWrapper>
						</FullscreenModal>
					)}
				</>
			)}
		</ToggleRPC>
	);
};

ProductGallery.propTypes = {
	onHeightChange: func
};

export default ProductGallery;
