import movieDetails from './movie_details_reducer';
import tvShowSeason from './tv_show_season_reducer';
import sectionDetails from './section_details_reducer';
import moviesList from './movies_list_reducer';
import genresList from './genres_list_reducer';
import packets from './packets_reducer';
import packet_details from './packet_details_reducer';
import recommendations from './recommendations_reducer';
import continue_watching from './continue_watching_reducer';
import product_packets from './product_packets_reducer';
import promotions from './promotions_reducer';
import voucherPromotions from './voucher_promotions_reducer';
import freeVodPackets from './packets_free_vod';
import geolockPurchase from './geolock_purchase_reducer';

export default {
	movieDetails,
	tvShowSeason,
	sectionDetails,
	moviesList,
	genresList,
	product_packets,
	packets,
	packet_details,
	recommendations,
	continue_watching,
	promotions,
	voucherPromotions,
	freeVodPackets,
	geolockPurchase
};
