import React from 'react';
import { string, number, bool } from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import helpers
import { createLinkParams } from 'helpers';
import { getDetailsButtonTranslation } from './helpers';

// Import components
import { CommonButton } from 'components/elements';

const DetailsButton = ({ uuid, width, subtype, title, ...restProps }) => {
	const { t } = useTranslation();

	const { name } = useParams();

	const linkRedirect = createLinkParams({
		id: uuid,
		name,
		type: subtype,
		title
	});

	const buttonTranslation = getDetailsButtonTranslation(restProps);

	return (
		<CommonButton isPrime as={Link} width={width} to={linkRedirect}>
			{t(buttonTranslation)}
		</CommonButton>
	);
};

DetailsButton.defaultProps = {
	width: 144,
	subtype: '',
	title: '',
	type: '',
	is_audio: false
};

DetailsButton.propTypes = {
	uuid: string.isRequired,
	width: number,
	subtype: string.isRequired,
	type: string.isRequired,
	is_audio: bool.isRequired,
	title: string
};

export default DetailsButton;
