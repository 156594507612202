import React from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';

// Import styled components
import { StyledSubMenu, StyledMenuItem, StyledLink } from '../common_styles';

// Import variables
import { USER_MENU_NAV_LINKS } from 'helpers/user_menu_nav';

const USER_MOBILE_MENU_UNIQUE_KEY = 'user_mobile_menu';

const UserMenu = ({ toggle, ...props }) => {
	const { t } = useTranslation();
	const renderMenuItems = USER_MENU_NAV_LINKS.map(({ path, translation }) => (
		<StyledMenuItem key={`${translation}-mobile`}>
			<StyledLink to={path} onClick={toggle}>
				{t(`user_profile_${translation}`)}
			</StyledLink>
		</StyledMenuItem>
	));
	return (
		<StyledSubMenu
			key={USER_MOBILE_MENU_UNIQUE_KEY}
			title={t('user_profile_heading')}
			{...props}
			eventKey={`item_${USER_MOBILE_MENU_UNIQUE_KEY}`}
			subMenuKey={`${USER_MOBILE_MENU_UNIQUE_KEY}-menu-`}
		>
			{renderMenuItems}
		</StyledSubMenu>
	);
};

UserMenu.propTypes = {
	toggle: func.isRequired
};

export default UserMenu;
