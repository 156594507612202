import React from 'react';
import PropTypes from 'prop-types';

// Import styles
import { Wrapper, Content } from './styles';

const SectionNavWrapper = ({
	children,
	isInverse = false,
	columns = '1fr auto',
	epg = false,
	boxShadow = false
}) => (
	<Wrapper isInverse={isInverse} boxShadow={boxShadow} epg={epg}>
		<Content epg={epg} columns={columns}>
			{children}
		</Content>
	</Wrapper>
);

SectionNavWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	epg: PropTypes.bool,
	isInverse: PropTypes.bool,
	columns: PropTypes.string,
	boxShadow: PropTypes.bool
};

export default SectionNavWrapper;
