import React from 'react';

import { arrayOf, number, string, array, shape } from 'prop-types';

// Import styles
import { NavLinksListStyled } from './styles';

// Import components
import NavLink from './NavLink';

// Import utilities
import { useAuth } from 'components/utilities';

const NavLinks = ({ menuNavLinks }) => {
	const { isAuth } = useAuth();

	const renderNav = menuNavLinks.map((linkData) => (
		<NavLink linkData={linkData} key={linkData.id} />
	));

	return (
		<NavLinksListStyled dropdown role="menu" isAuth={isAuth}>
			{menuNavLinks.length && renderNav}
		</NavLinksListStyled>
	);
};

NavLinks.propTypes = {
	menuNavLinks: arrayOf(
		shape({
			id: number.isRequired,
			name: string.isRequired,
			link: string.isRequired,
			submenu: array,
			model_id: string,
			model_type: string
		})
	).isRequired
};

export default NavLinks;
