// -----------------  FETCH PAYMENT OPTIONS -----------------
export const FETCH_PAYMENT_TYPES_LOADING = 'FETCH_PAYMENT_TYPES_LOADING';
export const FETCH_PAYMENT_TYPES_SUCCESS = 'FETCH_PAYMENT_TYPES_SUCCESS';
export const FETCH_PAYMENT_TYPES_ERROR = 'FETCH_PAYMENT_TYPES_ERROR';

// -----------------  FETCH PAYMENT INIT -----------------
export const FETCH_PAYMENT_INIT_LOADING = 'FETCH_PAYMENT_INIT_LOADING';
export const FETCH_PAYMENT_INIT_SUCCESS = 'FETCH_PAYMENT_INIT_SUCCESS';
export const FETCH_PAYMENT_INIT_ERROR = 'FETCH_PAYMENT_INIT_ERROR';

// -----------------  FETCH PAYMENT AGREEMENTS -----------------
export const FETCH_PAYMENT_AGREEMENTS_LOADING =
	'FETCH_PAYMENT_AGREEMENTS_LOADING';
export const FETCH_PAYMENT_AGREEMENTS_SUCCESS =
	'FETCH_PAYMENT_AGREEMENTS_SUCCESS';
export const FETCH_PAYMENT_AGREEMENTS_ERROR = 'FETCH_PAYMENT_AGREEMENTS_ERROR';

// -----------------  CHECK INVOICE CREDITS -----------------
export const CHECK_INVOICE_CREDITS = 'CHECK_INVOICE_CREDITS';

// -----------------  CLEAR PAYMENT -----------------
export const PAYMENT_CLEAR = 'PAYMENT_CLEAR';

// -----------------  SET MEMORIZED AGREEMENTS -----------------
export const SET_MEMORIZED_AGREEMENTS = 'SET_MEMORIZED_AGREEMENTS';

// -----------------  CLEAR MEMORIZED AGREEMENTS -----------------
export const CLEAR_MEMORIZED_AGREEMENTS = 'CLEAR_MEMORIZED_AGREEMENTS';
