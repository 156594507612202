import _ from 'lodash';

export const VOUCHER_EXPENSIVE = 'voucher_expensive';
export const VOUCHER_INCLUDES = 'voucher_included';
export const VOUCHER_NOT_EXIST = 'voucher_not_exist';

// initial promotion item
export const INITIAL_PROMOTION = {
	id: null,
	price: null,
	voucherCode: null,
	voucherMessage: ''
};

const NO_PROMOTION = {
	...INITIAL_PROMOTION,
	voucherMessage: VOUCHER_NOT_EXIST
};

// get promotion available for user
const getAvailablePromotions = ({ promotions, userPromotions }) =>
	promotions
		? Object.keys(promotions)
				.filter((key) => userPromotions.includes(parseInt(key)))
				.reduce((obj, key) => {
					obj[key] = promotions[key];
					return obj;
				}, {})
		: null;

// create array of promotions objects
// sort promotions by price and return first element
const getFavorablePromotion = (array) => {
	const promotions = array
		? Object.entries(array).map(([id, price]) => ({
				...INITIAL_PROMOTION,
				id,
				price
		  }))
		: null;

	return _.sortBy(promotions, 'price')[0];
};

// get the most favorable promotion for user
export const getPromotion = ({ price, promotions, userPromotions }) => {
	const availablePromotions = getAvailablePromotions({
		promotions,
		userPromotions
	});

	const promotion = getFavorablePromotion(availablePromotions);

	const isPromotion = promotion?.price < price;

	return isPromotion ? promotion : NO_PROMOTION;
};

export const getVoucherPromotion = ({
	data,
	userPromotions,
	voucherCode,
	selectedPriceId
}) => {
	const selectedPrice = data.find(({ id }) => id === selectedPriceId);

	let promotionData = NO_PROMOTION;

	if (selectedPrice) {
		const { promotions } = selectedPrice;

		const availablePromotions = getAvailablePromotions({
			promotions,
			userPromotions
		});

		const isPromotions = !_.isEmpty(availablePromotions);

		if (isPromotions) {
			const promotion = getFavorablePromotion(availablePromotions);

			promotionData = {
				...promotion,
				voucherCode,
				voucherMessage: VOUCHER_INCLUDES
			};
		}
	}

	return promotionData;
};
