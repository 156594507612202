import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

// Import helpers
import { createNavList } from 'helpers';

// Import styles
import { getNavLinkUnderline } from 'components/styles';

// Import utilities
import ToggleRPC from 'components/utilities/rpc/ToggleRPC';

// Import components
import SidebarSearch from './sidebar_search/SidebarSearch';

const SidebarNav = ({ categories, category, setCategory }) => {
	// Create nav list's items
	const NAV_CATEGORIES = createNavList(categories.slice(0, 3), 'all');

	const renderItemNav = ({ slug, id, name }) => (
		<Item key={id}>
			<ItemNav
				onClick={handleChangeCategory(slug)}
				slug={slug}
				category={category}
			>
				{name}
			</ItemNav>
		</Item>
	);
	const handleChangeCategory = (slug) => () => setCategory(slug);
	return (
		<SidebarNavStyled>
			<ToggleRPC>
				{({ on, toggle }) => (
					<>
						<List isVisible={on}>{NAV_CATEGORIES.map(renderItemNav)}</List>
						<SidebarSearch isOpen={on} openSearchBox={toggle} />
					</>
				)}
			</ToggleRPC>
		</SidebarNavStyled>
	);
};

const SidebarNavStyled = styled.div`
	padding: 1rem 0;
	margin: 1.4rem 0 0 0.9rem;
	position: relative;
`;

const List = styled.ul`
	padding-right: 7rem;
	display: flex;
	list-style: none;
	justify-content: space-between;
	opacity: 1;
	visibility: visible;

	${({ isVisible }) =>
		isVisible &&
		css`
			opacity: 0;
			visibility: hidden;
		`}
`;

const Item = styled.li`
	color: ${({ theme }) => theme.white};
`;

const ItemNav = styled.span`
	font-size: 1.4rem;
	padding: 0.6rem;
	position: relative;
	cursor: pointer;

	&:after {
		display: block;
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -0.4rem;
		height: 0.2rem;
		width: 100%;
		transform: scaleX(0);
		transform-origin: 0% 50%;
		transition: all 0.3s ease-in-out;
		${getNavLinkUnderline()}
	}

	${({ category, slug }) =>
		category === slug &&
		css`
			&:after {
				transform: scaleX(1);
			}
		`}
`;

SidebarNav.propTypes = {
	categories: PropTypes.array.isRequired
};

const mapStateToProps = ({ category: { data: categories } }) => ({
	categories
});

export default connect(mapStateToProps)(SidebarNav);
