import { useLocation } from 'react-router-dom';

// Import helpers
import { checkIsDesktopOnly, getUserAgentInfo } from 'helpers';
import { LOGIN_ROUTES } from './helpers';

const useApp = () => {
	const location = useLocation();
	const { pathname } = location;
	const { isMobile } = getUserAgentInfo();

	const isDesktopOnly = checkIsDesktopOnly();

	const showFooter = isDesktopOnly && isMobile;

	const showMainNav = LOGIN_ROUTES.some((item) => pathname.includes(item));

	return { showFooter: !showFooter, showMainNav: !showMainNav };
};

export default useApp;
