import { differenceInMinutes } from 'date-fns';

export const menuItems = [
	{ id: 1, minutes: 0 },
	{ id: 2, minutes: 5 },
	{ id: 3, minutes: 10 },
	{ id: 4, minutes: 15 }
];

export const getTimeDifference = (startDate) => {
	let timeDifference = 0;
	let showRemindersButton = false;

	if (startDate) {
		timeDifference = differenceInMinutes(new Date(startDate), new Date());
		showRemindersButton = timeDifference > 0;
	}

	return { timeDifference, showRemindersButton };
};
