import Loadable from 'react-loadable';

// Import components
import { Loader } from 'components/elements';

const LoadableMng = ({ func, loading = true, delay = 0 }) =>
	Loadable({
		loader: func,
		loading: loading ? Loader : () => null,
		delay
	});

export default LoadableMng;
