import React from 'react';
import { string, node, oneOfType, arrayOf } from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

// Import components
import { PortalAlert } from 'components/elements';

const ErrorBoundary = ({ children, text, ...rest }) => (
	<ReactErrorBoundary
		{...rest}
		FallbackComponent={() => <PortalAlert fixed text={text} />}
	>
		{children}
	</ReactErrorBoundary>
);

ErrorBoundary.defaultProps = {
	test: 'Error'
};

ErrorBoundary.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	message: string,
	text: string
};

export default ErrorBoundary;
