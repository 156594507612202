import React from 'react';
import { useLocation } from 'react-router-dom';

// Import heleprs
import { FLOAT_WIDGET } from 'helpers/variables';
import {
	$,
	INITIAL_STATE,
	SPINNER_INTERVAL_TIME,
	RESIZE_THROTTLE_TIME,
	getElementCoordinates,
	checkIsPlayerDisabled
} from './helpers';

import {
	setPlayerOpen,
	setFloatMode,
	setPageLoaded,
	setInitPageMode,
	setCoordinates,
	setAllToDefault
} from './store/actions';

// Import reducer
import reducer from './store/reducer';

// Import utilities
import { useViewportResize } from 'components/utilities';

const useFloatWidget = () => {
	const { pathname } = useLocation();

	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

	const { isPlayerOpen, isFloatMode, isPageLoaded, coordinates } = state;

	useViewportResize({
		callback: handleWindowResize,
		throttleTime: RESIZE_THROTTLE_TIME
	});

	// ----- Effects -----
	React.useEffect(() => {
		const isPlayerDisabled = checkIsPlayerDisabled(pathname);
		const wrapper = $(FLOAT_WIDGET.WRAPPER);

		const isFloatingWidgetDisabled =
			process.env.REACT_APP_IS_FLOATING_WIDGET_DISABLED === 'true';

		isFloatingWidgetDisabled && setAllToDefault()(dispatch);

		if (isPlayerDisabled) {
			handleCloseFloatMode();
		} else if (wrapper) {
			if (wrapper.dataset.type === FLOAT_WIDGET.INIT_DATA_TYPE) {
				setInitPageMode()(dispatch);
			}
			if (isFloatMode) {
				setPlayerOpen(false)(dispatch);
			} else {
				setFloatMode(true)(dispatch);
			}
		} else {
			setInitPageMode()(dispatch);
		}
		// eslint-disable-next-line
	}, [pathname]);

	React.useEffect(() => {
		let interval;
		interval = setInterval(() => {
			const wrapper = $(FLOAT_WIDGET.WRAPPER);
			const spinner = $(FLOAT_WIDGET.SPINNER);
			const element = $(FLOAT_WIDGET.ELEMENT);

			// Check if wrapper element is on the page
			// Check if spinner is removed from the page
			// Then set page state to loaded and get element anchor coordinates
			if (wrapper && !spinner && element) {
				clearInterval(interval);
				handleSetElementCoordinates(element);
				setPageLoaded(true)(dispatch);
				setFloatMode(true)(dispatch);
			} else if (!wrapper) {
				clearInterval(interval);
			}
		}, SPINNER_INTERVAL_TIME);
		return () => {
			clearInterval(interval);
		};
	}, [isFloatMode]);

	React.useEffect(() => {
		isFloatMode && setPlayerOpen(false)(dispatch);
		isPageLoaded && setPlayerOpen(true)(dispatch);
	}, [isFloatMode, isPageLoaded]);

	// ----- Handlers -----
	const handleSetElementCoordinates = (element) => {
		const elementCoordinates = getElementCoordinates(element);
		setCoordinates(elementCoordinates)(dispatch);
	};

	const handleCloseFloatMode = () => setPlayerOpen(false)(dispatch);

	function handleWindowResize() {
		const elment = $(FLOAT_WIDGET.ELEMENT);
		if (elment) {
			const elementCoordinates = getElementCoordinates(elment);
			setCoordinates(elementCoordinates)(dispatch);
		}
	}

	return {
		coordinates,
		isFloatMode: !isFloatMode,
		isPlayerOpen,
		closeFloatMode: handleCloseFloatMode
	};
};

export default useFloatWidget;
