import React from 'react';
import { Link } from 'react-router-dom';
import { oneOfType, bool, string, array, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import utilities
import { useSlideSize } from 'components/utilities';

// Import hooks
import useSliderSection from './useSliderSection';

// Import styles
import {
	Wrapper,
	Title,
	SliderHeader,
	ShowAllButton,
	IconArrowRightStyled
} from './styles';

// Import components
import { Slider, SliderItem } from 'components/elements';

const SliderSection = ({
	content,
	type,
	modifiers,
	showAll,
	renderSlideAsLink,
	onItemClick,
	id,
	name,
	addMarginTop,
	slideType,
	setSelected,
	selected
}) => {
	const { t } = useTranslation();

	const {
		targetRef,
		containerWidth,
		isDesktop,
		spaceBetweenSlides,
		slideHeight,
		slideWidth,
		slidesToShow
	} = useSlideSize({ type });

	const { sliderContent, showAllUrl } = useSliderSection({
		id,
		name,
		isDesktop,
		content,
		type
	});

	return (
		<Wrapper modifiers={modifiers} addMarginTop={addMarginTop}>
			<div ref={targetRef}>
				{containerWidth && (
					<>
						{name && (
							<SliderHeader>
								<Title type={type}>{name}</Title>
								{showAll && (
									<ShowAllButton
										as={Link}
										to={showAllUrl}
										modifiers="showAll"
										isDesktop={isDesktop}
										type={type}
									>
										{t('common_general_all')} <IconArrowRightStyled />
									</ShowAllButton>
								)}
							</SliderHeader>
						)}
						<Slider
							slidesToShow={slidesToShow}
							slideHeight={slideHeight}
							isDesktop={isDesktop}
						>
							{sliderContent.map((product, index) => (
								<SliderItem
									key={product.uuid || product.id}
									product={product}
									index={index}
									onItemClick={onItemClick}
									renderSlideAsLink={renderSlideAsLink}
									spaceBetweenSlides={spaceBetweenSlides}
									slideHeight={slideHeight}
									slideWidth={slideWidth}
									type={type}
									selected={selected}
									setSelected={setSelected}
									slideType={slideType}
								/>
							))}
						</Slider>
					</>
				)}
			</div>
		</Wrapper>
	);
};

SliderSection.defaultProps = {
	renderSlideAsLink: true,
	onItemClick: () => {},
	modifiers: [],
	showAll: true,
	addMarginTop: false
};

SliderSection.propTypes = {
	content: array.isRequired,
	renderSlideAsLink: bool,
	onItemClick: func,
	type: string,
	modifiers: oneOfType([string, array]),
	showAll: bool,
	id: oneOfType([number, string]).isRequired,
	name: string,
	addMarginTop: bool,
	setSelected: func,
	selected: number,
	slideType: string
};

export default SliderSection;
