import React, { useState } from 'react';

// Import helpers
import { getUserAgentInfo } from 'helpers';

// Import size
import { size } from 'components/styles';

const useResize = () => {
	const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
	const { isMobile } = getUserAgentInfo();

	React.useEffect(() => {
		isMobile || window.innerWidth <= size.medium
			? setViewportHeight(window.innerHeight)
			: setViewportHeight(document.body.scrollHeight);
		//eslint-disable-next-line
	}, []);

	return { viewportHeight };
};

export default useResize;
