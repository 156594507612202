import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateVideoSession,
	setPlayerReady
} from 'store/actions';

// Import helpers
import {
	INITIAL_STATE_PLAYER_TV,
	calculateVideoIntervalTime,
	hideTheoPlayerControlls,
	getVideoSource
} from '../helpers';
import { playerDeleteSyncRequest, SHAKA_PLAYER_ERRORS } from 'helpers/player';
import { fetchPlaylistData } from '../shakaPlayer';

// Import variables
import { THEO_LIBRARY_URL, EVENTS_NAMES } from 'helpers/variables';

// Create context
export const TheoPlayerTvContext = createContext();

const { SHAKA_PLAYER_ERROR_MESSAGE } = SHAKA_PLAYER_ERRORS;

const { PLAY, PAUSE, ERROR, WAITING, PLAYING, LOADEDDATA } = EVENTS_NAMES;

class TheoPlayerTvProvider extends React.Component {
	_isMounted = false;
	isPlayerReady = false;
	intervalUpdateWatchingProgress = null;
	intervalVideoSession = null;
	trackingInterval = null;
	streamStartDate = null;

	constructor(props) {
		super(props);
		this.state = INITIAL_STATE_PLAYER_TV;

		//Creating reference to store video component and video container
		this.videoComponentRef = React.createRef();
		this.videoContainerRef = React.createRef();

		// Shaka media
		this.video = null;
		this.videoContainer = null;
		this.player = null;
	}

	async componentDidMount() {
		const {
			playlist,
			isError,
			videoSessionId,
			isSiedebarVisible,
			configuration: { seekAvailable },
			isCatchup
		} = this.props;
		this._isMounted = true;
		this.setState({ isSiedebarVisible });

		// Delete video sesion and set video mute on sync request when component is unmount
		window.addEventListener('beforeunload', this.beforeunload, false);

		const playlistData = await fetchPlaylistData(playlist);

		if (videoSessionId && this._isMounted && !this.isPlayerReady) {
			// Getting reference to video container on DOM
			this.videoContainer = this.videoContainerRef.current;

			this.setState({ seekAvailable, buffering: true });

			// get sources
			const sources = getVideoSource(playlistData);

			this.player = new window.THEOplayer.Player(this.videoContainer, {
				libraryLocation: THEO_LIBRARY_URL
			});

			// Getting reference to video on DOM
			this.video = document.querySelector('video');

			// add player events listeners
			this.addEventsListeners();

			// hide theo player controlls
			hideTheoPlayerControlls({ isCatchup });

			this.player.autoplay = true;
			this.player.source = sources;
		} else if (isError && videoSessionId) {
			// If there is an error and video session is not equal to null
			// Turn on buffering
			this._isMounted && this.setState({ buffering: false });
		} else if (isError && !videoSessionId) {
			// If there is an error and video session is equal to null
			// Stop player
			this.player && this.player.destroy();
			// Turn on buffering,Set player ready
			this._isMounted && this.setState({ buffering: false, isReady: false });
		}
	}

	componentWillUnmount() {
		this.destroy();
	}

	addEventsListeners = () => {
		this.player.addEventListener(PLAY, this.onPlay);
		this.player.addEventListener(PAUSE, this.onPause);
		this.player.addEventListener(ERROR, this.onError);
		this.player.addEventListener(WAITING, this.onWaiting);
		this.player.addEventListener(PLAYING, this.onPlaying);
		this.player.addEventListener(LOADEDDATA, this.onReady);
	};

	removeEventsListeners = () => {
		this.player.removeEventListener(PLAY, this.onPlay);
		this.player.removeEventListener(PAUSE, this.onPause);
		this.player.removeEventListener(ERROR, this.onError);
		this.player.removeEventListener(WAITING, this.onWaiting);
		this.player.removeEventListener(PLAYING, this.onPlaying);
		this.player.removeEventListener(LOADEDDATA, this.onReady);
	};

	// Destroy player (this funciton is called on componentWillUnmount and when parenetal control destroys player)
	destroy = async () => {
		const { isError, videoSessionId, type, deleteVideoSession } = this.props;

		this._isMounted = false;

		// Clear intervals
		clearInterval(this.intervalVideoSession);
		clearInterval(this.trackingInterval);

		// Check if player is not undefined / null, if player container exists, if is not any API errors
		if (videoSessionId) {
			// Dispatch an deleteVideoSession action in player folder
			await deleteVideoSession({ type });
		}

		if (!isError && this.player && this.isPlayerReady) {
			// remove event listeners
			this.removeEventsListeners();
			// Destroy the player
			this.player.destroy();
			this.isPlayerReady = false;
		}
	};

	handleUpdateVideoSession = () => {
		const { videoSessionId, type, productID, updateVideoSession } = this.props;

		updateVideoSession({ videoSessionId, productID, type }, () => {
			// Turn on buffering
			this.setState({ buffering: false, isReady: false });
			// Destroy the player
			this.player.destroy();
			// Clear intervals
			clearInterval(this.intervalVideoSession);
		});
	};

	play = () => {
		this.player.play();
		this.setState({ isPaused: false });
	};

	pause = () => {
		this.player.pause();
		this.setState({ isPaused: true });
	};

	onWaiting = () => {
		this.setState({ buffering: true });
	};

	onPlaying = () => {
		this.setState({ buffering: false });
	};

	catchupRepeat = () => {
		const { isCatchup } = this.props;
		if (isCatchup) {
			this.video.currentTime = 0;
			this.play();
		}
	};

	// Delete video sesion and set video mute
	beforeunload = () => {
		const { videoSessionId } = this.props;
		// delete session
		playerDeleteSyncRequest(videoSessionId);
	};

	onReady = () => {
		const {
			type,
			setPlayerReady,
			configuration: { videoSession }
		} = this.props;
		// Set player ready
		this.setState({ isReady: true });
		this.isPlayerReady = true;
		// Dispatch an setPlayerReady action in player folder
		setPlayerReady({ type });

		// Set video session interval
		const videoSessionInvervalTime = calculateVideoIntervalTime(videoSession);
		this.intervalVideoSession = setInterval(
			this.handleUpdateVideoSession,
			videoSessionInvervalTime
		);

		this.play();
	};

	onPlay = () => {
		const { isCatchup } = this.props;
		isCatchup && this.setState({ isPaused: false });
	};

	onPause = () => {
		const { isCatchup } = this.props;

		isCatchup ? this.setState({ isPaused: true }) : this.play();
	};

	onEnded = () => {
		const { isCatchup } = this.props;
		isCatchup && this.setState({ isEnded: true, isPaused: true });
	};

	onError(error) {
		const isOnline = navigator.onLine;

		if (this && this.props) {
			const { setPlayerPlaybackError, type } = this.props;

			// Catch only known errors which should be handled in UI
			if (isOnline) {
				// Turn on buffering
				this.setState({ buffering: false, isReady: false });
				// Destroy the player
				this.player && this.player.destroy();
				// Set error message
				error.message = SHAKA_PLAYER_ERROR_MESSAGE;
				// Dispatch an setPlayerPlaybackError action in player folder
				setPlayerPlaybackError(error, type);
			}
		}
	}

	render() {
		const { isFocus, children, resetFocus, type, isError, isSafari } =
			this.props;
		const { isReady, buffering } = this.state;

		const showLoader = (!isError && !isReady) || buffering;

		return (
			<TheoPlayerTvContext.Provider
				value={{
					...this.state,
					play: this.play,
					pause: this.pause,
					resetFocus,
					isFocus,
					destroy: this.destroy,
					catchupRepeat: this.catchupRepeat,
					playerType: type,
					showLoader,
					isSafari
				}}
			>
				<div
					className="theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer"
					ref={this.videoContainerRef}
				/>
				{children}
			</TheoPlayerTvContext.Provider>
		);
	}
}

TheoPlayerTvProvider.defaultProps = {
	isSiedebarVisible: false,
	isCatchup: false
};

TheoPlayerTvProvider.propTypes = {
	isCatchup: PropTypes.bool,
	isSiedebarVisible: PropTypes.bool,
	playlist: PropTypes.string.isRequired,
	isError: PropTypes.bool.isRequired,
	videoSessionId: PropTypes.string,
	deleteVideoSession: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	setPlayerReady: PropTypes.func.isRequired,
	updateVideoSession: PropTypes.func.isRequired,
	configuration: PropTypes.object.isRequired,
	resetFocus: PropTypes.func.isRequired,
	playerParentElement: PropTypes.any,
	productID: PropTypes.string.isRequired,
	setPlayerPlaybackError: PropTypes.func.isRequired,
	isFocus: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
	isSafari: PropTypes.bool.isRequired
};

const mapStateToProps = (state, { type }) => {
	const {
		playlist,
		configuration,
		error: { isError },
		videoSessionId
	} = state[type];
	return {
		configuration,
		playlist,
		isError,
		videoSessionId
	};
};

export default connect(mapStateToProps, {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateVideoSession,
	setPlayerReady
})(TheoPlayerTvProvider);
