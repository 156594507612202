// Import helpers
import { createLinkParams } from 'helpers/index';
import { getProductTypeAlias } from 'helpers/products';

// Import hooks
import useProductRatingCheck from 'components/elements/molecules/parental_control/parental_control_utils/useProductRatingCheck';
import { useSearchInstitutions } from '../useSearchInstitutions';

export const useSearchItem = ({ product, close }) => {
	const { clearInstitution } = useSearchInstitutions(close);

	// REPLACE RATING WITH PC_RATING AFTER API UPDATE
	const { uuid, title, subtype, context, pc_rating } = product;

	const since = context?.since;

	// check program rating and block content accordingly
	const { isContentBlocked, placeholderRatingText } = useProductRatingCheck(
		pc_rating,
		since
	);

	// Set proper id to channel type product
	const id = uuid;

	const linkParams = createLinkParams({
		id,
		type: subtype,
		title
	});

	const productTypeAlias = getProductTypeAlias(product);

	return {
		linkParams,
		productTypeAlias,
		isContentBlocked,
		placeholderRatingText,
		title,
		onCloseSearchBox: clearInstitution
	};
};
