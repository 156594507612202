import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import context
import { useProductPaymentContext } from 'components/views/product_payment/context';

const useProductPaymentTemplate = () => {
	const history = useHistory();

	// context
	const { isPaymentValid, isResourcesError } = useProductPaymentContext();

	// redux
	const { isPaymentLoading, ...restPaymentData } = useSelector(
		({ payment }) => payment
	);

	const { isPurchaseLoading, ...restPurchaseData } = useSelector(
		({ purchase }) => purchase
	);

	// helpers
	const handleHistoryGoBack = () => history.goBack();

	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: 'smooth'
		});
		// eslint-disable-next-line
	}, []);

	const showLoader = isPaymentLoading || isPurchaseLoading;

	return {
		...restPaymentData,
		...restPurchaseData,
		isPaymentValid,
		showLoader,
		historyGoBack: handleHistoryGoBack,
		isResourcesError
	};
};

export default useProductPaymentTemplate;
