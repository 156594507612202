import { string, number, object, array, shape } from 'prop-types';
import { useParams } from 'react-router-dom';

// Import helpers
import { createLinkParams } from 'helpers';
import { getImage } from 'components/views/vod/helpers/getImage';

// Import variables
import { VOD_SECTIONS_TYPES, PRODUCT_IMAGES_TYPES } from 'helpers/variables';

// Import utilities
import { useVodProgress, useProductAvailability } from 'components/utilities';
import useImageCheck from './useImageCheck';

// Import components
import NoImageSVG from 'components/views/vod/images/placeholder.svg';

const { WEB_MOVIES_CONTINUE_WATCHING } = VOD_SECTIONS_TYPES;
const { LOGO, POSTER } = PRODUCT_IMAGES_TYPES;

const useSliderItemLogic = ({
	sectionLabel = '',
	data: { uuid, images, subtype, context, title, available_in },
	sliderId = '',
	width,
	height
}) => {
	const { name } = useParams();
	const duration = context && context.duration ? context.duration : 0;

	const isAvailable = useProductAvailability(uuid, available_in);

	const continuePercent = useVodProgress(uuid, duration);

	const isContinueWatchingSection =
		sectionLabel === WEB_MOVIES_CONTINUE_WATCHING;

	const isCollectionPage = sliderId === 'collection';

	const isChannel = subtype === 'channel';

	const channelLogo = getImage({ images, type: LOGO });
	const productPoster = getImage({ images, type: POSTER });

	const imageSrc = isChannel ? channelLogo : productPoster;

	const linkParams = createLinkParams({ id: uuid, name, type: subtype, title });

	const { isError } = useImageCheck(imageSrc);
	const backgroundImage = !isError && imageSrc ? imageSrc : NoImageSVG;

	const isPlaceholder = backgroundImage === NoImageSVG;

	const backgroundSize = isPlaceholder
		? 'cover'
		: isChannel
		? 'contain'
		: `${width}px ${height}px`;

	return {
		isAvailable,
		isContinueWatchingSection,
		isCollectionPage,
		backgroundImage,
		linkParams,
		continuePercent,
		backgroundSize
	};
};

useSliderItemLogic.propTypes = {
	sectionLabel: string.isRequired,
	data: shape({
		uuid: string.isRequired,
		images: object,
		subtype: string.isRequired,
		context: shape({ duration: number }),
		title: string,
		year: number,
		genres: array,
		rating: number,
		country: string
	}),
	width: number.isRequired,
	height: number.isRequired,
	sliderId: string
};

export default useSliderItemLogic;
