import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import components
import { Loader } from 'components/elements';
import SearchItem from './SearchItem/SearchItem';
import { SearchBoxStyled, Top, SearchList, LinkStyled } from './styles';

// Import helpers
import { getNumberOfItems } from './helpers';

// Import hooks
import useSearchBox from './useSearchBox';

const SearchBox = ({ data, toggle, query, isLoaded, channels }) => {
	const { viewportHeight, onSearchAll } = useSearchBox(toggle);
	const { t } = useTranslation();
	const encodedQuery = encodeURIComponent(query);

	return (
		<SearchBoxStyled>
			<Top>
				{isLoaded && (
					<LinkStyled
						onClick={onSearchAll}
						href={`/search/${encodedQuery}/all`}
					>
						{t('common_search_see_all')}
					</LinkStyled>
				)}
			</Top>

			<SearchList isLoaded={isLoaded}>
				{data.slice(0, getNumberOfItems(viewportHeight)).map((item) => (
					<SearchItem
						toggle={toggle}
						key={item.uuid}
						product={item}
						channels={channels}
					/>
				))}
				{!isLoaded && (
					<Loader
						width={10}
						height={10}
						zIndex={900}
						position="absolute"
						background={false}
					/>
				)}
			</SearchList>
		</SearchBoxStyled>
	);
};

SearchBox.propTypes = {
	toggle: PropTypes.func.isRequired,
	query: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	isLoaded: PropTypes.bool.isRequired,
	channels: PropTypes.array
};

const mapStateToProps = ({
	search: { data, isLoaded },
	channels: {
		data: { lives: channels }
	}
}) => ({
	data,
	isLoaded,
	channels
});

export default connect(mapStateToProps)(SearchBox);
