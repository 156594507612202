import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

// Import styles
import { transitionMain } from 'components/styles';

const TextEllipsis = ({
	children,
	childStyle = {},
	tooltipText,
	link = false,
	shouldEllipsis = true,
	isProductDetails = false
}) => {
	const [rootElement, setRootElement] = React.useState(null);
	const [tooltipClass, setTooltipClass] = React.useState('');

	React.useEffect(() => {
		rootElement && rootElement.offsetWidth < rootElement.scrollWidth
			? setTooltipClass('productTitle-tooltip')
			: setTooltipClass('ant-tooltip-hidden');
	}, [rootElement]);

	return (
		<Root
			link={link}
			shouldEllipsis={shouldEllipsis}
			isProductDetails={isProductDetails}
			ref={(el) => setRootElement(el)}
		>
			<Tooltip
				placement="topLeft"
				title={tooltipText}
				overlayClassName={tooltipClass}
			>
				<span style={childStyle}>{children}</span>
			</Tooltip>
		</Root>
	);
};

const Root = styled.div`
	${transitionMain}
	font-weight: ${({ theme }) => theme.font.medium};

	${({ link, theme }) =>
		css`
			color: ${link ? theme.user.tableItemName : theme.white};

			&:hover {
				color: ${link ? theme.user.tableItemNameHover : theme.white};
			}
		`}

	${({ shouldEllipsis }) =>
		shouldEllipsis &&
		css`
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		`}
             
    ${({ isProductDetails }) =>
		isProductDetails &&
		css`
			margin-right: 3rem;
		`}
`;

TextEllipsis.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	childStyle: PropTypes.object,
	tooltipText: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
		.isRequired,
	link: PropTypes.bool,
	shouldEllipsis: PropTypes.bool,
	isProductDetails: PropTypes.bool
};

export default TextEllipsis;
