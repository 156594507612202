import services from 'services/services';
import * as types from 'store/actions/types';

export const fetchContinueWatchingData =
	(id = null) =>
	async (dispatch) => {
		try {
			dispatch({ type: types.CONTINUE_WATCHING_LOADING });
			const isProductId = id || 'uuids';

			const url = `/subscriber/bookmarks/watching/${isProductId}`;

			const { data } = await services.get(url);

			const payload = id ? { data: [], time: data } : { data, time: null };

			dispatch({
				type: types.CONTINUE_WATCHING_SUCCESS,
				payload
			});
		} catch {
			dispatch({ type: types.CONTINUE_WATCHING_ERROR });
		}
	};

export const clearContinueWatchingData = (dispatch) => {
	dispatch({
		type: types.CLEAR_CONTINUE_WATCHING,
		payload: true
	});
};
