// -----------------  GET USER -----------------
export const REGISTER_USER_LOADING = 'REGISTER_USER_LOADING';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

// ******************** FETCH REGISTER AGREEMENTS ********************
export const FETCH_REGISTER_AGREEMENTS_LOADING =
	'FETCH_REGISTER_AGREEMENTS_LOADING';
export const FETCH_REGISTER_AGREEMENTS_SUCCESS =
	'FETCH_REGISTER_AGREEMENTS_SUCCESS';
export const FETCH_REGISTER_AGREEMENTS_ERROR =
	'FETCH_REGISTER_AGREEMENTS_ERROR';

export const CLEAR_REGISTER_AGREEMENTS = 'CLEAR_REGISTER_AGREEMENTS';

// ******************** CLEAR REGISTER DATA ********************
export const CLEAR_REGISTER_DATA = 'CLEAR_REGISTER_DATA';
