import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

// Import utilities
import { openNotification } from 'components/utilities/notification/notification';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';
import { fetchDocumentByAlias } from './helpers';

const useDocuments = ({ documents, queryKey }) => {
	const { t } = useTranslation();

	const queryClient = useQueryClient();
	const documentsCache = queryClient.getQueryData(queryKey);

	const handleFetchDocumentByAlias = (alias) => fetchDocumentByAlias(alias);

	const fetchResources = async () => {
		if (documentsCache?.length) {
			return documentsCache;
		}

		return Promise.all(documents.map(handleFetchDocumentByAlias)).then(
			(response) => response.map(({ data }) => data).flat()
		);
	};

	const handleError = (error) => {
		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: t('vod_movieDetails_error_title'),
				description: t('common_errors_connection')
			});
	};

	const { isLoading, isFetching, isError, data } = useQuery({
		queryKey,
		queryFn: fetchResources,
		onError: handleError
	});

	const documentsArray = data || [];

	return {
		documents: documentsArray,
		isDocumentsLoading: isLoading || isFetching,
		isDocumentsError: isError
	};
};

export default useDocuments;
