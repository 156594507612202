import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';

// Import helpers
import { isNumber } from 'helpers/prices';
import { fetchSectionContent } from './helpers';

// Import api
import { QUERY_SECTION_CONTENT } from 'helpers/api';

// Import variables
import { PAGES_LABELS } from 'helpers/variables';

const { INSTITUTION, MAIN_WEB } = PAGES_LABELS;

const useSectionsTemplate = ({ sections, label }) => {
	const { institution } = useSelector(({ institutions }) => institutions);

	const institutionUuid = institution?.uuid;

	const queryKey = `${QUERY_SECTION_CONTENT}_${label}_${institutionUuid}`;

	const queryFn = ({ pageParam: index }) =>
		fetchSectionContent({ sections, index });

	const getPreviousPageParam = () => false;

	const getNextPageParam = (prev) => {
		const prevIndex = prev?.index;

		if (isNumber(prevIndex)) {
			const nextIndex = prevIndex + 1;
			const isNextSection = nextIndex < sections.length;
			return isNextSection ? nextIndex : undefined;
		}

		return undefined;
	};

	const { data, fetchNextPage, hasNextPage, isLoading, remove } =
		useInfiniteQuery({
			queryKey,
			queryFn,
			getPreviousPageParam,
			getNextPageParam
		});

	useEffect(() => {
		return () => remove();
		//eslint-disable-next-line
	}, []);

	const sectionsData = data?.pages ?? [];

	const isContentFetched = sectionsData.length === sections.length;

	const hasContent = sectionsData.some((section) => section?.content?.length);

	const isInstitutionPage = label === INSTITUTION;
	const isHomePage = label === MAIN_WEB;

	const showContentMessage =
		isContentFetched && !hasContent && !isInstitutionPage && !isHomePage;

	return {
		fetchNextPage,
		hasNextPage,
		sectionsData,
		isLoading,
		isContentFetched,
		hasContent,
		showContentMessage
	};
};

export default useSectionsTemplate;
