// Import variables
import { SECTIONS_ID } from 'helpers/variables';

const { NAVIGATION, SEARCH, CONTENT, FOOTER } = SECTIONS_ID;

export const TAB_NAV_LIST = [
	{
		href: `#${NAVIGATION}`,
		text: 'common_header_tab_nav_navigation'
	},
	{
		href: `#${SEARCH}`,
		text: 'common_header_tab_nav_search'
	},
	{
		href: `#${CONTENT}`,
		text: 'common_header_tab_nav_content'
	},
	{
		href: `#${FOOTER}`,
		text: 'common_header_tab_nav_footer'
	},
	{
		href: '/',
		text: 'common_header_tab_nav_home'
	}
];
