import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Import utilities
import { useNoAuthPathSave } from 'components/utilities';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { LOGIN } = APP_CORE_PATHS;

export const useNavToolbar = () => {
	const history = useHistory();

	const { isLogged } = useSelector(({ auth }) => auth);

	const { savePath } = useNoAuthPathSave();

	const handleLoginClick = () => {
		savePath();
		history.push(`/${LOGIN}`);
	};

	return {
		loginClick: handleLoginClick,
		isLogged
	};
};
