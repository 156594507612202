import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';

// Import utilities
import { DrawerRPC, ToggleRPC } from 'components/utilities';

// Import icons
import { ReactComponent as FavoriteIcon } from 'assets/icons/ic_nav_favorite.svg';

// Import components
import { AuthModal, Loader } from 'components/elements';
import Favourite from './Favourite';

// Import styled components
import { StyledButton } from './styles';

const Favourites = () => {
	const { t } = useTranslation();

	const navItemTitle = t('common_favourites_title');

	const { data: favourites, isLoaded } = useSelector(
		({ favourites }) => favourites
	);

	const renderContent = (toggle) =>
		favourites.map((favourite) => (
			<Favourite toggle={toggle} key={favourite.uuid} favourite={favourite} />
		));

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<AuthModal>
						<Tooltip placement="bottom" onClick={toggle} title={navItemTitle}>
							<StyledButton modifiers={['navIcon']} title={navItemTitle}>
								<FavoriteIcon aria-hidden="true" title={navItemTitle} />
							</StyledButton>
						</Tooltip>
					</AuthModal>

					<DrawerRPC isOpen={on} onClose={toggle} title={navItemTitle}>
						{isLoaded ? (
							renderContent(toggle)
						) : (
							<Loader
								zIndex={900}
								position="relative"
								background={false}
								width={8}
								height={8}
								top="2rem"
							/>
						)}
					</DrawerRPC>
				</>
			)}
		</ToggleRPC>
	);
};

export default Favourites;
