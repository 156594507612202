import { useSelector } from 'react-redux';

// Import helpers
import { getAvailableIn } from './helpers';

// Import utilities
import { useProductAvailability, useAuth } from 'components/utilities';

const useLiveEventInfo = () => {
	const { data } = useSelector(({ movieDetails }) => movieDetails);
	const { uuid: productUuid, available_in, since, till } = data;
	const { isAuth } = useAuth();

	const isAvailable = useProductAvailability(productUuid, available_in);

	const isEventAvailable = getAvailableIn({
		since,
		till,
		isAvailable
	});

	const hasEventEnded = new Date(till) < new Date();

	return { isEventAvailable, isAuth, hasEventEnded };
};

export default useLiveEventInfo;