import React from 'react';

// Import context
import {
	ShakaPlayerTvProvider,
	TheoPlayerTvProvider
} from 'components/context/player/player_tv';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

const PlayerProvider = (props) => {
	switch (DEFAULT_PLAYER) {
		case THEO_PLAYER:
			return <TheoPlayerTvProvider {...props} />;

		default:
			return <ShakaPlayerTvProvider {...props} />;
	}
};

export default PlayerProvider;
