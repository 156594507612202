import styled, { css } from 'styled-components';

// SEEKBAR COMPONENTS
export const Root = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0 2rem;

	${({ isVisible }) =>
		isVisible &&
		css`
			display: none;
		`}
`;

export const StrapsContainer = styled.div`
	height: 20px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: start;
	cursor: pointer;
`;

export const BaseStrap = styled.div`
	height: 4px;
	width: 100%;
	background-color: rgba(225, 225, 225, 0.35);
	display: flex;
	align-items: center;
	justify-content: start;
	border-radius: 6px;
`;

export const LoadedStrap = styled.div.attrs(({ percent }) => ({
	style: {
		width: `${percent}%`
	}
}))`
	height: 100%;
	background: ${({ theme }) => theme.player.loadedStrapBg};
	border-radius: 6px;
`;

export const Point = styled.div.attrs(({ translateX, leftPercent }) => ({
	style: {
		transform: `translate(${translateX}px)`,
		left: `${leftPercent}%`
	}
}))`
	position: absolute;
	left: 0;
	width: 1px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const DotContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Dot = styled.div`
	height: 15px;
	width: 15px;
	border-radius: 100px;
	border: 1px solid ${({ theme }) => theme.player.dotBorder};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: ${({ theme }) => theme.player.dotBg};
`;

// PREVIEW POPOVER COMPONENTS

export const PreviewWrapper = styled.div`
	position: absolute;
	bottom: ${({ isCatchup }) => (isCatchup ? 4 : 2)}rem;
	left: ${({ shiftToLeft }) => shiftToLeft};
	font-size: 1.4rem;
	padding: ${({ isSmall }) => (isSmall ? 0.3 : 0.5)}rem;
	font-weight: 300;
	background-color: ${({ theme }) => theme.black};
	border-radius: 0.5rem;
	text-align: center;

	&::before {
		content: '';
		position: absolute;
		display: inline-block;
		width: 1rem;
		height: 1rem;
		background-color: ${({ theme }) => theme.black};
		bottom: 0;
		left: 50%;
		transform: rotate(-45deg) translateX(-50%);
		z-index: -1;
	}
`;

export const SeekTime = styled.p`
	margin-top: ${({ isSmall }) => (isSmall ? 0 : 0.5)}rem;
	color: ${({ theme }) => theme.white};
`;

export const PreviewImage = styled.div`
	height: 9rem;
	width: 16rem;
	background-image: ${({ imgUrl }) => `url(${imgUrl})`};
	background-position: center ${({ currentFrame }) => currentFrame * -9}rem;
	background-size: cover;
	background-color: ${({ theme }) => theme.black};
	cursor: pointer;
`;
