// -----------------  FETCH PROGRAM DETAILS -----------------
export const FETCH_PROGRAM_DETAILS_LOADING = 'FETCH_PROGRAM_DETAILS_LOADING';
export const FETCH_PROGRAM_DETAILS_SUCCESS = 'FETCH_PROGRAM_DETAILS_SUCCESS';
export const FETCH_PROGRAM_DETAILS_ERROR = 'FETCH_PROGRAM_DETAILS_ERROR';

// -----------------  CLEAR PROGRAM DETAILS -----------------
export const CLEAR_PROGRAM_DETAILS = 'CLEAR_PROGRAM_DETAILS';

// -----------------  FETCH PROGRAM DETAILS BROADCASTS -----------------
export const FETCH_PROGRAM_DETAILS_BROADCASTS_LOADING =
	'FETCH_PROGRAM_DETAILS_BROADCASTS_LOADING';
export const FETCH_PROGRAM_DETAILS_BROADCASTS_SUCCESS =
	'FETCH_PROGRAM_DETAILS_BROADCASTS_SUCCESS';
export const FETCH_PROGRAM_DETAILS_BROADCASTS_ERROR =
	'FETCH_PROGRAM_DETAILS_BROADCASTS_ERROR';

// -----------------  FETCH PROGRAM DETAILS RECOMMENDATIONS -----------------
export const FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_LOADING =
	'FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_LOADING';
export const FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_SUCCESS =
	'FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_SUCCESS';
export const FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_ERROR =
	'FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_ERROR';
