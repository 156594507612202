import * as types from 'store/actions/types';
import services from 'services/services';

// ******************** FETCH ACTIVE PAYMENT METHODS ********************
export const fetchActivePaymentMethods = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_ACTIVE_PAYMENT_METHODS_LOADING,
			payload: true
		});

		const { data } = await services.get('/payments/paymentData/list');

		const { data: subscriptionsList } = await services.get(
			'/payments/subscriptions/list'
		);

		const activePaymentMethods = data.map((method) => {
			const subscriptions = subscriptionsList.filter(
				({ payment_data_id }) => payment_data_id === method.id
			);

			return { ...method, subscriptions };
		});

		dispatch({
			type: types.FETCH_ACTIVE_PAYMENT_METHODS_SUCCESS,
			payload: activePaymentMethods
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ACTIVE_PAYMENT_METHODS_ERROR,
			payload: false
		});
	}
};

// ******************** FETCH_LAST_GATEWAY_TYPE_ ********************
export const fetchLastGatewayType = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_LAST_GATEWAY_TYPE_LOADING,
			payload: true
		});

		const { data } = await services.get('/payments/getLastGatewayType');

		dispatch({
			type: types.FETCH_LAST_GATEWAY_TYPE_SUCCESS,

			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_LAST_GATEWAY_TYPE_ERROR,
			payload: false
		});
	}
};

// ******************** Active  Subscriptions List ********************
export const fetchActiveSubscriptionsList = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_ACTIVE_SUBSCRIPTIONS_LOADING,
			payload: true
		});

		const { data } = await services.get(
			'/payments/basicSubscriptions/list?withInactive=1'
		);

		dispatch({
			type: types.FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_ACTIVE_SUBSCRIPTIONS_ERROR,
			payload: false
		});
	}
};
