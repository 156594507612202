import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	data: [],
	error: false,
	loading: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.FETCH_PACKETS_FREE_VOD_SUCCESS:
				draft.data = action.payload.uuids;
				draft.loading = false;
				return;

			case types.FETCH_PACKETS_FREE_VOD_ERROR:
				draft.loading = false;
				draft.error = true;
				return;

			default:
				return state;
		}
	});
