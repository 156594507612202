import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { PlayerElements } from 'components/elements';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import styles
import { ButtonFullScreen } from './styles';

const { IconExitFullScreen, IconFullScreen } = PlayerElements;

const FullScreenButton = () => {
	const { t } = useTranslation();

	const { setFullScreen, isFullScreen } = usePlayerVodContext();

	const buttonTitle = isFullScreen
		? t('common_player_full_screen_exit_title')
		: t('common_player_full_screen_title');

	return (
		<ButtonFullScreen
			onClick={setFullScreen}
			modifiers={['icon', 'playerIconHover']}
			title={buttonTitle}
		>
			{isFullScreen ? (
				<IconExitFullScreen
					aria-hidden="true"
					title={t('common_player_exit_full_screen_icon_title')}
				/>
			) : (
				<IconFullScreen
					aria-hidden="true"
					title={t('common_player_full_screen_icon_title')}
				/>
			)}
		</ButtonFullScreen>
	);
};

export default FullScreenButton;
