import React from 'react';
import { array } from 'prop-types';

// Import styles
import { StyledTable } from './styles';

const ProfileTable = (props) => <StyledTable {...props} />;

ProfileTable.propTypes = {
	tableColumns: array.isRequired,
	tableData: array
};

export default ProfileTable;
