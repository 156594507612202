import { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Import helpers
import { checkCookiesAccepted } from 'helpers/cookies';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { COOKIES } = APP_CORE_PATHS;

const useCookies = () => {
	const { pathname } = useLocation();

	// ---- refs ----
	const formRef = useRef();

	// ---- state ----
	const [showCookieMessage, setShowCookieMessage] = useState(false);

	// ---- variables ----
	const isCookiesPage = pathname.includes(COOKIES);

	// ---- handlers ----
	const handleFormikSubmit = () => formRef.current.handleSubmit();

	const handleCheckCookiesAccepted = () => {
		if (!isCookiesPage) {
			setShowCookieMessage(false);
			const isCookiesAccepted = checkCookiesAccepted();
			setShowCookieMessage(!isCookiesAccepted);
			return isCookiesAccepted;
		}
	};

	return {
		showCookieMessage,
		formRef,
		formikSubmit: handleFormikSubmit,
		checkCookiesAccepted: handleCheckCookiesAccepted,
		setShowCookieMessage
	};
};

export default useCookies;
