import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setInternalServerError } from 'store/actions';
import { useTranslation } from 'react-i18next';

// Import helpers
import { getErrorStatus } from 'helpers/error_code_api';

// Import components
import { Loader, Alert } from 'components/elements';

// Import utilities
import { useAuth } from 'components/utilities';

const ContentTemplate = ({ children }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { auth, menu, portal } = useSelector((state) => state);

	const { isAuth } = useAuth();

	const { packets, signOutLoading, signOutError } = auth;
	const { isInternalServerError } = portal;

	const isUserLoaded = isAuth ? packets.isLoaded : menu.isLoaded;

	const handleSetInterlanServerErrro = () =>
		setInternalServerError(false)(dispatch);

	return (
		<>
			{signOutError && (
				<Alert fixed={true} text={t('common_errors_sign_out')} />
			)}
			{isInternalServerError && (
				<Alert
					fixed={true}
					text={getErrorStatus(500)}
					action={handleSetInterlanServerErrro}
				/>
			)}
			{signOutLoading && <Loader />}
			{isUserLoaded ? children : <Loader />}
		</>
	);
};

ContentTemplate.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired
};

export default ContentTemplate;
