import { APP_CORE_PATHS } from './app';

const {
	HOME_PAGE,
	PAGE,
	USER,
	SEARCH,
	TRANSACTION,
	PACKETS,
	PAYMENT,
	INSTITUTION,
	TV
} = APP_CORE_PATHS;

export const MAIN_PATHS = {
	HOME_PAGE,
	PAGE: `/${PAGE}`,
	USER: `/${USER}`,
	SEARCH: `/${SEARCH}/:query/:category`,
	TRANSACTION: `/${TRANSACTION}/:query`,
	PACKETS: `/${PACKETS}`,
	PAYMENT: `/${PAYMENT}/:type/:id/:title`,
	INSTITUTIONS: `/${INSTITUTION}`,
	TV: `/${TV}`
};
