import _ from 'lodash';

// Check if product has availableIn array with products
export const checkIsProductAvailableInPackets = ({
	products,
	uuid,
	availableIn
}) => {
	const productAvailable = _.intersection(products, [...availableIn, uuid]);
	return !!productAvailable.length;
};

export const checkIsProductAvailable = ({ products, uuid }) =>
	!!products.find((id) => id === uuid);

export const checkIsSubscriptionAvailable = ({ subscriptions, uuid }) =>
	subscriptions.some(({ product_uuid }) => product_uuid === uuid);
