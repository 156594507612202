import { useTranslation } from 'react-i18next';
import services from 'services/services';
import { useQuery } from 'react-query';

// Import utilities
import { openNotification } from 'components/utilities/notification/notification';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';
import { GET_COUNTIRES_LIST_API, QUERY_COUNTRIES } from 'helpers/api';

const useCountries = () => {
	const { t } = useTranslation();

	const fetchCountires = async () => {
		const { data } = await services.get(GET_COUNTIRES_LIST_API);
		return data;
	};

	const handleError = (error) => {
		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: t('common_countires_error_title'),
				description: t('common_products_countires_description')
			});
	};

	const { data, isLoading: isCountriesLoading } = useQuery(
		QUERY_COUNTRIES,
		fetchCountires,
		{
			onError: handleError
		}
	);

	return { countries: data || {}, isCountriesLoading };
};

export default useCountries;
