import styled, { css } from 'styled-components';

// Import components
import { Button } from 'components/elements';

// Import styles
import {
	flex,
	customMinResponsive,
	size,
	customResponsive
} from 'components/styles';

export const Container = styled.div`
	position: relative;
	text-align: center;
	padding: 1.8rem 1.8rem 0;
	${flex({ jc: 'space-between', fd: 'column' })}
	${customResponsive(
		size.medium,
		css`
			&:nth-of-type(even) {
				border-top: 1px solid;
				border-bottom: 1px solid;
				border-color: ${({ theme }) =>
					theme.payment.paymentOptions.borderColorMobile};
			}
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			min-height: 3rem;
			width: 37rem;
			padding: 4rem 0 2.5rem 0;
			flex: 1 1 0px;

			&:nth-of-type(even) {
				border-left: 1px solid;
				border-right: 1px solid;
				border-color: ${({ theme }) =>
					theme.payment.paymentOptions.borderColor};
			}
		`
	)}
`;

export const Name = styled.p`
	font-size: ${({ theme }) =>
		theme.fontSize.payment.paymentOptions.name.mobile}rem;
	color: ${({ theme }) => theme.payment.paymentOptions.name.color};
	line-height: 1.2;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.payment.paymentOptions.name.desktop}rem;
			line-height: 3.6rem;
		`
	)}
`;

export const Info = styled.p`
	font-size: 1.4rem;
	color: ${({ theme }) => theme.payment.paymentOptions.info.color};
	font-weight: ${({ theme }) => theme.payment.paymentOptions.info.fontWeight};
	margin-bottom: 2rem;
`;

export const Price = styled.span`
	font-size: ${({ theme }) =>
		theme.fontSize.payment.paymentOptions.price.mobile}rem;
	color: ${({ theme }) => theme.payment.paymentOptions.price.color};
	display: inline-block;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.payment.paymentOptions.price.desktop}rem;
			margin-top: 0.8rem;
			margin-bottom: 2.1rem;
			line-height: 2.8rem;
		`
	)}
`;

export const PromotionPrice = styled.strong`
	color: ${({ theme }) => theme.payment.paymentOptions.promotionPrice.color};
	margin-left: 1rem;
`;

export const ButtonStyled = styled(Button)`
	width: 16.8rem;
	padding: 0.8rem 0;
	position: relative;

	${customResponsive(
		size.medium,
		css`
			margin-bottom: 1.8rem;
		`
	)}

	&[type='submit'] {
		${customResponsive(
			size.medium,
			css`
				width: 13.1rem;
				position: absolute;
				top: 0;
				right: 0;
				margin: 0;
			`
		)}
	}
`;

export const FlexContainer = styled.div`
	width: 100%;
	${flex({ fd: 'column' })};

	${customResponsive(
		size.medium,
		css`
			margin-bottom: 1.8rem;
			${flex({ jc: 'space-between' })};
		`
	)}
`;
