import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

// Import variables
import { LANGUAGES_TYPES } from 'helpers/variables';

// Import helpers
import {
	languagesOptions,
	getUserLanguage,
	hasSeveralLanguages
} from 'helpers/languages';

// Import utilities
import { useLanguageDropdown } from './useLanguageDropdown';

const { Option } = Select;

const LanguageDropdown = () => {
	const { i18n, t } = useTranslation();
	const { language, updateLanguage } = useLanguageDropdown(
		i18n,
		getUserLanguage
	);

	const renderLanguageOptions = languagesOptions.map(({ value, name }) => {
		switch (name) {
			case LANGUAGES_TYPES.PL:
				return (
					<Option key={value} value={value}>
						{t('user_profile_language_pl')}
					</Option>
				);
			case LANGUAGES_TYPES.EN:
				return (
					<Option key={value} value={value}>
						{t('user_profile_language_en')}
					</Option>
				);
			default:
				return null;
		}
	});

	return (
		<Select value={language} onChange={updateLanguage}>
			{hasSeveralLanguages && renderLanguageOptions}
		</Select>
	);
};

export default LanguageDropdown;
