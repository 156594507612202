import * as types from 'store/actions/types';
import services from 'services/services';
import i18next from 'i18next';
import { openNotification } from 'components/utilities/notification/notification';
import axios from 'axios';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';
import { getErrorCodeResults } from 'helpers/error_code_api';

let source = axios.CancelToken.source();

// ******************** FETCH RECORDINGS ********************
export const fetchRecordings = (
	id = null,
	isAppInit = false,
	isLoader = true
) => async (dispatch, getState) => {
	try {
		// Cancel config request
		source.cancel();
		source = axios.CancelToken.source();

		// Dispatch an loading action
		isLoader &&
			dispatch({
				type: types.FETCH_RECORDINGS_LOADING,
				payload: true
			});

		const {
			devices: { data }
		} = getState();

		let recordings = [];

		if (data.length > 0) {
			const { StbId } = data[0];
			const deviceId = id === null ? StbId : id;
			// Get recordings
			const { data: recordingDevices } = await services.get(
				`/recordings/dvr/${deviceId}?platform=BROWSER`,
				{
					cancelToken: source.token
				}
			);
			recordings = recordingDevices;
		}

		// Dispatch an action
		dispatch({
			type: types.FETCH_RECORDINGS_SUCCESS,
			payload: recordings
		});
	} catch (error) {
		let message = '';

		if (error && error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			message = codeErrorMessage;

			// Dispatch an error action
			dispatch({
				type: types.FETCH_RECORDINGS_ERROR,
				payload: { message }
			});
		}
	}
};

export const clearRecordings = () => (dispatch) => {
	// Dispatch an loading action
	dispatch({
		type: types.FETCH_RECORDINGS_LOADING,
		payload: true
	});
};

export const clearErrorRecordings = () => (dispatch) => {
	// Dispatch an loading action
	dispatch({
		type: types.CLEAR_RECORDINGS_ERRORS,
		payload: true
	});
};

// ******************** REMOVE RECORDING ********************
export const removeRecording = (
	StbId,
	recordingId,
	pin,
	callbackError,
	callbackSuccess
) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.REMOVE_RECORDING_LOADING,
			payload: true
		});

		const payload = {
			params: {
				stbid: StbId,
				pin: pin
			}
		};

		await services.delete(
			`/recordings/dvr/${recordingId}?platform=BROWSER`,
			payload
		);

		// Dispatch an action
		dispatch({
			type: types.REMOVE_RECORDING_SUCCESS,
			payload: recordingId
		});

		callbackSuccess();
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);

			error.response.data.message = codeErrorMessage;
			// Dispatch an error action
			dispatch({
				type: types.REMOVE_RECORDING_ERROR,
				payload: error.response.data.message
			});

			checkErrorStatus(error) &&
				openNotification({
					type: 'error',
					title: i18next.t('common_recordings_error_title'),
					description:
						codeErrorMessage || i18next.t('common_recordings_error_description')
				});
		}

		callbackError();
	}
};

// ******************** SCHEDULE RECORDING ********************
export const scheduleRecording = (epgID, selectedDevice, callback) => async (
	dispatch
) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.SCHEDULE_RECORDING_LOADING,
			payload: true
		});

		openNotification({
			type: 'success',
			title: i18next.t('recordings_title_schedule'),
			description: i18next.t('recordings_desc_schedule')
		});

		const payload = {
			program_uuid: epgID,
			stbid: selectedDevice,
			type: 'stb'
		};

		await services.post('/recordings/dvr/create?platform=BROWSER', payload);

		callback();

		// Dispatch an action
		dispatch({
			type: types.SCHEDULE_RECORDING_SUCCESS,
			payload: true
		});

		openNotification({
			type: 'success',
			title: i18next.t('recordings_title_success'),
			description: i18next.t('recordings_desc_success')
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.SCHEDULE_RECORDING_ERROR,
			payload: true
		});

		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);

			checkErrorStatus(error) &&
				openNotification({
					type: 'error',
					title: i18next.t('recordings_title_error'),
					description: codeErrorMessage || i18next.t('recordings_desc_error')
				});
		}
	}
};
