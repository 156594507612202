import { useEffect, useState } from 'react';
import { func, string } from 'prop-types';

const ImageOnLoadRPC = ({ children, renderPlaceholder, src }) => {
	const [isLoadError, setLoadError] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const handleSetLoadError = () => setLoadError(true);

	const handleSetLoaded = () => setIsLoaded(true);

	const handleResetState = () => {
		setIsLoaded(false);
		setLoadError(false);
	};

	useEffect(() => {
		handleResetState();
		// eslint-disable-next-line
	}, [src]);

	return isLoadError
		? renderPlaceholder()
		: children({
				setError: handleSetLoadError,
				isLoaded,
				setLoaded: handleSetLoaded,
				isError: isLoadError
		  });
};

ImageOnLoadRPC.defaultProps = {
	renderPlaceholder: () => null,
	src: ''
};

ImageOnLoadRPC.propTypes = {
	children: func.isRequired,
	renderPlaceholder: func,
	src: string
};

export default ImageOnLoadRPC;
