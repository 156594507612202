import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const FormWrapper = styled.div`
	width: 100%;
	max-width: 387px;
	margin: 0 auto;
`;

export const Title = styled.h2`
	font-family: 'Roboto', sans-serif;
	color: ${({ theme }) => theme.white};
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: 2rem;
	line-height: 2.6rem;
	text-align: center;
	margin-bottom: 2rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: 2.4rem;
			line-height: 3.6rem;
			margin-bottom: 2.5rem;
		`
	)}
`;
