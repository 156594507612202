import React, { useEffect } from 'react';
import { func, string, oneOfType, arrayOf, node } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductPackets } from 'store/actions';

// Import components
import {
	Wrapper,
	Box,
	ButtonClose,
	IconCloseStyled,
	Content,
	BlackTransparentBackground
} from './styles';

const PacketsModalTemplate = ({ children, toggle, uuid }) => {
	const dispatch = useDispatch();
	const { loading } = useSelector(({ product_packets }) => product_packets);

	const handleFetchPackets = async () =>
		await fetchProductPackets(uuid)(dispatch);

	useEffect(() => {
		handleFetchPackets();
		// eslint-disable-next-line
	}, []);

	return (
		<Wrapper>
			<Box>
				{!loading && (
					<ButtonClose onClick={toggle}>
						<IconCloseStyled />
					</ButtonClose>
				)}
				<Content>{children}</Content>
			</Box>
			<BlackTransparentBackground />
		</Wrapper>
	);
};

PacketsModalTemplate.propTypes = {
	toggle: func.isRequired,
	uuid: string.isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default PacketsModalTemplate;
