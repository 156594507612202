// Font size for WCAG
export const AVAILABLE_FONT_SIZE = {
	DEFAULT: 'default',
	MEDIUM: 'large',
	LARGE: 'xl_large'
};

const { DEFAULT, MEDIUM, LARGE } = AVAILABLE_FONT_SIZE;

export const getFontSize = (fontSize) => {
	switch (fontSize) {
		case DEFAULT:
			return 1;
		case MEDIUM:
			return 1.15;
		case LARGE:
			return 1.3;
		default:
			throw new Error('Unsupported font size');
	}
};
