import styled, { css } from 'styled-components';
import PortalLogo from './portal_logo';
import { MenuButton } from '@reach/menu-button';

// Import components
import { Nav } from 'components/elements';
import { customMinResponsive, size } from 'components/styles';

export const NavButton = styled(MenuButton)`
	text-transform: capitalize;
	white-space: nowrap;
	background: none;
	border: none;
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 3rem;
	color: ${({ theme: { body } }) => body.nav.color};
	font-size: ${({ theme }) => theme.fontSize.nav.link}rem;
	font-weight: ${({ theme }) => theme.font.medium};
	transition: all 0.3s ease-in-out;

	${customMinResponsive(
		size.medium,
		css`
			padding: 0 1.25rem !important;
		`
	)}

	${customMinResponsive(
		950,
		css`
			padding: 0 2rem !important;
		`
	)}

	${customMinResponsive(
		1050,
		css`
			padding: 0 3rem !important;
		`
	)}

	&:hover {
		color: ${({ theme }) => theme.body.nav.hover};
	}

	&:focus-visible {
		color: ${({ theme }) => theme.body.nav.hover};
	}

	&:focus {
		outline: none;
	}
`;

export const NavLinksListStyled = styled(Nav.List)``;

export const WrapperElement = styled.div`
	padding-right: 24px;
	position: relative;
	left: 20px;
`;

export const StyledPortalLogo = styled(PortalLogo)`
	max-width: 123px;
`;

const transitionIn = css`
	transition: opacity 0.4s;
`;

const transitionOut = css`
	transition: opacity 0.1s;
`;

export const DesktopWrapper = styled.div`
	visibility: ${({ intersection }) => (intersection ? 'hidden' : 'visible')};
	opacity: ${({ intersection }) => (intersection ? 0 : 1)};
	position: absolute;
	top: 0;
	width: 100vw;
	${({ intersection }) => (intersection ? transitionOut : transitionIn)}
`;
