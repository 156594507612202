import { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import services from 'services/services';
import { fetchActiveSubscriptionsList } from 'store/actions';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import { PAYMENT_METHODS } from 'helpers/prices';
import { openNotification } from 'components/utilities/notification/notification';

// Import api
import { getSubscriptionCancelApi } from 'helpers/api';

// Import variables
import { SUBSCRIPTION_TOKEN } from 'helpers/variables';

const useSubscriptionManage = (uuid) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isDisabled, setIsDisabled] = useState(false);

	const subscriptions = useSelector(
		({ activeSubscriptions }) => activeSubscriptions.data
	);

	const { login_token } = useSelector(({ auth }) => auth.authenticate);

	const subscription = subscriptions.find(
		({ id, product_uuid }) => id === uuid || product_uuid === uuid
	);

	const id = subscription?.id;
	const status = subscription?.status;
	const provider = subscription?.provider;
	const isBluemedia = provider === PAYMENT_METHODS.BLUEMEDIA;
	const isExternal = provider === PAYMENT_METHODS.EXTERNAL;

	const { isLoading, mutateAsync: subscriptionCancel } = useMutation({
		mutationFn: () => {
			const url = getSubscriptionCancelApi({ provider, id });
			return services.post(url);
		},
		onSuccess: () => fetchActiveSubscriptionsList()(dispatch),
		onError: (error) => {
			const { codeErrorMessage } = getErrorCodeResults(error);

			openNotification({
				type: 'error',
				title: t('vod_root_error_title'),
				description: codeErrorMessage
			});
		}
	});

	const handleSubscriptionCancel = () => {
		if (isExternal) {
			setIsDisabled(true);
			window.open(
				`${process.env.REACT_APP_CFM_CANCELATION_URL}?${SUBSCRIPTION_TOKEN}=${login_token}`,
				'_blank'
			);
		} else subscriptionCancel();
	};

	const cancelHref = `${process.env.REACT_APP_CFM_CANCELATION_URL}?${SUBSCRIPTION_TOKEN}=${login_token}`;

	return {
		isLoading,
		isDisabled: isLoading || isDisabled,
		status,
		isBluemedia,
		provider,
		subscriptionCancel: handleSubscriptionCancel,
		cancelHref
	};
};

export default useSubscriptionManage;
