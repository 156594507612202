import * as types from 'store/actions/types';
import produce from 'immer';

// Import helpers
import { setNextChannel } from './helpers';

const INITIAL_STATE = {
	data: {
		lives: []
	},
	date: { isToday: true, since: '', till: '' },
	isError: false,
	isLoaded: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Fetch tv programs *******************
			case types.FETCH_TV_PROGRAMS_LOADING:
				return INITIAL_STATE;

			case types.FETCH_TV_PROGRAMS_REFRESH_LOADING:
				draft.date.since = action.payload.since;
				draft.date.till = action.payload.till;
				draft.date.isToday = action.payload.isToday;
				return;

			case types.FETCH_TV_PROGRAMS_SUCCESS:
				draft.isLoaded = true;
				draft.data = action.payload.data;
				draft.date.since = action.payload.since;
				draft.date.till = action.payload.till;
				draft.date.isToday = action.payload.isToday;
				return;

			case types.FETCH_TV_PROGRAMS_ERROR:
				draft.isError = true;
				return;

			// ******************* Set tv programs date *******************
			case types.SET_TV_PROGRAMS_DATE:
				draft.date.since = action.payload.since;
				draft.date.till = action.payload.till;
				draft.date.isToday = action.payload.isToday;
				return;

			// ******************* Set tv program live *******************
			case types.SET_TV_PROGRAM_LIVE:
				const channelIndex = draft.data.lives.findIndex(
					(channel) => channel.id === action.payload
				);
				const channel = draft.data.lives[channelIndex];

				if (channel) {
					const currentLiveIndex = channel.currentLiveIndex;

					const nextProgramIndex = setNextChannel(channel, currentLiveIndex);

					if (nextProgramIndex) {
						draft.data.lives[channelIndex].currentLiveIndex = nextProgramIndex;
					}
				}

				return;

			// ******************* Set tv programs in local *******************
			case types.SET_TV_PROGRAMS_IN_LOCALE:
				draft.data.lives = action.payload;
				return;

			// ******************* Browser wake up update current lives *******************
			case types.WAKE_UP_UPDATE_CURRENT_TV_PROGRAMS:
				draft.data.lives = action.payload;
				return;

			default:
				return state;
		}
	});
