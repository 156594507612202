import React from 'react';
import { object } from 'prop-types';

// Import styles
import { Cell } from 'components/elements/molecules/table/styles';

const TableCell = ({ cell }) => (
	<Cell
		width={cell.column.width}
		className="blueonline-table__cell"
		{...cell.getCellProps()}
	>
		{cell.render('Cell')}
	</Cell>
);

TableCell.propTypes = {
	cell: object.isRequired
};

export default TableCell;
