// Import helpers
import { CUSTOM_ERROR } from 'helpers/error_code_api';

export const checkErrorStatus = (error) =>
	error.response && error.response.status !== 401;

// check if server responded with 422 for invalid data
// and set error message to custom one
export const checkForInvalidDataError = (error) => {
	if (error.response.status === 422) {
		error.response.data.message = CUSTOM_ERROR.CUSTOM_REQUEST_INVALID_DATA;
	}

	return error;
};
