import React from 'react';

const useImageCheck = (imageSrc) => {
	const [isError, setError] = React.useState(false);

	let image = new Image();
	image.src = imageSrc;

	const handleError = () => {
		setError(true);
		image = null;
	};

	const handleLoad = () => (image = null);

	image.onerror = handleError;
	image.onload = handleLoad;

	return { isError };
};

export default useImageCheck;
