import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelectField = styled(Select)`
	color: ${({ theme }) => theme.authentication.input.color};
	height: 4.4rem;
	div.ant-select-selection-selected-value {
		width: 100%;
		line-height: 4.4rem;
		text-align: left;
	}

	&.ant-select {
		.ant-select-selection__rendered {
			margin-left: 0;
			padding-left: 2rem;
		}
	}

	&.errors {
		div.ant-select-selection {
			border: 1px solid ${({ theme }) => theme.error.border} !important;
		}
	}
`;
