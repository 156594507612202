import styled from 'styled-components';

// Import components
import { Input } from 'components/elements';

export const FieldWrapper = styled.div`
	position: relative;
	height: 100%;
`;

export const InputStyled = styled(Input)`
	height: 100%;
	border: 1px solid ${({ theme }) => theme.authentication.input.borderColor};
	color: ${({ theme }) => theme.authentication.input.color};

	&:focus {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.error.border};
		color: ${({ theme }) => theme.error.color};

		&:focus {
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
				0 0 8px rgba(255, 0, 0, 0.6);
		}
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.authentication.input.color};
		-webkit-box-shadow: 0 0 0px 1000px transparent inset;
		transition: background-color 5000s ease-in-out 0s;
	}
`;

export const FormError = styled.span`
	position: absolute;
	top: 4rem;
	left: 0;
	visibility: ${({ isErrorVisible }) =>
		!isErrorVisible ? 'hidden' : 'visible'};
	font-size: 1rem;
	color: ${({ theme }) => theme.error.color};
	text-align: left;
`;
