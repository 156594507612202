import React from 'react';
import { useParams } from 'react-router-dom';
import { string, array, shape } from 'prop-types';
import { useSelector } from 'react-redux';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';
import { getAuxiliaryProductData } from 'helpers/products';
import { checkProductSubtype } from 'helpers';

// Import variables
import { PRODUCTS_TYPES, PRODUCT_IMAGES_TYPES } from 'helpers/variables';

// Import components
import TileImage from './TileImage';
import TilePromotedIcon from './TilePromotedIcon';

// Import styles
import {
	TileContentWrapper,
	TileTitle,
	TileDescription,
	StyledLink
} from './styles';
import { IconTickCircle } from 'components/elements/molecules/slider/SliderItem/styles';

// Import utilities
import { useProductAvailability } from 'components/utilities';

const { COVER, LOGO } = PRODUCT_IMAGES_TYPES;
const { CHANNEL } = PRODUCTS_TYPES;

const GridTile = ({ product }) => {
	const { name: pageName } = useParams();

	const channels = useSelector(({ channels }) => channels.data.lives);

	const { images, uuid, available_in, title, subtype, type, context } = product;

	const imageType = type === CHANNEL ? LOGO : COVER;

	const image = getImage({ images, type: imageType });
	const { linkParams, description } = getAuxiliaryProductData({
		product,
		pageName,
		channels
	});

	const isAvailable = useProductAvailability(uuid, available_in, subtype);

	const { isBanner } = checkProductSubtype(subtype);
	if (isBanner) return null;

	const promotionType = context?.promoted_product;
	const isPromoted = !!promotionType;

	return (
		<StyledLink to={linkParams}>
			{isAvailable && <IconTickCircle aria-hidden="true" />}
			{isPromoted && <TilePromotedIcon promotionType={promotionType} />}
			<TileContentWrapper>
				<TileImage src={image} alt={title} />
				{title && <TileTitle>{title}</TileTitle>}
				{description && <TileDescription>{description}</TileDescription>}
			</TileContentWrapper>
		</StyledLink>
	);
};

GridTile.propTypes = {
	product: shape({
		uuid: string.isRequired,
		title: string.isRequired,
		subtype: string.isRequired,
		available_in: array.isRequired,
		images: array.isRequired
	}).isRequired
};

export default GridTile;
