import { useSelector } from 'react-redux';

// Import helpers
import {
	checkIsProductAvailableInPackets,
	checkIsProductAvailable
} from './helpers';

const useFreeProduct = ({ uuid, availableIn }) => {
	const { data: products } = useSelector(
		({ freeVodPackets }) => freeVodPackets
	);

	if (availableIn?.length) {
		return checkIsProductAvailableInPackets({ products, uuid, availableIn });
	}

	return checkIsProductAvailable({ products, uuid });
};

export default useFreeProduct;
