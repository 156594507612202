import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { saveLatestNoAuthPath } from 'store/actions';

const useNoAuthPathSave = () => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const savePath = () => saveLatestNoAuthPath(pathname)(dispatch);

	return { savePath };
};

export default useNoAuthPathSave;
