import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

// Import utilities
import { useFullScreen } from 'components/utilities';

// Import context
import PlayerVodProvider from './PlayerVodProvider';

// Import helpers
import { playerVodStyles } from 'helpers/player';
import { getUserAgentInfo } from 'helpers';
import { PLAYER_SELECTORS } from 'helpers/variables';

// Import utilities
import { PlayerFocus } from 'components/utilities';

// Import components
import { Portal } from 'components/utilities';
import { Loader, PlayerError, ErrorBoundary } from 'components/elements';
import PlayerContent from './player_content/PlayerContent';
import PlayerProvider from '../../player/player_context/PlayerProvider';
import PlayerTvContent from '../../player/player_content/PlayerTvContent';

// Import redux actions
import { clearRecommendations } from 'store/actions';

const Root = styled.div`
	flex: 1;
	background-color: ${({ theme }) => theme.black};
	width: 100%;
	height: 100%;
	position: relative;

	${({ isReady }) =>
		css`
			${playerVodStyles(isReady)}
		`}

	${({ isSafari, isPreview }) =>
		isSafari &&
		isPreview &&
		css`
			position: fixed;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 9999;
		`}
`;

const EmbedWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border: 0;
`;

const { PLAYER, VOD } = PLAYER_SELECTORS;

export default function PlayerWrapper({
	isLoaded,
	isDetailsLoaded,
	isReady,
	type,
	error,
	errorMessage,
	toggle = () => {},
	isPreview = false,
	floatMode,
	autoplay = true,
	isLiveEvent
}) {
	const dispatch = useDispatch();

	const handleClosePreview = () => toggle();

	const { isSafari, isIphone } = getUserAgentInfo();

	const { setFullscreen, elementRef } = useFullScreen({
		callback: handleClosePreview,
		isIphone
	});

	const isPlayerTv = type === PLAYER;
	const isPlayerVod = type === VOD;

	React.useEffect(() => {
		// If video is a preview set fullScreen at the begining
		isPreview && setFullscreen();

		return () => clearRecommendations(dispatch);
		// eslint-disable-next-line
	}, []);

	// Overwrite error message to one, that is defined in props
	error.message = errorMessage ?? error.message;

	return isSafari && isPreview ? (
		<Portal>
			<PlayerFocus intervalTime={250} isReady={isReady}>
				{({ isFocus, resetFocus }) => (
					<Root isSafari={isSafari} isPreview={isPreview} isReady={isReady}>
						<ErrorBoundary resetKeys={[type]}>
							<EmbedWrapper id={type}>
								{!isLoaded && !isDetailsLoaded && (
									<Loader background={false} position="absolute" zIndex={1} />
								)}
								{isLoaded && !isDetailsLoaded && (
									<PlayerVodProvider
										closePreview={handleClosePreview}
										isPreview={isPreview}
										isSafari={isSafari}
										type={type}
										isFocus={isFocus}
										resetFocus={resetFocus}
										floatMode={floatMode}
										autoplay={autoplay}
										isIphone={isIphone}
										isLiveEvent={isLiveEvent}
									>
										{!isIphone && (
											<PlayerContent type={type} isFocus={isFocus} />
										)}
										{error.isError && (
											<PlayerError
												error={error}
												isFloatMode={floatMode.isFloatMode}
											/>
										)}
									</PlayerVodProvider>
								)}
							</EmbedWrapper>
						</ErrorBoundary>
					</Root>
				)}
			</PlayerFocus>
		</Portal>
	) : (
		<PlayerFocus intervalTime={250} isReady={isReady}>
			{({ isFocus, resetFocus }) => (
				<Root isReady={isReady}>
					<EmbedWrapper ref={elementRef} id={type}>
						{!isLoaded && !isDetailsLoaded && (
							<Loader background={false} position="absolute" zIndex={1} />
						)}

						{isPlayerVod && isLoaded && !isDetailsLoaded && (
							<PlayerVodProvider
								closePreview={handleClosePreview}
								isPreview={isPreview}
								isSafari={isSafari}
								type={type}
								isFocus={isFocus}
								resetFocus={resetFocus}
								floatMode={floatMode}
								autoplay={autoplay}
								isIphone={isIphone}
								isLiveEvent={isLiveEvent}
							>
								{!isIphone && <PlayerContent type={type} isFocus={isFocus} />}
								{error.isError && (
									<PlayerError
										error={error}
										isFloatMode={floatMode.isFloatMode}
									/>
								)}
							</PlayerVodProvider>
						)}

						{isPlayerTv && isLoaded && (
							<PlayerProvider
								closePreview={handleClosePreview}
								isPreview={isPreview}
								isSafari={isSafari}
								type={type}
								isFocus={isFocus}
								resetFocus={resetFocus}
								floatMode={floatMode}
								autoplay={autoplay}
								isIphone={isIphone}
							>
								{!isIphone && (
									<PlayerTvContent
										type={type}
										isFocus={isFocus}
										error={error}
									/>
								)}
							</PlayerProvider>
						)}
					</EmbedWrapper>
				</Root>
			)}
		</PlayerFocus>
	);
}

PlayerWrapper.propTypes = {
	isLoaded: PropTypes.bool.isRequired,
	isReady: PropTypes.bool.isRequired,
	type: PropTypes.string.isRequired,
	error: PropTypes.object.isRequired,
	isLiveEvent: PropTypes.bool.isRequired,
	errorMessage: PropTypes.string,
	toggle: PropTypes.func,
	isPreview: PropTypes.bool,
	floatMode: PropTypes.object,
	autoplay: PropTypes.bool,
	isDetailsLoaded: PropTypes.bool
};
