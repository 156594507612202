export const menuItems = [
	{ id: 1, minutes: 0 },
	{ id: 2, minutes: 5 },
	{ id: 3, minutes: 10 },
	{ id: 4, minutes: 15 }
];

export const getMinutesFromNow = (startDate) => {
	const current = new Date().getTime();
	const start = new Date(startDate.replace(/-/g, '/')).getTime();

	return parseInt((start - current) / 60000);
};
