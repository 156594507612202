import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

// Import utilties
import { customMinResponsive, size } from 'components/styles';

export const Wrapper = styled.nav`
	${flex({ jc: 'space-between' })};
	background-color: ${({ theme }) => theme.body.nav.bgColor};
	padding: 0 2rem;
	height: ${({ theme: { body } }) => body.nav.heightOnMobile}rem;
	position: relative;
	width: 100%;

	${customMinResponsive(
		size.medium,
		css`
			background-color: ${({ theme }) => theme.body.nav.bgColor};
			height: ${({ theme: { body } }) => body.nav.heightOnDesktop}rem;
		`
	)}

	${customMinResponsive(
		size.large,
		css`
			padding: 0 4rem 0 4rem;
		`
	)}
`;
