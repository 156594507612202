import Cookies from 'js-cookie';

// Import variables
import { COOKIE_EXPIRES, COOKIES_ACCEPTED } from 'helpers/variables';

export const COOKIES_MODAL_TYPES = {
	MODAL: 'MODAL',
	BANNER: 'BANNER'
};

export const COOKIES_TYPES = {
	REQUIRED: 'required',
	FUNCTIONAL: 'statistic',
	MARKETING: 'marketing'
};

export const COOKIES_NAMES_MAPPED = {
	required: 'wymagane',
	wymagane: 'required',
	statistic: 'statystyczne',
	statystyczne: 'statistic',
	marketing: 'marketingowe',
	marketingowe: 'marketing'
};

export const setAcceptedCookies = (agreements) => {
	const acceptedNames = agreements
		.map(({ name }) => COOKIES_NAMES_MAPPED[name.toLowerCase()])
		.join(',');

	Cookies.set(COOKIES_ACCEPTED, acceptedNames, { expires: COOKIE_EXPIRES });
};

export const checkCookiesAccepted = () => {
	// Get cookie notice
	const cookie = Cookies.get(COOKIES_ACCEPTED);
	// check is required cookie accepted
	const isCookieAccepted = cookie?.includes(COOKIES_TYPES.REQUIRED);
	return isCookieAccepted;
};

export const checkFunctionalCookiesAccepted = () => {
	const { statistic } = COOKIES_NAMES_MAPPED;
	// Get cookie notice
	const cookie = Cookies.get(COOKIES_ACCEPTED);
	// check is required cookie accepted
	const checkCookie = (name) => !!cookie?.includes(name);
	return checkCookie(COOKIES_TYPES.FUNCTIONAL) || checkCookie(statistic);
};

export const getAcceptedCookies = () => {
	const cookies = Cookies.get(COOKIES_ACCEPTED);
	return cookies ? cookies.split(',') : [];
};
