import styled, { css } from 'styled-components';

// Import components
import { PlayerElements as Player } from 'components/elements';

export const BgGradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(
		0deg,
		${({ theme }) => theme.black} 0%,
		transparent 50%,
		rgba(0, 0, 0, 0.8) 100%
	);
	opacity: 0;
	visibility: hidden;
	cursor: none;
	transition: all 0.5s ease-in-out;
	z-index: 1;

	${({ isFocus }) =>
		isFocus &&
		css`
			visibility: visible;
			opacity: 1;
			cursor: initial;
		`}
`;
export const Poster = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 0;
	background: ${({ theme }) => theme.black};
`;

export const PosterImg = styled.img`
	height: 100%;
	width: 100%;
	object-fit: contain;
`;

export const IconPlayStyled = styled(Player.IconPlay)`
	width: 15rem;
	height: 15rem;
`;
