import styled from 'styled-components';
import { default as SlickSlider } from 'react-slick';

export const SliderWrapper = styled.div`
	position: relative;
`;

export const StyledSlickSlider = styled(SlickSlider)`
	.slick-track {
		margin: 0;
	}
`;
