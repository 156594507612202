import styled, { css } from 'styled-components';

// Import styles
import { iconSearch, customMinResponsive, size, flex } from 'components/styles';

// Import icons
import { ReactComponent as SearchIcon } from 'assets/icons/ic_nav_search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close.svg';

// Import components
import { Button } from 'components/elements';

export const FormStyled = styled.form`
	${flex({ jc: 'space-between' })}
	width: 100%;
	${customMinResponsive(
		size.medium,
		css`
			position: relative;
		`
	)}
`;

export const ButtonStyledSearch = styled(Button)`
	padding: 0rem 0.6rem;
`;

export const ButtonStyledClose = styled(Button)`
	padding: 0;
	border: 0;
	${flex({ ai: 'flex-end' })}

	${customMinResponsive(
		size.medium,
		css`
			display: block;
			padding: 0rem 0.6rem;
		`
	)};
`;

export const SearchIconStyled = styled(SearchIcon)`
	${iconSearch(2.6, 2.6)}
	${customMinResponsive(
		size.medium,
		css`
			margin-right: 0.5rem;
		`
	)}
`;

export const CloseIconStyled = styled(CloseIcon)`
	margin-left: 1.2rem;
	${iconSearch(2.2, 2.2)}
`;

export const SearchInputStyled = styled.div`
	flex: 1;

	${customMinResponsive(
		size.medium,
		css`
			width: 492px !important;
			margin-left: 1.7rem;
		`
	)}
`;

export const InputStyled = styled.input`
	width: 100%;
	padding: 0.1rem 0;
	color: ${({ theme }) => theme.search.input.mobileColor};
	font-size: ${({ theme }) => theme.fontSize.search.input.mobile}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	background-color: ${({ theme: { search } }) => search.input.bg};
	border: 0;
	border-bottom: 1px solid ${({ theme }) => theme.search.input.borderMobile};

	&:focus {
		outline: none;
	}

	&:focus-visible {
		outline: none;
	}

	&::placeholder {
		color: ${({ theme: { search } }) => search.input.mobilePlaceholderColor};
		font-size: ${({ theme }) => theme.fontSize.search.input.mobile}rem;
		font-weight: ${({ theme }) => theme.font.normal};

		${customMinResponsive(
			size.medium,
			css`
				padding: 0.7rem 0;
				color: ${({ theme: { search } }) => search.input.placeholderColor};
				font-size: ${({ theme }) => theme.fontSize.search.input.desktop}rem;
				font-weight: ${({ theme }) => theme.font.medium};
			`
		)}
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.search.input.color};
		background-color: ${({ theme: { search } }) => search.input.bg} inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	${customMinResponsive(
		size.medium,
		css`
			padding: 0.7rem 0;
			font-size: ${({ theme }) => theme.fontSize.search.input.desktop}rem;
			font-weight: ${({ theme }) => theme.font.medium};
			color: ${({ theme }) => theme.search.input.color};
			border-color: ${({ theme }) => theme.search.input.border};
			&:focus,
			&:focus-visible {
				border-bottom: 1px solid
					${({ theme }) => theme.search.input.borderFocus};
			}
		`
	)}
`;
