import i18n from 'i18next';

export const getRequestStatusText = (status) => {
	switch (status) {
		case 'success':
		case 'error':
			return i18n.t(`user_profile_general_resend_email_${status}`);

		default:
			return '';
	}
};
