import React from 'react';
import { object } from 'prop-types';

// Import assets
import backgroundImagePlacholder from 'assets/images/background-placeholder-live-banner.png';
import icPlayButton from 'assets/icons/ic_play_button.svg';
import logo from 'assets/images/pfl_logo.png';

// Import components
import { AuthModal } from 'components/elements';
import { LiveBadge } from './LiveBadge';

// Import styles
import { PlaceholderWrapper } from '../styles';
import {
	ContentWrapper,
	PlayButtonIcon,
	Logo,
	BackgroundImage
} from './styles';

const LivePlaceholder = () => {
	return (
		<AuthModal>
			<PlaceholderWrapper>
				<LiveBadge />
				<ContentWrapper>
					<PlayButtonIcon src={icPlayButton} />
					<Logo src={logo} />
				</ContentWrapper>
				<BackgroundImage src={backgroundImagePlacholder} />
			</PlaceholderWrapper>
		</AuthModal>
	);
};

LivePlaceholder.propTypes = {
	vidoBanners: object
};

export default LivePlaceholder;
