import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import services from 'services/services';

// Import api
import { QUERY_PACKET_PRODUCTS_LIST } from 'helpers/api';

// Import helpers
import { PRODUCT_TYPES_MAPPED } from 'helpers';
import { getProductListApi } from './helpers';

const usePacketSlider = () => {
	const { id, type } = useParams();

	const subtype = PRODUCT_TYPES_MAPPED[type];

	const queryKey = `${QUERY_PACKET_PRODUCTS_LIST}_${id}`;

	const queryFn = () => {
		const url = getProductListApi({ subtype, id });
		return services.get(url);
	};

	const { isLoading, data, remove } = useQuery({ queryKey, queryFn });

	const products = data?.data?.data || [];

	useEffect(() => {
		return () => remove();
		// eslint-disable-next-line
	}, []);

	return { isLoading, products };
};

export default usePacketSlider;
