import static_content from './static_content/static_content_reducer';
import epg from './epg/epg_reducer';
import channels from './channels/channels_reducer';
import selected_program from './selected_program/selected_program_reducer';
import category from './category/category_reducer';
import program_details from './program_details/program_details_reducer';
import pin from './pin/pin_reducer';
import recordings from './recordings/recordings_reducer';
import favourites from './favourites/favourites';
import reminders from './reminders/reminders';

export default {
	static_content,
	epg,
	channels,
	selected_program,
	category,
	program_details,
	pin,
	recordings,
	favourites,
	reminders
};
