import React from 'react';
import { string, func } from 'prop-types';

// Import helpers
import { COOKIES_MODAL_TYPES } from 'helpers/cookies';

// Import components
import CookieBanner from './CookieBanner';
import { AgreementsModal, UserApproval } from 'components/elements';

// Import utilities
import { useCookiesAgreements } from 'components/utilities';

const { MODAL } = COOKIES_MODAL_TYPES;

const CookieMessage = (props) => {
	const {
		isMessageOpened,
		messageToggle,
		isModalOpened,
		toggleCookies,
		isObligatoryAgreementsAccepted,
		acceptCheckedCookies,
		acceptAllCookies,
		isDocumentsLoading,
		bannerDocument,
		modalDocument,
		agreements,
		toggleAgreement,
		acceptedAgreements,
		isAgreementsLoading
	} = useCookiesAgreements(props);

	return (
		!isDocumentsLoading && (
			<>
				{isMessageOpened && (
					<CookieBanner
						messageToggle={messageToggle}
						toggleCookies={toggleCookies}
						acceptAllCookies={acceptAllCookies}
						document={bannerDocument}
					/>
				)}

				{isModalOpened && (
					<AgreementsModal toggle={toggleCookies}>
						<UserApproval
							headerText={modalDocument?.title}
							descriptionText={modalDocument?.content}
							isDisabled={!isObligatoryAgreementsAccepted}
							onAcceptChecked={acceptCheckedCookies}
							onAcceptAll={acceptAllCookies(MODAL)}
							agreements={agreements}
							toggleAgreement={toggleAgreement}
							acceptedAgreements={acceptedAgreements}
							isAgreementsLoading={isAgreementsLoading}
						/>
					</AgreementsModal>
				)}
			</>
		)
	);
};

CookieMessage.propTypes = {
	initial: string,
	onAccept: func,
	onModalClose: func
};

export default CookieMessage;
