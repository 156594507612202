import React from 'react';
import { object } from 'prop-types';

// Import styles
import { Header } from 'components/elements/molecules/table/styles';

const TableHeader = ({ column }) => (
	<Header
		width={column.width}
		className="blueonline-table__header"
		{...column.getHeaderProps()}
	>
		{column.render('Header')}
	</Header>
);

TableHeader.propTypes = {
	column: object.isRequired
};

export default TableHeader;
