export const sliderSettings = {
	dots: false,
	infinite: true,
	speed: 500,
	autoplaySpeed: 6000,
	swipe: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	adaptiveHeight: true
};

export const getCoreDomain = (url) => {
	if (url) {
		return url.replace(/^https?:\/\//g, '').split(/\//);
	}

	return '';
};

export const checkBannerUrl = (url_pc) => {
	const url = getCoreDomain(window.location.href);
	const urlPc = getCoreDomain(url_pc);

	const isExternalPath = url[0] === urlPc[0];

	return { isExternalPath, urlPc };
};
