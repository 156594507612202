import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getCodeErrorMessage } from 'helpers/error_code_api';

// ******************** FETCH PROGRAM DETAILS ********************
export const fetchProgramDetails = (id) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PROGRAM_DETAILS_LOADING,
			payload: true
		});

		// Get program details
		const { data } = await services.get(`/products/program/${id}`);

		// Dispatch an action
		dispatch({
			type: types.FETCH_PROGRAM_DETAILS_SUCCESS,
			payload: data
		});
	} catch (error) {
		// Dispatch an error action

		if (error.response) {
			const { errorCode, message } = error.response.data;

			const errorMessage = getCodeErrorMessage(errorCode) || message;
			error.response.data.message = errorMessage;

			if (errorMessage) {
				dispatch({
					type: types.FETCH_PROGRAM_DETAILS_ERROR,
					payload: error.response.data
				});
			}
		} else {
			dispatch({
				type: types.FETCH_PROGRAM_DETAILS_ERROR
			});
		}
	}
};

// ******************** FETCH PROGRAM DETAILS RECOMMENDATIONS ********************
export const fetchProgramRecommendations = (id, type = 'program') => async (
	dispatch
) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_LOADING,
			payload: true
		});

		// Get program details
		const { data } = await services.get(`recommendations/${type}/${id}`);

		// Dispatch an action
		dispatch({
			type: types.FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_SUCCESS,
			payload: data
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_ERROR,
			payload: true
		});
	}
};

// ******************** FETCH PROGRAM DETAILS BROADCASTS ********************
export const fetchProgramBroadcasts = (id) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PROGRAM_DETAILS_BROADCASTS_LOADING,
			payload: true
		});

		// Get program details
		const { data } = await services.get(`/products/program/${id}/broadcast`);

		// Dispatch an action
		dispatch({
			type: types.FETCH_PROGRAM_DETAILS_BROADCASTS_SUCCESS,
			payload: data
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_PROGRAM_DETAILS_BROADCASTS_ERROR,
			payload: true
		});
	}
};

// ******************** CLEAR PROGRAM DETAILS ********************
export const clearProgramDetails = () => ({
	type: types.CLEAR_PROGRAM_DETAILS,
	payload: true
});
