import React from 'react';
import { arrayOf, oneOfType, node, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import heleprs
import { callAllFns } from 'helpers';
// Import utilities
import { ToggleRPC } from 'components/utilities';
// Import components
import { AriaBaseModal, AriaModalContent } from 'components/elements';
import { AuthButton } from '../AuthButton';
import useAuthModal from './useAuthModal';
const AuthModal = ({ children, onClick = () => {} }) => {
	const { t } = useTranslation();
	const { isAuth, redirect, auth, open } = useAuthModal();
	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					{React.cloneElement(children, {
						onClick: callAllFns(
							open(onClick),
							auth(toggle),
							open(children?.props?.onClick)
						),
						isAuth
					})}
					{on && (
						<AriaBaseModal
							titleText={t('common_auth_modal_login_required')}
							close={toggle}
							style={{ width: '47vw' }}
						>
							<AriaModalContent
								title={t('common_auth_modal_login_required')}
								description={t('common_auth_modal_login_description_required')}
							>
								<AuthButton onClick={redirect(toggle)} />
							</AriaModalContent>
						</AriaBaseModal>
					)}
				</>
			)}
		</ToggleRPC>
	);
};
AuthModal.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	onClick: func
};
export default AuthModal;
