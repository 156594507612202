import { useEffect } from 'react';
import { fetchStaticContent, clearStaticContent } from 'store/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { string } from 'prop-types';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { USER } = APP_CORE_PATHS;

const StaticPage = ({ alias: pageAlias }) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { alias } = useParams();

	const { isLoaded, isError } = useSelector(
		({ static_content }) => static_content
	);

	const documentAlias = alias || pageAlias || pathname.split('/')[2];

	const isUserProfile = pathname.includes(USER);

	const showLoader = !isLoaded && !isError && !isUserProfile;

	useEffect(() => {
		fetchStaticContent(documentAlias)(dispatch);
		// eslint-disable-next-line
	}, [alias]);

	useEffect(() => {
		return () => clearStaticContent()(dispatch);
		// eslint-disable-next-line
	}, []);

	return {
		isUserProfile,
		showLoader
	};
};

StaticPage.propTypes = {
	alias: string
};

export default StaticPage;
