import React from 'react';

// Import context
import { InfoContext } from './InfoProvider';

const useInfoContext = () => {
	const context = React.useContext(InfoContext);
	return context;
};

export default useInfoContext;
