import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, node } from 'prop-types';

// Import components
import { AriaBaseModal } from 'components/elements';

// Import logic
import useAgreementsModal from './useAgreementsModal';

const AgreementsModal = ({ toggle, children }) => {
	const { t } = useTranslation();

	const { centerModalContent, contentRef } = useAgreementsModal();

	return (
		<AriaBaseModal
			titleText={t('agreements_marketing_modal_titleText')}
			close={toggle}
			centerModalContent={centerModalContent}
			ref={contentRef}
		>
			{children}
		</AriaBaseModal>
	);
};

AgreementsModal.propTypes = {
	toggle: func.isRequired,
	children: node.isRequired
};

export default AgreementsModal;
