import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { customResponsive, size, flex } from 'components/styles';

// Import variables
import { PRODUCTS_TYPES } from 'helpers/variables';

const { ARTICLE, LIVE_EVENT } = PRODUCTS_TYPES;

const MODIFIERS = {
	[LIVE_EVENT]: () => css`
		height: 60rem;
		flex-basis: 60%;
	`,
	[ARTICLE]: () => css`
		height: 44.2rem;
		width: 100%;
		margin-bottom: 3rem;

		${customResponsive(
			size.small,
			css`
				height: 20rem;
			`
		)}
	`
};

export const Wrapper = styled.div`
	background: ${({ theme, cover }) =>
		`${theme.black} url(${cover}) no-repeat scroll center top/cover`};

	${flex()}

	${applyStyleModifiers(MODIFIERS)};
`;
