// Import styles
import { size, customResponsive } from 'components/styles';
import styled, { css } from 'styled-components';

// Import components
import { Button } from 'components/elements';

export const ButtonStyled = styled(Button)`
	${customResponsive(
		size.small,
		css`
			display: none;
		`
	)}
`;
