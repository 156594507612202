import React from 'react';
import { array, string } from 'prop-types';

//Import hooks
import useVideoBanners from './useVideoBanners';

// Import components

// Import helpers
import VideoBanner from './video_banner/VideoBanner';
import VideoBannerLive from './video_banner_live/VideoBannerLive';

const VideoBanners = ({ name, content, type }) => {
	const vidoBanners = useVideoBanners({
		content
	});

	const isPublic = vidoBanners.currentProduct.previews.banner[0].is_public;

	if (isPublic) {
		return <VideoBanner vidoBanners={vidoBanners} type={type} name={name} />;
	}

	return <VideoBannerLive vidoBanners={vidoBanners} type={type} name={name} />;
};

VideoBanners.propTypes = {
	content: array.isRequired,
	type: string.isRequired,
	name: string.isRequired
};

export default VideoBanners;
