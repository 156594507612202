import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Alert } from 'components/elements';

const PaymentError = ({ message }) => {
	const { t } = useTranslation();

	return (
		<Alert
			closeBtn={false}
			text={message || t('common_errors_connection')}
			fixed
		/>
	);
};

PaymentError.propTypes = {
	message: string
};

export default PaymentError;
