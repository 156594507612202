import { useContext } from 'react';

// Import context
import { ShakaPlayerContext } from 'components/context/player/player_vod';

// Import variables

const usePlayerVodContext = () => useContext(ShakaPlayerContext);

export default usePlayerVodContext;
