import React from 'react';
import { useTranslation } from 'react-i18next';
import { number, string, any } from 'prop-types';

// Import helpers
import { convertPrice } from 'helpers';
import { isNumber } from 'helpers/prices';

// Import styles
import { OldPrice, RegularPrice } from './styles';

const ProductPrice = ({ regular, promotion, oldPriceColor }) => {
	const { t } = useTranslation();

	const isPromotion = isNumber(promotion);

	const regularPrice = convertPrice(regular);
	const promotionPrice = convertPrice(promotion);

	return isPromotion ? (
		<>
			<OldPrice color={oldPriceColor}>
				{regularPrice} {t('payment_currency')}
			</OldPrice>
			<span>
				{promotionPrice} {t('payment_currency')}
			</span>
		</>
	) : (
		<RegularPrice>
			{regularPrice} {t('payment_currency')}
		</RegularPrice>
	);
};

ProductPrice.propTypes = {
	regular: number.isRequired,
	promotion: any,
	oldPriceColor: string
};

export default ProductPrice;
