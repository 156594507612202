import { useSelector } from 'react-redux';

// Import utilities
import { useFreeProduct } from 'components/utilities';

// Import helpers
import { checkProductSubtype } from 'helpers';
import {
	checkIsProductAvailableInPackets,
	checkIsProductAvailable,
	checkIsSubscriptionAvailable
} from './helpers';

const useProductAvailability = (uuid, availableIn = [], subtype = '') => {
	const { products } = useSelector(({ auth }) => auth);
	const { data: subscriptions } = useSelector(
		({ activeSubscriptions }) => activeSubscriptions
	);

	const isProductFree = useFreeProduct({ uuid, availableIn });

	const { isPacket } = checkProductSubtype(subtype);

	if (isPacket) {
		return checkIsSubscriptionAvailable({ subscriptions, uuid });
	}

	if (isProductFree) {
		return true;
	} else if (availableIn?.length) {
		return checkIsProductAvailableInPackets({ products, uuid, availableIn });
	}

	return checkIsProductAvailable({ products, uuid });
};

export default useProductAvailability;
