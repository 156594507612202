import services from 'services/services';

// Import helpers
import { replaceStringChar } from 'helpers';

// Import api
import { GET_SECTION_CONTENT_API } from 'helpers/api';

// Import helpers
import { SECTIONS_TYPES } from 'helpers/variables';

const { CHANNEL_EPG } = SECTIONS_TYPES;

export const fetchSectionContent = async ({ sections = [], index = 0 }) => {
	const section = sections[index];

	if (section) {
		const { id, main_limit: mainLimit, live_epg, type } = section;

		const url = replaceStringChar({
			string: GET_SECTION_CONTENT_API,
			regex: /\b(?:id|mainLimit)\b/gi,
			object: {
				id,
				mainLimit
			}
		});

		const response = await services.get(url);

		const content = response?.data?.data || [];

		return {
			...section,
			type: live_epg ? CHANNEL_EPG : type,
			content,
			index
		};
	}
};
