import { APP_CORE_PATHS } from './app';

const { PAGE, WATCH, SECTION, CATEGORIES } = APP_CORE_PATHS;

export const VOD_PATHS = {
	PLAYER: `${WATCH}/:type/:id`,
	SECTION_DETAIL_PAGE: `/${PAGE}/:name/${SECTION}/:sectionId/:sectionName`,
	PRODUCT_DETAILS: `/${PAGE}/:name/:type/:id/:title`,
	CATEGORY_PAGE: `/${PAGE}/:name/${CATEGORIES}`,
	SECTIONS_GROUPS_PAGE: `/${PAGE}/:name`
};
