import { USER_PROFILE_PATHS } from 'components/routes/paths';

const {
	GENERAL,
	PARENTAL_CONTROL,
	PRODUCTS,
	PURCHASE_HISTORY,
	SUBSCRIPTIONS,
	FAVOURITES,
	REMINDERS,
	REGULATION,
	POLICY,
	COOKIES_POLICY
} = USER_PROFILE_PATHS;

export const USER_MENU_NAV_LINKS = [
	{
		path: GENERAL,
		translation: 'navigation_general'
	},
	{
		path: PARENTAL_CONTROL,
		translation: 'navigation_parental_control'
	},
	{
		path: PRODUCTS,
		translation: 'navigation_products'
	},
	{
		path: PURCHASE_HISTORY,
		translation: 'navigation_rental_history'
	},
	{
		path: SUBSCRIPTIONS,
		translation: 'navigation_subscription'
	},
	{
		path: FAVOURITES,
		translation: 'navigation_favourites'
	},
	{
		path: REMINDERS,
		translation: 'navigation_reminders'
	},
	{
		path: REGULATION,
		translation: 'navigation_regulation'
	},
	{
		path: POLICY,
		translation: 'navigation_policy'
	},
	{
		path: COOKIES_POLICY,
		translation: 'navigation_cookies_policy'
	}
];
