import i18next from 'i18next';
import services from 'services/services';
import * as types from 'store/actions/types';
import { openNotification } from 'components/utilities/notification/notification';

export async function fetchRecommendations(id, type = 'vod', dispatch) {
	try {
		dispatch({ type: types.RECOMMENDATIONS_LOADING });

		const url = `/recommendations/${type}/${id}`;

		const { data } = await services.get(url);

		dispatch({
			type: types.RECOMMENDATIONS_SUCCESS,
			payload: data
		});
	} catch {
		dispatch({ type: types.RECOMMENDATIONS_ERROR });

		openNotification({
			type: 'error',
			title: i18next.t('vod_movieDetails_error_title'),
			description: i18next.t('vod_movieDetails_error_description')
		});
	}
}

export function clearRecommendations(dispatch) {
	dispatch({ type: types.RECOMMENDATIONS_CLEAR });
}
