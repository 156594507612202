import services from 'services/services';

// Import api
import { GET_DOCUMENT_BY_ALIAS_API } from 'helpers/api';

// Import helpers
import { replaceStringChar } from 'helpers';

export const fetchDocumentByAlias = (type) => {
	const url = replaceStringChar({
		string: GET_DOCUMENT_BY_ALIAS_API,
		regex: /\b(?:type)\b/gi,
		object: { type }
	});

	return services.get(url);
};
