import React from 'react';
import ReactAriaModal from 'react-aria-modal';
import { oneOfType, arrayOf, node, string, object, func } from 'prop-types';

const AriaModal = ({
	children,
	initialFocus,
	underlayStyle,
	onExit,
	titleText,
	...restProps
}) => {
	const getApplicationNode = () => document.getElementById('root');

	return (
		<ReactAriaModal
			titleText={titleText}
			onExit={onExit}
			initialFocus={initialFocus}
			getApplicationNode={getApplicationNode}
			underlayStyle={underlayStyle}
			{...restProps}
		>
			{children}
		</ReactAriaModal>
	);
};

AriaModal.defaultProps = {
	initialFocus: '',
	underlayStyle: {},
	titleText: 'aria-modal',
	toggle: () => {}
};

AriaModal.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	initialFocus: string,
	underlayStyle: object,
	toggle: func.isRequired,
	titleText: string.isRequired,
	dialogClass: string,
	underlayClass: string
};

export default AriaModal;
