import _ from 'lodash';

/**
 *	Function that checks product availability for user. Product can be
 *	available in packets (available_in) or in products bought/renterd by user.
 * @param {string} uuid - uuid of single product
 * @param {Array} productAvailableIn - array of packets ids in which product is available
 * @param {Array} products - array of products available to user
 */
export const checkProductAvailability = (
	uuid,
	productAvailableIn,
	products = []
) => {
	// Check if product has availableIn array with products
	if (productAvailableIn && productAvailableIn.length > 0) {
		const availableIn = [...productAvailableIn, uuid];

		const isProductAvailable = _.intersection(products, availableIn);

		return isProductAvailable.length > 0 ? true : false;
	} else {
		const isProductAvailable = products.find(
			(productUuid) => productUuid === uuid
		);
		return isProductAvailable ? true : false;
	}
};
