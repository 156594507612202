import { useContext } from 'react';

// Import context
import { ShakaPlayerTvContext } from './ShakaPlayerTvProvider';
import { TheoPlayerTvContext } from './TheoPlayerTvProvider';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

const usePlayerTvContext = () => {
	const shakaPlayerContext = useContext(ShakaPlayerTvContext);
	const theoPlayerContext = useContext(TheoPlayerTvContext);

	switch (DEFAULT_PLAYER) {
		case THEO_PLAYER:
			return theoPlayerContext;

		default:
			return shakaPlayerContext;
	}
};

export default usePlayerTvContext;
