import myAxios from './myAxiosFactory';

// Import helpers
import {
	checkUrlPlatform,
	createAxiosInterceptors,
	prepareFetchApiUrl
} from './helpers';
import { convertApiResponse } from './helpers/api_response_convert';

const servicesFactory = (myAxios) => ({
	post: (url, payload = {}) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.post(checkUrlPlatform(url), payload);
	},
	put: (url, payload = {}) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.put(checkUrlPlatform(url), payload);
	},
	patch: (url, payload = {}) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.patch(checkUrlPlatform(url), payload);
	},
	delete: (url, payload = {}) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);
		return axios.delete(checkUrlPlatform(url), payload);
	},
	get: async (url, token) => {
		let axios = myAxios();

		axios = createAxiosInterceptors(axios);

		const apiUrl = prepareFetchApiUrl(url);

		const response = await axios.get(apiUrl, token);

		return convertApiResponse(response);
	}
});

const services = servicesFactory(myAxios);
export default services;
