import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	previousPath: '',
	latestNoAuthPath: ''
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.UPDATE_PREVIOUS_LOCATION:
				draft.previousPath = action.payload.previousPath;
				return;
			case types.SAVE_LATEST_NO_AUTH_PATH:
				draft.latestNoAuthPath = action.payload;
				return;
			default:
				return state;
		}
	});
