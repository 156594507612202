import React from 'react';
import { string, object, shape } from 'prop-types';

// Import styled components
import { CodeFieldStyled, StyledInput } from './styles';

const CodeField = ({
	type,
	field,
	name,
	autoComplete,
	form: { touched, errors },
	placeholder
}) => {
	const isErrorVisible = touched[field.name] && errors[field.name];
	return (
		<CodeFieldStyled>
			<StyledInput
				className={`${isErrorVisible ? 'errors' : ''}`}
				type={type}
				name={name}
				autoComplete={autoComplete}
				placeholder={placeholder}
				error={errors[field.name]}
				{...field}
			/>
		</CodeFieldStyled>
	);
};

CodeField.defaultProps = {
	type: 'text',
	name: 'pin',
	autoComplete: 'false'
};

CodeField.propTypes = {
	type: string.isRequired,
	field: object.isRequired,
	name: string,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	placeholder: string.isRequired,
	autoComplete: string
};

export default CodeField;
