import React from 'react';
import { string, arrayOf, oneOfType, node } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { Headline } from 'components/elements';

//Import styled components
import { Static, Row } from 'components/elements';

const StaticWrapper = ({ title, children }) => {
	const { t } = useTranslation();
	return (
		<Static.Container>
			<Row>
				<Static.Heading as="h2" modifiers={['primary', 'big']}>
					{t(title)}
				</Static.Heading>
			</Row>
			<Headline />
			<Row>{children}</Row>
		</Static.Container>
	);
};

StaticWrapper.propTypes = {
	title: string.isRequired,
	children: oneOfType([node, arrayOf(node)])
};

export default StaticWrapper;
