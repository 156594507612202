import styled from 'styled-components';

export const Timer = styled.span`
	width: 6rem;
	height: 4rem;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.9rem;
	padding-bottom: 0.3rem;
	color: ${({ theme }) => theme.player.timer};
`;
