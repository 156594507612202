import * as types from 'store/actions/types';

// ******************** SET SELECTED PROGRAM ********************
export const setSelectedProgram = (program, channelId) => (
	dispatch,
	getState
) => {
	const {
		channels: { data }
	} = getState();

	if (program) {
		dispatch({
			type: types.SET_SELECTED_PROGRAM,
			payload: program
		});
	} else if (data && data.lives) {
		const live = channelId
			? data.lives.find(({ uuid }) => uuid === channelId)
			: data.lives.find(
					({ currentLiveIndex, noPrograms }) =>
						currentLiveIndex >= 0 && !noPrograms
			  );

		if (live) {
			// Get first avaiable program
			const {
				epgProgrammes,
				currentLiveIndex,
				title: channelName,
				uuid
			} = live;
			const program = epgProgrammes[currentLiveIndex];
			if (program) {
				// Get program's title and id
				const { title, uuid: id } = program;
				// Check if there is selected program, if not find first channel and first epg program
				const checkProgram = {
					channel: channelName,
					title,
					id,
					channelID: uuid
				};
				dispatch({
					type: types.SET_SELECTED_PROGRAM,
					payload: checkProgram
				});
			}
		}
	}
};

export const clearSelectedProgram = () => ({
	type: types.CLEAR_SELECTED_PROGRAM,
	payload: true
});
