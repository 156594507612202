import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	sendResetEmail: {
		loading: false,
		isSuccess: false,
		error: {
			message: '',
			name: '',
			isError: false,
			errorCode: null,
			status: 200
		}
	},
	checkHash: {
		loading: true,
		isHashValid: false,
		error: {
			message: '',
			name: '',
			isError: false,
			errorCode: null,
			status: 200
		}
	},
	setNewPassword: {
		isSuccess: false,
		loading: false,
		error: {
			message: '',
			name: '',
			isError: false,
			errorCode: null,
			status: 200
		}
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ------------ Send reset mail ------------
			case types.SEND_RESET_EMAIL_LOADING:
				draft.sendResetEmail.loading = true;
				draft.sendResetEmail.isSuccess = false;
				draft.sendResetEmail.error = INITIAL_STATE.sendResetEmail.error;
				break;
			case types.SEND_RESET_EMAIL_SUCCESS:
				draft.sendResetEmail.loading = false;
				draft.sendResetEmail.isSuccess = true;
				break;
			case types.SEND_RESET_EMAIL_ERROR:
				draft.sendResetEmail.loading = false;
				draft.sendResetEmail.isSuccess = false;
				draft.sendResetEmail.error.isError = true;
				draft.sendResetEmail.error.name = action.payload.data.name || '';
				draft.sendResetEmail.error.message = action.payload.data.message || '';
				draft.sendResetEmail.error.status = action.payload.status;
				break;

			// ------------ Check reset password hash ------------
			case types.CHECK_RESET_PASSWORD_HASH_LOADING:
				draft.checkHash.loading = true;
				draft.checkHash.isHashValid = false;
				draft.checkHash.error = INITIAL_STATE.checkHash.error;
				break;
			case types.CHECK_RESET_PASSWORD_HASH_SUCCESS:
				draft.checkHash.loading = false;
				draft.checkHash.isHashValid = true;
				break;
			case types.CHECK_RESET_PASSWORD_HASH_ERROR:
				draft.checkHash.loading = false;
				draft.checkHash.isHashValid = false;
				draft.checkHash.error.isError = true;
				draft.checkHash.error.name = action.payload.data.name || '';
				draft.checkHash.error.message = action.payload.data.message || '';
				draft.checkHash.error.status = action.payload.status;
				break;

			// ------------ Send new passwords ------------
			case types.SEND_NEW_PASSWORDS_LOADING:
				draft.setNewPassword.loading = true;
				draft.setNewPassword.isSuccess = false;
				draft.setNewPassword.error = INITIAL_STATE.setNewPassword.error;
				break;
			case types.SEND_NEW_PASSWORDS_SUCCESS:
				draft.setNewPassword.loading = false;
				draft.setNewPassword.isSuccess = true;
				break;
			case types.SEND_NEW_PASSWORDS_ERROR:
				draft.setNewPassword.loading = false;
				draft.setNewPassword.isSuccess = false;
				draft.setNewPassword.error.isError = true;
				draft.setNewPassword.error.name = action.payload.data.name || '';
				draft.setNewPassword.error.message = action.payload.data.message || '';
				draft.setNewPassword.error.status = action.payload.status;
				break;

			// ------------ Clear state ------------
			case types.CLEAR_RESET_PASSWORD_STATE:
				return { ...INITIAL_STATE };

			default:
				break;
		}
	});
