import React from 'react';
import { func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { CommonButton } from 'components/elements';

const PreviewButton = ({ watchPreview, width = 155 }) => {
	const { t } = useTranslation();

	return (
		<CommonButton onClick={watchPreview} width={width}>
			{t('vod_buttons_preview')}
		</CommonButton>
	);
};

PreviewButton.propTypes = {
	width: number,
	watchPreview: func
};

export default PreviewButton;
