import React from 'react';
import { object, string, shape, bool } from 'prop-types';
import styled from 'styled-components';

// Import components
import { Input } from 'components/elements';

const FormField = ({
	type = 'text',
	field,
	name = 'pin',
	autoComplete = 'true',
	form: { touched, errors },
	placeholder,
	required = false
}) => {
	return (
		<FormFieldStyled>
			<Input
				className={`${
					touched[field.name] && errors[field.name] ? 'errors' : ''
				}`}
				type={type}
				name={name}
				autoComplete={autoComplete}
				placeholder={`${placeholder} ${required ? '*' : ''}`}
				{...field}
			/>
			{touched[field.name] && errors[field.name] && (
				<FormError>{errors[field.name]}</FormError>
			)}
		</FormFieldStyled>
	);
};

const FormFieldStyled = styled.div`
	margin-bottom: 1.5rem;
`;

const FormError = styled.span`
	display: block;
	margin-top: 1rem;
	font-size: 1.2rem;
	color: ${({ theme }) => theme.error.color};
	text-align: left;
	padding-left: 0.7rem;
`;

FormField.propTypes = {
	type: string.isRequired,
	field: object.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	placeholder: string.isRequired,
	autoComplete: string,
	name: string,
	required: bool
};

export default FormField;
