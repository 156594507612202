// Import helpers
import { SECTIONS_TYPES } from 'helpers/variables';

const { BANNER, HIGH_BANNER, VIDEO_BANNER } = SECTIONS_TYPES;

export const CONTAINER_SCREEN_RATIO_DESKTOP = {
	[HIGH_BANNER]: 1920 / 880,
	[BANNER]: 1920 / 400
};

export const CONTAINER_SCREEN_RATIO_MOBILE = {
	[HIGH_BANNER]: 1920 / 880,
	[BANNER]: 1920 / 400
};
export const WCAG_PROPORTION_MULTIPLIER = 1.5;

export const baseSettings = {
	dots: false,
	infinite: false,
	speed: 500,
	swipe: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
	arrows: true,
	initialSlide: 0,
	centerMode: false,
	accessibility: true
};

export const BANNER_MODIFIER_KEYS = {
	HIGH_BANNER,
	BANNER,
	VIDEO_BANNER,
	ARROWS: 'arrow_navigation',
	NO_ARROWS: 'no_arrow_navigation'
};

export const BANNER_RATIOS = {
	[HIGH_BANNER]: {
		width: 1920,
		height: 880
	},
	[BANNER]: {
		width: 1920,
		height: 400
	}
};
