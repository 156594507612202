import React from 'react';

// Import components
import Mobile from 'components/views/Mobile';
import Routes from './Routes';
import { Static } from './helpers/components';

// Import logic
import useAppRoutesDesktop from './useAppRoutesDesktop';

const AppRoutesDesktop = () => {
	const { isDesktop, mobileType, isFromApp } = useAppRoutesDesktop();

	if (isDesktop) {
		return <Routes />;
	} else if (isFromApp) {
		return <Static />;
	}

	return <Mobile mobileType={mobileType} />;
};

export default AppRoutesDesktop;
