import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	data: [],
	id: null,
	title: '',
	type: '',
	isLoaded: false,
	isError: false,
	isStatusError: false
};

export default function(state = INITIAL_STATE, action = {}) {
	return produce(state, (draft) => {
		switch (action.type) {
			// FETCH REMINDER
			case types.FETCH_REMINDERS_LOADING:
				draft.isLoaded = false;
				break;

			case types.FETCH_REMINDERS_SUCCESS:
				draft.data = action.payload;
				draft.isLoaded = true;
				break;

			case types.FETCH_REMINDERS_ERROR:
				draft.isError = true;
				break;

			// DELETE REMINDER
			case types.DELETE_REMINDER_LOADING:
				draft.isStatusError = false;
				break;

			case types.DELETE_REMINDER_ERROR:
				draft.isStatusError = true;
				break;

			// ADD REMINDER
			case types.ADD_REMINDER_LOADING:
				draft.isStatusError = false;
				break;

			case types.ADD_REMINDER_ERROR:
				draft.isStatusError = true;
				break;

			default:
				return state;
		}
	});
}
