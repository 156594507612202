import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

// Import styles
import { transitionMain } from 'components/styles';

export const Nav = styled(NavLink)`
	color: ${({ theme }) => theme.veryLightGray};
	font-size: ${({ theme }) => theme.fontSize.nav.link}rem;
	line-height: 3.4rem;
`;

export const ItemStyled = styled(Menu.Item)`
	> ${Nav} {
		color: ${({ theme }) => theme.dropdown.menu.itemColor};
		${transitionMain}

		&.active-drop-nav {
			background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemActiveBg};
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemActiveColor};
		}

		&:focus {
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
		}

		&:focus-visible {
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
		}
	}

	&.ant-dropdown-menu-item-active {
		background: none;

		> ${Nav} {
			background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemHoverBg};
			color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
		}
	}
`;
