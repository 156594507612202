import React from 'react';
import { arrayOf, oneOfType, node, string } from 'prop-types';

// Import styled components
import { Wrapper } from './styles';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

const { NAVIGATION } = SECTIONS_ID;

const NavTemplate = ({ children, className }) => {
	return (
		<Wrapper className={className} id={NAVIGATION}>
			{children}
		</Wrapper>
	);
};

NavTemplate.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	className: string
};

export default NavTemplate;
