import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	data: [],
	isLoaded: false,
	isError: false,
	errorMessage: '',
	remove: {
		isLoaded: false,
		isError: false,
		message: ''
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Fetch recordings *******************
			case types.FETCH_RECORDINGS_LOADING:
				return { ...INITIAL_STATE };

			case types.FETCH_RECORDINGS_SUCCESS:
				draft.isLoaded = true;
				draft.data = action.payload;
				draft.isError = false;
				draft.errorMessage = '';
				return;

			case types.FETCH_RECORDINGS_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				draft.errorMessage = action.payload.message;
				return;

			// ******************* Remove recording *******************
			case types.REMOVE_RECORDING_LOADING:
				draft.remove.isLoaded = false;
				return;

			case types.REMOVE_RECORDING_SUCCESS:
				draft.remove.isLoaded = true;

				return;

			case types.REMOVE_RECORDING_ERROR:
				draft.remove.isError = true;
				draft.remove.message = action.payload || '';
				return;

			// ******************* Remove recording error data *******************
			case types.CLEAR_RECORDINGS_ERRORS:
				draft.remove.isError = false;
				draft.remove.message = '';
				draft.remove.isLoaded = false;
				return;

			default:
				return state;
		}
	});
