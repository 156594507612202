import * as types from 'store/actions/types';
import produce from 'immer';

export const INITIAL_STATE = {
	data: {},
	error: false,
	loading: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.FETCH_VOUCHER_PROMOTIONS_LOADING:
				return INITIAL_STATE;

			case types.FETCH_VOUCHER_PROMOTIONS_SUCCESS:
				draft.data = action.payload;
				draft.loading = false;
				return;

			case types.FETCH_VOUCHER_PROMOTIONS_ERROR:
				draft.loading = false;
				draft.error = true;
				return;

			case types.CLEAR_VOUCHER_DATA:
				return INITIAL_STATE;

			default:
				return state;
		}
	});
