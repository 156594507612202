import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import icons
import { ReactComponent as IconClock } from 'assets/icons/ic_clock.svg';

// Import styles
import { iconClock } from 'components/styles';

// Import helpers
import { convertTime } from 'helpers/index';

// Import components
import { Paragraph } from 'components/elements';

// Import hooks
import useProductRatingCheck from 'components/elements/molecules/parental_control/parental_control_utils/useProductRatingCheck';

const SidebarInfo = ({ since, till, title, isUpcoming, pcRating }) => {
	// check program rating and block content accordingly
	const { isContentBlocked, placeholderRatingText } = useProductRatingCheck(
		pcRating,
		since
	);

	return (
		<Info isUpcoming={isUpcoming}>
			{isContentBlocked ? (
				<ParagraphStyled>{placeholderRatingText}</ParagraphStyled>
			) : (
				<>
					<TimeBox>
						<Clock /> <Time>{convertTime(since, till)}</Time>
					</TimeBox>
					<Piep>|</Piep>
					{title && <ParagraphStyled>{title}</ParagraphStyled>}
				</>
			)}
		</Info>
	);
};

const Info = styled.div`
	font-size: 1.4rem;
	${({ isUpcoming }) =>
		isUpcoming &&
		css`
			opacity: 0.6;
		`}
`;

const Clock = styled(IconClock)`
	position: relative;
	${iconClock};
	top: 0.4rem;
`;

const TimeBox = styled.div`
	float: left;
	display: flex;
`;

const Time = styled.p`
	font-weight: ${({ theme }) => theme.font.normal};
`;

const Piep = styled.span`
	float: left;
	margin: 0 0.4rem;
`;

const ParagraphStyled = styled(Paragraph.Ellipsis)`
	width: 61%;
`;

SidebarInfo.propTypes = {
	since: PropTypes.string.isRequired,
	till: PropTypes.string.isRequired,
	title: PropTypes.string,
	isUpcoming: PropTypes.bool,
	pcRating: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
};

export default SidebarInfo;
