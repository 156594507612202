import { useMutation } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import services from 'services/services';

// Import actions
import { fetchUserDetails } from 'store/actions';

// Import api
import {
	POST_SUBSCRIBER_APPROVAL_EMAIL_API,
	QUERY_SUBSCRIBER_APPROVAL_EMAIL
} from 'helpers/api';

// Import helpers
import { getRequestStatusText } from './helpers';

const useEmailConfirmation = () => {
	const dispatch = useDispatch();

	const {
		isUserDetailsLoading,
		authenticate: { email_approved }
	} = useSelector(({ auth }) => auth);

	const handleFetchUserDetails = async () => {
		await fetchUserDetails()(dispatch);
	};

	const handleSendApprovalEmail = () => {
		return services.post(POST_SUBSCRIBER_APPROVAL_EMAIL_API);
	};

	const {
		mutateAsync: resendConfirmation,
		status,
		isLoading,
		isSuccess,
		isError
	} = useMutation(handleSendApprovalEmail, {
		mutationKey: QUERY_SUBSCRIBER_APPROVAL_EMAIL
	});

	const requestStatusText = getRequestStatusText(status);

	return {
		resendConfirmation,
		isLoading,
		isSuccess,
		isError,
		requestStatusText,
		isEmailConfirmed: email_approved,
		fetchUserDetails: handleFetchUserDetails,
		isUserDetailsLoading
	};
};

export default useEmailConfirmation;
