import styled, { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

export const Wrapper = styled.div`
	margin-top: 0.8rem;
`;

export const Content = styled.div`
	margin: 0.5rem 0 4rem;
	padding-bottom: 0.6rem;
	display: grid;
	grid-template-columns: ${({ columns }) => columns};

	${customMinResponsive(
		400,
		css`
			margin-bottom: 0.6rem;
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			margin: 3.5rem 0 2.8rem;
			padding-left: 11.3rem;
		`
	)}
`;
