import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { fetchSearchQuery } from 'store/actions';

// Import components
import SearchInput from './SearchInput';
import SearchBox from './search_box/SearchBox';
import {
	FormStyled,
	ButtonStyledSearch,
	SearchIconStyled,
	ButtonStyledClose,
	CloseIconStyled
} from './styles';

export class NavSerachForm extends Component {
	constructor() {
		super();
		this.state = {
			query: ''
		};
		this.debounce = debounce((query) => this.handleFetchData(query), 600);
		this.formRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside, false);
	}

	handleClickOutside = (e) => {
		// Close modal if user clicks outside the form area
		if (
			!this.props.isMobileView &&
			this.formRef &&
			!this.formRef.current.contains(e.target)
		) {
			this.props.toggle();
		}
	};

	// Prevent default form actions
	handleOnSubmit = (e) => e.preventDefault();

	handleFetchData = (value) => {
		const { fetchSearchQuery } = this.props;
		// Fetch search query
		// Dispatch an fetchSearchQuery action in search folder
		value.length >= 3 && fetchSearchQuery(value);
	};

	handleOnChange = ({ target: { value } }) => {
		// Update state with query
		this.setState({ query: value });
		// Fetch search query
		this.debounce(value);
	};

	handleClose = () => {
		const { toggle } = this.props;
		// Reset the form
		this.setState({ query: '' });
		// Close search
		toggle();
	};

	render() {
		const { query } = this.state;
		const { toggle } = this.props;
		return (
			<FormStyled ref={this.formRef} onSubmit={this.handleOnSubmit}>
				<ButtonStyledSearch>
					<SearchIconStyled />
				</ButtonStyledSearch>

				<SearchInput
					query={query}
					handleOnChange={this.handleOnChange}
					autoFocus={this.props.autoFocus}
				/>
				<ButtonStyledClose type="button" onClick={this.handleClose}>
					<CloseIconStyled />
				</ButtonStyledClose>
				{query.length >= 3 && <SearchBox query={query} toggle={toggle} />}
			</FormStyled>
		);
	}
}

NavSerachForm.propTypes = {
	fetchSearchQuery: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
	isMobileView: PropTypes.bool,
	autoFocus: PropTypes.bool
};

export default connect(null, { fetchSearchQuery })(NavSerachForm);
