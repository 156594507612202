import styled from 'styled-components';

const Catchup = styled.span`
	background-color: ${({ theme }) => theme.epg.catchup.bg};
	color: ${({ theme }) => theme.epg.catchup.color};
	padding: 0.2rem 0.6rem 0.3rem;
	font-size: ${({ theme }) => theme.fontSize.epg.catchup}rem;
	line-height: 1;
	font-weight: ${({ theme }) => theme.font.medium};
	border-radius: 3px;
`;

export default Catchup;
