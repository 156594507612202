import React from 'react';
import { Helmet } from 'react-helmet';
import { object, bool } from 'prop-types';

// Import components
import useHelemtMetaTags from './useHelemtMetaTags';

const MetaTags = (props) => {
	const { titleMeta, nameMeta, descriptionMeta, keywordsMeta, imageMeta } =
		useHelemtMetaTags(props);

	return (
		<Helmet>
			<title>{titleMeta}</title>
			{/* <!-- for Google --> */}
			<meta name="description" content={descriptionMeta} />
			<meta name="keywords" content={keywordsMeta} />
			<meta name="application-name" content={nameMeta} />

			{/* <!-- for Facebook --> */}
			<meta property="og:title" content={nameMeta} />
			<meta name="og:keywords" content={keywordsMeta} />
			<meta property="og:description" content={descriptionMeta} />
			<meta property="og:image" content={imageMeta} />
			<meta property="og:url" content={process.env.REACT_APP_WEBSITE_URL} />

			{/* <!-- for Twitter --> */}
			<meta name="twitter:title" content={nameMeta} />
			<meta name="twitter:keywords" content={keywordsMeta} />
			<meta name="twitter:description" content={descriptionMeta} />
			<meta property="twitter:image" content={imageMeta} />
		</Helmet>
	);
};

MetaTags.defaultProps = {
	metaTags: {},
	isTranslationKey: true
};

MetaTags.propTypes = {
	metaTags: object.isRequired,
	isTranslationKey: bool
};

export default MetaTags;
