import * as types from './types';
import produce from 'immer';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_PRODUCT:
				draft.product = action.payload;
				break;

			case types.SET_IS_MODAL_OPEN:
				draft.isModalOpened = action.payload;
				break;

			case types.SET_SELECTED:
				draft.selected = action.payload;
				break;

			case types.SET_SELECTED_EPISODE_NUMBER:
				draft.selectedEpisodeNumber = action.payload;
				break;

			case types.SET_VOD_ONLY_IN_PACKET:
				draft.vodOnlyInPackets = action.payload;
				break;

			case types.SET_IS_PACKET_MODAL_OPEN:
				draft.isPacketsModalOpen = action.payload;
				break;

			default:
				return state;
		}
	});

export default reducer;
