import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { playerOnCancel, clearMovieDetails } from 'store/actions';

// Import utilities
import { usePip, useAuth } from 'components/utilities';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';
import { getProductType } from 'helpers/products';
import { fetchMovieResources, checkProductPage, getMatchedId } from './helpers';
import { checkProductSubtype } from 'helpers';

const { VOD, PLAYER } = PLAYER_SELECTORS;

const usePlayer = ({ isVodPlayer }) => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const { isAuth } = useAuth();

	const playerSelector = isVodPlayer ? VOD : PLAYER;

	const {
		isReady,
		isLoaded,
		error: playerError,
		configuration: { tracking }
	} = useSelector((state) => state[playerSelector]);

	const productType = tracking?.customData?.productType;

	const { isLiveEvent } = checkProductSubtype(productType);

	const {
		error,
		errorMessage,
		loading: isDetailsLoaded
	} = useSelector((state) => state.movieDetails);

	// Set use pip
	const { exitPip } = usePip();

	const { authenticate } = useSelector(({ auth }) => auth);
	const parentalControlEnabled = authenticate?.parentalControlEnabled;

	useEffect(() => {
		const productId = getMatchedId(pathname);
		const productType = getProductType(pathname);
		const isDetails = checkProductPage(pathname);

		isVodPlayer &&
			fetchMovieResources({
				id: productId,
				type: productType,
				dispatch,
				isDetails,
				isAuth
			});
		// eslint-disable-next-line
	}, [parentalControlEnabled]);

	useEffect(() => {
		return () => {
			// Clear player
			playerOnCancel(playerSelector)(dispatch);
			// Clear movie details
			clearMovieDetails(dispatch);
			// Exit pip
			exitPip();
		};
		// eslint-disable-next-line
	}, []);

	return {
		isReady,
		isLoaded,
		isDetailsLoaded,
		error: error.isError ? error : playerError,
		errorMessage,
		type: playerSelector,
		isLiveEvent
	};
};

export default usePlayer;
