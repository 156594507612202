import styled, { css } from 'styled-components';

// Import components

import { Heading } from 'components/elements';

// Import assets
import PortalLogo from 'assets/icons/redcarpet_logo.svg';

export const PopoverWrapper = styled.div`
	display: flex;
	color: ${({ theme }) => theme.popover.color};
	overflow: hidden;
	background-color: ${({ theme }) => theme.popover.bg};
	border: 1px solid ${({ theme }) => theme.popover.border};

	${({ dragged }) =>
		dragged &&
		css`
			display: none;
		`};
`;

export const Container = styled.div`
	width: 28rem;
	display: flex;
	flex-direction: column;
`;

export const PlaceholderContainer = styled.div`
	flex: 1;
	padding: 1rem;
`;

export const PlaceholderText = styled(Heading.Third)`
	color: ${({ theme }) => theme.popover.placeholder};
	font-size: ${({ theme }) => theme.fontSize.popover.placeholder}rem;
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 0.4rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Preview = styled.div`
	width: 26rem;
	background: url(${PortalLogo}) 50% /80% auto ${({ theme }) =>
			theme.popover.fallbackBg} no-repeat;
`;
export const Image = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
	display: ${({ isLoaded }) => (isLoaded ? 'block' : 'none')};
`;
