import React, { useReducer, useEffect, useMemo } from 'react';
import { node } from 'prop-types';
import { useLocation } from 'react-router-dom';

// Import utilities
import { useAgreements } from 'components/utilities';

// Import reducer
import reducer from './store/reducer';

// Import actions
import { setSelectedPriceId, setTransactionType } from './store/actions';

// Import helpers
import { PAYMENT_AGREEMENTS, QUERY_PAYMENT_AGREEMENTS } from 'helpers';
import { INITIAL_STATE, getTransactionType, PAYMENT_METHOD } from './helpers';

// Import hooks
import { useResources } from './hooks';

// Create context
export const ProductPaymentContext = React.createContext();

const ProductPaymentProvider = ({ children }) => {
	const { state: locationState } = useLocation();

	// ---- state reducer ----
	const [state, stateDispatch] = useReducer(reducer, INITIAL_STATE);
	const invoiceFormRef = React.useRef(null);

	const { paymentMethods, selectedPriceId } = state;

	const initialPriceId = locationState?.priceId;
	const update_subscription = locationState?.update_subscription ?? false;
	const provider = locationState?.provider;

	const resources = useResources({
		state,
		stateDispatch,
		update_subscription,
		provider
	});

	const agreementsStatus = useAgreements({
		queryKey: QUERY_PAYMENT_AGREEMENTS,
		agreementsToFetch: PAYMENT_AGREEMENTS
	});

	const method = useMemo(() => {
		const method = paymentMethods.find(({ id }) => id === selectedPriceId);
		return method ?? paymentMethods[0] ?? PAYMENT_METHOD;
		// eslint-disable-next-line
	}, [selectedPriceId, paymentMethods.length]);

	// ---- effect ----
	useEffect(() => {
		const type = getTransactionType(method);
		setTransactionType(type)(stateDispatch);
		// eslint-disable-next-line
	}, [method.id]);

	useEffect(() => {
		// set selected method id
		setSelectedPriceId(initialPriceId)(stateDispatch);
		// eslint-disable-next-line
	}, [initialPriceId]);

	return (
		<ProductPaymentContext.Provider
			value={{
				...state,
				...agreementsStatus,
				...resources,
				invoiceFormRef,
				dispatch: stateDispatch,
				update_subscription,
				method
			}}
		>
			{children}
		</ProductPaymentContext.Provider>
	);
};

ProductPaymentProvider.propTypes = {
	children: node
};

export default ProductPaymentProvider;
