export const getNumberOfItems = (height) => {
	if (height >= 447 && height <= 549) {
		return 3;
	}

	if (height >= 550 && height <= 634) {
		return 4;
	}

	if (height >= 635 && height <= 729) {
		return 5;
	}

	if (height >= 730 && height <= 819) {
		return 6;
	}

	if (height > 910) {
		return 7;
	}

	return 2;
};
