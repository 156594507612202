import * as types from './types';

export const setProduct = (payload) => (dispatch) => {
	dispatch({ type: types.SET_PRODUCT, payload });
};

export const setIsModalOpen = (payload) => (dispatch) => {
	dispatch({ type: types.SET_IS_MODAL_OPEN, payload });
};

export const setSelected = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SELECTED, payload });
};

export const setSelectedEpisodeNumber = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SELECTED_EPISODE_NUMBER, payload });
};

export const setIsVodOnlyInPackets = (payload) => (dispatch) => {
	dispatch({ type: types.SET_VOD_ONLY_IN_PACKET, payload });
};

export const setIsPacketsModalOpen = (payload) => (dispatch) => {
	dispatch({ type: types.SET_IS_PACKET_MODAL_OPEN, payload });
};
