import { getCoreDomain } from 'components/elements/molecules/banners/helpers/index.js';
export const checkDetailsUrl = (url_pc) => {
	const url = getCoreDomain(window.location.href);
	const urlPc = getCoreDomain(url_pc);

	const isInternalPath = url?.[0] === urlPc?.[0];

	return {
		isInternalPath,
		url: isInternalPath ? `/${urlPc?.splice(1).join('/')}` : url_pc
	};
};
