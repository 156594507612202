import { useState, useEffect } from 'react';

const TheoPlayerFocus = ({ isReady, children, intervalTime = 800 }) => {
	const [isFocus, setFocus] = useState(true);

	const player = () => document.querySelector('.theoplayer-container');

	const handleIsFocus = () => {
		player() && player().classList.contains('vjs-user-inactive')
			? setFocus(false)
			: setFocus(true);
	};

	useEffect(() => {
		let focusInterval = null;

		if (isReady) {
			focusInterval = setInterval(handleIsFocus, intervalTime);
		}

		return () => {
			clearInterval(focusInterval);
		};
		// eslint-disable-next-line
	}, [isReady]);

	return children({
		isFocus,
		resetFocus: () => {},
		onSidebarMouseEnter: () => {},
		onSidebarMouseLeave: () => {}
	});
};

export default TheoPlayerFocus;
