import styled, { css } from 'styled-components';

// Import helpers
import {
	CONTAINER_SCREEN_PERCENT_DESKTOP,
	CONTAINER_SCREEN_PERCENT_MOBILE
} from '../helpers';

// Import styles
import {
	customMinResponsive,
	customResponsiveOrientation,
	size,
	orientation
} from 'components/styles';

// Import icons
import { ReactComponent as IconInfo } from 'assets/icons/ic_info.svg';

// Import variables
import { DESCRIPTION_LINE_HEIGHT_LIMIT } from 'helpers/variables';

const { LINE_HEIGHT_DESKTOP } = DESCRIPTION_LINE_HEIGHT_LIMIT;

const dotHover = css`
	&:focus,
	&:hover {
		background-color: ${({ theme }) => theme.slider.dot.bgHover};
		border-color: ${({ theme }) => theme.slider.dot.borderHover};
	}
`;

export const Wrapper = styled.section`
	position: relative;
	background-color: ${({ theme }) => theme.highBanners.bg};
	height: ${CONTAINER_SCREEN_PERCENT_MOBILE}vh;

	${customMinResponsive(
		size.medium,
		css`
			height: ${CONTAINER_SCREEN_PERCENT_DESKTOP}vh;
		`
	)}

	.slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide > div {
		height: 100%;
	}

	.slick-dots {
		bottom: 5%;

		${customMinResponsive(
			size.medium,
			css`
				bottom: 10%;
			`
		)}

		.slider_dot_btn {
			height: 1rem;
			width: 1rem;
			border-radius: 50%;
			border: 2px solid ${({ theme }) => theme.slider.dot.border};
			${dotHover}

			${customMinResponsive(
				size.medium,
				css`
					height: 1.4rem;
					width: 1.4rem;
					border-width: 2.5px;
				`
			)}
		}

		.slick-active > .slider_dot_btn {
			background-color: ${({ theme }) => theme.slider.dot.bgActive};
			${dotHover}
		}
	}
`;

export const SliderDots = styled.ul`
	list-style: none;
	z-index: 2;
`;

export const BannerWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	padding: 12.6rem 1.7rem 0;
	background: ${({ theme, cover }) =>
		`${theme.black} url(${cover}=w1920-h1080-c) no-repeat scroll center top/cover`};

	${customResponsiveOrientation(
		size.medium,
		orientation.landscape,
		css`
			padding: 2rem 1.7rem 0;
		`
	)}

	${({ theme, isContrast }) =>
		isContrast &&
		css`
			background: ${theme.body.bg.primary};
		`}

	${customMinResponsive(
		size.medium,
		css`
			padding: 11.1vw 4rem 0;
		`
	)}
`;

export const BannerContent = styled.div`
	z-index: 2;
	position: relative;

	${customMinResponsive(
		size.medium,
		css`
			width: 41vw;

			a {
				width: max-content;
			}
		`
	)}
`;

export const Title = styled.h3`
	color: ${({ theme }) => theme.highBanners.titleColor};
	font-size: 2.6rem;
	line-height: 2.5rem;
	margin-bottom: 1.9rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: 4.8rem;
			line-height: 5.7rem;
			margin-bottom: 2.8rem;
		`
	)}
`;

export const Description = styled.p`
	color: ${({ theme }) => theme.highBanners.descriptionColor};
	font-size: 1.4rem;
	overflow: hidden;
	line-height: 19px;
	max-height: ${19 * 6}px;
	margin-bottom: 2.6rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: 2.4rem;
			line-height: ${LINE_HEIGHT_DESKTOP}px;
			max-height: ${LINE_HEIGHT_DESKTOP * 10}rem;
			margin-bottom: 4.3rem;
		`
	)}
`;

export const InfoIcon = styled(IconInfo)`
	margin-right: 0.8rem;
	height: 100%;
`;

export const Dot = styled.button`
	background-color: transparent;

	&::before,
	&::after {
		display: none;
	}
`;
