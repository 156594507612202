import services from 'services/services';
import * as types from 'store/actions/types';

export const fetchPromotions = async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PROMOTIONS_LOADING,
			payload: true
		});

		const { data } = await services.get('/subscriber/promotions');

		// Dispatch an success action
		dispatch({
			type: types.FETCH_PROMOTIONS_SUCCESS,
			payload: data
		});
	} catch {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_PROMOTIONS_ERROR,
			payload: true
		});
	}
};

export const fetchVoucherPromotions = async (uuid, voucherCode, dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_VOUCHER_PROMOTIONS_LOADING,
			payload: true
		});

		const { data } = await services.get(
			`/payments/${uuid}/${voucherCode}/promotion/prices`
		);

		// Dispatch an success action
		dispatch({
			type: types.FETCH_VOUCHER_PROMOTIONS_SUCCESS,
			payload: data
		});

		return data;
	} catch {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_VOUCHER_PROMOTIONS_ERROR,
			payload: true
		});
	}
};

export const clearVoucherData = (dispatch) => {
	dispatch({
		type: types.CLEAR_VOUCHER_DATA
	});
};

export const setFinalPromotion = (finalPromotion, dispatch) => {
	dispatch({
		type: types.SET_FINAL_PROMOTION,
		payload: finalPromotion
	});
};

export const clearFinalPromotion = (dispatch) => {
	dispatch({
		type: types.CLEAR_FINAL_PROMOTION
	});
};
