import { useQuery } from 'react-query';
import services from 'services/services';

// Import utilities
import { useFreeProduct, useAuth } from 'components/utilities';

// Import helpers
import { checkProductSubtype } from 'helpers';
import { getLimitData } from 'helpers/limits';

// Import api
import { GET_PRODUCT_LIMITS_API, QUERY_PRODUCT_LIMITS } from 'helpers/api';

const useProductLimits = ({
	limitType,
	product: { uuid = '', available_in: availableIn, subtype }
}) => {
	const { isAuth } = useAuth();

	const isProductFree = useFreeProduct({ uuid, availableIn });

	const { isPass } = checkProductSubtype(subtype);

	const queryKey = `${QUERY_PRODUCT_LIMITS}_${uuid}`;

	const queryFn = () => {
		const url = GET_PRODUCT_LIMITS_API.replace('uuid', uuid);
		return services.get(url);
	};

	const { data } = useQuery({ queryKey, queryFn, enabled: !!uuid });

	const limits = data?.data || [];

	const limit = getLimitData({
		limits,
		isProductFree,
		isAuth,
		limitType,
		isPass
	});

	return limit;
};

export default useProductLimits;
