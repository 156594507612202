import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Import actions
import { fetchTvCategory, fetchTvPrograms } from 'store/actions';

// Push notification
import { initializePush } from 'helpers/push-notification';

// Import hooks
import { useAuth } from 'components/utilities';

const MainTemplate = ({ children }) => {
	const dispatch = useDispatch();

	const { isLoaded } = useSelector(({ epg }) => epg);

	const { isAuth } = useAuth();

	React.useEffect(() => {
		fetchTvCategory()(dispatch);
		fetchTvPrograms({ isAuth, isLoaded })(dispatch);
		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		isAuth && initializePush();
		// eslint-disable-next-line
	}, [isAuth]);

	return children;
};

MainTemplate.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired
};

export default MainTemplate;
