import React from 'react';

// Import components
import { useLogic } from './useLogic';
import PlayerWrapper from './PlayerWrapper';

const PlayerVod = (props) => {
	const data = useLogic(props);

	return <PlayerWrapper {...data} />;
};

export default PlayerVod;
