import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

// Import styles
import { BANNER_WRAPPER_CONFIG } from 'components/elements/molecules/slider_banners/styles/modifiers';
import { customMinResponsive, size } from 'components/styles';

export const BannerWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	padding: 8px 13px;
	${applyStyleModifiers(BANNER_WRAPPER_CONFIG)}
`;

export const BannerContent = styled.div`
	z-index: 2;
	position: relative;
	width: 100%;
	display: flex;
	justify-content: end;
`;

export const PlayButtonIcon = styled.img`
	z-index: 3;
	aspect-ratio: 1/1;
	width: 60px;
	${customMinResponsive(
		size.small,
		css`
			width: 96px;
		`
	)}
`;

export const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const BackgroundImage = styled.img`
	top: 0;
	left: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

export const Logo = styled.img`
	height: 100%;
	aspect-ratio: 1/1;
	padding-top: 20px;
	padding-bottom: 20px;
	object-fit: contain;
	position: absolute;
	z-index: 2;

	${customMinResponsive(
		size.small,
		css`
			padding-top: 54px;
			padding-bottom: 53px;
		`
	)}
`;
