import React from 'react';
import { arrayOf, number, oneOfType, node, oneOf, func } from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { useLogic } from './useLogic';
import { linearGradient } from 'components/styles';

const Root = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.svgStyle {
		position: absolute;
	}

	.content {
		position: absolute;
		overflow: hidden;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const randomId = () =>
	Math.random()
		.toString(36)
		.substring(2, 15) +
	Math.random()
		.toString(36)
		.substring(2, 15);

const BorderButton = ({
	children,
	background,
	border: handleBorder,
	backgroundGradient: handleBackgroundGradient,
	borderWidth,
	parentWidth,
	theme
}) => {
	const { ref, svgSize } = useLogic(parentWidth);

	const strokeId = randomId();

	const fillId = randomId();

	const borderSettings = handleBorder && handleBorder(theme);
	const backgroundGradientSettings =
		handleBackgroundGradient && handleBackgroundGradient(theme);

	return (
		<Root ref={ref}>
			{svgSize && (
				<>
					<svg
						className="svgStyle"
						width={svgSize.width}
						height={svgSize.height}
					>
						<defs>
							<linearGradient id={strokeId} x1="0%" y1="0%" x2="0%" y2="100%">
								{borderSettings &&
									borderSettings.map(({ offset, color }) => (
										<stop
											key={offset}
											offset={`${offset}%`}
											stopColor={color}
										/>
									))}
							</linearGradient>

							<linearGradient
								id={`${fillId}-linear-gradient`}
								x1="0%"
								y1="0%"
								x2="0%"
								y2="100%"
							>
								{backgroundGradientSettings &&
									backgroundGradientSettings.map(({ offset, color }) => (
										<stop
											key={offset}
											offset={`${offset}%`}
											stopColor={color}
										/>
									))}
							</linearGradient>

							<radialGradient
								id={`${fillId}-radial-gradient`}
								cy="0.2"
								r="0.5"
								fy="-0.1"
							>
								{backgroundGradientSettings &&
									backgroundGradientSettings.map(({ offset, color }) => (
										<stop
											key={offset}
											offset={`${offset}%`}
											stopColor={color}
										/>
									))}
							</radialGradient>
						</defs>
						<rect
							x={borderWidth / 2}
							y={borderWidth / 2}
							width={svgSize.width - borderWidth}
							height={svgSize.height - borderWidth}
							fill={
								background === 'transparent'
									? background
									: `url('#${fillId}-${background}')`
							}
							rx="2px"
							ry="2px"
							strokeWidth="1px"
							stroke={`url('#${strokeId}')`}
						/>
					</svg>
					<div className="content">{children}</div>
				</>
			)}
		</Root>
	);
};

BorderButton.defaultProps = {
	background: 'transparent',
	border: [{ offset: 0, color: '#fff' }, { offset: 100, color: '#fff' }],
	borderWidth: 1,
	parentWidth: null
};

BorderButton.propTypes = {
	children: oneOfType([node, arrayOf(node)]),
	background: oneOf(['transparent', 'linear-gradient', 'radial-gradient']),
	backgroundGradient: func,
	border: func,
	borderWidth: number,
	parentWidth: number
};

export default withTheme(BorderButton);
