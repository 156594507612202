import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

const Tag = styled.span`
	color: ${({ theme }) => theme.white};
	font-size: 1.1rem;
	letter-spacing: 0.5px;
	padding: 0.3rem 1rem;
	border-radius: 3px;
	text-transform: uppercase;
	height: max-content;

	${({ display }) =>
		display === 'inline-block'
			? css`
					display: inline-block;
					vertical-align: top;
			  `
			: flex()}

	${({ type }) => {
		switch (type) {
			case 'channel':
				return css`
					background-color: ${({ theme }) => theme.product.tags.channelBg};
				`;
			case 'vod':
				return css`
					background-color: ${({ theme }) => theme.product.tags.vodBg};
				`;
			case 'vod_in_profile':
				return css`
					background-color: ${({ theme: { user } }) => user.tags.vodInProfile};
				`;
			case 'program':
				return css`
					background-color: ${({ theme }) => theme.product.tags.programBg};
				`;
			case 'series':
				return css`
					background-color: ${({ theme }) => theme.product.tags.seriesBg};
				`;
			case 'season':
				return css`
					background-color: ${({ theme }) => theme.product.tags.seasonBg};
				`;
			case 'episode':
				return css`
					background-color: ${({ theme }) => theme.product.tags.episodeBg};
				`;
			case 'packet':
				return css`
					background-color: ${({ theme }) => theme.product.tags.packetBg};
				`;
			case 'provision':
				return css`
					background-color: ${({ theme }) => theme.product.tags.provisionBg};
				`;
			case 'collection':
				return css`
					background-color: ${({ theme }) => theme.product.tags.collectionBg};
				`;
			case 'main':
				return css`
					background-color: ${({ theme }) => theme.product.tags.mainBg};
				`;
			default:
				return css`
					background-color: ${({ theme }) => theme.product.tags.defaultBg};
				`;
		}
	}}
`;

export default Tag;
