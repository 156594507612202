import React from 'react';
import { string } from 'prop-types';

// Import components
import { SectionItem, EpisodeItem } from './components';

// Import variables
import { SLIDER_SLIDE_TYPES } from 'helpers/variables';

const { EPISODES } = SLIDER_SLIDE_TYPES;

const SliderItem = ({ type, ...restProps }) => {
	switch (type) {
		case EPISODES:
			return <EpisodeItem {...restProps} />;

		default:
			return <SectionItem {...restProps} sectionType={type} />;
	}
};

SliderItem.propTypes = {
	type: string
};

export default SliderItem;
