import React from 'react';
import { object, string, number, bool, func } from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import components
import BasicSlide from '../BasicSlide';

// Import helpers
import { getAuxiliaryProductData } from 'helpers/products';

// Import utilities
import { useProductTranslations } from 'components/utilities';

const SectionItem = ({
	product,
	index,
	renderSlideAsLink,
	onItemClick,
	spaceBetweenSlides,
	slideHeight,
	slideWidth,
	sectionType
}) => {
	const { name: pageName } = useParams();

	const {
		product: { title }
	} = useProductTranslations({ data: product });

	const channels = useSelector(({ channels }) => channels.data.lives);

	const auxiliaryProductData = getAuxiliaryProductData({
		product,
		pageName,
		sectionType,
		channels
	});

	const { linkParams, description, availabilityDate } = auxiliaryProductData;

	return (
		<BasicSlide
			title={title}
			description={description}
			linkParams={linkParams}
			product={product}
			index={index}
			renderSlideAsLink={renderSlideAsLink}
			onItemClick={onItemClick}
			spaceBetweenSlides={spaceBetweenSlides}
			slideHeight={slideHeight}
			slideWidth={slideWidth}
			availabilityDate={availabilityDate}
			sectionType={sectionType}
		/>
	);
};

SectionItem.propTypes = {
	product: object.isRequired,
	sectionType: string,
	index: number.isRequired,
	renderSlideAsLink: bool,
	onItemClick: func,
	spaceBetweenSlides: number.isRequired,
	slideHeight: number.isRequired,
	slideWidth: number.isRequired
};

export default SectionItem;
