import i18n from 'i18next';

// Import helpers
import { PRODUCT_LIMIT_TYPES } from 'helpers';
import { isNumber } from 'helpers/prices';

const { VIEWS } = PRODUCT_LIMIT_TYPES;

const INITIAL_LIMIT = {
	isLimitExceeded: false,
	limitMessage: '',
	limitCounter: ''
};

export const getLimitData = ({
	limits,
	isProductFree,
	isAuth,
	limitType,
	isPass
}) => {
	const limit = limits?.find(({ limit_type, subscriber_id }) => {
		const isCorrectType = limit_type === limitType;

		switch (limitType) {
			case VIEWS:
				const subscriber = isProductFree ? !subscriber_id : !!subscriber_id;
				return isCorrectType && subscriber;

			default:
				return isCorrectType;
		}
	});

	// return initial limit if limit doesn't exist
	if (!isAuth || !limit) return INITIAL_LIMIT;

	const { limit_size, stats_count, limit_type, subscriber_id } = limit;

	const isGlobalViewsLimit = limit_type === VIEWS && !subscriber_id;

	// check if limit exist
	const isLimit = isNumber(limit_size) && isNumber(stats_count);

	// check if the limit has been used up
	const isLimitExceeded = isLimit ? stats_count >= limit_size : false;

	const limitTransitionKey = isPass
		? 'vod_movieDetails_pass_purchases_limit_exceeded'
		: `vod_movieDetails_${limit_type}_limit_exceeded`;

	const limitMessage = i18n.t(limitTransitionKey);

	const addLimitCounter = isLimit && !isGlobalViewsLimit;

	const limitCounter = addLimitCounter ? ` (${stats_count}/${limit_size})` : '';

	const passLimitMessage = isLimit
		? `${i18n.t(
				'vod_movieDetails_pass_usage_limit'
		  )} ${stats_count}/${limit_size}`
		: '';

	return { isLimitExceeded, limitMessage, limitCounter, passLimitMessage };
};
