import { APP_CORE_PATHS } from './app';
import { ROUTES_CORE_PATHS } from 'helpers/variables';
const { TV, EPG, CHANNELS, PROGRAM_DETAILS } = APP_CORE_PATHS;
const { WATCH } = ROUTES_CORE_PATHS;

export const TV_PATHS = {
	EPG: `/${TV}/${EPG}`,
	PROGRAM_DETAILS: `/${TV}/${CHANNELS}/${PROGRAM_DETAILS}/:id`,
	PLAYER: `/${TV}/${CHANNELS}/${WATCH}/:id`
};
