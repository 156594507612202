import styled, { css } from 'styled-components';

// Import styles
import { size, customResponsive, flex, textEllipsis } from 'components/styles';

// Import components
import { Button } from 'components/elements';

export const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 5rem 4rem;
	display: flex;
	justify-content: space-between;
	z-index: 99;
	opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
	visibility: ${({ isFocus }) => (isFocus ? 'visible' : 'hidden')};
	transition: all 0.3s ease-in-out;

	${customResponsive(
		size.small,
		css`
			justify-content: flex-end;
			padding: 1rem;
		`
	)}

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			padding: 2rem;
			button {
				svg {
					height: 2.6rem;
					width: 2.6rem;
				}
			}
		`}
`;

export const Heading = styled(Button)`
	color: ${({ theme }) => theme.white};
	margin-bottom: 1.8rem;
	font-weight: ${({ theme }) => theme.font.light};
	font-size: 2.6rem !important;
	font-family: 'Lato', sans-serif;
	opacity: 0.7;
	padding: 0;

	&:hover {
		opacity: 1;
	}

	&:focus-visible {
		color: ${({ theme }) => theme.primaryBlueLight};
	}

	${customResponsive(
		size.small,
		css`
			display: none;
		`
	)}
`;

export const Title = styled.span`
	color: ${({ theme }) => theme.white};
	font-size: 2.4rem;
	padding: 0;
	margin: 0;
	height: 100%;
	display: inline-block;
	${textEllipsis}

	${customResponsive(
		size.small,
		css`
			display: none;
		`
	)}
`;

export const Controller = styled.div`
	${flex({ ai: 'start', jc: 'flex-end' })}
	width: 100%;

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			justify-content: space-between;
		`}

	${({ isFullScreen }) =>
		isFullScreen &&
		css`
			width: auto;
		`}
`;

export const TitleWrapper = styled.div`
	${flex({ jc: 'flex-start' })}
	column-gap: 2rem;
	width: 100%;
`;

export const BasicInfoWrapper = styled.div`
	${flex({ fd: 'column', ai: 'flex-start' })};
	width: 70%;
	gap: 2rem;

	${({ isFloatMode }) =>
		isFloatMode &&
		css`
			position: absolute;
			top: 6rem;
		`}
`;

export const RatingWrapper = styled.div`
	${flex()};
	padding: 0 0.4rem;
`;
