import React from 'react';
import { arrayOf, number, string, array, shape, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers
import { getLinkDetails } from 'components/layout/nav/helpers';

// Import styled components
import { StyledSubMenu, StyledMenuItem } from '../common_styles';

// Import components
import SubMenuList from './SubMenuList';
import MenuLink from './MenuLink';

const NavMenu = ({ menuLinks, toggle, ...props }) => {
	const { t } = useTranslation();
	const renderMenu = menuLinks.map(
		({ submenu, model_id, model_type, link, name }, idx) => {
			const { path, isInternalLink } = getLinkDetails({
				modelId: model_id,
				modelType: model_type,
				name
			});

			return (
				<StyledSubMenu
					key={`${link}-${idx}`}
					title={name}
					{...props}
					eventKey={`item_${idx}`}
					subMenuKey={`${idx}-menu-`}
				>
					<StyledMenuItem>
						<MenuLink
							isInternalLink={!!isInternalLink}
							path={path}
							link={link}
							name={t('common_header_nav_mobile_show_all')}
							toggle={toggle}
							isMainLink={true}
						/>
					</StyledMenuItem>
					<SubMenuList submenu={submenu} basePath={path} toggle={toggle} />
				</StyledSubMenu>
			);
		}
	);
	return renderMenu;
};

NavMenu.propTypes = {
	toggle: func.isRequired,
	menuLinks: arrayOf(
		shape({
			id: number.isRequired,
			name: string.isRequired,
			link: string.isRequired,
			submenu: array,
			model_id: string,
			model_type: string
		})
	).isRequired
};

export default NavMenu;
