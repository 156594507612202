import React from 'react';
import { func, shape, string, object, bool } from 'prop-types';

// Import styled components
import { Wrapper, Title, Description, ButtonsContainer } from './styles';

// Import components
import Preview from 'components/views/vod/components/sliders/Carousel/components/Info/preview/Preview';
import {
	DetailsButton,
	FavouriteButton
} from 'components/views/vod/components/buttons';
import { MoreInfoButton } from 'components/elements';

// Import helpers
import { checkProductSubtype, getPreviewAssetId } from 'helpers';

// Import utilities
import { useProductTranslations } from 'components/utilities';

const BasicInfo = ({ currentItem, kids, startStopCarousel }) => {
	const { uuid, previews, subtype, context } = currentItem;

	const { isBanner } = checkProductSubtype(subtype);

	const {
		product: { title, short_desc }
	} = useProductTranslations({ data: currentItem });

	const videoID = getPreviewAssetId(previews);

	const url = context?.url_pc;

	return (
		<Wrapper>
			<Title>{title}</Title>
			<Description>{short_desc}</Description>
			<ButtonsContainer isPreview={videoID}>
				{isBanner ? (
					<MoreInfoButton url={url} />
				) : (
					<>
						<DetailsButton {...currentItem} />
						<FavouriteButton kids={kids} uuid={uuid} />
						{videoID && (
							<Preview
								uuid={uuid}
								videoID={videoID}
								startStopCarousel={startStopCarousel}
							/>
						)}
					</>
				)}
			</ButtonsContainer>
		</Wrapper>
	);
};

BasicInfo.propTypes = {
	startStopCarousel: func.isRequired,
	currentItem: shape({
		uuid: string.isRequired,
		title: string.isRequired,
		short_desc: string.isRequired,
		subtype: string.isRequired,
		previews: object
	}),
	kids: bool.isRequired
};

export default BasicInfo;
