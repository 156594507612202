import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';
import {
	Content,
	IconTriangleStyled,
	Profile,
	ProfileName,
	Profiles,
	ProfilesBox,
	SettingsWrapper
} from './styles';

const SettingsController = ({ isCatchupControllers }) => {
	// Get player methods from Player context
	const {
		profiles,
		selectedProfile,
		setProfile,
		isFocus,
		languages,
		selectedLanguage,
		setLanguage,
		isSafari
	} = usePlayerTvContext();

	const { t } = useTranslation();

	// Set profile in player provider
	const handleSetProfile = React.useCallback(
		(values) => () => setProfile(values),
		[setProfile]
	);

	const hasVideoProfile = profiles && selectedLanguage;
	const hasVideoLanguages = languages?.length > 1;

	const renderProfiles = () => {
		return (
			<ProfilesBox>
				<ProfileName>{t('player_settings_profiles')}</ProfileName>
				<Profile
					onClick={handleSetProfile({ id: 'auto' })}
					profile="auto"
					selected={selectedProfile}
				>
					<span>auto</span>
				</Profile>
				{hasVideoProfile &&
					!isSafari &&
					profiles[selectedLanguage].map(({ id, height, bandwidth }) => (
						<Profile
							onClick={handleSetProfile({ id, bandwidth })}
							profile={id}
							selected={selectedProfile}
							key={id}
						>
							<span>{`${height}p`}</span>
						</Profile>
					))}
			</ProfilesBox>
		);
	};

	const renderLanguages = () => {
		return (
			<ProfilesBox>
				<ProfileName>{t('player_settings_language')}</ProfileName>
				{languages.map((language, index) => (
					<Profile
						onClick={() => setLanguage(language)}
						profile={language}
						selected={selectedLanguage}
						key={index}
					>
						<span>{language.toUpperCase()}</span>
					</Profile>
				))}
			</ProfilesBox>
		);
	};

	return (
		<SettingsWrapper>
			<Button
				isCatchupControllers={isCatchupControllers}
				modifiers={['icon', 'playerIconHover']}
				isFocus={isFocus}
			>
				<Player.IconSettings />
			</Button>
			<Content>
				<Profiles>
					{renderProfiles()}
					{hasVideoLanguages && renderLanguages()}
				</Profiles>
				<IconTriangleStyled />
			</Content>
		</SettingsWrapper>
	);
};

SettingsController.propTypes = {
	isCatchupControllers: bool
};

export default SettingsController;
