import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isLoaded: false,
	isError: false,
	errorMessage: ''
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Set parental control pin *******************
			case types.UPDATE_PIN_NUMBER_LOADING:
				return { ...INITIAL_STATE };

			case types.UPDATE_PIN_NUMBER_SUCCESS:
				draft.isLoaded = true;
				return;

			case types.UPDATE_PIN_NUMBER_ERROR:
				draft.isError = true;
				draft.errorMessage = action.payload.data.message || '';
				return;

			case types.UPDATE_PIN_CLEAR_PIN_DATA:
				return { ...INITIAL_STATE };

			default:
				return state;
		}
	});
