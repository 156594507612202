import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateVideoSession,
	setPlayerReady
} from 'store/actions';

// import shaka-player
import shaka from 'shaka-player/dist/shaka-player.ui.js';

// Import helpers
import {
	INITIAL_STATE_PLAYER_TV,
	calculateVideoIntervalTime
} from '../helpers';
import { playerDeleteSyncRequest, SHAKA_PLAYER_ERRORS } from 'helpers/player';

// Import variables
import { LOCALSTORAGE_PLAYER, TRACKING_EVENTS } from 'helpers/variables';

// styles and helpers
import {
	VideoContainer,
	ShakaVideo,
	PlayerFullScreenTarget,
	fetchPlaylistData,
	removeShakaPlayerListeners,
	playerInitialization,
	setSupportError,
	setTrackingEvent,
	sendPlayerEvents,
	sendPreviousSessionTracking
} from '../shakaPlayer';

// Create context
export const ShakaPlayerTvContext = createContext();

const { SHAKA_PLAYER_ERROR_MESSAGE } = SHAKA_PLAYER_ERRORS;

class ShakaPlayerTVProvider extends React.Component {
	_isMounted = false;
	isPlayerReady = false;
	intervalUpdateWatchingProgress = null;
	intervalVideoSession = null;
	trackingInterval = null;
	streamStartDate = null;

	constructor(props) {
		super(props);
		this.state = INITIAL_STATE_PLAYER_TV;
		//Creating reference to store video component and video container
		this.videoComponentRef = React.createRef();
		this.videoContainerRef = React.createRef();

		// Shaka media
		this.video = null;
		this.videoContainer = null;
		this.player = null;
	}

	async componentDidMount() {
		const { playlist, isError, videoSessionId, isSiedebarVisible } = this.props;
		this._isMounted = true;
		this.setState({ isSiedebarVisible });

		// send previous session tracking data
		sendPreviousSessionTracking();

		// Delete video sesion and set video mute on sync request when component is unmount
		window.addEventListener('beforeunload', this.beforeunload, false);

		const playlistData = await fetchPlaylistData(playlist);

		if (videoSessionId && this._isMounted && !this.isPlayerReady) {
			// Install built-in polyfills to patch browser incompatibilities.
			shaka.polyfill.installAll();

			// Getting reference to video and video container on DOM
			this.video = this.videoComponentRef.current;
			this.videoContainer = this.videoContainerRef.current;

			shaka.Player.isBrowserSupported()
				? playerInitialization.call(this, [shaka, playlistData])
				: setSupportError.call(this);
		} else if (isError && videoSessionId) {
			// If there is an error and video session is not equal to null
			// Turn on buffering
			this._isMounted && this.setState({ buffering: false });
		} else if (isError && !videoSessionId) {
			// If there is an error and video session is equal to null
			// Stop player
			this.player && this.player.destroy();
			// Turn on buffering,Set player ready
			this._isMounted && this.setState({ buffering: false, isReady: false });
		}
	}

	componentWillUnmount() {
		this.destroy();
	}

	componentDidUpdate() {
		const { buffering } = this.state;
		const { isCatchup } = this.props;

		if (!buffering && !isCatchup && this.video) {
			this.video.play();
		}
	}

	// Destroy player (this funciton is called on componentWillUnmount and when parenetal control destroys player)
	destroy = async () => {
		this._isMounted = false;
		// Clear intervals
		clearInterval(this.intervalVideoSession);
		clearInterval(this.trackingInterval);

		const { isError, videoSessionId, type, deleteVideoSession } = this.props;
		// Check if player is not undefined / null, if player container exists, if is not any API errors
		if (videoSessionId) {
			// Dispatch an deleteVideoSession action in player folder
			await deleteVideoSession({ type });
		}

		if (!isError && this.player !== null && this.isPlayerReady) {
			// send tracking events
			setTrackingEvent.call(this, TRACKING_EVENTS.CLOSED);
			sendPlayerEvents.call(this);
			// remove event listeners
			removeShakaPlayerListeners.call(this);
			// Destroy the player
			this.player.destroy();
			this.isPlayerReady = false;
		}
	};

	handleUpdateVideoSession = () => {
		const { videoSessionId, type, productID, updateVideoSession } = this.props;

		updateVideoSession({ videoSessionId, productID, type }, () => {
			// Turn on buffering
			this.setState({ buffering: false, isReady: false });
			// Destroy the player
			this.player.destroy();
			// Clear intervals
			clearInterval(this.intervalVideoSession);
		});
	};

	setFullScreen = () => {
		if (!document.fullscreenElement) {
			this.videoContainer.requestFullscreen();
			this.setState({ isFullScreen: true });
		} else {
			document.exitFullscreen();
			this.setState({ isFullScreen: false });
		}
	};

	play = () => {
		this.video.play();
		this.setState({ isPaused: false });
	};

	pause = () => {
		this.video.pause();
		this.setState({ isPaused: true });
	};

	catchupRepeat = () => {
		if (this.props.isCatchup) {
			this.video.currentTime = 2;
			this.play();
		}
	};

	updateTime = (time) => {
		this.video.currentTime = time;
		setTrackingEvent.call(this, TRACKING_EVENTS.SEEKING);
	};

	currentTimeProgress = (callback) =>
		this.video.addEventListener('timeupdate', () =>
			callback(this.video.currentTime)
		);

	// Set profile
	setProfile = ({ id, bandwidth, lang }) => {
		const { profiles, selectedLanguage } = this.state;
		const clearBuffer = true;
		let language = selectedLanguage;

		// after change language 'selectedLanguage' has an old value
		// if 'lang' param exist set its value to language variable
		if (lang) {
			language = lang;
		}

		if (id !== 'auto') {
			this.player.configure({ abr: { enabled: false } });
			const variant = profiles[language].find((variant) => variant.id === id);
			this.player.selectVariantTrack(variant, clearBuffer);
		} else {
			this.player.configure({ abr: { enabled: true } });
		}

		setTrackingEvent.call(this, TRACKING_EVENTS.TRACK_CHANGED);

		this.setState({
			selectedProfile: id,
			selectedProfileBandwidth: bandwidth
		});
	};

	// set audio language
	setLanguage = (lang) => {
		const { profiles, selectedProfileBandwidth } = this.state;
		this.setState({ selectedLanguage: lang });
		this.player.selectAudioLanguage(lang);

		// set quality profile after audio language change
		const videoProfile = profiles[lang].find(
			({ bandwidth }) => bandwidth === selectedProfileBandwidth
		);

		if (videoProfile) {
			const { id, bandwidth } = videoProfile;
			this.setProfile({ id, bandwidth, lang });
		}
	};

	// Mute or unmute player
	mute = () => {
		const playerStorage = JSON.parse(localStorage.getItem(LOCALSTORAGE_PLAYER));
		const { isMuted } = this.state;

		if (!isMuted) {
			this.setState({ isMuted: true, volume: 0 });
		} else {
			if (playerStorage && playerStorage.volume) {
				const volume = playerStorage.volume;
				this.video.volume = volume / 100;
				this.setState({ volume });
			}
			this.setState({ isMuted: false });
		}

		localStorage.setItem(
			LOCALSTORAGE_PLAYER,
			JSON.stringify({ ...playerStorage, isMuted: !isMuted })
		);

		this.setState({ isMuted: !isMuted });
	};

	// Delete video sesion and set video mute
	beforeunload = () => {
		const { videoSessionId } = this.props;

		// delete session
		playerDeleteSyncRequest(videoSessionId);
	};

	// Set current volume
	setVolume = (volume) => {
		const playerStorage = JSON.parse(localStorage.getItem(LOCALSTORAGE_PLAYER));
		const isMuted = parseInt(volume) === 0;
		localStorage.setItem(
			LOCALSTORAGE_PLAYER,
			JSON.stringify({ ...playerStorage, volume, isMuted })
		);

		this.video.volume = volume / 100;
		this.setState({ volume, isMuted });
	};

	onReady = () => {
		const {
			type,
			setPlayerReady,
			configuration: { videoSession }
		} = this.props;
		// Set player ready
		this.setState({ isReady: true });
		this.isPlayerReady = true;
		// Dispatch an setPlayerReady action in player folder
		setPlayerReady({ type });

		// Set video session interval
		const videoSessionInvervalTime = calculateVideoIntervalTime(videoSession);
		this.intervalVideoSession = setInterval(
			this.handleUpdateVideoSession,
			videoSessionInvervalTime
		);

		this.video.play();
		setTrackingEvent.call(this, TRACKING_EVENTS.PLAYING);
	};

	setInitialVolume = () => {
		const playerStorage = JSON.parse(localStorage.getItem(LOCALSTORAGE_PLAYER));
		// Get volume
		let volume = this.video.volume;
		if (playerStorage && playerStorage.isMuted) {
			this.setState({ isMuted: playerStorage.isMuted });
			volume = 0;
		} else if (playerStorage && playerStorage.volume) {
			const playerVolume = playerStorage.volume;
			this.video.volume = playerVolume / 100;
			this.setState({ volume: playerVolume });
			volume = playerVolume;
		}

		this.video.volume = volume / 100;
		// Set current volume
		this.setState({ volume });
	};

	onPlay = () => {
		if (this.props.isCatchup) {
			this.setState({ isPaused: false });
			setTrackingEvent.call(this, TRACKING_EVENTS.PLAYING);
		}
	};

	onPause = () => {
		if (!this.props.isCatchup) {
			this.video.play();
		} else {
			this.setState({ isPaused: true });
			setTrackingEvent.call(this, TRACKING_EVENTS.PAUSED);
		}
	};

	onBufferStateChange = () => {
		const buffering = this.player.isBuffering();
		this.setState({ buffering });
		buffering && setTrackingEvent.call(this, TRACKING_EVENTS.BUFFERING);
	};

	onEnded = () => {
		if (this.props.isCatchup) {
			this.setState({ isEnded: true, isPaused: true });
			setTrackingEvent.call(this, TRACKING_EVENTS.COMPLETE);
		}
	};

	onAutoQualityChange = () =>
		setTrackingEvent.call(this, TRACKING_EVENTS.TRACK_CHANGED);

	onErrorEvent(event) {
		// Extract the shaka.util.Error object from the event.
		this.onError(event.detail);
	}

	onError(error) {
		const isOnline = navigator.onLine;

		if (this && this.props) {
			const { setPlayerPlaybackError, type } = this.props;

			setTrackingEvent.call(this, TRACKING_EVENTS.ERROR);

			// Catch only known errors which should be handled in UI
			if (error && error.code && isOnline) {
				// Turn on buffering
				this.setState({ buffering: false, isReady: false });
				// Destroy the player
				if (this.player) {
					this.player.destroy();
				}
				// Set error message
				error.message = SHAKA_PLAYER_ERROR_MESSAGE;
				// Dispatch an setPlayerPlaybackError action in player folder
				setPlayerPlaybackError(error, type);
			}
		}
	}

	onFullScreenChange = () => {
		const isFullScreen = !!document.fullscreenElement;
		this.setState({ isFullScreen });
	};

	render() {
		const {
			isFocus,
			isCatchup,
			children,
			resetFocus,
			type,
			isError,
			isSafari,
			floatMode
		} = this.props;
		const { isReady, isMuted, buffering } = this.state;

		const showLoader = (!isError && !isReady) || buffering;

		return (
			<ShakaPlayerTvContext.Provider
				value={{
					...this.state,
					setVolume: this.setVolume,
					setProfile: this.setProfile,
					mute: this.mute,
					setFullScreen: this.setFullScreen,
					play: this.play,
					pause: this.pause,
					resetFocus,
					isFocus,
					destroy: this.destroy,
					updateTime: this.updateTime,
					currentTimeProgress: this.currentTimeProgress,
					catchupRepeat: this.catchupRepeat,
					playerType: type,
					setLanguage: this.setLanguage,
					showLoader,
					isSafari,
					floatMode
				}}
			>
				<VideoContainer
					className="video-container"
					ref={this.videoContainerRef}
					isReady={isReady}
					isCatchup={isCatchup}
					isFocus={isFocus}
				>
					<ShakaVideo
						className="shaka-video"
						id="shaka-video"
						ref={this.videoComponentRef}
						autoPlay={true}
						muted={isMuted}
						onLoadedData={this.play}
					/>
					<PlayerFullScreenTarget onDoubleClick={this.setFullScreen} />
					{children}
				</VideoContainer>
			</ShakaPlayerTvContext.Provider>
		);
	}
}

ShakaPlayerTVProvider.defaultProps = {
	isSiedebarVisible: false,
	isCatchup: false
};

ShakaPlayerTVProvider.propTypes = {
	isCatchup: PropTypes.bool,
	isSiedebarVisible: PropTypes.bool,
	playlist: PropTypes.string.isRequired,
	isError: PropTypes.bool.isRequired,
	videoSessionId: PropTypes.string,
	deleteVideoSession: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	setPlayerReady: PropTypes.func.isRequired,
	updateVideoSession: PropTypes.func.isRequired,
	configuration: PropTypes.object.isRequired,
	resetFocus: PropTypes.func.isRequired,
	playerParentElement: PropTypes.any,
	productID: PropTypes.string.isRequired,
	setPlayerPlaybackError: PropTypes.func.isRequired,
	isSafari: PropTypes.bool.isRequired,
	isFocus: PropTypes.bool.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	floatMode: PropTypes.object
};

const mapStateToProps = (state, { type }) => {
	const {
		playlist,
		configuration,
		error: { isError },
		videoSessionId
	} = state[type];
	return {
		configuration,
		playlist,
		isError,
		videoSessionId
	};
};

export default connect(mapStateToProps, {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateVideoSession,
	setPlayerReady
})(ShakaPlayerTVProvider);
