import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { string, func, array, bool } from 'prop-types';

// Import components
import { Loader, CommonButton } from 'components/elements';
import Agreement from './Agreement';

// Import styles
import { Title, Description, ButtonsWrapper } from './styles';

const UserApproval = ({
	headerText,
	descriptionText,
	agreements,
	toggleAgreement,
	acceptedAgreements,
	isAgreementsLoading,
	onAcceptChecked,
	onAcceptAll,
	isDisabled
}) => {
	const { t } = useTranslation();

	return isAgreementsLoading ? (
		<Loader background={false} position="absolute" zIndex={1} />
	) : (
		<>
			{headerText && <Title>{headerText}</Title>}
			{descriptionText && (
				<Description>{ReactHtmlParser(descriptionText)}</Description>
			)}

			{agreements?.map((agreement) => (
				<Agreement
					key={agreement.id}
					toggleAgreement={toggleAgreement}
					acceptedAgreements={acceptedAgreements}
					{...agreement}
				/>
			))}

			<ButtonsWrapper>
				<CommonButton onClick={onAcceptChecked} isDisabled={isDisabled}>
					{t('agreements_modal_accept')}
				</CommonButton>
				<CommonButton onClick={onAcceptAll} isPrime>
					{t('agreements_modal_accept_all')}
				</CommonButton>
			</ButtonsWrapper>
		</>
	);
};

UserApproval.propTypes = {
	headerText: string,
	descriptionText: string,
	agreements: array.isRequired,
	toggleAgreement: func.isRequired,
	acceptedAgreements: array.isRequired,
	isAgreementsLoading: bool.isRequired,
	onAcceptChecked: func.isRequired,
	onAcceptAll: func.isRequired,
	isDisabled: bool
};

export default UserApproval;
