import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isError: false,
	isLoading: true,
	data: {
		available_gateway_types: { one_time: [], recurring: [] },
		gateway_type: null,
		recurring_gateway_type: null
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* ACTIVE SUBSCRIPTIONS *******************
			case types.FETCH_LAST_GATEWAY_TYPE_LOADING:
				draft.isError = false;
				draft.isLoading = true;
				return;

			case types.FETCH_LAST_GATEWAY_TYPE_SUCCESS:
				draft.data = action.payload;
				draft.isError = false;
				draft.isLoading = false;
				return;

			case types.FETCH_LAST_GATEWAY_TYPE_ERROR:
				draft.data = [];
				draft.isError = true;
				draft.isLoading = false;
				return;

			case types.CLEAR_LAST_GATEWAY_TYPE:
				return INITIAL_STATE;

			default:
				return state;
		}
	});
