import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { func, number, object } from 'prop-types';

// Import utilities
import {
	useProductAvailability,
	useVodProgress,
	useProductPrices,
	useLowestPrice
} from 'components/utilities';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import helpers
import { PRODUCTS_TYPES } from 'helpers/variables';
import {
	checkIsBeforeAvailability,
	getFormatedDate,
	getPaymentLink
} from 'helpers';

// Import variables
import { DATE_FORMAT } from 'helpers/variables';

// Import redux actions
import { setPlayerTvOpen } from 'store/actions';

const { DATE_WITH_TIME } = DATE_FORMAT;

// API-URL
const { REACT_APP_API_URL } = process.env;

const { EPISODE } = PRODUCTS_TYPES;
const { WATCH } = APP_CORE_PATHS;

const useEpisodeItem = ({ product, index, setSelected, selected }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const episodeRef = useRef();

	const series = useSelector(({ movieDetails }) => movieDetails.data);
	const season = useSelector(({ tvShowSeason }) => tvShowSeason.data);

	const seriesUuid = series?.uuid;
	const seriesAvailableIn = series?.available_in;
	const seasonUuid = season?.uuid;
	const seasonAvailableIn = season?.available_in;

	const {
		title: episodeTitle,
		uuid: episodeUuid,
		available_in: episodeAvailableIn,
		is_available: isAvailable,
		duration,
		since
	} = product;

	const episodeCover = `${REACT_APP_API_URL}/assets/${episodeUuid}/cover`;

	// Episode prices
	const { purchase: episodePurchase, rent: episodeRent } = useProductPrices({
		product
	});

	// Get the lowest price of episode to set it as default on payments view
	const { priceId } = useLowestPrice({ product });


	// check episode availability based on its own availability and its parents availability
	const isSeriesAvailable = useProductAvailability(
		seriesUuid,
		seriesAvailableIn
	);
	const isSeasonAvailable = useProductAvailability(
		seasonUuid,
		seasonAvailableIn
	);
	const isEpisodeAvailable = useProductAvailability(
		episodeUuid,
		episodeAvailableIn
	);

	const isAvailableToPlay =
		(isSeriesAvailable || isSeasonAvailable || isEpisodeAvailable) &&
		isAvailable;

	const isAvailableToBuy = episodePurchase.price || episodeRent.price;

	const handleEpisodeHover = () => {
		setSelected(index);
	};

	const handleEpisodeFocus = () => setSelected(index);

	const continuePercent = useVodProgress(episodeUuid, duration);

	const showAvailabilityDate =
		!isAvailable && checkIsBeforeAvailability({ since });

	const availablilityDate = getFormatedDate(since, DATE_WITH_TIME);

	const handleEpisodeClick = () => {
		// In some cases we can have open tv player before, so to prevent displaying of tv player, we have to change this flag
		dispatch(setPlayerTvOpen(false));

		if (!isAvailable) return null;

		if (isAvailableToPlay) {
			history.push(`${WATCH}/serial/${episodeUuid}`);
		} else if (isAvailableToBuy) {
			const paymentLink = getPaymentLink({
				id: episodeUuid,
				type: EPISODE,
				title: episodeTitle,
				priceId
			});

			history.push(paymentLink);
		}
	};

	const isSelected = selected === index;
	const isDisabled = !isAvailableToPlay && !isAvailableToBuy;

	return {
		episodeCover,
		isSelected,
		episodeTitle,
		isAvailable,
		availablilityDate,
		showAvailabilityDate,
		isAvailableToPlay,
		isAvailableToBuy,
		episodeRef,
		continuePercent,
		isDisabled,
		episodeClick: handleEpisodeClick,
		episodeFocus: handleEpisodeFocus,
		episodeHover: handleEpisodeHover
	};
};

useEpisodeItem.propTypes = {
	product: object.isRequired,
	setSelected: func.isRequired,
	selected: number.isRequired,
	index: number.isRequired
};

export default useEpisodeItem;
