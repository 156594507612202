import { useDispatch } from 'react-redux';
import { deleteFavourite } from 'store/actions';

// Import helpers
import { createLinkParams } from 'helpers/index';
import { getProductTypeAlias } from 'helpers/products';

const useFavourite = ({ favourite }) => {
	const dispatch = useDispatch();
	const { title, uuid: id, subtype } = favourite;

	const link = createLinkParams({ id, type: subtype, title });

	const handleDeleteFavourite = () => deleteFavourite(id)(dispatch);

	const productAlias = getProductTypeAlias(favourite);

	return { link, deleteFavourite: handleDeleteFavourite, productAlias };
};

export default useFavourite;
