import { keyframes } from 'styled-components';

export const spin = keyframes`
     0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
`;
export const spinReverse = keyframes`
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(-360deg)
    }
`;

export const fadeIn = (value = 0) => keyframes`
    0% {
        opacity: ${value};
    }
    100% {
        opacity: 1;
    }
`;

export const fadeInUp = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
`;

export const modalFadeInUp = keyframes`
    0% {
        opacity: 0;
        top: 16rem;

    }
    100% {
        opacity: 1;
        top: 6rem;
    }
`;

export const fadeInScale = keyframes`
    0% {
        transform: scale(0.9);
        opacity: 0.8;
    }
    100% {
        transform: scale(0.98);
        opacity: 1;
    }
`;
