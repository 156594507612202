import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useBannerSlider = () => {
	const sliderRef = useRef(null);

	const { areAnimationsAvailable } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	const slider = sliderRef?.current;

	useEffect(() => {
		if (slider) {
			areAnimationsAvailable ? slider.slickPlay() : slider.slickPause();
		}
		// eslint-disable-next-line
	}, [areAnimationsAvailable]);

	const handleChangeSlide = (index) => {
		if (slider) {
			slider.slickGoTo(index);
		}
	};

	return {
		sliderRef,
		changeSlide: handleChangeSlide
	};
};

export default useBannerSlider;
