import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	customResponsive,
	customMinResponsive,
	size
} from 'components/styles';
import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from 'helpers/variables';
// Import icons
import { ReactComponent as IconInfo } from 'assets/icons/ic_content_info.svg';

export const Wrapper = styled.div`
	margin-bottom: 4rem;
`;

export const NoContentWrapper = styled.section`
	${flex({ fd: 'column' })}
	height: ${({ heightOnMobile }) =>
		heightOnMobile ? '' : `calc(100vh - ${HEADER_HEIGHT_DESKTOP}px)`};

	${customResponsive(
		size.medium,
		css`
			height: ${({ heightOnMobile }) =>
				heightOnMobile ?? `calc(100vh - ${HEADER_HEIGHT_MOBILE}px)`};
			padding: 0 1.4rem;
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			padding-top: ${({ paddingTopDesktop }) => paddingTopDesktop};
		`
	)}
`;

export const InfoIcon = styled(IconInfo)`
	${customResponsive(
		size.medium,
		css`
			width: 4.4rem;
			height: 4.4rem;
		`
	)}
`;

export const NoContentHeader = styled.h1`
	opacity: 0.6;
	color: ${({ theme }) => theme.black};
	font-size: 2.6rem;
	text-align: center;
	font-weight: ${({ theme }) => theme.font.normal};
	margin-top: 4rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: 4.8rem;
		`
	)}
`;

export const NoContentDescription = styled.p`
	opacity: 0.6;
	color: ${({ theme }) => theme.black};
	font-size: 1.8rem;
	text-align: center;
	font-weight: ${({ theme }) => theme.font.normal};
	margin-top: 2rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: 2.4rem;
		`
	)}
`;
