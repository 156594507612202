import { useEffect, useRef } from 'react';
import _ from 'lodash';

const useViewportResize = ({ callback = () => { }, throttleTime = 1000 }) => {
    const throttleResize = useRef(
        _.throttle(callback, throttleTime)
    ).current;

    useEffect(() => {
        window.addEventListener('resize', throttleResize);

        return () => {
            window.removeEventListener('resize', throttleResize);
        }
    });
}

export default useViewportResize;