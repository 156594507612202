import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { deleteVideoSession } from 'store/actions';

// Import styles
import { fadeIn } from 'components/styles';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';
import { fetchMovieResources } from '../helpers';

// Import components
import { Button } from 'components/elements';
import Timer from './Timer';

const playerSelector = PLAYER_SELECTORS.VOD;

const NextEpisode = ({ setEndMargin, toggle }) => {
	const { t } = useTranslation();

	const store = useStore();

	const {
		next: { uuid: id }
	} = useSelector((state) => state.movieDetails.episodes);

	const dispatch = useDispatch();
	const { type } = useParams();

	const handlePlayNextEpisode = async () => {
		// Delete video live session
		// Dispatch an deleteVideoSession action in player folder
		await deleteVideoSession({ type: playerSelector })(
			dispatch,
			store.getState
		);
		// Fetch movie resources
		await fetchMovieResources({ id, type, dispatch });
	};

	const handleCancelNextEpisode = () => {
		// Turn off end margin
		setEndMargin();
		// Close nextEpisode box
		toggle();
	};

	return (
		<Wrapper>
			<Text>
				{t('player_next_episode_desc')}
				<Timer />
			</Text>
			<Controllers>
				<Button onClick={handleCancelNextEpisode} modifiers="primaryVod" cancel>
					{t('player_next_episode_btn_cancle')}
				</Button>
				<ButtonPlayNext onClick={handlePlayNextEpisode} modifiers="primaryVod">
					{t('player_next_episode_btn_play_next')}
				</ButtonPlayNext>
			</Controllers>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: absolute;
	right: 4rem;
	bottom: 12rem;
	z-index: 999;
	animation: ${fadeIn(0)} 0.5s ease-in-out forwards;
`;

const Text = styled.p`
	font-size: ${({ theme }) => theme.fontSize.player.box.text}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.player.box.text};
	padding: 0.6rem 2rem 0.6rem 0;
	text-align: right;
	opacity: 0.8;
`;

const Controllers = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ButtonPlayNext = styled(Button)`
	width: 18rem;
	margin-left: 1rem;
`;

NextEpisode.propTypes = {
	toggle: func.isRequired,
	setEndMargin: func.isRequired
};

export default NextEpisode;
