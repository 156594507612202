import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

export const HamburgerBtn = styled.button`
	cursor: pointer;
	${flex({ jc: 'space-around', fd: 'column' })};
	width: 3rem;
	height: 3rem;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;
	span {
		background-color: ${({ theme }) => theme.body.nav.icon};
		width: 2.5rem;
		height: 3px;
		border-radius: 1rem;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;
		&:nth-child(1) {
			transform: rotate(0);
		}
		&:nth-child(2) {
			transform: translateX(0);
			opacity: 1;
		}
		&:nth-child(3) {
			transform: rotate(0);
		}
	}

	&:focus-visible {
		span {
			background-color: ${({ theme }) => theme.body.nav.iconActive};
		}
	}

	&:hover {
		span {
			background-color: ${({ theme }) => theme.body.nav.iconActive};
		}
	}

	${({ isOpen }) =>
		isOpen &&
		css`
			span {
				width: 3rem;
				&:nth-child(1) {
					transform: rotate(45deg);
				}
				&:nth-child(2) {
					transform: translateX(1.8rem);
					opacity: 0;
				}
				&:nth-child(3) {
					transform: rotate(-45deg);
				}
			}
		`}
`;
