import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

// import helpers
import { initialItem, getPromotion } from './helpers';

// Import utilities
import { useProductPrices } from 'components/utilities';

const useEpisodePromotion = (episodeUuid = '') => {
	const { episodes } = useSelector(({ tvShowSeason }) => tvShowSeason.data);
	const userPromotions = useSelector(({ promotions }) => promotions.data);
	const [rentPromotion, setRentPromotion] = useState(initialItem);
	const [purchasePromotion, setPurchasePromotion] = useState(initialItem);

	const currentEpisode = episodes.find(({ uuid }) => uuid === episodeUuid);

	// Episode prices
	const {
		purchase: { price: purchasePrice, promotions: purchasePromotions },
		rent: { price: rentPrice, promotions: rentPromotions }
	} = useProductPrices({ product: currentEpisode });

	React.useEffect(() => {
		const userHasPromotions = userPromotions && userPromotions.length > 0;

		if (userHasPromotions) {
			// rent promotions
			if (rentPromotions) {
				const favorablePromotion = getPromotion(
					rentPrice,
					rentPromotions,
					userPromotions,
					false
				);
				setRentPromotion(favorablePromotion);
			}

			// purchase promotions
			if (purchasePromotions) {
				const favorablePromotion = getPromotion(
					purchasePrice,
					purchasePromotions,
					userPromotions,
					false
				);
				setPurchasePromotion(favorablePromotion);
			}
		}
	}, [
		userPromotions,
		purchasePromotions,
		rentPromotions,
		purchasePrice,
		rentPrice
	]);

	return { rentPromotion, purchasePromotion };
};

useEpisodePromotion.propTypes = {
	episodeUuid: string.isRequired
};

export default useEpisodePromotion;
