import styled, { css } from 'styled-components';

// Import styles
import { flex, customMinResponsive, size } from 'components/styles';

export const Title = styled.h2`
	color: ${({ theme }) => theme.agreements.titleColor};
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: 2.2rem;
	line-height: 2.6rem;
	text-align: center;
	margin-bottom: 2rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: 3.6rem;
			line-height: 3.6rem;
			margin-bottom: 3.4rem;
		`
	)}
`;

export const Description = styled.p`
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.agreements.descriptionColor};
	font-size: 1.4rem;
	line-height: 2.8rem;
	margin-bottom: 2.8rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: 1.8rem;
			line-height: 3.6rem;
			margin-bottom: 3.6rem;
		`
	)}
`;

export const AgreementName = styled.span`
	display: inline-block;
	color: ${({ theme }) => theme.agreements.nameColor};
	font-weight: ${({ theme }) => theme.font.medium};
	font-size: 1.4rem;
	line-height: 1.8rem;
	margin-bottom: 1rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: 1.8rem;
			line-height: 2.1rem;
			margin-bottom: 1rem;
		`
	)}
`;

export const AgreementDescription = styled.span`
	display: inline-block;
	font-size: 1.4rem;
	line-height: 2.8rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.agreements.descriptionColor};

	${customMinResponsive(
		size.medium,
		css`
			font-size: 1.8rem;
			line-height: 3.6rem;
		`
	)}
`;

export const ButtonsWrapper = styled.div`
	margin-top: 3.5rem;
	${flex({ fd: 'column' })}

	${customMinResponsive(
		size.medium,
		css`
			${flex({ fd: 'row' })}
		`
	)}

	button {
		width: 100%;
		padding: 0;

		${customMinResponsive(
			size.medium,
			css`
				margin: 0;

				&:first-of-type {
					margin-right: 1.6rem;
				}
			`
		)}
	}
`;
