import styled, { css } from 'styled-components';

// Import utilities
import { customMinResponsive, size } from 'components/styles';

export const Container = styled.div`
	order: 1;
	color: ${({ theme }) => theme.body.footer.color};
	font-size: ${({ theme }) => theme.fontSize.footer.media.text.mobile}rem;
	line-height: 1.6rem;
	text-align: center;
	width: 100%;
	font-weight: ${({ theme }) => theme.font.medium}!important;
	margin-top: 2.4rem;

	${customMinResponsive(
		size.medium,
		css`
			order: 0;
			font-weight: ${({ theme }) => theme.font.medium};
			font-size: ${({ theme }) => theme.fontSize.footer.media.link}rem;
			line-height: 2.3rem;
			width: auto;
			margin: 0 0 0 2.4rem;
		`
	)};
`;

export const StyledLink = styled.a`
	text-decoration: none;
	color: ${({ theme }) => theme.body.footer.color};
	font-weight: ${({ theme }) => theme.font.medium};
	&:hover {
		color: ${({ theme: { body } }) => body.footer.hover};
	}

	&:focus-visible {
		color: ${({ theme: { body } }) => body.footer.hover};
	}
`;
