import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import styled from 'styled-components';
import List from 'react-virtualized/dist/commonjs/List';

// Import helpers
import { getFilteredChannels } from './sidebar_channel/helpers/index';
import { getLivePrograms, getFavouriteChannels } from 'helpers/epg';

// Import components
import SidebarChannel from './sidebar_channel/SidebarChannel';

const SidebarChannels = React.memo(
	(props) => {
		const channels = React.useMemo(() => {
			const { lives, favourites, query, category } = props;
			const livesFiltered = lives.filter(getLivePrograms);
			const filterOptions = {
				lives: livesFiltered,
				category,
				query
			};

			return category === 'ulubione'
				? getFavouriteChannels(lives, favourites)
				: getFilteredChannels(filterOptions);
			// eslint-disable-next-line
		}, [JSON.stringify(props)]);

		const channelRow = ({ index, style, isScrolling, isVisible }) => {
			const currentLiveIndex = channels[index].currentLiveIndex;
			return (
				<ListItem key={channels[index].uuid} style={style}>
					<SidebarChannel
						images={channels[index].images}
						channelID={channels[index].uuid}
						channelName={channels[index].title}
						program={channels[index].epgProgrammes[currentLiveIndex]}
						nextProgram={channels[index].epgProgrammes[currentLiveIndex + 1]}
						avaiable={channels[index].avaiable}
						isScrolling={isScrolling}
						isVisible={isVisible}
					/>
				</ListItem>
			);
		};

		return (
			<Wrapper>
				<AutoSizer>
					{({ height, width }) => (
						<List
							className="player-sidebar-list"
							width={width}
							height={height}
							rowCount={channels.length}
							rowHeight={99}
							rowRenderer={channelRow}
							overscanRowCount={2}
						/>
					)}
				</AutoSizer>
			</Wrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

const Wrapper = styled.div`
	flex: 1;
	width: 100%;
	padding: 1rem 0;
`;

const ListItem = styled.div`
	padding-bottom: 0.2rem;
`;

SidebarChannels.propTypes = {
	lives: PropTypes.array.isRequired,
	favourites: PropTypes.array.isRequired
};

const mapStateToProps = ({
	channels: {
		data: { lives }
	},
	favourites: { data }
}) => ({
	lives,
	favourites: data
});

export default connect(mapStateToProps)(SidebarChannels);
