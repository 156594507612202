// -----------------  FETCH RECORDINGS -----------------
export const FETCH_RECORDINGS_LOADING = 'FETCH_RECORDINGS_LOADING';
export const FETCH_RECORDINGS_SUCCESS = 'FETCH_RECORDINGS_SUCCESS';
export const FETCH_RECORDINGS_ERROR = 'FETCH_RECORDINGS_ERROR';

// -----------------  REMOVE RECORDING -----------------
export const REMOVE_RECORDING_LOADING = 'REMOVE_RECORDING_LOADING';
export const REMOVE_RECORDING_SUCCESS = 'REMOVE_RECORDING_SUCCESS';
export const REMOVE_RECORDING_ERROR = 'REMOVE_RECORDING_ERROR';

// -----------------  SCHEDULE RECORDING -----------------
export const SCHEDULE_RECORDING_LOADING = 'SCHEDULE_RECORDING_LOADING';
export const SCHEDULE_RECORDING_SUCCESS = 'SCHEDULE_RECORDING_SUCCESS';
export const SCHEDULE_RECORDING_ERROR = 'SCHEDULE_RECORDING_ERROR';

// -----------------  CLEAR RECORDIGNS ERRORS -----------------
export const CLEAR_RECORDINGS_ERRORS = 'CLEAR_RECORDINGS_ERRORS';
