import React from 'react';
import { string, object, func, shape } from 'prop-types';

// Import styled components
import { SearchItemStyled, StyledLink, ParagraphStyled } from './styles';

// Import custom hook
import { useSearchItem } from './useSearchItem';

const SearchItem = ({ toggle, product }) => {
	const {
		isContentBlocked,
		placeholderRatingText,
		title,
		onCloseSearchBox,
		linkParams
	} = useSearchItem({ product, close: toggle });

	return (
		<SearchItemStyled>
			<StyledLink to={linkParams} onClick={onCloseSearchBox}>
				{isContentBlocked ? (
					<ParagraphStyled>{placeholderRatingText}</ParagraphStyled>
				) : (
					title && <ParagraphStyled>{title}</ParagraphStyled>
				)}
			</StyledLink>
		</SearchItemStyled>
	);
};

SearchItem.propTypes = {
	product: shape({
		uuid: string.isRequired,
		images: object,
		screenshots: object,
		title: string.isRequired,
		type: string.isRequired
	}).isRequired,
	toggle: func.isRequired
};

export default SearchItem;
