/**
 * returns poster image or error image
 * @param {object} images
 * @return {*}
 */

export function coverImage(images) {
	const types = Object.keys(images);
	const result = {};

	types.forEach((type) => {
		result[type] = images?.[type]?.[0]?.url || null;
	});

	return result;
}
