import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Import utilities
import { useProductAvailability } from 'components/utilities';

const useFavouriteAvailability = ({ uuid, available_in, isChannel }) => {
	const { lives } = useSelector(({ epg }) => epg.data);
	const isVodAvailable = useProductAvailability(uuid, available_in);
	const [isAvailable, setIsAvailable] = React.useState(false);

	React.useEffect(() => {
		const channel = lives.find((channel) => channel.uuid === uuid);
		const isChannelAvailable = channel && channel.avaiable;

		isChannel
			? channel && setIsAvailable(isChannelAvailable)
			: setIsAvailable(isVodAvailable);
	}, [lives, uuid, isChannel, isVodAvailable]);

	return { isAvailable };
};

useFavouriteAvailability.propTypes = {
	uuid: PropTypes.string.isRequired,
	available_in: PropTypes.array.isRequired,
	isChannel: PropTypes.bool.isRequired
};

export default useFavouriteAvailability;
