import React from 'react';
import { Link } from 'react-router-dom';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import components
import { AuthButtonStyled } from './styles';

const AuthButton = ({ isLink, ...rest }) => {
	const { t } = useTranslation();
	const options = isLink ? { as: Link, to: `/${APP_CORE_PATHS.LOGIN}` } : {};

	return (
		<AuthButtonStyled {...options} {...rest} modifiers="default">
			{t('common_auth_modal_login_button')}
		</AuthButtonStyled>
	);
};

AuthButton.defaultProps = { isLink: false };

AuthButton.propTypes = { isLink: bool };

export default AuthButton;
