import React from 'react';
import { bool } from 'prop-types';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';
import VolumeController from './VolumeController';
import SettingsController from './SettingsController';
import PlayController from './PlayController';
import ShakaSlider from 'components/elements/organisms/player_vod/PlayerWrapper/components/ShakaSlider';
import { ControllersWrapper } from './styles';

const PlayerControllers = ({ isFocus, isCatchup, isFloatMode }) => {
	// Get player methods from Player context
	const {
		setFullScreen,
		isReady,
		isFullScreen,
		seekAvailable,
		duration,
		updateTime,
		currentTimeProgress
	} = usePlayerTvContext();

	return (
		isReady && (
			<ControllersWrapper
				isCatchupControllers={isCatchup}
				isFloatMode={isFloatMode}
				isFocus={isFocus}
			>
				{seekAvailable && <PlayController />}
				<Button
					modifiers={['icon', 'catchup', 'playerIconHover']}
					onClick={setFullScreen}
					isFocus={isFocus}
				>
					{isFullScreen ? (
						<Player.IconExitFullScreen />
					) : (
						<Player.IconFullScreen />
					)}
				</Button>
				<SettingsController />
				<VolumeController />
				{seekAvailable && (
					<ShakaSlider
						duration={duration}
						updateTime={updateTime}
						currentTimeProgress={currentTimeProgress}
						seekAvailable={seekAvailable}
						isCatchup={isCatchup}
					/>
				)}
			</ControllersWrapper>
		)
	);
};

PlayerControllers.propTypes = {
	isFocus: bool.isRequired,
	isCatchup: bool.isRequired,
	isFloatMode: bool.isRequired
};

export default PlayerControllers;
