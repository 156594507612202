import React from 'react';
import { func } from 'prop-types';

// Import styles
import { ButtonStyled } from './styles';

// Import components
import { PlayerElements as Player } from 'components/elements';

const ButtonClose = ({ close }) => (
	<ButtonStyled onClick={close} modifiers={['icon', 'playerIconHover']}>
		<Player.IconCloseThin />
	</ButtonStyled>
);

ButtonClose.propTypes = {
	close: func.isRequired
};

export default ButtonClose;
