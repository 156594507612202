import * as types from 'store/actions/types';
import services from 'services/services';

// ******************** FETCH USER'S DEVICES ********************
export const fetchStaticContent = (type) => async (dispatch) => {
	try {
		// Dispatch an action
		dispatch({
			type: types.STATIC_CONTENT_LOADING,
			payload: true
		});

		// Get documents
		const { data } = await services.get(`/documents/byAlias/${type}`);

		// Dispatch an action
		dispatch({
			type: types.STATIC_CONTENT_SUCCESS,
			payload: data
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.STATIC_CONTENT_ERROR,
			payload: true
		});
	}
};

export const clearStaticContent = () => (dispatch) => {
	dispatch({
		type: types.CLEAR_STATIC_CONTENT,
		payload: true
	});
};
