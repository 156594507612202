// Import utilities
import LoadableMng from 'components/utilities/lodable_manager/LodableMng';

export const Login = LoadableMng({
	func: () => import('components/views/authentication/login/Login'),
	delay: 800
});

export const Main = LoadableMng({
	func: () => import('components/views/Main')
});

export const Contact = LoadableMng({
	func: () => import('components/views/contact')
});

export const Static = LoadableMng({
	func: () => import('components/templates/StaticContentTemplate')
});

export const Register = LoadableMng({
	func: () => import('components/views/authentication/register/Register')
});

export const ResetPassword = LoadableMng({
	func: () =>
		import('components/views/authentication/reset_password/ResetPassword')
});

export const EmailConfirmation = LoadableMng({
	func: () =>
		import(
			'components/views/authentication/email_confirmation/EmailConfirmation'
		)
});

export const CookiesManagement = LoadableMng({
	func: () => import('components/views/cookies_management')
});
