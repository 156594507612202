import { useEffect } from 'react';
/**
 * Scroll window to top on when component mounts
 */
const useWindowScroll = () => {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);
};

export default useWindowScroll;
