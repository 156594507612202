import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import { AuthModal } from 'components/elements';
import { useDispatch } from 'react-redux';

// Import icons
import { ReactComponent as BellIcon } from 'assets/icons/new_ic_bell.svg';

// Import components
import { Box, ReminderButtonStyled } from '../styles';
import { RemindersMenu } from '../../../common/RemindersMenu';

// Import actions
import { deleteReminder } from 'store/actions';

// Import helpers
import { useTranslation } from 'react-i18next';

const ReminderButtons = ({
	isUpcoming,
	isNotEnded,
	reminderId,
	programId,
	startDate
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const shouldDisplayReminderButtons = isUpcoming && isNotEnded;

	const handleDeleteRemider = () => {
		deleteReminder(reminderId)(dispatch);
	};

	if (shouldDisplayReminderButtons) return null;

	return (
		<Box>
			{reminderId ? (
				<ReminderButtonStyled
					onClick={handleDeleteRemider}
					modifiers="secondary"
				>
					<BellIcon /> {t('vod_buttons_live_remove_reminder')}
				</ReminderButtonStyled>
			) : (
				<Dropdown
					trigger={['click']}
					overlay={() => (
						<RemindersMenu programId={programId} startDate={startDate} />
					)}
				>
					<AuthModal>
						<ReminderButtonStyled modifiers="secondary">
							<BellIcon />
							{t('vod_buttons_live_add_reminder')}
						</ReminderButtonStyled>
					</AuthModal>
				</Dropdown>
			)}
		</Box>
	);
};

ReminderButtons.propTypes = {
	isUpcoming: PropTypes.bool.isRequired,
	programId: PropTypes.string.isRequired,
	reminderId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
	isNotEnded: PropTypes.bool,
	startDate: PropTypes.string
};

export default ReminderButtons;
