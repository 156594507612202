import styled, { css } from 'styled-components';

const CIRCLE_SIZE = '1.8rem';
const CIRCLE_MARGIN = '0.3rem';

const pseudoElementStyles = ({ size = '100%', radius = '50%', color }) => css`
	content: '';
	display: inline-block;
	transition: 0.2s ease-in-out;
	width: ${size};
	height: ${size};
	border-radius: ${radius};
	background: ${({ theme: { toggler } }) => toggler[color]};
`;

export const Checkbox = styled.input`
	position: relative;
	width: 4.2rem;
	height: 2.4rem;
	cursor: pointer;
	appearance: initial;

	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}

	&:focus-visible {
		outline: 2px solid ${({ theme }) => theme.outline.focusColor};
	}

	&::before {
		${pseudoElementStyles({ size: CIRCLE_SIZE, color: 'control' })}
		position: absolute;
		top: 50%;
		left: ${CIRCLE_MARGIN};
		transform: translateY(-50%);
		box-shadow: 1px 3px 3px 1px ${({ theme }) => theme.toggler.shadow};
		border: 3px slid green !important;
	}

	&::after {
		${pseudoElementStyles({ radius: '1.5rem', color: 'bg' })}
	}

	&:checked {
		&::before {
			left: calc(100% - ${CIRCLE_SIZE} - ${CIRCLE_MARGIN});
		}

		&::after {
			background: ${({ theme }) => theme.toggler.bgActive};
		}
	}
`;
