import styled from 'styled-components';

// Import components
import { PlayerElements as Player } from 'components/elements';

export const VolumeStyled = styled(Player.Volume)`
	height: 5px;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	padding: 0 1rem;
`;
