import React from 'react';
import { func } from 'prop-types';
import { useLanguages } from 'components/utilities/hooks/languages/useLanguages.js';
import { LANGUAGE_OPTIONS } from 'helpers/languages';
import { StyledMenuLanguageItem, StyledSubMenu } from './styles';

const USER_MOBILE_MENU_UNIQUE_KEY = 'user_mobile_language_menu';

const LanguageDropDownMobile = ({ ...props }) => {
	const { handleLanguageChange, currentLanguage, mapCodeToLanguage, t } =
		useLanguages();

	const renderMenuItems = LANGUAGE_OPTIONS.map(({ code }) => {
		return (
			<StyledMenuLanguageItem
				activeLanguage={currentLanguage === code}
				onClick={() => handleLanguageChange(code)}
				key={code}
			>
				{mapCodeToLanguage(code)}
			</StyledMenuLanguageItem>
		);
	});

	return (
		<StyledSubMenu
			key={USER_MOBILE_MENU_UNIQUE_KEY}
			title={t('user_profile_navigation_language')}
			{...props}
			eventKey={`item_${USER_MOBILE_MENU_UNIQUE_KEY}`}
			subMenuKey={`${USER_MOBILE_MENU_UNIQUE_KEY}-menu-`}
		>
			{renderMenuItems}
		</StyledSubMenu>
	);
};

LanguageDropDownMobile.propTypes = {
	toggle: func.isRequired
};

export default React.memo(LanguageDropDownMobile);
