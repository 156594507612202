import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import { checkForInvalidDataError } from 'store/helpers/error_status';

// ******************** CHECK EMAIL CONFIRMATION HASH ********************
export const checkEmailConfirmationHash = (hash) => async (dispatch) => {
	try {
		dispatch({
			type: types.CHECK_EMAIL_CONFIRMATION_HASH_LOADING
		});

		const url = `/register/approveEmail/${hash}`;
		await services.post(url);

		dispatch({
			type: types.CHECK_EMAIL_CONFIRMATION_HASH_SUCCESS
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Check if it's not invalid data error
		checkForInvalidDataError(error);

		dispatch({
			type: types.CHECK_EMAIL_CONFIRMATION_HASH_ERROR,
			payload: error.response
		});
	}
};
