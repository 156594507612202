import { useState, useCallback, useEffect } from 'react';
import { array, string } from 'prop-types';

// Import hooks
import useFetchAgreements from './useFetchAgreements';

const useAgreements = ({
	queryKey,
	agreementsToFetch,
	initialAcceptedAgreements = []
}) => {
	const [acceptedAgreements, setAcceptedAgreements] = useState([]);
	const [isCustomAgreementAccepted, setIsCustomAgreementAccepted] =
		useState(true);

	const { agreements, isAgreementsError, isAgreementsLoading } =
		useFetchAgreements({ queryKey, agreementsToFetch, useCache: true });

	const checkIsAgreementAccepted = ({ id }) => acceptedAgreements.includes(id);

	// check is all agrements accepted
	const isAllAgreementsAccepted = agreements.every(checkIsAgreementAccepted);

	// check is all obligatory agrements accepted
	const isObligatoryAgreementsAccepted = agreements
		.filter(({ obligatory }) => obligatory)
		.every(checkIsAgreementAccepted);

	// get array of accepted agreements versions ids
	const acceptedAgreementsVersions = agreements
		.filter(checkIsAgreementAccepted)
		.map(({ version }) => version.id);

	const handleSetIsCustomAgreementAccepted = () => {
		setIsCustomAgreementAccepted((prev) => !prev);
	};

	const handleToggleAgreement = useCallback(
		(id) => {
			const isAlreadyAccepted = acceptedAgreements.includes(id);
			const filteredAgreements = acceptedAgreements.filter(
				(item) => item !== id
			);

			const newAccepted = isAlreadyAccepted
				? filteredAgreements
				: [...acceptedAgreements, id];

			setAcceptedAgreements(newAccepted);
		},
		[acceptedAgreements]
	);

	const handleToggleAllAgreements = useCallback(() => {
		const allAgreements = agreements.map(({ id }) => id);

		const newAccepted = isAllAgreementsAccepted ? [] : allAgreements;

		setAcceptedAgreements(newAccepted);
		handleSetIsCustomAgreementAccepted();
	}, [agreements, isAllAgreementsAccepted]);

	useEffect(() => {
		if (agreements.length) {
			const initialAgreements = new Set([...initialAcceptedAgreements]);

			// set initial accepted agreements
			setAcceptedAgreements([...initialAgreements]);
		}
		// eslint-disable-next-line
	}, [agreements.length, initialAcceptedAgreements.length]);

	return {
		agreements,
		isAllAgreementsAccepted,
		isObligatoryAgreementsAccepted,
		acceptedAgreementsVersions,
		acceptedAgreements,
		isAgreementsLoading,
		isAgreementsError,
		toggleAgreement: handleToggleAgreement,
		toggleAllAgreements: handleToggleAllAgreements,
		setAcceptedAgreements,
		isCustomAgreementAccepted,
		setIsCustomAgreementAccepted: handleSetIsCustomAgreementAccepted
	};
};

useAgreements.propTypes = {
	agreementsToFetch: array.isRequired,
	queryKey: string.isRequired,
	initialAcceptedAgreements: array
};

export default useAgreements;
