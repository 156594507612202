import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import styles
import { Figure, Figcaption, Image } from './styles';

// Import utilities
import { ImageOnLoadRPC } from 'components/utilities';

const TileImage = ({ src, alt }) => {
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	return (
		<Figure isContrast={isContrast} title={alt}>
			<ImageOnLoadRPC>
				{({ setError }) => (
					<Image onError={setError} src={src} isImageUrl={src} alt={alt} />
				)}
			</ImageOnLoadRPC>
			<Figcaption>{alt}</Figcaption>
		</Figure>
	);
};

TileImage.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string
};

export default TileImage;
