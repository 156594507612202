import { css } from 'styled-components';

// Import styles
import { customMinResponsive, size } from 'components/styles';

// Import helpers
import { BANNER_MODIFIER_KEYS } from '../helpers';

const { HIGH_BANNER, BANNER, ARROWS, NO_ARROWS, VIDEO_BANNER } =
	BANNER_MODIFIER_KEYS;

export const BANNER_ARROWS_CONFIG = {
	[ARROWS]: ({ theme }) => css`
		padding: ${theme.isWCAG ? '1rem 5rem' : '1rem 6rem'};
		${customMinResponsive(
			size.medium,
			css`
				padding: ${theme.isWCAG ? '1rem 6rem' : '1rem 10rem'};
			`
		)}
		${customMinResponsive(
			size.large,
			css`
				padding: ${theme.isWCAG ? '0 12rem' : '0 16.7rem'};
			`
		)}
	`,
	[NO_ARROWS]: ({ theme }) => css`
		${customMinResponsive(
			size.large,
			css`
				padding: ${theme.isWCAG ? '0 2rem' : '0 4rem'};
			`
		)}
	`
};

export const BANNER_WRAPPER_CONFIG = {
	[HIGH_BANNER]: ({ theme }) => css`
		padding-top: ${theme.isWCAG ? '1rem' : '4rem'};
		${customMinResponsive(
			size.large,
			css`
				padding-top: ${theme.isWCAG ? '1rem' : '11.1vw'};
			`
		)}
	`,
	[VIDEO_BANNER]: ({ theme }) => css`
		padding-top: 1rem;
		${customMinResponsive(
			size.medium,
			css`
				padding-top: ${theme.isWCAG ? '10.5rem' : '9.5vw'};
			`
		)}
	`,
	[BANNER]: ({ theme }) => css`
		padding-top: 3rem;
		${customMinResponsive(
			size.medium,
			css`
				padding-top: ${theme.isWCAG ? '1rem' : '5rem'};
			`
		)}
		${customMinResponsive(
			1920,
			css`
				padding-top: ${theme.isWCAG ? '1rem' : '8.5rem'};
			`
		)}
	`
};

export const BANNER_CONTENT_CONFIG = {
	[HIGH_BANNER]: ({ theme }) => css`
		width: 100%;
		${customMinResponsive(
			size.medium,
			css`
				width: 41vw;
				width: ${theme.isWCAG ? '100%' : '41vw'};

				a {
					width: max-content;
				}
			`
		)}
	`,
	[VIDEO_BANNER]: ({ theme }) => css`
		${customMinResponsive(
			size.medium,
			css`
				width: 41vw;
				width: ${theme.isWCAG ? '100%' : '41vw'};
			`
		)}
	`,
	[BANNER]: ({ theme }) => css`
		width: 100%;
		${customMinResponsive(
			1700,
			css`
				width: 50vw;
				width: ${theme.isWCAG ? '100%' : '50vw'};

				a {
					width: max-content;
				}
			`
		)}
	`
};
