import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';

// ******************** UPDATE PIN NUMBER ********************
export const updatePinNumber = ({
	values,
	isParental = true,
	callback
}) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.UPDATE_PIN_NUMBER_LOADING,
			payload: true
		});

		const { pin, pin_confirmation, old_pin } = values;

		const options = {
			pin: `${pin}`,
			pin_confirmation: `${pin_confirmation}`,
			old_pin: `${old_pin}`
		};

		const url = isParental
			? '/subscriber/parental/pin'
			: '/subscriber/payments/pin';

		// Get program details
		await services.put(url, options);

		// Dispatch an action
		dispatch({
			type: types.UPDATE_PIN_NUMBER_SUCCESS,
			payload: true
		});
		callback();
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);

			error.response.data.message = codeErrorMessage;

			// Dispatch an error action
			dispatch({
				type: types.UPDATE_PIN_NUMBER_ERROR,
				payload: error.response
			});
		}
	}
};

export const clearPinData = () => (dispatch) => {
	// Dispatch an action
	dispatch({
		type: types.UPDATE_PIN_CLEAR_PIN_DATA,
		payload: true
	});
};
