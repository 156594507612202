import React from 'react';
import { bool, string } from 'prop-types';

// Import components
import StaticPage from './StaticPage';
import StaticWrapper from './StaticWrapper';
import useStaticContentTemplate from './useStaticContentTemplate';

const StaticContentTemplate = ({ isPage, ...rest }) => {
	useStaticContentTemplate();

	return isPage ? <StaticPage {...rest} /> : <StaticWrapper {...rest} />;
};

StaticContentTemplate.defaultProps = { isPage: true, title: '', alias: '' };
StaticContentTemplate.propTypes = {
	isPage: bool,
	title: string,
	alias: string
};

export default StaticContentTemplate;
