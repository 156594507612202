// -----------------  FETCH AVAILABLE CHANNELS -----------------
export const FETCH_AVAILABLE_CHANNELS_LOADING =
	'FETCH_AVAILABLE_CHANNELS_LOADING';
export const FETCH_AVAILABLE_CHANNELS_SUCCESS =
	'FETCH_AVAILABLE_CHANNELS_SUCCESS';
export const FETCH_AVAILABLE_CHANNELS_ERROR = 'FETCH_AVAILABLE_CHANNELS_ERROR';

// -----------------  FETCH TV PROGRAMS -----------------
export const FETCH_TV_PROGRAMS_LOADING = 'FETCH_TV_PROGRAMS_LOADING';
export const FETCH_TV_PROGRAMS_REFRESH_LOADING =
	'FETCH_TV_PROGRAMS_REFRESH_LOADING';
export const FETCH_TV_PROGRAMS_SUCCESS = 'FETCH_TV_PROGRAMS_SUCCESS';
export const FETCH_TV_PROGRAMS_ERROR = 'FETCH_TV_PROGRAMS_ERROR';

// -----------------  SET DATE -----------------
export const SET_TV_PROGRAMS_DATE = 'SET_TV_PROGRAMS_DATE';

// -----------------  SET PROGRAM LIVE -----------------
export const SET_TV_PROGRAM_LIVE = 'SET_TV_PROGRAM_LIVE';

// -----------------  SET PROGRAMS IN LOCALE-----------------
export const SET_TV_PROGRAMS_IN_LOCALE = 'SET_TV_PROGRAMS_IN_LOCALE';

// -----------------  WAKE UP UPDATE CURRENT TV PROGRAMS -----------------
export const WAKE_UP_UPDATE_CURRENT_TV_PROGRAMS =
	'WAKE_UP_UPDATE_CURRENT_TV_PROGRAMS';
