import styled, { css } from 'styled-components';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Button } from 'components/elements';

export const ToolBox = styled.div`
	height: 8rem;
	border-top: 1px solid ${({ theme: { popover } }) => popover.border};
	display: flex;
	justify-content: space-between;
`;

export const Box = styled.div`
	flex: 1;
	${flex()}

	&:nth-child(2) {
		border-left: 1px solid ${({ theme: { popover } }) => popover.border};
		border-right: 1px solid ${({ theme: { popover } }) => popover.border};
	}

	svg {
		height: 3rem;
		width: 3rem;
	}

	${({ isCatchup }) =>
		isCatchup &&
		css`
			svg {
				g,
				path {
					fill: ${({ theme }) => theme.epg.catchup.bg};
				}
			}
		`}
`;

export const ButtonStyled = styled(Button)`
	height: 5rem;
	width: 24.6rem;
	text-align: center;
	background: ${({ theme }) => theme.epg.buttons.bg};
	border-color: ${({ theme }) => theme.epg.buttons.border};
	:hover {
		border-color: ${({ theme }) => theme.epg.buttons.borderHover};
		background: ${({ theme }) => theme.epg.buttons.bgHover} !important;
	}
`;

export const ReminderButtonStyled = styled(Button)`
	height: 5rem;
	width: 24.6rem;
	display: flex;
	${flex()}

	svg {
		width: 1.8rem;
		height: 1.8rem;
		margin-right: 1rem;
	}
`;
