import React from 'react';

// Import components
import { Alert } from 'components/elements';
import { Portal } from 'components/utilities';

const PortalAlert = ({ text, fixed, absolute, action, closeBtn }) => (
	<Portal>
		<Alert
			fixed={fixed}
			absolute={absolute}
			text={text}
			action={action}
			closeBtn={closeBtn}
		/>
	</Portal>
);

export default PortalAlert;
