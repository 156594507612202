import i18next from 'i18next';
import services from 'services/services';
import * as types from 'store/actions/types';
import { openNotification } from 'components/utilities/notification/notification';

export const fetchProductPackets = (id) => async (dispatch) => {
	try {
		dispatch({ type: types.PRODUCT_PACKETS_LOADING });

		const url = `/products/${id}/packets?new=1&limit=10&offset=0`;

		const { data } = await services.get(url);

		dispatch({
			type: types.PRODUCT_PACKETS_SUCCESS,
			payload: data.data
		});
	} catch {
		dispatch({ type: types.PRODUCT_PACKETS_ERROR });

		openNotification({
			type: 'error',
			title: i18next.t('vod_movieDetails_error_title'),
			description: i18next.t('vod_movieDetails_error_description')
		});
	}
};

export function clearProductPackets(dispatch) {
	dispatch({ type: types.PRODUCT_PACKETS_CLEAR });
}
