import React from 'react';
import { ReactComponent as IcLiveBadge } from 'assets/icons/ic_live_badge.svg';

// Import styles
import { BannerWrapper, BannerContent } from './styles';

export const LiveBadge = () => {
	return (
		<BannerWrapper>
			<BannerContent>
				<IcLiveBadge />
			</BannerContent>
		</BannerWrapper>
	);
};
