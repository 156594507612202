export const getUserDetails = ({ user, additionalUserInfo }) => {
	const { email } = user;

	const {
		providerId,
		profile: { last_name, given_name, family_name, first_name }
	} = additionalUserInfo;

	const firstname = given_name || first_name;
	const lastname = family_name || last_name;

	return { firstname, lastname, email, signInMethod: providerId };
};

export const EMAIL = 'email';
