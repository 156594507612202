export const sliderOptions = {
	dots: false,
	infinite: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 0,
	rows: 1,
	speed: 0,
	fade: true
};

export const getAssetInfo = ({ galleryMetadata, metadata }) => {
	const author = metadata?.author || galleryMetadata?.author;
	const description = metadata?.description || galleryMetadata?.description;
	const title = metadata?.title || galleryMetadata?.title;
	const source = metadata?.source || galleryMetadata?.source;

	return { author, description, source, title };
};

export const DESCRIPTION_HEIGHT = 60;
