import { useEffect, useState } from 'react';
import { calculateTimeLeft } from './Timer.helpers';

export const useTimer = (targetDate) => {
	const initialTimeLeft = calculateTimeLeft(targetDate);
	const [timeLeft, setTimeLeft] = useState(initialTimeLeft);

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft(targetDate));
		}, 1000);

		return () => clearTimeout(timer);
	}, [timeLeft, targetDate]);

	return { timeLeft };
};
