import styled, { css } from 'styled-components';

// Import styles
import { flex, fadeInUp, customMinResponsive, size } from 'components/styles';

// Import icons
import { ReactComponent as IconCloseSVG } from 'assets/icons/ic_close_thin.svg';

// Import components
import { Button } from 'components/elements';

export const Box = styled.div`
	position: relative;
	background-color: ${({ theme }) => theme.modal.bg};
	border: 1px solid ${({ theme }) => theme.modal.borderColor};
	border-radius: 3px;
	padding: 5rem 1.7rem;
	width: 100%;
	min-height: 20rem;
	min-width: 34rem;
	animation: ${fadeInUp} 0.5s ease-in-out forwards;

	${customMinResponsive(
		size.medium,
		css`
			width: 57.7vw;
			padding: 5rem 8.2rem 6rem 8.2rem;
		`
	)}
`;

export const Bottom = styled.div`
	margin-top: 4rem;
	${({ flexDirection = 'column' }) => flex({ fd: flexDirection })}

	${customMinResponsive(
		size.medium,
		css`
			${({ flexDirection = 'row' }) => flex({ fd: flexDirection })}
		`
	)}

	& > button {
		width: 100%;

		${customMinResponsive(
			size.medium,
			css`
				margin: 0;

				&:not(:first-of-type) {
					margin-left: 1.6rem;
				}
			`
		)}
	}
`;

export const ButtonClose = styled(Button)`
	position: absolute;
	top: 1.2rem;
	right: 1.4rem;

	svg > g > g {
		fill: ${({ theme }) => theme.modal.closeButton};
	}

	&:hover {
		cursor: pointer;

		svg > g > g {
			fill: ${({ theme }) => theme.modal.closeButtonHover};
		}
	}

	&:focus-visible {
		svg > g > g {
			fill: ${({ theme }) => theme.modal.closeButtonHover};
		}
	}
`;

export const IconClose = styled(IconCloseSVG)`
	position: relative;
	height: 1.8rem;
`;

export const Text = styled.p`
	color: ${({ theme }) => theme.modal.textColor};
	font-weight: ${({ theme }) => theme.font.normal};
	font-size: 2.5rem !important;
	white-space: initial;
	margin-bottom: 2rem;

	${({ description }) =>
		description &&
		css`
			font-weight: ${({ theme }) => theme.font.light};
		`}
`;
