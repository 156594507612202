import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { number, string, oneOfType, bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import getSlug from 'speakingurl';

// COMPONENTS
import { BorderButton as StyledBorderButton } from 'components/elements';
// HELPERS
import { borderGradient } from './helpers';
import { getButtonHoverGradient } from 'helpers';

// Import styles
import { flex } from 'components/styles';

const LinkWrapper = styled.div`
	height: 50px;
	width: 155px;

	${({ addBottomMargin }) =>
		addBottomMargin &&
		css`
			margin-bottom: 1.5rem;
		`}
`;

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.white};
	width: 100%;
	height: 100%;
	text-decoration: none;
	${flex()};

	&:hover {
		color: ${({ theme }) => theme.white};
	}
`;

export function VodSectionButton({
	sectionId,
	sectionTitle,
	redirectPath = '',
	addBottomMargin = false
}) {
	const [handleHover, setHandleHover] = useState(false);
	const { t } = useTranslation();
	const { sectionsName } = useSelector((store) => store.sections);
	const { pathname } = useLocation();
	const sectionName = getSlug(sectionTitle);

	const redirectToSection = {
		pathname: `/vod/sekcja/${sectionId}/${sectionName}`,
		state: {
			fromPage: sectionsName,
			fromPagePath: pathname
		}
	};

	const redirectTo = redirectPath || redirectToSection;

	const { backgroundHover, borderHover } = getButtonHoverGradient();
	const buttonBorder = handleHover ? borderHover : borderGradient;
	const buttonBackground = handleHover ? backgroundHover : null;

	return (
		<LinkWrapper addBottomMargin={addBottomMargin}>
			<StyledBorderButton
				border={buttonBorder}
				backgroundGradient={buttonBackground}
				background={handleHover ? 'linear-gradient' : 'transparent'}
			>
				<StyledLink
					to={redirectTo}
					onMouseEnter={() => setHandleHover(true)}
					onMouseLeave={() => setHandleHover(false)}
				>
					{t('vod_buttons_all_products')}
				</StyledLink>
			</StyledBorderButton>
		</LinkWrapper>
	);
}

VodSectionButton.propTypes = {
	sectionId: oneOfType([string, number]).isRequired,
	sectionTitle: string.isRequired,
	redirectPath: string,
	addBottomMargin: bool
};
