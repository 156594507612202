import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';

// Import services
import services from 'services/services';

// Import utilities
import { openNotification } from 'components/utilities/notification/notification';
import { useToggle } from 'components/utilities';

// Import helpers
import { createPlayerPlaylist } from 'helpers/player';
import { setConfig } from 'store/actions/player/player_helpers/index.js';
import { checkErrorStatus } from 'services/helpers';
import { filterProductsWithVideoBanner } from 'helpers/products';
import {
	DELETE_VIDEO_SESSION_API,
	GET_PLAYER_CONFIGURATION_API,
	QUERY_PLAYER_CONFIGURATION,
	UPDATE_VIDEO_SESSION_API
} from 'helpers/api/player_api';
import { PRODUCTS_TYPES } from 'helpers/variables';

const { BANNER } = PRODUCTS_TYPES;

const useVideoBanners = ({ content }) => {
	const { t } = useTranslation();

	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	const [isMuted, toggleSound] = useToggle(true);

	const [currentProductIndex, setCurrentProductIndex] = useState(0);
	const [isActive, setIsActive] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const filteredContent = filterProductsWithVideoBanner(content);

	const productsLength = filteredContent?.length;

	const nextProductIndex = currentProductIndex + 1;
	const previousProductIndex = currentProductIndex - 1;
	const lastProductIndex = productsLength - 1;

	const hasNextProduct = nextProductIndex <= lastProductIndex;
	const hasPreviousProduct = previousProductIndex >= 0;

	const currentProduct = filteredContent?.[currentProductIndex];

	const fetchCurrentPlayerConfig = async () => {
		const lastVideoIdFromThelist =
			currentProduct?.previews?.banner[
				currentProduct?.previews?.banner.length - 1
			]?.video_id;
		const productID = currentProduct?.uuid;

		// Get config
		const { data: config } = await services.get(
			generatePath(GET_PLAYER_CONFIGURATION_API, {
				productID,
				type: BANNER,
				video_id: lastVideoIdFromThelist
			})
		);

		// Get video session
		const { videoSessionId } = config.videoSession;
		// Create player playlist
		const playlist = createPlayerPlaylist({
			id: productID,
			videoSessionId
		});

		return {
			configuration: setConfig(config),
			playlist,
			videoSessionId
		};
	};

	const handleError = (error) => {
		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: t('vod_movieDetails_error_title'),
				description: t('common_errors_connection')
			});
	};

	const { isLoading, isFetching, isError, data } = useQuery({
		queryKey: [QUERY_PLAYER_CONFIGURATION, currentProductIndex],
		queryFn: fetchCurrentPlayerConfig,
		onError: handleError
	});

	const { mutateAsync: deleteVideoSession } = useMutation({
		mutationFn: (videoSessionId) =>
			services.delete(
				generatePath(DELETE_VIDEO_SESSION_API, { videoSessionId })
			),
		onError: handleError
	});

	const { mutateAsync: updateVideoSession } = useMutation({
		mutationFn: (videoSessionId) =>
			services.put(generatePath(UPDATE_VIDEO_SESSION_API, { videoSessionId })),
		onError: handleError
	});

	const handleNextProduct = () => {
		hasNextProduct
			? setCurrentProductIndex((prev) => prev + 1)
			: setCurrentProductIndex(0);
	};

	const handlePreviousProduct = () => {
		hasPreviousProduct
			? setCurrentProductIndex((prev) => prev - 1)
			: setCurrentProductIndex(lastProductIndex);
	};

	const handleVideoBannersFocus = () => {
		setIsActive(true);
		setIsFocused(true);
	};

	const handleVideoBannersBlur = () => {
		setIsActive(false);
		setIsFocused(false);
	};

	const handleMouseOver = () => !isActive && setIsActive(true);

	const handleMouseLeave = () => !isFocused && setIsActive(false);

	return {
		isLoading: isLoading || isFetching,
		isError,
		data,
		currentProduct,
		isContrast,
		isMuted,
		isActive,
		nextProduct: handleNextProduct,
		previousProduct: handlePreviousProduct,
		deleteVideoSession,
		updateVideoSession,
		toggleSound,
		onFocus: handleVideoBannersFocus,
		onBlur: handleVideoBannersBlur,
		onMouseOver: handleMouseOver,
		onMouseLeave: handleMouseLeave,
		hasNextProduct,
		hasPreviousProduct
	};
};

export default useVideoBanners;
