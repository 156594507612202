import React from 'react';
import { object, string, shape } from 'prop-types';
import NumberFormat from 'react-number-format';

// Import styled components
import { Wrapper, FormError, ErrorWrapper, InputStyled } from './styles';

const NumberField = ({
	field,
	form: { touched, errors },
	placeholder,
	format,
	...rest
}) => {
	const { name } = field;
	const errorText = errors[name];
	const isError = touched[name] && errorText;
	const className = isError ? 'errors' : '';

	return (
		<Wrapper>
			<NumberFormat
				format={format}
				className={className}
				name={name}
				placeholder={placeholder}
				customInput={InputStyled}
				allowNegative={false}
				modifiers="payment"
				{...field}
				{...rest}
			/>
			<ErrorWrapper>
				<FormError
					isErrorVisible={isError}
					role="alert"
					aria-atomic="true"
					id={`${name}-alert`}
					align="center"
				>
					{errorText}
				</FormError>
			</ErrorWrapper>
		</Wrapper>
	);
};

NumberField.defaultProps = {
	type: 'text',
	autoComplete: 'true'
};

NumberField.propTypes = {
	type: string.isRequired,
	field: object.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired
	}),
	placeholder: string.isRequired,
	autoComplete: string,
	maskSymbol: string,
	inputFormat: string,
	format: string
};

export default NumberField;
