import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import components
import { Input } from 'components/elements';

const FirebaseAuthField = ({
	type = 'text',
	field,
	name = 'pin',
	autoComplete = 'off',
	form: { touched, errors },
	placeholder,
	marginBottom = '1.9rem',
	isDialCodeField = false,
	disabled = false
}) => {
	const isErrorVisible = touched[field.name] && errors[field.name];
	const errorText = errors[field.name] || '';

	return (
		<Wrapper marginBottom={marginBottom} isDialCodeField={isDialCodeField}>
			<InputStyled
				className={`${
					touched[field.name] && errors[field.name] ? 'errors' : ''
				}`}
				type={type}
				name={name}
				autoComplete={autoComplete}
				placeholder={placeholder}
				disabled={disabled}
				{...field}
			/>
			<FormError isErrorVisible={isErrorVisible}>{errorText}</FormError>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
	margin-bottom: ${({ marginBottom }) => marginBottom};
	flex: 1;

	${({ isDialCodeField }) =>
		isDialCodeField &&
		css`
			max-width: 5.5rem;
			margin-right: 1rem;
			margin-bottom: 0;
		`}
`;

const InputStyled = styled(Input)`
	border: 1px solid ${({ theme }) => theme.authentication.input.borderColor};
	color: ${({ theme }) => theme.authentication.input.color};

	&:focus {
		border-color: ${({ theme }) => theme.authentication.input.focusBorderColor};
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.error.border};
		color: ${({ theme }) => theme.error.color};

		&:focus {
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
				0 0 8px rgba(255, 0, 0, 0.6);
		}
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.authentication.input.color};
		-webkit-box-shadow: 0 0 0px 1000px
			${({ theme }) => theme.authentication.contentBg} inset;
		transition: background-color 5000s ease-in-out 0s;
	}
`;

const FormError = styled.span`
	position: absolute;
	top: 4rem;
	left: 0;
	visibility: ${({ isErrorVisible }) =>
		!isErrorVisible ? 'hidden' : 'visible'};
	font-size: 1rem;
	color: ${({ theme }) => theme.error.color};
	text-align: left;
`;

FirebaseAuthField.propTypes = {
	type: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	placeholder: PropTypes.string,
	autoComplete: PropTypes.string,
	tooltipHintText: PropTypes.string,
	marginBottom: PropTypes.string,
	isDialCodeField: PropTypes.bool,
	disabled: PropTypes.bool,
	name: PropTypes.string
};

export default FirebaseAuthField;
