import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';

const Root = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
`;

const Item = styled.div.attrs(({ bottom }) => ({
	style: {
		bottom
	}
}))`
	position: absolute;
	left: 0;
`;

const BottomChecker = styled.div`
	position: absolute;
	bottom: 0;
	height: 0;
`;

const ButtonContainer = styled.div`
	width: 0;
	height: 100%;
	position: relative;
`;

export function PlayerStickyButtons({ children }) {
	const bottomCheckerRef = useRef(null);
	const [bottom, setBottom] = useState(0);

	const calcButtonsPosition = () => {
		const { top } = bottomCheckerRef.current.getBoundingClientRect();

		const difference = window.innerHeight - 1;

		if (top > difference) {
			setBottom(top - difference);
		} else {
			setBottom(0);
		}
	};

	const throttleCalcButtonsPosition = throttle(calcButtonsPosition, 20);

	useEffect(() => {
		bottomCheckerRef && bottomCheckerRef.current && calcButtonsPosition();
	}, [bottomCheckerRef]);

	useEffect(() => {
		window.addEventListener('scroll', throttleCalcButtonsPosition);
		window.addEventListener('resize', throttleCalcButtonsPosition);
		return () => {
			window.removeEventListener('scroll', throttleCalcButtonsPosition);
			window.removeEventListener('resize', throttleCalcButtonsPosition);
		};
	});

	return (
		<Root>
			<ButtonContainer>
				<Item bottom={bottom} id="tv-player-buttons-container">
					{children}
				</Item>
				<BottomChecker ref={bottomCheckerRef} />
			</ButtonContainer>
		</Root>
	);
}
