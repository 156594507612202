// Import utilities
import { useResize } from 'components/utilities';

// Import hooks
import { useSearchInstitutions } from './useSearchInstitutions';

const useSearchBox = (close) => {
	const { clearInstitution } = useSearchInstitutions(close);
	const { viewportHeight } = useResize();

	return { viewportHeight, onSearchAll: clearInstitution };
};

export default useSearchBox;
