import i18n from 'i18next';
import { openNotification } from 'components/utilities/notification/notification';
import { addReminder } from 'store/actions';

export const triggerReminder = (data, dispatch) => {
	if (Notification.permission === 'denied') {
		openNotification({
			type: 'error',
			title: i18n.t('common_reminders_error_title'),
			description: i18n.t(
				'common_reminders_error_description_permission_blocked'
			),
			duration: 8
		});
	} else {
		addReminder(data)(dispatch);
	}
};
