import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EventListener from 'react-event-listener';
import { debounce } from 'lodash';

class MasonryLayout extends React.Component {
	constructor() {
		super();

		this.state = {
			columns: 4,
			elements: 9
		};

		this.debounce = debounce(this.handleResize, 200);
	}

	componentDidMount() {
		// Get window with
		const width = window.innerWidth;
		// Set column count
		this.handleColumnWidth(width);
	}

	// Adjust element height to the Layout
	adjustHeight = (index) => {
		let height;
		if (
			(index !== 0 &&
				index !== 6 &&
				index !== 9 &&
				index !== 10 &&
				index !== 11 &&
				index !== 15 &&
				(index % 3 === 0 || index % 5 === 0 || index % 8 === 0)) ||
			index === 14
		) {
			height = 400;
		} else {
			height = 192;
		}
		return height;
	};

	calculateElements = (columns, increment = 1) => columns * 2 + increment;

	handleColumnWidth = (innerWidth) => {
		if (innerWidth > 2200) {
			this.setState({ columns: 7, elements: this.calculateElements(7, 2) });
		} else if (innerWidth > 1900) {
			this.setState({ columns: 6, elements: this.calculateElements(6, 2) });
		} else if (innerWidth > 1600) {
			this.setState({ columns: 5, elements: this.calculateElements(5, 2) });
		} else if (innerWidth < 1000) {
			this.setState({ columns: 2, elements: this.calculateElements(2) });
		} else if (innerWidth < 1200) {
			this.setState({ columns: 3, elements: this.calculateElements(3) });
		} else {
			this.setState({ columns: 4, elements: this.calculateElements(4) });
		}
	};

	handleResize = (props) => {
		const currentTarget = props.target || props.currentTarget;
		this.handleColumnWidth(currentTarget.innerWidth);
	};

	render() {
		const { elements, columns } = this.state;
		return (
			<React.Fragment>
				<EventListener target="window" onResize={this.debounce} />
				<Wrapper columns={columns}>
					{this.props.children({ elements, adjustHeight: this.adjustHeight })}
				</Wrapper>
			</React.Fragment>
		);
	}
}

const Wrapper = styled.div`
	padding: 0 1.5rem;
	columns: ${({ columns }) => columns};
	column-gap: 1rem;
`;

MasonryLayout.propTypes = {
	children: PropTypes.func.isRequired
};

export default MasonryLayout;
