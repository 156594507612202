import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, Dropdown, Tooltip } from 'antd';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

// Import redux actions
import { signOut } from 'store/actions';

// Import utilities
import { useAuth } from 'components/utilities';

// Import icons
import { ReactComponent as ProfileIcon } from 'assets/icons/ic_nav_profile.svg';

// Import styles
import { Nav, ItemStyled } from 'components/elements/molecules/dropdown/styles';
import { StyledButton, StyledMenu, ButtonLogout } from './styles';

// Import components
import { AuthModal } from 'components/elements';

// Import variables
import { USER_MENU_NAV_LINKS } from 'helpers/user_menu_nav';

const NavDropdown = ({ t, signOut }) => {
	const [isTooltipVisible, setIsTooltipVisible] = useState(false);

	const { isAuth } = useAuth();

	// SignOut
	// Dispatch an signOut action in auth folder
	// Trigger Loader component in Dashboard view
	const handleSignOut = () => signOut();

	const toggleTooltip = () => setIsTooltipVisible((prev) => !prev);

	const menu = (
		<StyledMenu>
			{USER_MENU_NAV_LINKS.map(({ path, translation }) => (
				<ItemStyled key={path}>
					<Nav
						to={path}
						activeClassName="active-drop-nav"
						title={t(`user_profile_${translation}`)}
					>
						{t(`user_profile_${translation}`)}
					</Nav>
				</ItemStyled>
			))}
			<Menu.Divider />
			<ButtonLogout
				onClick={handleSignOut}
				title={t('common_static_btn_sign_out')}
			>
				{t('common_static_btn_sign_out')}
			</ButtonLogout>
		</StyledMenu>
	);

	const renderMenuButton = () => {
		const userProfileTitle = t('common_user_profile_title');

		return (
			<Tooltip
				placement="bottom"
				title={userProfileTitle}
				visible={isTooltipVisible}
				onVisibleChange={toggleTooltip}
			>
				<StyledButton
					modifiers={['navIcon']}
					title={userProfileTitle}
					onClick={toggleTooltip}
				>
					<ProfileIcon aria-hidden="true" title={userProfileTitle} />
				</StyledButton>
			</Tooltip>
		);
	};

	return isAuth ? (
		<Dropdown
			overlay={menu}
			trigger={['click']}
			getPopupContainer={(triggerNode) => triggerNode.parentNode}
		>
			{renderMenuButton()}
		</Dropdown>
	) : (
		<AuthModal>{renderMenuButton}</AuthModal>
	);
};

NavDropdown.propTypes = {
	signOut: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	login: PropTypes.string
};

const mapStateToProps = ({ auth: { authenticate } }) => ({ authenticate });

export default compose(
	connect(mapStateToProps, { signOut }),
	withTranslation()
)(NavDropdown);
