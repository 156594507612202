import React from 'react';
// import { useTranslation } from 'react-i18next';
import { oneOfType, arrayOf, node } from 'prop-types';
// import { useSelector } from 'react-redux';

// Import Context Provider
import AuthenticationProvider from 'components/context/authentication/AuthenticationProvider';

// Import styled Components
import {
	AuthenticationWrapper,
	AuthenticationContainer,
	AuthenticationBox
} from './styles';

const AuthenticationTemplate = ({ children }) => {
	return (
		<AuthenticationProvider>
			<AuthenticationWrapper>
				<AuthenticationContainer>
					<AuthenticationBox>{children}</AuthenticationBox>
				</AuthenticationContainer>
			</AuthenticationWrapper>
		</AuthenticationProvider>
	);
};

AuthenticationTemplate.propTypes = {
	children: oneOfType([arrayOf(node), node])
};

export default AuthenticationTemplate;
