import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const List = styled.ul`
	list-style: none;
	margin-top: 1rem;
	background-color: ${({ theme }) => theme.dropdown.submenu.bgColor};
	padding: 0;

	${({ isSubmenu }) =>
		isSubmenu &&
		css`
			padding: 1.5rem 11.5rem 3rem 3rem;
		`}
`;

export const Item = styled.li`
	padding: 0.5rem 0;
	list-style: none;
`;

const baseLink = css`
	font-size: ${({ theme }) => theme.fontSize.nav.link}rem;
	line-height: 3.4rem;
	text-align: left;
	text-transform: capitalize;
	color: ${({ theme }) => theme.dropdown.submenu.textColor};
	&:hover,
	&:focus {
		color: ${({ theme }) => theme.dropdown.submenu.textColorOnHover};
	}
`;

export const StyledNavLink = styled(NavLink)`
	${baseLink}
`;

export const AnchorLink = styled.a`
	${baseLink}
`;
