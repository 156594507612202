import React from 'react';
import { number, string, oneOfType, bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { Heading } from 'components/elements';
// COMPONENTS
import { VodSectionButton } from 'components/views/vod/components/buttons/VodSectionButton';

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0 4.5rem 0 4.5rem;

	${({ borderLine }) =>
		borderLine &&
		css`
			border-bottom: 1px solid rgba(238, 238, 238, 0.22);
			padding: 0.5rem 4.5rem 2rem;
		`}
`;

const Title = styled(Heading.First)`
	color: ${({ theme }) => theme.white};
	font-size: 2.2rem;
	margin: 0;
	z-index: 2;
`;

export function RegularHeader({
	title,
	sectionId,
	showAllMoviesButton,
	borderLine = false
}) {
	return (
		<Container borderLine={borderLine}>
			<Title>{title}</Title>
			{showAllMoviesButton && (
				<VodSectionButton sectionId={sectionId} sectionTitle={title} />
			)}
		</Container>
	);
}

RegularHeader.propTypes = {
	title: string.isRequired,
	sectionId: oneOfType([number, string]).isRequired,
	showAllMoviesButton: bool.isRequired,
	borderLine: bool
};
