import React from 'react';
import PropTypes from 'prop-types';

// Import styles
import {
	IconEUPromo,
	IconEUPLPromo
} from 'components/elements/molecules/slider/SliderItem/styles';

//Import variables
import { VOD_PROMOTED_TYPES } from 'helpers/variables';

const { eu, pl_eu } = VOD_PROMOTED_TYPES;

const TilePromotedIcon = ({ promotionType }) => {
	switch (promotionType) {
		case eu:
			return <IconEUPromo />;
		case pl_eu:
			return <IconEUPLPromo />;
		default:
			return null;
	}
};

TilePromotedIcon.propTypes = {
	promotionType: PropTypes.string.isRequired
};

export default TilePromotedIcon;
