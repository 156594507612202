import styled from 'styled-components';

// Import styles
import { flex } from 'components/styles';

const Headline = styled.div`
	padding-bottom: 2rem;
	margin: 0 0 2.2rem;
	border-bottom: 1px solid ${({ theme }) => theme.line};

	${({ spaceBetween }) => spaceBetween && flex({ jc: 'space-between' })}
`;

export default Headline;
