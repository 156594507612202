import styled, { css } from 'styled-components';

// Import styles
import { flex, customMinResponsive, size } from 'components/styles';

// Import icons
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

const commonWrapperStyles = css`
	border: 1px solid ${({ theme }) => theme.body.alert.border};
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	padding: 2rem;
	z-index: 1000;

	${customMinResponsive(
		size.medium,
		css`
			width: 62.5rem;
		`
	)}
`;

export const AlertWrapper = styled.div`
	background-color: ${({ theme }) => theme.body.alert.bg};
	border: 1px solid ${({ theme }) => theme.body.alert.border};
	color: ${({ theme }) => theme.body.alert.color};
	padding: 1.5rem;
	margin-bottom: 1.8rem;
	border-radius: 4px;
	${flex()}

	${({ fixed }) =>
		fixed &&
		css`
			position: fixed;
			top: 8rem;
			${commonWrapperStyles}
		`}

	${({ absolute }) =>
		absolute &&
		css`
			position: absolute;
			top: 3rem;
			${commonWrapperStyles}
		`}
`;

export const AlertText = styled.span`
	font-size: 1.4rem;
`;

export const CloseButton = styled.button`
	border: none;
	background: transparent;
	position: absolute;
	right: 20px;
	height: 10px;
	width: 10px;
	pointer-events: auto;
	cursor: pointer;
`;

export const StyledCloseIcon = styled(CloseIcon)`
	width: 100%;
	height: 100%;
	display: block;
	path {
		stroke: ${({ theme }) => theme.red};
	}
`;
