import getSlug from 'speakingurl';

// Import routing paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { PAGE, SECTION, CATEGORIES, HOME_PAGE, TV, EPG } = APP_CORE_PATHS;

const MODEL_TYPES = {
	PAGE: 'page',
	SECTION: 'section',
	GENRE: 'genre',
	EPG: 'epg'
};

export const getNavigationPath = ({
	modelId,
	modelType,
	name,
	basePath = '',
	genresList = []
}) => {
	switch (modelType) {
		case MODEL_TYPES.PAGE:
			return `/${PAGE}/${modelId}`;
		case MODEL_TYPES.SECTION:
			return `${basePath}/${SECTION}/${modelId}/${getSlug(name)}`;
		case MODEL_TYPES.EPG:
			return `/${TV}/${EPG}`;
		case MODEL_TYPES.GENRE:
			const corePath = `${basePath}/${CATEGORIES}`;

			const genres = genresList.find(({ id }) => id.toString() === modelId);

			if (genres?.slug) {
				const { slug } = genres;

				return `${corePath}?genre=${getSlug(slug)}`;
			}

			return corePath;
		default:
			return HOME_PAGE;
	}
};

export const getLinkDetails = ({
	modelId,
	modelType,
	name,
	basePath = '',
	genresList = []
}) => {
	const isInternalLink = modelId && modelType;

	const path = getNavigationPath({
		modelId,
		modelType,
		name,
		basePath,
		genresList
	});

	return { isInternalLink, path };
};

export const getSubLinkDetails = (sublink) => {
	if (sublink) {
		const { link, name, model_id, model_type } = sublink;

		return { link, name, model_id, model_type };
	}

	return {};
};
