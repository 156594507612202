import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import helpres
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import utilities
import { useAuth, usePrevious } from 'components/utilities';

// Import components
import { useFloatWidget } from 'components/elements';

// Import hooks
import usePlayer from './usePlayer';

const { TV } = APP_CORE_PATHS;

const useFloatPlayer = () => {
	const { isAuth } = useAuth();
	const floatMode = useFloatWidget();

	const { isPlayerTvOpen } = useSelector(({ player_vod }) => player_vod);

	const { pathname } = useLocation();
	const previousPath = usePrevious(pathname || '');

	const isVodPlayer =
		!isPlayerTvOpen && !pathname.includes(TV) && !previousPath.includes(TV);

	const playerProps = usePlayer({ isVodPlayer });

	return { isVodPlayer, floatMode, playerProps, isAuth };
};

export default useFloatPlayer;
