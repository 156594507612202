import { useSelector } from 'react-redux';

// Import core paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { HOME_PAGE } = APP_CORE_PATHS;

const useNoAuthRedirect = () => {
	const { latestNoAuthPath } = useSelector(
		({ previous_location }) => previous_location
	);

	// redirect user to location from before login
	const redirectPath = latestNoAuthPath || HOME_PAGE;
	return { redirectPath };
};

export default useNoAuthRedirect;
