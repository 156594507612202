import React from 'react';
import { useSelector } from 'react-redux';
import { number } from 'prop-types';

// Import styled components
import {
	StyledIconAgeRating0,
	StyledIconAgeRating7,
	StyledIconAgeRating12,
	StyledIconAgeRating16,
	StyledIconAgeRating18
} from './styles';

const AgeRatingIcon = ({ rating }) => {
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	switch (rating) {
		case 0:
			return (
				<StyledIconAgeRating0
					iscontrast={isContrast ? 1 : 0}
					aria-hidden="true"
				/>
			);
		case 7:
			return (
				<StyledIconAgeRating7
					iscontrast={isContrast ? 1 : 0}
					aria-hidden="true"
				/>
			);
		case 12:
			return (
				<StyledIconAgeRating12
					iscontrast={isContrast ? 1 : 0}
					aria-hidden="true"
				/>
			);
		case 16:
			return (
				<StyledIconAgeRating16
					iscontrast={isContrast ? 1 : 0}
					aria-hidden="true"
				/>
			);
		case 18:
			return (
				<StyledIconAgeRating18
					iscontrast={isContrast ? 1 : 0}
					aria-hidden="true"
				/>
			);
		default:
			return null;
	}
};

AgeRatingIcon.propTypes = {
	rating: number.isRequired
};

export default AgeRatingIcon;
