import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import i18n from 'i18next';
import services from 'services/services';

// Import helpers
import { checkErrorStatus } from 'store/helpers/error_status';
import { POST_SUBSCRIBER_NOTIFICATIONS_REGISTER_API } from './api';

// Import utilities
import { openNotification } from 'components/utilities/notification/notification';

const sendTokenToServer = async (token) => {
	try {
		const payload = {
			token
		};

		await services.post(POST_SUBSCRIBER_NOTIFICATIONS_REGISTER_API, payload);
	} catch (error) {
		checkErrorStatus(error) &&
			openNotification({
				type: 'error',
				title: i18n.t('common_reminders_error_title'),
				description: i18n.t('common_reminders_error_description_notification'),
				duration: 8
			});
	}
};

export function initializePush() {
	const { isSupported } = firebase.messaging;

	if (isSupported()) {
		const messaging = firebase.messaging();
		Notification.requestPermission()
			.then(() => messaging.getToken())
			.then((token) => {
				//send new found FCM token to the server
				sendTokenToServer(token);
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log('An error occurred while retrieving token. ', { err });
			});

		messaging.onMessage((payload) => {
			payload?.notification && openNotification(payload.notification);
		});
	}
}
