import React from 'react';
import { string, number, node, object } from 'prop-types';

// Import components
import PaymentOptionPrice from './PaymentOptionPrice';
import { Container, Name, FlexContainer } from './styles';

const PaymentOption = ({ price, paymentName, children, promotion }) => (
	<Container>
		<FlexContainer>
			<Name>{paymentName}</Name>
			<PaymentOptionPrice price={price} promotion={promotion} />
		</FlexContainer>
		{children}
	</Container>
);

PaymentOption.defaultProps = {
	price: null,
	paymentName: ''
};

PaymentOption.propTypes = {
	price: number,
	paymentName: string.isRequired,
	children: node.isRequired,
	promotion: object
};

export default PaymentOption;
