import React from 'react';
import { func, string, oneOf } from 'prop-types';
import styled from 'styled-components';
// ICON
import { ReactComponent as Right } from 'assets/icons/ic_arrow_r.svg';
import { ReactComponent as Left } from 'assets/icons/ic_arrow_l.svg';

const IconContainer = styled.div`
	height: 140px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	right: ${({ direction }) => (direction === 'right' ? '-40px' : 'unset')};
	left: ${({ direction }) => (direction === 'left' ? '-40px' : 'unset')};

	&::before {
		content: '';
	}

	&.slick-disabled {
		display: none;
	}

	svg g polygon {
		fill: ${({ theme }) => theme.slider.arrow.color};
	}
`;

export function CustomArrow({ onClick, className, direction }) {
	return (
		<IconContainer
			direction={direction}
			className={className}
			onClick={onClick}
		>
			{direction === 'right' && <Right width="50px" height="50px" />}
			{direction === 'left' && <Left width="50px" height="50px" />}
		</IconContainer>
	);
}

CustomArrow.propTypes = {
	onClick: func,
	className: string,
	direction: oneOf(['right', 'left'])
};
