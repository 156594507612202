import styled, { css } from 'styled-components';

// base style for generic table can be added here
// watch out though - it will change all tables in app
// To customize table UI make styled component wrapper
// and modify specific classes e.g. react-table__row

const setRowWidth = ({ width }) =>
	width
		? css`
				min-width: ${width};
		  `
		: css`
				flex: 1;
		  `;

export const Table = styled.table`
	min-width: '600px';
`;

export const Head = styled.thead``;

export const HeaderRow = styled.tr`
	display: flex;
	align-items: center;
`;

export const Header = styled.th`
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	${setRowWidth}
`;

export const Body = styled.tbody``;

export const Row = styled.tr`
	display: flex;
	align-items: center;
`;

export const Cell = styled.td`
	display: inline-block;
	${setRowWidth}
`;
