import styled from 'styled-components';

export const ContinueWatchingData = styled.div`
	position: absolute;
	height: 2px;
	width: 80%;
	background-color: rgba(255, 255, 255, 0.4);
	bottom: 6%;
`;

export const Strap = styled.div`
	height: 100%;
	width: ${({ percent }) => percent}%;
	background: linear-gradient(
		90deg,
		rgba(30, 41, 110) 0%,
		rgb(58, 96, 153) 60%,
		rgba(53, 125, 177) 100%
	);
`;
