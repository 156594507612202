import styled, { css } from 'styled-components';

//Import styles
import { customMinResponsive, size } from 'components/styles';

//Import components
import PortalLogo from 'components/layout/nav/portal_logo/PortalLogo.js';

export const AuthenticationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100vh;
	background-color: ${({ theme: { body } }) => body.bg.primary};
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;

	&::after {
		top: 0;
		display: block;
		height: 100vh;
		width: 100%;
		position: fixed;
		content: '';

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		${customMinResponsive(
			size.small,
			css`
				background-image: url(${process.env
					.REACT_APP_API_URL}/assets/model/landingPageBrowserRwd/cover);
				background-color: ${({ theme: { body } }) => body.bg.primary};
			`
		)}

		${customMinResponsive(
			size.medium,
			css`
				background-image: url((${process.env
					.REACT_APP_API_URL}/assets/model/landingPageBrowser/cover);
				background-color: ${({ theme: { body } }) => body.bg.primary};;;
			`
		)}
	}
`;

export const StyledLogo = styled.img`
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};
`;

export const AuthenticationContainer = styled.div`
	width: 100%;
`;

export const AuthenticationBox = styled.div`
	width: 100%;
	margin: 0 auto;
`;

export const StyledPortalLogo = styled(PortalLogo)`
	aspect-ratio: 1/1;
	width: 30rem;
	height: 100%;
`;
