import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import context
import useInfoContext from 'components/views/vod/views/ProductDetails/Layout/Info/context/useInfoContext';

// Import components
import { CommonButton } from 'components/elements';

export function PacketsButton({ uuid, subtype }) {
	const { t } = useTranslation();
	const { setVodOnlyInPackets } = useInfoContext();

	const handleClick = () => setVodOnlyInPackets(uuid, subtype);

	return (
		<CommonButton onClick={handleClick} isPrime>
			{t('vod_buttons_check_availability_details')}
		</CommonButton>
	);
}

PacketsButton.propTypes = {
	uuid: string.isRequired,
	subtype: string.isRequired
};
