import React from 'react';
import PropTypes from 'prop-types';

// Import helpers
import { PLAYER_ERROR_TYPES } from 'helpers/player';

// Import components
import { PlayerElements as Player } from 'components/elements';
import ParentalControlButton from './ParentalControlButton';

const { BLOCKED_BY_PARENTAL_CONTROL } = PLAYER_ERROR_TYPES;

const ParentalControlBlocked = ({
	error: { message, errorCode = '' },
	configCallback
}) => (
	<Player.Error>
		<Player.ErrorContent>
			<Player.IconLock />
			<Player.ErrorHeading>{message}</Player.ErrorHeading>
			{errorCode === BLOCKED_BY_PARENTAL_CONTROL && (
				<ParentalControlButton configCallback={configCallback} />
			)}
		</Player.ErrorContent>
	</Player.Error>
);

ParentalControlBlocked.propTypes = {
	error: PropTypes.shape({
		message: PropTypes.string.isRequired,
		errorCode: PropTypes.string.isRequired
	}).isRequired,
	configCallback: PropTypes.func.isRequired
};

export default ParentalControlBlocked;
