import { PRODUCTS_TYPES } from 'helpers/variables';
const { VOD, AUDIO, ALL, ARTICLE, LIVE_EVENT } = PRODUCTS_TYPES;

// show genres selector in 'all products view' (sections and categories)
export const PRODUCTS_WITH_GENRES = [VOD, AUDIO, ALL];

const PRODUCTS = 'products';

const setUrlType = (type) => {
	switch (type) {
		case ARTICLE:
			return ARTICLE;

		case LIVE_EVENT:
			return LIVE_EVENT;

		case ALL:
			return '';

		default:
			return VOD;
	}
};

const addTypeToQuery = (url, type) => {
	if (!url.includes(PRODUCTS)) {
		return `&type=${type}`;
	}
	return '';
};

export const getUrl = ({ sectionId, isPacket, type }) => {
	if (isPacket) return `/products/packet/${sectionId}/products`;
	if (sectionId) return `/sections/${sectionId}/content`;
	return `/products/${setUrlType(type)}`;
};

export const getQueries = ({ filter, url }) => {
	const { offset, limit, order, orderDir, genre, type } = filter;

	let query = `?limit=${limit}&offset=${offset}`;

	const sendGenres = PRODUCTS_WITH_GENRES.includes(type);

	if (order && orderDir) {
		query += `&order=${order}&orderDir=${orderDir}`;
	}
	if (sendGenres && genre && genre !== 'all') {
		query += `&genre=${genre}`;
	}
	if (type !== ALL) {
		if (type === AUDIO || type === VOD) {
			const queryType = addTypeToQuery(url, VOD);
			const isAudio = type === AUDIO ? 1 : 0;
			query += `${queryType}&is_audio=${isAudio}`;
		} else {
			query += `${addTypeToQuery(url, type)}`;
		}
	}

	return query;
};
