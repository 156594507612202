import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDeviceInfo } from 'components/utilities';

export const useMobileNav = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isSearchingOpen, setIsSearchingOpen] = useState(false);

	const { pathname } = useLocation();
	const { isMobileDevice } = useDeviceInfo();

	const closeMenu = () => {
		setIsMenuOpen(false);
	};

	const closeSearching = () => {
		setIsSearchingOpen(false);
	};

	const toggleMenu = () => {
		setIsMenuOpen((prevState) => !prevState);
	};

	const toggleSearching = () => {
		setIsSearchingOpen((prevState) => !prevState);
		closeMenu();
	};

	const applicationRoot = document.getElementById('root');

	const toggleAriaVisibility = useCallback(
		(bool) => applicationRoot.setAttribute('aria-hidden', bool),
		[applicationRoot]
	);

	const handleMenuOpenClass = () => {
		isMobileDevice
			? document.body.classList.add('pfl-menu-opened-mobile')
			: document.body.classList.add('pfl-menu-opened-desktop');
		toggleAriaVisibility(true);
	};

	const handleMenuCloseClass = () => {
		document.body.classList.remove('pfl-menu-opened-desktop');
		document.body.classList.remove('pfl-menu-opened-mobile');
		toggleAriaVisibility(false);
	};

	// Hide mobile nav and search after route change
	useEffect(() => {
		closeMenu();
		setIsSearchingOpen(false);
	}, [pathname]);

	useEffect(() => {
		if (isMenuOpen || isSearchingOpen) {
			handleMenuOpenClass();
		} else {
			handleMenuCloseClass();
		}
		// Toggle mobile nav and search after mobile/desktop change
		return () => {
			handleMenuCloseClass();
		};
		// eslint-disable-next-line
	}, [isMenuOpen, isSearchingOpen, toggleAriaVisibility]);

	return {
		toggleMenu,
		toggleSearching,
		isMenuOpen,
		isSearchingOpen,
		closeMenu,
		closeSearching
	};
};
