import { createGlobalStyle, css } from 'styled-components';
import { customMinResponsive, size, transitionMain } from 'components/styles';

// Import styles
import { flex } from 'components/styles';

const notificationStyles = ['success', 'info', 'warning', 'error'].map(
	(type) => css`
		.notification-${type} {
			background-color: ${({ theme }) => theme.notification[type].bg};

			.ant-notification-notice {
				&-message,
				&-description,
				&-icon {
					color: ${({ theme }) => theme.notification[type].color};
				}
			}
		}
	`
);
const GlobalStyle = createGlobalStyle`

  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }

  html {
    font-size: 62.5%;
    background-color: ${({ theme }) => theme.body.bg.primary};
  }

  body {
    font-size: 1.6rem;
    font-family: 'Oswald', sans-serif;
    font-weight: ${({ theme }) => theme.font.medium};
    background-color: ${({ theme }) => theme.body.bg.primary};
  }

  a:hover {
    color: ${({ theme: { general } }) => general.linkHover}
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  ul, p {
    margin-bottom: 0;
  }

  svg {
    pointer-events: none;
  }


  .ant-notification-notice {
    &-message {
      margin-bottom: 0 !important;
    }

  }

  /* Notifications styles for different states | 'success' | 'info' | 'warning' | 'error'  */

  ${notificationStyles}
 
  /* Dropdown */
  .ant-dropdown-menu {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.bg}
  }

  .ant-dropdown-menu-item-divider, .ant-dropdown-menu-submenu-title-divider {
    background-color: ${({ theme }) => theme.line};
  }


  /* Notification menu on Popover and Program detail page */
  .ant-menu {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.bg}
  }

  .ant-menu-vertical {
    border-right: none;
  }

  .ant-menu-item {
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};

    &.ant-menu-item-active {
      background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemHoverBg};
      color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;

    &:hover {
      background-color: ${({ theme: { dropdown } }) =>
				dropdown.menu.itemHoverBg};
    }
  }


  /* Popover */
  .ant-popover-inner-content {
    padding: 0 !important;
  }

  .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
    border-bottom-color: ${({ theme: { popover } }) => popover.bg};
    border-left-color: ${({ theme: { popover } }) => popover.bg};
  }

  .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
    border-top-color: ${({ theme: { popover } }) => popover.bg};
    border-right-color: ${({ theme: { popover } }) => popover.bg};
  }

  /* Select Languages */
  .language-dropdown {
		border:none!important;

    & li {
      border-bottom: 1px solid transparent!important;    
    }
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      /* background-color: white!important; */
      background-color: ${({ theme: { body } }) => body.nav.bgColor}!important;
      &:hover {
        color:  ${({ theme: { body } }) => body.nav.hoverSecondary}!important;
			  background:  ${({ theme: { body } }) => body.nav.focus}!important;
		}
    }
	}

  /* Select */
  .ant-select {
    height: 4.4rem;
    width: 100%;
    color: ${({ theme: { dropdown } }) => dropdown.textColor};
    font-size: 1.4rem;
    font-weight: ${({ theme }) => theme.font.normal};
    user-select: none;
    background: ${({ theme: { dropdown } }) => dropdown.bg};

    .ant-select-selection {
      background-color: transparent;
      border: 1px solid ${({ theme: { dropdown } }) => dropdown.borderColor};
      border-radius: ${({ theme: { dropdown } }) => dropdown.borderRadius}rem;
    }

    .ant-select-selection--single {
      height: 100%;
      line-height: 1;

      &:focus,
			&:hover {
        border-color: ${({ theme: { dropdown } }) => dropdown.borderHoverColor};
      }
    }

    .ant-select-selection__rendered {
      position: relative;
      height: 100%;
      margin-right: 2.1rem;

      &::before {
        content: '';
        position: absolute;
        right: 2.1rem;
        height: 3.2rem;
        margin: 0.4rem 0;
        width: 1px;
        background-color: ${({ theme: { dropdown } }) => dropdown.bg};
      }
    }


    .ant-select-arrow {
      color: ${({ theme: { dropdown } }) => dropdown.arrowColor};
      right: 1.65rem;
      ${transitionMain}
    }

    &.ant-select-open .ant-select-selection {
      border-color: ${({ theme: { dropdown } }) => dropdown.borderColor};
      box-shadow: none;
    }

    &.ant-select-open .ant-select-arrow-icon svg {
      transform: none;
    }

    &.ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      border-color: ${({ theme: { dropdown } }) => dropdown.borderActiveColor};
      box-shadow: none;
    }

    &:hover,
    &.ant-select-open {
      color: ${({ theme: { dropdown } }) => dropdown.textColor};

      .ant-select-arrow {
        color: ${({ theme: { dropdown } }) => dropdown.arrowHoverColor};
      }
    }
  }


  &.ant-select-selection-selected-value {
    line-height: 4rem;
    max-width: calc(100% - 3rem);
  }

  .ant-select-dropdown {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.bg};
    border: 1px solid ${({ theme: { dropdown } }) => dropdown.menu.borderColor};
    border-radius: ${({ theme: { dropdown } }) =>
			dropdown.menu.borderRadius}rem;
    z-index: 3; // select dropdown overflowed nav dropdown - take that to account when modifying
  }

  .ant-select-dropdown-menu-item {
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};
    padding: 1.2rem 2rem;

    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme: { dropdown } }) =>
				dropdown.menu.borderColor};;
    }
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${({ theme: { dropdown } }) =>
			dropdown.menu.itemHoverBg} !important;
  }

  .ant-select-dropdown-menu-item-active {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverBg};
    color: ${({ theme: { dropdown } }) =>
			dropdown.menu.itemHoverColor} !important;
  }

  .ant-select-dropdown-menu-item {
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverBg};
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
  }

  .ant-select-dropdown-menu-item-selected:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${({ theme: { dropdown } }) =>
			dropdown.menu.itemActiveBg};
    color: ${({ theme: { dropdown } }) => dropdown.menu.itemActiveColor};
  }

  .ant-select-dropdown-menu-item-group-title {
    color: ${({ theme }) => theme.white};
    height: 4.6rem;
    padding-left: 2rem;
    font-weight: ${({ theme }) => theme.font.normal};
    font-size: 1.4rem;
    ${flex({ jc: 'flex-start' })} 
  }

  .ant-empty-image {
    display: none !important;
  }

  .ant-empty-description {
    color: ${({ theme: { white } }) => white}
  }

  /* Calendar */
  .ant-calendar-input-wrap {
    display: none;
  }

  .ant-calendar-header a:hover {
    &::before, &::after {
      color: ${({ theme }) => theme.black};
      border-color: ${({ theme }) => theme.black} !important;
    }
  }

  .ant-calendar-year-select,
  .ant-calendar-month-select {
    pointer-events: none;
  }

  .ant-calendar-ym-select {
    display: flex;
    justify-content: center;
  }

  .ant-calendar-year-select {
    order: 2;
  }

  .ant-calendar-month-select {
    order: 1;
  }

  .ant-calendar {
    background-color: ${({ theme }) => theme.calendar.bg};
    border: 1px solid ${({ theme }) => theme.calendar.border};
  }

  .ant-calendar-date {
    color: ${({ theme }) => theme.calendar.calendarColor} !important;
  }

  .ant-calendar-prev-month-btn,
  .ant-calendar-prev-year-btn,
  .ant-calendar-next-month-btn,
  .ant-calendar-next-year-btn,
  .ant-calendar-year-select,
  .ant-calendar-month-select,
  .ant-calendar-column-header,
  .ant-calendar-cell,
  .ant-calendar-last-month-cell {
    color: ${({ theme }) => theme.calendar.dayNameColor} !important;
    
    &:hover {
      color: ${({ theme }) => theme.calendar.dayNameHoverColor};
      &::before, &::after {
        color: ${({ theme }) => theme.calendar.dayNameHoverColor};
      }
    }
  }

  .ant-calendar-header .ant-calendar-prev-month-btn:hover::before, .ant-calendar-header .ant-calendar-prev-month-btn:hover::after, .ant-calendar-header .ant-calendar-next-month-btn:hover::before, .ant-calendar-header .ant-calendar-next-month-btn:hover::after {
    border-color: ${({ theme }) => theme.black} !important;
  }

  .ant-calendar-header, .ant-calendar-footer {
    border: 1px solid transparent !important;
  }

  .ant-calendar-footer {
    padding-bottom: 1rem;
  }

  .ant-calendar-disabled-cell .ant-calendar-date {
    background: ${({ theme }) => theme.globalButtons.primary.bgColor};
  }

  .ant-calendar-date {
    color: ${({ theme }) => theme.calendar.dayColor};
  }

  .ant-calendar-date:hover {
    color: ${({ theme }) => theme.calendar.dayHoverColor};
    background: ${({ theme }) => theme.calendar.dayHoverBg};;
  }

  .ant-calendar-cell .ant-calendar-selected-date .ant-calendar-selected-day {
    .ant-calendar-selected-day .ant-calendar-date {
      background: transparent !important;
    }
  }

  .ant-calendar-today {
    .ant-calendar-date {
      background:  ${({ theme: { calendar } }) => calendar.todayBg} !important;
      border: 1px solid ${({ theme: { calendar } }) => calendar.todayBorder};
      color: ${({ theme }) => theme.calendar.todayColor}!important;
    }
  }


  .ant-calendar-selected-day .ant-calendar-date {
    background: ${({ theme: { calendar } }) => calendar.selectedDayBg};
    color: ${({ theme: { calendar } }) => calendar.selectedDayColor};
    border: 1px solid ${({ theme }) => theme.blue};
  }

  .ant-calendar-selected-date {
    .ant-calendar-date {
      color: ${({ theme }) => theme.calendar.selectedDayColor} !important;
      border: 1px solid ${({ theme }) => theme.transparent} !important;
      font-weight: ${({ theme }) => theme.font.medium} !important;
    }

  }

  .ant-calendar-selected-day.ant-calendar-disabled-cell {
    .ant-calendar-date {
      background: ${({ theme }) =>
				theme.globalButtons.primary.bgColor} !important;
      font-weight: inherit !important;
      border: 1px solid transparent !important;

      &:hover {
        background: transparent !important;
      }

      &::before {
        display: none !important;
      }
    }
  }

  .ant-calendar-today-btn {
    width: 100%;
    font-weight: ${({ theme }) => theme.font.medium};
    background-image: ${({ theme: { globalButtons } }) =>
			globalButtons.primary.bgColor};
    color: ${({ theme: { globalButtons } }) => globalButtons.primary.color};
    border-color: ${({ theme: { globalButtons } }) =>
			globalButtons.primary.borderColor};
    border-radius: 0.4rem;

    &:hover {
      color: ${({ theme: { globalButtons } }) => globalButtons.primary.color};
      box-shadow: 0 0 4px ${({ theme: { globalButtons } }) =>
				globalButtons.primary.borderColor};
    }
  }

  .ant-input:hover, .ant-input:focus {
    border-color: ${({ theme: { input } }) => input.borderColor};
  }

  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: ${({ theme: { input } }) => input.borderColor};
  }

  .ant-calendar-selected-date .ant-calendar-date {
    border: 1px solid ${({ theme: { transparent } }) => transparent} !important
  }

  .ant-calendar-disabled-cell .ant-calendar-date {
    background: ${({ theme }) => theme.calendar.disabledDayBg};
    color: ${({ theme }) => theme.calendar.disabledDayColor};
    cursor: pointer;
  }

  .ant-calendar-disabled-cell .ant-calendar-date:hover {
    background: ${({ theme }) => theme.calendar.disabledDayBgHover};
    color: ${({ theme }) => theme.calendar.disabledDayColorHover};
  }

  .ant-calendar-selected-day.ant-calendar-disabled-cell .ant-calendar-date {
    background: ${({ theme }) => theme.calendar.disabledDayBgHover} !important;
    color: ${({ theme }) => theme.calendar.disabledDayColorHover};
  }

  .ant-calendar-picker:hover .ant-calendar-picker-clear {
    display: none;
  }


  /* Payment pin input style for Safari */
  @media not all and (min-resolution: .001dpcm) {
    @media {
      input.mask {
        font-size: 3.6rem;
        line-height: 1;
        padding-bottom: 0;
      }
    }
  }


  /* React virtualized styles */
  .ReactVirtualized__Collection {
    background-color: ${({ theme }) => theme.externalLib.react_v.collectionBg};
    user-select: none;

    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Collection__innerScrollContainer {
    ${customMinResponsive(
			size.medium,
			css`
				position: relative;
			`
		)};

    &:focus {
      outline: none;
    }

    &::after {
      content: '';
      display: block;
      height: 100%;
      width: 2px;
      position: absolute;
      background-color: ${({ theme }) => theme.epg.nowBarBg};
      top: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  .ReactVirtualized__List {
    overflow: hidden !important;
  }

  .ReactVirtualized__List::-webkit-scrollbar-track {
    background-color: white !important;
  }

  .ReactVirtualized__List::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  .ReactVirtualized__Grid, .ReactVirtualized__Grid__innerScrollContainer {
    &:focus {
      outline: none;
    }
  }


  /* Player sidebard list */
  .player-sidebar-list::-webkit-scrollbar-track, .ReactVirtualized__Collection::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .player-sidebar-list::-webkit-scrollbar, .ReactVirtualized__Collection::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent !important;
  }

  .player-sidebar-list, .ReactVirtualized__Collection {
    scrollbar-width: none;
  }

  /* Epg popover arrow style */
  .epg-popover {
    .ant-popover-arrow {
      display: none;
      border-color: ${({ theme }) => theme.popover.bg} !important;
      z-index: -1;
    }
  }

  /* Login and register form tooltips styles */
  .ant-tooltip-inner {
    color: ${({ theme }) => theme.white};
    border: 0.5px solid ${({ theme: { authentication } }) =>
			authentication.tooltip.border};
    background-color: ${({ theme: { authentication } }) =>
			authentication.tooltip.background};
  }
  /* Reach UI - menu button */
    [data-reach-menu] {
      z-index: 9999;
    }
    [data-reach-menu-button]{
      cursor: pointer;
    }
    [data-reach-menu-item][data-selected] {
      background: none;
      color: ${({ theme }) => theme.dropdown.submenu.textColorOnHover};

      &::after {
        transform: scaleX(1);
		}
  };

  .pfl-menu-opened-desktop {
    overflow: hidden;
    padding-right:15px;
  }

  .pfl-menu-opened-mobile {
    overflow: hidden;
  }
`;

export default GlobalStyle;
