import { useState } from 'react';

/**
 * Small utility hook to toggle UI elements
 * @param {bool} defaultValue - set default visibility = ON / OFF
 * @returns {array} isModalOpened - state property, toggle - toggle ON / OFF function
 */
const useToggle = (initial = false) => {
	const [isOpened, setIsOpened] = useState(initial);

	const toggle = () => setIsOpened((prev) => !prev);

	return [isOpened, toggle];
};

export default useToggle;
