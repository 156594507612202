import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	institution: {
		uuid: '',
		contact: '',
		name: '',
		url: '',
		assets: []
	},
	institutions: [],
	isLoading: false,
	isError: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.FETCH_INSTITUTIONS_LIST_LOADING:
				draft.institutions = [];
				draft.isLoading = true;
				draft.isError = false;
				break;

			case types.FETCH_INSTITUTIONS_LIST_SUCCESS:
				draft.institutions = action.payload;
				draft.isLoading = false;
				draft.isError = false;
				break;

			case types.FETCH_INSTITUTIONS_LIST_ERROR:
				draft.institutions = [];
				draft.isLoading = false;
				draft.isError = true;
				break;

			case types.CLEAR_INSTITUTIONS_LIST:
				draft.institutions = [];
				draft.isLoading = false;
				draft.isError = false;
				break;

			case types.SET_INSTITUTION:
				draft.institution = action.payload;
				break;

			case types.CLEAR_INSTITUTION:
				draft.institution = INITIAL_STATE.institution;
				break;

			default:
				return state;
		}
	});
