import * as types from 'store/actions/types';

const INITIAL_STATE = {
	data: [],
	isLoaded: false,
	error: false
};

export default function(state = INITIAL_STATE, action = {}) {
	switch (action.type) {
		case types.GENRES_LIST_LOADING:
			return {
				...state,
				isLoaded: true,
				error: false
			};
		case types.GENRES_LIST_SUCCESS:
			return {
				...state,
				isLoaded: true,
				error: false,
				data: action.payload
			};
		case types.GENRES_LIST_ERROR:
			return {
				...state,
				isLoaded: true,
				error: true
			};
		default:
			return state;
	}
}
