import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const PlayController = () => {
	const { t } = useTranslation();
	const { isPaused, play, pause } = usePlayerVodContext();

	return isPaused ? (
		<Button
			modifiers={['icon', 'playerIconHover']}
			onClick={play}
			title={t('common_player_play_title')}
		>
			<Player.IconPlay
				title={t('common_player_play_title')}
				aria-hidden="true"
			/>
		</Button>
	) : (
		<Button
			modifiers={['icon', 'playerIconHover']}
			onClick={pause}
			title={t('common_player_pause_title')}
		>
			<Player.IconPause
				aria-hidden="true"
				title={t('common_player_pause_title')}
			/>
		</Button>
	);
};

export default PlayController;
