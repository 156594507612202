import styled, { css } from 'styled-components';
import { size, customResponsive } from 'components/styles';

export const BannersSliderWrap = styled.div`
	.slick-slider {
		.button {
			height: 100%;
			bottom: 0;
		}
	}
`;
export const InnerWrapper = styled.div`
	${customResponsive(
		size.medium,
		css`
			flex-direction: column;
			gap: 0.8rem;
			max-width: 100%;
			text-align: center;
		`
	)}

	display: flex;
	width: 100%;
	justify-content: center;
	gap: 2.4rem;
	align-items: center;
`;
export const ContentWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.white};
`;

export const Item = styled.div`
	position: relative;
	display: block !important;
`;

export const Figure = styled.figure`
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0;
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};

	${({ theme, isPlaceholder }) =>
		isPlaceholder &&
		css`
			object-fit: contain;
			background-color: ${theme.lightBlack};
		`}
`;

export const Caption = styled.figcaption`
	display: none;
`;

export const BannerItemContent = styled.div`
	line-height: 2rem;
	font-family: 'TerminaBold', sans-serif;
	font-weight: bold;
	${customResponsive(
		size.medium,
		css`
			line-height: 1rem;
			font-size: 12px;
		`
	)}
`;
