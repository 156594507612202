import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { LastLocationProvider } from 'react-router-last-location';
import { ReactQueryDevtools } from 'react-query/devtools';

// Import components
import App from 'components/views/App';

// Import react redux
import { Provider } from 'react-redux';

// Import store
import store from './store/store';

const queryCache = new QueryCache();

const overrides = {
	queryCache,
	defaultOptions: {
		queries: {
			retry: 0,
			refetchOnWindowFocus: false
		}
	}
};

const queryClient = new QueryClient(overrides);

const isDevMode = process.env.NODE_ENV !== 'development';

const Root = () => (
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			{isDevMode && <ReactQueryDevtools />}
			<BrowserRouter>
				<LastLocationProvider>
					<App />
				</LastLocationProvider>
			</BrowserRouter>
		</QueryClientProvider>
	</Provider>
);

export default Root;
