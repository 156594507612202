import * as types from 'store/actions/types';
import services from 'services/services';

// ******************** FETCH SEARCH QUERY ********************
export const fetchSearchQuery =
	(query, results = 100) =>
	async (dispatch) => {
		// const encodedQuery = encodeURIComponent(query);
		// console.log({ encodedQuery, query });
		try {
			// Dispatch an loading action
			dispatch({
				type: types.FETCH_SEARCH_QUERY_LOADING,
				payload: { query }
			});

			// Get tv categories
			const { data } = await services.get(
				`/products/search?limit=${results}&offset=0&q=${query}`
			);

			// Dispatch an action
			dispatch({
				type: types.FETCH_SEARCH_QUERY_SUCCESS,
				payload: { items: data.data }
			});
		} catch (error) {
			// Dispatch an error action
			dispatch({
				type: types.FETCH_SEARCH_QUERY_ERROR,
				payload: true
			});
		}
	};
