import React, { useRef } from 'react';
import { bool } from 'prop-types';

// Import components
import Nav from 'components/layout/nav/Nav';
import MobileNav from '../nav/nav_mobile/MobileNav';
import TabNav from 'components/layout/tab_nav';
// Import styled components
import { StyledHeader, InnerWrapper } from './styles';

// Import hooks

import { useIntersectionObserver } from './useIntersection';

const Header = ({ showMainNav }) => {
	const target = useRef(null);

	const { entry } = useIntersectionObserver(target, {
		threshold: 1,
		rootMargin: '-5px'
	});

	const intersection = !entry?.isIntersecting;

	return (
		<StyledHeader>
			<TabNav />
			{showMainNav && (
				<InnerWrapper>
					<MobileNav intersection={intersection} />
					<Nav ref={target} intersection={intersection} />
				</InnerWrapper>
			)}
		</StyledHeader>
	);
};

Header.propTypes = {
	showMainNav: bool.isRequired
};

export default Header;
