import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TimeBlock } from './styles';

export const TimeBlockWithFixedWidth = ({ children }) => {
	const blockRef = useRef(null);
	const [width, setWidth] = useState(null);

	useEffect(() => {
		if (blockRef.current && width === null) {
			setWidth(blockRef.current.offsetWidth);
		}
	}, [width]);

	return (
		<TimeBlock ref={blockRef} style={{ width: width ? `${width}px` : 'auto' }}>
			{children}
		</TimeBlock>
	);
};

TimeBlockWithFixedWidth.propTypes = {
	children: PropTypes.node.isRequired
};

export default TimeBlockWithFixedWidth;
