import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

// Import styled components
import { FormFieldStyled, FormError } from './styles';

const SelectField = ({
	field,
	name,
	form: { touched, errors },
	placeholder,
	children,
	...rest
}) => {
	const handleOnChange = (event) => field.onChange(field.name)(event);
	return (
		<FormFieldStyled>
			<Select
				{...field}
				style={{ width: '100%' }}
				className={`${
					touched[field.name] && errors[field.name] ? 'errors' : ''
				}`}
				name={name}
				placeholder={placeholder}
				onChange={handleOnChange}
				optionFilterProp="children"
				getPopupContainer={(trigger) => trigger.parentNode}
				{...rest}
			>
				{children}
			</Select>
			{touched[field.name] && errors[field.name] && (
				<FormError>{errors[field.name]}</FormError>
			)}
		</FormFieldStyled>
	);
};
SelectField.defaultProps = {
	name: 'rating',
	autoComplete: 'true'
};

SelectField.propTypes = {
	field: PropTypes.object.isRequired,
	form: PropTypes.shape({
		touched: PropTypes.object.isRequired,
		errors: PropTypes.object.isRequired
	}),
	autoComplete: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string
};

export default SelectField;
