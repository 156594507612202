import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

// Import components
import { Alert, Headline, Loader, Static } from 'components/elements';
import HelmetMetaTags from 'components/layout/helmet/HelmetMetaTags';

const StaticContent = ({ isUserProfile }) => {
	const { t } = useTranslation();
	const {
		data: { title, content },
		isError,
		isLoaded
	} = useSelector(({ static_content }) => static_content);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		// eslint-disable-next-line
	}, [isLoaded]);

	if (!isLoaded && !isError && isUserProfile) {
		return <Loader zIndex={900} position="absolute" background={false} />;
	}

	const StaticUserHeading = (
		<Static.UserProfileHeading as="h2">{title}</Static.UserProfileHeading>
	);
	const StaticHeading = (
		<Static.Heading as="h2" modifiers={['primary', 'big']}>
			{title}
		</Static.Heading>
	);

	return (
		<>
			{title && <HelmetMetaTags title={title} />}
			<Headline spaceBetween>
				{isUserProfile ? StaticUserHeading : StaticHeading}
				{/* DISABLE PDF DOWNLOAD FOR FIRST MAJOR PROD RELEASE */}
				{/* {isLoaded && <DocumentDownload title={title} content={content} />} */}
			</Headline>
			<Static.Content>
				{isError && <Alert text={t('common_errors_default_error')} />}

				{ReactHtmlParser(content)}
			</Static.Content>
		</>
	);
};

StaticContent.defaultProps = {
	isUserProfile: false
};

StaticContent.propTypes = {
	isUserProfile: bool
};

export default StaticContent;
