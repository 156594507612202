import * as types from 'store/actions/types';

// IMPORT HELPERS
import { PLAYER_SELECTORS } from 'helpers/variables';
import { getCodeErrorMessage } from 'helpers/error_code_api';

export const setActionType = (type) => {
	switch (type) {
		case PLAYER_SELECTORS.DETAILS:
			return {
				loadingType: types.FETCH_PLAYER_DETAILS_CONFIG_LOADING,
				successType: types.FETCH_PLAYER_DETAILS_CONFIG_SUCCESS,
				errorType: types.FETCH_PLAYER_DETAILS_CONFIG_ERROR,
				deleteLoadingType: types.PLAYER_DETAILS_DELETE_VIDEO_SESSION_LOADING,
				deleteSuccessType: types.PLAYER_DETAILS_DELETE_VIDEO_SESSION_SUCCESS,
				deleteErrorType: types.PLAYER_DETAILS_DELETE_VIDEO_SESSION_ERROR,
				playerErrorType: types.PLAYER_DETAILS_SET_PLAYBACK_ERROR,
				updateVideoSessionType:
					types.PLAYER_DETAILS_UPDATE_VIDEO_SESSION_SUCCESS,
				readyType: types.PLAYER_DETAILS_IS_READY,
				clearPlaybackError: types.PLAYER_DETAILS_CLEAR_PLAYBACK_ERROR
			};

		case PLAYER_SELECTORS.VOD:
			return {
				loadingType: types.FETCH_PLAYER_VOD_PREVIEW_CONFIG_LOADING,
				successType: types.FETCH_PLAYER_VOD_PREVIEW_CONFIG_SUCCESS,
				errorType: types.FETCH_PLAYER_VOD_PREVIEW_CONFIG_ERROR,
				deleteLoadingType:
					types.PLAYER_VOD_PREVIEW_DELETE_VIDEO_SESSION_LOADING,
				deleteSuccessType:
					types.PLAYER_VOD_PREVIEW_DELETE_VIDEO_SESSION_SUCCESS,
				deleteErrorType: types.PLAYER_VOD_PREVIEW_DELETE_VIDEO_SESSION_ERROR,
				playerErrorType: types.PLAYER_VOD_PREVIEW_SET_PLAYBACK_ERROR,
				updateVideoSessionType:
					types.PLAYER_VOD_PREVIEW_UPDATE_VIDEO_SESSION_SUCCESS,
				readyType: types.PLAYER_VOD_PREVIEW_IS_READY,
				clearPlaybackError: types.PLAYER_VOD_PREVIEW_CLEAR_PLAYBACK_ERROR
			};

		default:
			return {
				loadingType: types.FETCH_PLAYER_CONFIG_LOADING,
				successType: types.FETCH_PLAYER_CONFIG_SUCCESS,
				errorType: types.FETCH_PLAYER_CONFIG_ERROR,
				deleteLoadingType: types.PLAYER_DELETE_VIDEO_SESSION_LOADING,
				deleteSuccessType: types.PLAYER_DELETE_VIDEO_SESSION_SUCCESS,
				deleteErrorType: types.PLAYER_DELETE_VIDEO_SESSION_ERROR,
				playerErrorType: types.PLAYER_SET_PLAYBACK_ERROR,
				updateVideoSessionType: types.PLAYER_UPDATE_VIDEO_SESSION_SUCCESS,
				readyType: types.PLAYER_IS_READY,
				clearPlaybackError: types.PLAYER_CLEAR_PLAYBACK_ERROR
			};
	}
};

export const getErrorCodeMessage = (type, error) => {
	const { errorType } = setActionType(type);
	let { code, message, errorCode, status } = error.response.data;
	// Find error message
	const codeErrorMessage = getCodeErrorMessage(errorCode) || message;
	return { codeErrorMessage, errorType, code, errorCode, status };
};

export const setPlayerUrlConfiguration = ({
	productID,
	isCatchup,
	programID,
	videoID,
	videoType
}) => {
	const catchupProgram = isCatchup ? `&programId=${programID}` : '';
	const checkVideoID = videoID ? `&videoId=${videoID}` : '';

	return `/player/product/${productID}/configuration?type=${videoType}${catchupProgram}${checkVideoID}`;
};

export const setTrackingConfiguration = (tracking) => ({
	customerId: tracking.customerId,
	collector: tracking.url,
	productId: tracking.contentId,
	subscriberId: tracking.subscriberId,
	sessionId: tracking.sessionId,
	customData: tracking.customData
});

export const setConfig = (config) => {
	if (config.tracking) {
		config.tracking = setTrackingConfiguration(config.tracking);
	}

	return config;
};

export const getAvailableChannel = (programs) =>
	programs.lives.find(
		({ currentLiveIndex, noPrograms, subscriberLocalLimited }) =>
			currentLiveIndex >= 0 && !noPrograms && !subscriberLocalLimited
	);
