import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const Packets = () => {
	const { t } = useTranslation();

	const {
		floatMode: { isFloatMode }
	} = usePlayerVodContext();

	return (
		<Wrapper isFloatMode={isFloatMode}>
			<Paragraph isFloatMode={isFloatMode}>
				{t('player_error_resources_not_in_products')}
			</Paragraph>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	margin: 0rem auto;
	width: ${({ isFloatMode }) => (isFloatMode ? 'auto' : '61rem')};
`;

const Paragraph = styled.p`
	text-align: center;
	letter-spacing: 0.63px;
	${({ isFloatMode }) => (isFloatMode ? '1.3rem' : '2rem')};
	font-weight: ${({ theme }) => theme.font.light};
	color: ${({ theme }) => theme.white};
	opacity: 0.4;
`;

export default Packets;
