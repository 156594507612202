import React from 'react';

// Import components
import { FloatWidget } from 'components/elements';
import FloatPlayer from './FloatPlayer';

const FloatPlayerWidget = () => (
	<FloatWidget>
		<FloatPlayer />
	</FloatWidget>
);

export default FloatPlayerWidget;
