import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedPriceLimit } from 'store/actions';

// Import utilities
import { useLowestPrice } from 'components/utilities';

// Import helpers
import {
	PRODUCT_LIMIT_TYPES,
	getRenderAsLink,
	checkProductSubtype,
	getPaymentLink
} from 'helpers';
import { isNumber } from 'helpers/prices';
import { getPaymentButtonTranslation } from '../helpers';

// Import utilities
import {
	useTheme,
	useProductPaymentStatus,
	useProductLimits
} from 'components/utilities';

const { PURCHASES } = PRODUCT_LIMIT_TYPES;

const usePaymentButton = ({ product, isSubscriptionUpdate, provider }) => {
	const dispatch = useDispatch();

	const { subtype, uuid, metadata, title } = product;

	const productName = metadata?.title || title;

	// product payment pending
	const { isPaymentPending, isLoading } = useProductPaymentStatus({ uuid });

	// product payment limit
	const { isLimitExceeded, limitMessage } = useProductLimits({
		product,
		limitType: PURCHASES
	});

	const theme = useTheme();

	const { regular, promotion, priceId, priceLimit, periodWithUnit } =
		useLowestPrice({ product });

	const isAvailable = isNumber(regular);

	const isDisabled = isLoading || isPaymentPending || isLimitExceeded;

	const { isPacket } = checkProductSubtype(subtype);

	const periodText = isPacket ? `/ ${periodWithUnit}` : '';

	const redirectData = getPaymentLink({
		id: uuid,
		type: subtype,
		title: productName,
		priceId,
		isSubscriptionUpdate,
		provider
	});

	const buttonText = getPaymentButtonTranslation({
		subtype,
		isSubscriptionUpdate
	});

	const isLink = !isLimitExceeded && !isLoading;

	const renderAs = getRenderAsLink(isLink);

	const hidePaymentButton = !!isSubscriptionUpdate;

	useEffect(() => {
		if (priceLimit) {
			setSelectedPriceLimit(priceLimit)(dispatch);
		}
		// eslint-disable-next-line
	}, [priceLimit]);

	return {
		theme,
		regular,
		promotion,
		redirectData,
		buttonText,
		isAvailable,
		isDisabled,
		isPaymentPending,
		isLoading,
		isLimitExceeded,
		hidePaymentButton,
		limitMessage,
		renderAs,
		periodText
	};
};

export default usePaymentButton;
