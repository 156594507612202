import React from 'react';
import { func, string, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import styled components
import {
	Wrapper,
	StyledLink,
	ProductTitle,
	ProductType,
	TrashIconStyled,
	ButtonStyled
} from '../../common_styles.js';

// Import logic
import useFavourite from './useFavourite';

const Favourite = ({ favourite, toggle }) => {
	const { t } = useTranslation();
	const { link, deleteFavourite, productAlias } = useFavourite({
		favourite
	});
	const { title } = favourite;

	return (
		<Wrapper>
			<StyledLink to={link} onClick={toggle}>
				{title && <ProductTitle>{title}</ProductTitle>}
				<ProductType>{productAlias}</ProductType>
			</StyledLink>
			<ButtonStyled
				onClick={deleteFavourite}
				aria-label={t('aria_product_remove')}
				title={t('aria_product_remove')}
			>
				<TrashIconStyled aria-hidden="true" />
			</ButtonStyled>
		</Wrapper>
	);
};

Favourite.propTypes = {
	toggle: func.isRequired,
	favourite: shape({
		uuid: string.isRequired,
		subtype: string.isRequired,
		title: string
	})
};

export default Favourite;
