import styled from 'styled-components';

// Import components
import { ReactTable } from 'components/elements';

// CSS classes available on React Table
// blueonline-table
// blueonline-table__head
// blueonline-table__row
// blueonline-table__head-row
// blueonline-table__header
// blueonline-table__body
// blueonline-table__body-row
// blueonline-table__cell

export const StyledTable = styled(ReactTable)`
	width: 100%;

	.blueonline-table {
		&__row {
			border-bottom: 1px solid ${({ theme }) => theme.line};
		}

		&__header {
			padding-bottom: 1.2rem;
			color: ${({ theme: { user } }) => user.topHeading};
			font-size: ${({ theme }) => theme.fontSize.user.table.header}rem;
			font-weight: ${({ theme }) => theme.font.medium};
			text-align: left;
		}

		&__cell {
			padding: 3rem 1rem 3rem 0;
			font-size: ${({ theme }) => theme.fontSize.user.table.cell}rem;
			font-weight: ${({ theme }) => theme.font.medium};
			color: ${({ theme }) => theme.user.tableItemValue};
		}
	}
`;
