import styled, { css } from 'styled-components';

export const BannersSliderWrap = styled.div`
	margin-bottom: 4rem;

	.slick-slider {
		.button {
			height: 100%;
			bottom: 0;
		}
	}
`;

export const Item = styled.div`
	display: block !important;
`;

export const Figure = styled.figure`
	width: 100%;
	height: 100%;
	height: ${({ bannerHeight }) => bannerHeight}px;
	position: relative;
	margin: 0;
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};

	${({ theme, isPlaceholder }) =>
		isPlaceholder &&
		css`
			object-fit: contain;
			background-color: ${theme.lightBlack};
		`}
`;

export const Caption = styled.figcaption`
	display: none;
`;
