import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { LOCALSTORAGE_USER } from 'helpers/variables';
import {
	machtChannelsEpg,
	formatChannels,
	formatChannelsByLocal,
	setTvProgramsTime,
	convertParamsDateFormat,
	updateCurrentLiveProgram
} from '../../../helpers/epg';
import { CHANNEL_OFFSET_LIMIT } from 'helpers/variables';

// ******************** FETCH TV PROGRAMMS ********************
export const fetchTvPrograms =
	({ type = 'channel', isLoaded = false, isAuth = true } = {}) =>
	async (dispatch) => {
		try {
			// Get program time, start , end
			const { since, till, isToday } = setTvProgramsTime();

			// Dispatch an loading action
			isLoaded
				? dispatch({
						type: types.FETCH_TV_PROGRAMS_REFRESH_LOADING,
						payload: { since, till, isToday }
				  })
				: dispatch({
						type: types.FETCH_TV_PROGRAMS_LOADING,
						payload: isLoaded
				  });

			// Get tiemstamps
			const startDate = convertParamsDateFormat(since);
			const endDate = convertParamsDateFormat(till);

			const {
				data: { data: channels }
			} = await services.get(`/products/${type}?${CHANNEL_OFFSET_LIMIT}`);

			let subscriberProducts = [];
			if (isAuth) {
				const {
					data: { data }
				} = await services.get(`/subscriber/products/uuids`);
				subscriberProducts = data;
			}

			// Get user from local storage
			const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));

			const subscriber_local_active = user?.subscriber_local_active;

			dispatch({
				type: types.SET_EPG_CHANNELS,
				payload: {
					lives: formatChannels(
						channels,
						subscriberProducts,
						subscriber_local_active
					),
					now: since
				}
			});

			// Fetch programs
			const { data: epg } = await services.get(
				`/epg?startDate=${startDate}&endDate=${endDate}`
			);

			// Format channels, sort chnnels to avaiable and unavaiable
			const formatedChannels = formatChannels(
				channels,
				subscriberProducts,
				subscriber_local_active
			);
			// Match epg to channel
			const matchedChannelsEpg = machtChannelsEpg(formatedChannels, epg);

			// Dispatch an action
			dispatch({
				type: types.FETCH_TV_PROGRAMS_SUCCESS,
				payload: {
					data: { now: since, lives: matchedChannelsEpg },
					since,
					till,
					isToday
				}
			});
		} catch (error) {
			// Dispatch an error action
			dispatch({
				type: types.FETCH_TV_PROGRAMS_ERROR,
				payload: true
			});
		}
	};

export const setChannelsByLocal = () => (dispatch, getState) => {
	// Get user from local storage
	const { subscriber_local_active } = JSON.parse(
		localStorage.getItem(LOCALSTORAGE_USER)
	);
	const {
		channels: {
			data: { lives }
		}
	} = getState();

	const channelsByLocale = formatChannelsByLocal(
		lives,
		subscriber_local_active
	);

	// Dispatch an action
	dispatch({
		type: types.SET_TV_PROGRAMS_IN_LOCALE,
		payload: channelsByLocale
	});
};

export const setTvProgramsDate = (date = null) => {
	const { since, till, isToday } = setTvProgramsTime(date);

	return {
		type: types.SET_TV_PROGRAMS_DATE,
		payload: { since, till, isToday }
	};
};

export const setTvProgramsLive = (channelId) => ({
	type: types.SET_TV_PROGRAM_LIVE,
	payload: channelId
});

export const updateCurrentLives = () => (dispatch, getState) => {
	const {
		channels: {
			data: { lives }
		}
	} = getState();

	if (lives) {
		const updatedLives = updateCurrentLiveProgram(lives);

		// Dispatch an action
		dispatch({
			type: types.WAKE_UP_UPDATE_CURRENT_TV_PROGRAMS,
			payload: updatedLives
		});
	}
};
