import React, { createContext } from 'react';

export const PlayerSidebarContext = createContext();

const PlayerSidebarProvider = ({ children }) => {
	const defaultCategory = 'all';

	const [category, setCategory] = React.useState(defaultCategory);
	const [query, setQuery] = React.useState('');

	const handleSetCategory = (category = defaultCategory) =>
		setCategory(category);

	const handleSetQuery = (query = '') => setQuery(query);

	return (
		<PlayerSidebarContext.Provider
			value={{
				category,
				setCategory: handleSetCategory,
				query,
				setQuery: handleSetQuery
			}}
		>
			{children}
		</PlayerSidebarContext.Provider>
	);
};

export default PlayerSidebarProvider;
