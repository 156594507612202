import React from 'react';
import { string, func, number } from 'prop-types';

// Import components
import { useLogic } from './useLogic';
import PlayerWrapper from 'components/elements/organisms/player_vod/PlayerWrapper';

const PlayerPreview = React.memo(
	({ toggle, ...restProps }) => {
		const data = useLogic(restProps);

		return <PlayerWrapper isPreview toggle={toggle} {...data} />;
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

PlayerPreview.propTypes = {
	uuid: string.isRequired,
	startStopCarousel: func.isRequired,
	toggle: func.isRequired,
	videoID: number.isRequired
};

export default PlayerPreview;
