import { useRef, useEffect } from 'react';

// Import utilities
import { useCarousel } from 'components/utilities';

const useHighBanners = ({ content }) => {
	const sliderRef = useRef(null);

	const { currentIndex, changeCurrentIndex } = useCarousel({ content });

	useEffect(() => {
		if (sliderRef.current) {
			sliderRef.current.slickGoTo(currentIndex);
		}
		// eslint-disable-next-line
	}, [currentIndex]);

	return {
		sliderRef,
		currentIndex,
		changeCurrentIndex
	};
};

export default useHighBanners;
