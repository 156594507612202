import styled, { css } from 'styled-components';
import { customResponsive, size } from 'components/styles';

//Import styles

export const Wrapper = styled.main`
	flex: 1;
	display: flex;
	flex-direction: column;

	${({ isEPG }) =>
		!isEPG &&
		css`
			min-height: 100vh;
		`};

	${customResponsive(
		size.medium,
		css`
			min-height: ${({ isEPG }) => (isEPG ? '100vh' : '60vh')};
		`
	)}
`;
