import React from 'react';
import {
	number,
	func,
	bool,
	string,
	object,
	oneOfType,
	arrayOf,
	node,
	array,
	shape
} from 'prop-types';

// Import utilities
import { useProductAvailability } from 'components/utilities';

// Import components
import SlideImage from './SlideImage';
import TilePromotedIcon from 'components/elements/molecules/product_items_grid/TilePromotedIcon';
import { AuthModal } from 'components/elements';
// Import styles
import {
	StyledButton,
	Title,
	Description,
	AvailabilityDate,
	IconTickCircle
} from 'components/elements/molecules/slider/SliderItem/styles';

// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';
import { checkProductSubtype, getRenderAsLink } from 'helpers';

// Import variables
import {
	PRODUCT_IMAGES_TYPES,
	SECTIONS_TYPES,
	PRODUCTS_TYPES
} from 'helpers/variables';

const { COVER, LOGO } = PRODUCT_IMAGES_TYPES;
const { CAROUSEL } = SECTIONS_TYPES;
const { CHANNEL } = PRODUCTS_TYPES;

const BasicSlide = ({
	slideWidth,
	slideHeight,
	spaceBetweenSlides,
	onItemClick,
	renderSlideAsLink,
	index,
	title,
	description,
	linkParams,
	product: { uuid, available_in, subtype, images, type, context },
	availabilityDate,
	sectionType
}) => {
	const imageType = type === CHANNEL ? LOGO : COVER;
	const image = getImage({ images, type: imageType });
	const { isBanner, isChannel } = checkProductSubtype(subtype);
	const isAnchor = isBanner && sectionType !== CAROUSEL;
	const renderAs = getRenderAsLink(renderSlideAsLink, isAnchor);

	const handleItemClick = () => onItemClick(index);

	const isAvailable = useProductAvailability(uuid, available_in, subtype);

	const promotionType = context?.promoted_product;
	const isPromoted = !!promotionType;
	const ShouldAuthorize = isChannel ? AuthModal : React.Fragment;

	return (
		<ShouldAuthorize>
			<StyledButton
				as={renderAs}
				to={linkParams}
				href={linkParams}
				onClick={handleItemClick}
				marginright={spaceBetweenSlides}
				width={slideWidth}
				height={slideHeight}
				title={title}
			>
				{isAvailable && <IconTickCircle aria-hidden="true" />}
				{isPromoted && <TilePromotedIcon promotionType={promotionType} />}
				<SlideImage
					slideHeight={slideHeight}
					src={image ? `${image}=w1920-h1080-c` : ''}
					alt={title}
				/>
				{title && <Title type={sectionType}>{title}</Title>}
				{description && (
					<Description type={sectionType}>{description}</Description>
				)}
				{availabilityDate && (
					<AvailabilityDate>{availabilityDate}</AvailabilityDate>
				)}
			</StyledButton>
		</ShouldAuthorize>
	);
};

BasicSlide.defaultProps = {
	onItemClick: () => {},
	linkParams: '/',
	renderSlideAsLink: false
};

BasicSlide.propTypes = {
	slideWidth: number.isRequired,
	slideHeight: number.isRequired,
	spaceBetweenSlides: number.isRequired,
	onItemClick: func.isRequired,
	renderSlideAsLink: bool,
	index: number.isRequired,
	title: string,
	linkParams: object.isRequired,
	description: oneOfType([arrayOf(node), node, string]),
	product: shape({
		uuid: string.isRequired,
		available_in: array,
		subtype: string.isRequired,
		images: object
	}),
	availabilityDate: string,
	sectionType: string.isRequired
};

export default BasicSlide;
