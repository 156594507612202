import styled, { css } from 'styled-components';

// Import styles
import { size, customResponsive } from 'components/styles';

// Import components
import { Button } from 'components/elements';

export const PlayButton = styled(Button)`
	svg {
		width: ${({ isFloatMode }) => (isFloatMode ? 10 : 15)}rem;
		height: ${({ isFloatMode }) => (isFloatMode ? 10 : 15)}rem;
	}

	${customResponsive(
		size.small,
		css`
			svg {
				width: 8rem;
				height: 8rem;
			}
		`
	)}
`;
