import * as types from 'store/actions/types';
import services from 'services/services';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import { createQueryParams } from 'helpers';

// ******************** FETCH PAYEMENT TYPES ********************
export const fetchPaymentTypes = (productId, period) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PAYMENT_TYPES_LOADING,
			payload: true
		});

		// Get fetch payment type
		const url = `/products/${productId}/prices/${period}`;
		const { data } = await services.get(url);

		// Dispatch an action
		dispatch({
			type: types.FETCH_PAYMENT_TYPES_SUCCESS,
			payload: data
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Dispatch an error action
		dispatch({
			type: types.FETCH_PAYMENT_TYPES_ERROR,
			payload: error
		});
	}
};

// ******************** FETCH PAYEMENT INIT ********************
export const fetchInitPayment =
	({ productId, priceId, promotion, update_subscription = false }) =>
	async (dispatch) => {
		try {
			// Dispatch an loading action
			dispatch({
				type: types.FETCH_PAYMENT_INIT_LOADING
			});

			const query = createQueryParams({
				promotion_id: promotion?.id,
				context: 'out'
			});

			const url = `/payments/${productId}/${priceId}/init${query}`;

			const { data } = await services.post(url, { update_subscription });

			// Dispatch an action
			dispatch({
				type: types.FETCH_PAYMENT_INIT_SUCCESS,
				payload: data
			});

			// return request data for purchase action
			return data;
		} catch (error) {
			if (error.response) {
				const { codeErrorMessage } = getErrorCodeResults(error);

				// Dispatch an error action
				error.response.data.message = codeErrorMessage;
			}

			// Dispatch an error action
			dispatch({
				type: types.FETCH_PAYMENT_INIT_ERROR,
				payload: error
			});
		}
	};

// ******************** FETCH PAYEMENT AGREEMENTS ********************
export const fetchPaymentAgreements = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.FETCH_PAYMENT_AGREEMENTS_LOADING,
			payload: true
		});

		// Get fetch payment type
		const url = `/payments/agreements`;
		const { data } = await services.get(url);

		// Dispatch an action
		dispatch({
			type: types.FETCH_PAYMENT_AGREEMENTS_SUCCESS,
			payload: data
		});
	} catch (error) {
		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
		}

		// Dispatch an error action
		dispatch({
			type: types.FETCH_PAYMENT_AGREEMENTS_ERROR,
			payload: error
		});
	}
};

export const clearPayment = (dispatch) =>
	dispatch({ type: types.PAYMENT_CLEAR });

// set memorized agreements
export const setMemorizedAgreements = (agreements, dispatch) => {
	dispatch({ type: types.SET_MEMORIZED_AGREEMENTS, payload: agreements });
};

// clear memorized agreements
export const clearMemorizedAgreements = (dispatch) => {
	dispatch({ type: types.CLEAR_MEMORIZED_AGREEMENTS });
};
