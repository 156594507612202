import styled, { css } from 'styled-components';

// Import icons
import { ReactComponent as SearchIcon } from 'assets/icons/ic_search.svg';

// Import components
import { Button } from 'components/elements';

// Import styles
import { flex, customMinResponsive, size } from 'components/styles';

export const Wrapper = styled.div`
	${flex()}
	width: 100%;

	${customMinResponsive(
		size.medium,
		css`
			${flex({ ai: 'flex-start' })}
		`
	)};
`;

export const FormStyled = styled.form`
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0 2.1rem;

	${({ showClearButton }) =>
		!showClearButton &&
		css`
			padding-right: 0;
		`}

	${customMinResponsive(
		size.medium,
		css`
			padding: 0;
			padding-left: 3.3rem;
		`
	)};
`;

export const ButtonStyled = styled(Button)`
	padding: 0;
	visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

	svg {
		height: 25px;
		width: 25px;
	}

	${customMinResponsive(
		size.medium,
		css`
			padding: 0 0 0 4.5rem;
		`
	)};
`;

export const SearchIconStyled = styled(SearchIcon)`
	height: ${({ height }) => height}rem;
	width: ${({ width }) => width}rem;
`;

export const InputStyled = styled.input`
	width: 100%;
	border: 0;
	color: ${({ theme }) => theme.search.input.color};
	font-size: ${({ theme }) => theme.fontSize.search.input}rem;
	font-weight: ${({ theme }) => theme.font.medium};
	background-color: ${({ theme }) => theme.search.input.bg};
	border-bottom: 1px solid ${({ theme }) => theme.search.input.border};
	padding-bottom: 0.5rem;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: ${({ theme }) => theme.search.input.placeholderColor};
	}

	${customMinResponsive(
		size.medium,
		css`
			padding-bottom: 1.5rem;
			&:focus,
			&:focus-visible {
				border-bottom: 1px solid
					${({ theme }) => theme.search.input.borderFocus};
			}
		`
	)};

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.search.input.color};
		-webkit-box-shadow: none;
		transition: background-color 5000s ease-in-out 0s;
	}
`;
