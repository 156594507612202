import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	object,
	string,
	func,
	shape,
	oneOfType,
	arrayOf,
	node,
	bool
} from 'prop-types';

// Import styled components
import { StyledSelectField } from './styles';
import { Wrapper, FormError, InputWrapper, Label } from '../styles';

const RegisterSelectField = ({
	field,
	form: { touched, errors, setFieldTouched },
	placeholder,
	children,
	styles,
	required,
	...rest
}) => {
	const { t } = useTranslation();
	const { name, value } = field;
	const isErrorVisible = touched[name] && errors[name];
	const errorText = errors[name] || '';
	const handleOnChange = (event) => field.onChange(name)(event);
	const handleOnBlur = (value) => {
		setFieldTouched(name);
		field.onBlur && field.onBlur(value);
	};

	return (
		<Wrapper>
			<InputWrapper isErrorVisible={isErrorVisible}>
				<Label
					backgroundColor={styles?.label?.background}
					className={value && 'filled'}
					htmlFor={name}
					isErrorVisible={isErrorVisible}
				>
					{placeholder} {required && '*'}
				</Label>
				<StyledSelectField
					notFoundContent={t('common_no_search_results')}
					id={name}
					aria-required="true"
					aria-invalid={isErrorVisible}
					aria-describedby={`${name}-alert`}
					style={{ width: '100%' }}
					className={`${isErrorVisible ? 'errors' : 'test'}`}
					{...field}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
					optionFilterProp="children"
					{...rest}
				>
					{children}
				</StyledSelectField>
			</InputWrapper>
			<FormError
				role="alert"
				aria-atomic="true"
				id={`${name}-alert`}
				isErrorVisible={isErrorVisible}
			>
				{errorText}
			</FormError>
		</Wrapper>
	);
};
RegisterSelectField.defaultProps = {
	autoComplete: 'true',
	styles: {},
	required: false
};

RegisterSelectField.propTypes = {
	field: object.isRequired,
	placeholder: string.isRequired,
	form: shape({
		touched: object.isRequired,
		errors: object.isRequired,
		setFieldTouched: func.isRequired
	}),
	children: oneOfType([arrayOf(node), node]).isRequired,
	autoComplete: string,
	styles: object,
	required: bool
};

export default RegisterSelectField;
