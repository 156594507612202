import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import getSlug from 'speakingurl';
import { useDispatch, useSelector } from 'react-redux';

// Import components
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import utilities
import { useAuth } from 'components/utilities';

// Import helpers
import { getChannelSliderContent } from './helpers';

// Import variables
import { SECTIONS_TYPES } from 'helpers/variables';
import { TV_PATHS } from 'components/routes/paths';

// Import redux actions
import { fetchTvPrograms } from 'store/actions';

const { PAGE, SECTION, PRODUCTS } = APP_CORE_PATHS;
const { CHANNEL_EPG } = SECTIONS_TYPES;
const { EPG } = TV_PATHS;

const useSliderSection = ({ id, name: productName, content, type }) => {
	const dispatch = useDispatch();
	const { isAuth } = useAuth();
	const { name: categoryName } = useParams();

	const channels = useSelector(({ channels }) => channels.data.lives);

	const isSectionChannelEPG = type === CHANNEL_EPG;

	useEffect(() => {
		if (isSectionChannelEPG && !channels.length) {
			fetchTvPrograms({ isAuth })(dispatch);
		}
		// eslint-disable-next-line
	}, []);

	const corePath = categoryName || PRODUCTS;
	const slug = getSlug(productName);

	const sliderContentUrl = `/${PAGE}/${corePath}/${SECTION}/${id}/${slug}`;

	// if slider has type channel_epg, button 'showAll' redirect to page of epg, because epg doesn't have view of section
	const showAllUrl = isSectionChannelEPG ? EPG : sliderContentUrl;

	const sliderContent = isSectionChannelEPG
		? getChannelSliderContent({ content, channels })
		: content;

	return { sliderContentUrl, sliderContent, showAllUrl };
};

export default useSliderSection;
