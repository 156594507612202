import styled, { css } from 'styled-components';

// Import utilties
import { customMinResponsive } from 'components/styles';

export const Wrapper = styled.span`
	display: block;
	overflow: hidden;
	position: absolute;
	z-index: 5;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: background-color 0.3s ease-in-out 0s;
`;

export const Item = styled.span`
	display: block;
	position: absolute;
	left: 0.6rem;
	right: 0.6rem;
	bottom: 0;
	padding-bottom: 1rem;
	transform: translateY(100%);
	transition: transform 0.3s ease-in-out 0s;

	${customMinResponsive(
		993,
		css`
			left: 1rem;
			right: 1rem;
		`
	)}

	${customMinResponsive(
		1700,
		css`
			left: 1.4rem;
			right: 1.4rem;
			padding-bottom: 2.4rem;
		`
	)}
`;

export const Title = styled.span`
	display: block;
	color: #fff;
	font-size: 1.2rem;
	line-height: 1.2;

	${customMinResponsive(
		993,
		css`
			font-size: 1.4rem;
		`
	)}

	${customMinResponsive(
		1271,
		css`
			font-size: 1.8rem;
		`
	)}

	${customMinResponsive(
		1700,
		css`
			font-size: 2.4rem;
		`
	)}
`;

export const Year = styled.span`
	display: block;
	margin-top: 0.5rem;
	margin-bottom: 0.8rem;
	color: #fff;
	font-size: 1.2rem;
	line-height: 1;

	${customMinResponsive(
		993,
		css`
			font-size: 1.4rem;
		`
	)}

	${customMinResponsive(
		1271,
		css`
			margin-bottom: 1.4rem;
			font-size: 1.6rem;
		`
	)}
`;

export const Info = styled.span`
	display: block;
	color: ${({ theme }) => theme.darkGray};
	font-size: 1.2rem;
	line-height: 1.3;

	${customMinResponsive(
		993,
		css`
			font-size: 1.4rem;
		`
	)}

	${customMinResponsive(
		1700,
		css`
			display: inline;
			font-size: 1.6rem;
			line-height: 1.2;
		`
	)}
`;

export const Divider = styled.span`
	display: none;
	margin: 0 0.8rem;
	color: ${({ theme }) => theme.darkGray};
	font-size: 1.2rem;
	line-height: 1.2;

	${customMinResponsive(
		1700,
		css`
			display: inline-block;
			font-size: 1.6rem;
		`
	)}
`;

export const ItemDetailsHoverStyle = css`
	&:hover {
		${Wrapper} {
			background-color: rgba(0, 0, 0, 0.8);
		}

		${Item} {
			transform: translateY(0);
		}
	}
`;
