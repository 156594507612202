import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

// Import heleprs
import { initGA, logPageViewGA } from 'helpers/ga';

// Import variables
import { GOOGLE_TASK_MANAGER } from 'helpers/variables';

const { PAGE_VIEW } = GOOGLE_TASK_MANAGER;

const tagManagerArgs = {
	// eslint-disable-next-line
	gtmId: process.env.REACT_APP_GTM_ID
};

const useGTM = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		initGA();
		TagManager.initialize(tagManagerArgs);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		logPageViewGA();

		// GTM
		window.dataLayer.push({
			event: PAGE_VIEW
		});
		// eslint-disable-next-line
	}, [pathname]);
};

export default useGTM;
