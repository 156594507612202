import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	data: [],
	meta: {},
	isLoaded: false,
	isError: false,
	searchQuery: ''
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Fetch tv categories *******************
			case types.FETCH_SEARCH_QUERY_LOADING:
				return { ...INITIAL_STATE, searchQuery: action.payload.query };

			case types.FETCH_SEARCH_QUERY_SUCCESS:
				draft.isLoaded = true;
				draft.data = action.payload.items;
				draft.meta = action.payload.meta;
				return;

			case types.FETCH_SEARCH_QUERY_ERROR:
				draft.isError = true;
				return;

			default:
				return state;
		}
	});
