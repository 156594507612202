import React from 'react';
import { bool } from 'prop-types';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

// Import variables
import { VolumeStyled } from './styles';

const VolumeController = ({ isCatchupControllers }) => {
	// Get player methods from Player context
	const { volume, setVolume, mute, isMuted, isFocus } = usePlayerTvContext();

	const handleChangeVolume = (e) => {
		e.persist();
		// Get volume
		const value = e.target.value;
		// Set volume in player provider
		setVolume(value);
	};
	// Mute or unmute in player provider
	const handleMute = () => mute();

	const renderVolumeRange = () => {
		return (
			<>
				<Player.Range
					value={volume}
					onChange={handleChangeVolume}
					type="range"
					name="volume"
					className="volume_range"
					id="volume"
					min="0"
					max="100"
					step="1"
				/>
				<Player.RangeValue modifiers={['primaryRange']} value={volume} />
			</>
		);
	};

	return (
		<Player.Wrapper>
			<Button
				isCatchupControllers={isCatchupControllers}
				onClick={handleMute}
				modifiers={['icon', 'playerIconHover']}
				isFocus={isFocus}
			>
				{!isMuted ? <Player.IconVolume /> : <Player.IconVolumeOff />}
			</Button>

			<VolumeStyled>{renderVolumeRange()}</VolumeStyled>
		</Player.Wrapper>
	);
};

VolumeController.propTypes = {
	isCatchupControllers: bool
};

export default VolumeController;
