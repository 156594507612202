import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import getSlug from 'speakingurl';
import { useHistory } from 'react-router-dom';

// Import helpers
import { getInstitutionData } from 'helpers/institutions';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const { INSTITUTION } = APP_CORE_PATHS;

const useInstitutionLogo = () => {
	const history = useHistory();
	const { t } = useTranslation();

	const { institution } = useSelector(({ institutions }) => institutions);

	const {
		institutionLogo,
		institutionWebsite,
		institutionName,
		isNameTranslation
	} = getInstitutionData(institution);

	const institutionNameTranslation = isNameTranslation
		? t(institutionName)
		: institutionName;

	const { uuid, name } = institution;

	const handleRedirect = () => {
		const institutionUrl = `/${INSTITUTION}/${uuid}/${getSlug(name)}`;
		history.push(institutionUrl);
	};

	return {
		institutionLogo,
		institutionWebsite,
		institutionName: institutionNameTranslation,
		institutionUuid: uuid,
		redirect: handleRedirect
	};
};

export default useInstitutionLogo;
