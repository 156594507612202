import React from 'react';
import {
	object,
	oneOfType,
	node,
	arrayOf,
	bool,
	string,
	func
} from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import helpers
import { DISPLAY_FLEX_STYLES } from './helpers';

// Import styles
import { Box, ButtonClose, IconClose, Bottom, Text } from './styles';

// Import components
import { AriaModal } from 'components/elements';

const AriaBaseModal = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const {
		children,
		close,
		titleText,
		style,
		centerModalContent,
		isCloseButton
	} = props;

	const underlayStyle = centerModalContent ? DISPLAY_FLEX_STYLES : {};

	return (
		<AriaModal
			underlayStyle={underlayStyle}
			titleText={titleText}
			onExit={close}
		>
			<Box ref={ref} style={style}>
				{isCloseButton && (
					<ButtonClose
						onClick={close}
						title={t('common_modal_close_button_title')}
					>
						<IconClose
							aria-hidden="true"
							title={t('common_modal_close_button_title')}
						/>
					</ButtonClose>
				)}
				{children}
			</Box>
		</AriaModal>
	);
});

AriaBaseModal.defaultProps = {
	style: {},
	centerModalContent: true,
	isCloseButton: true
};

AriaBaseModal.propTypes = {
	children: oneOfType([node, arrayOf(node)]),
	style: object,
	close: func,
	titleText: string,
	centerModalContent: bool,
	isCloseButton: bool
};

AriaBaseModal.Bottom = Bottom;
AriaBaseModal.Text = Text;

export default AriaBaseModal;
