import { NO_PROGRAM } from 'helpers/variables';

export const getFilteredChannels = ({ lives, query, category }) => {
	const channels = lives.reduce((acc, live) => {
		const { title, currentLiveIndex, epgProgrammes } = live;
		const currentProgram = epgProgrammes[currentLiveIndex];
		const nextProgram = epgProgrammes[currentLiveIndex + 1];
		const formatTitle = (title) => title.toLowerCase().includes(query);

		const isCurrentProgram =
			currentProgram &&
			currentProgram.uuid !== NO_PROGRAM &&
			formatTitle(currentProgram.title);
		const isNextProgram =
			nextProgram &&
			nextProgram.uuid !== NO_PROGRAM &&
			formatTitle(nextProgram.title);

		if (formatTitle(title)) {
			acc.push(live);
		} else if (isCurrentProgram) {
			acc.push(live);
		} else if (isNextProgram) {
			acc.push(live);
		}
		return acc;
	}, []);

	const channelsFiltered = channels.filter(
		({ genres }) => genres && genres.length > 0 && genres[0].slug === category
	);

	return category === 'all' ? channels : channelsFiltered;
};
