import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import placeholder
import { WATERMARK_URL as placeholder } from 'helpers/variables';

// Import styles
import {
	customMinResponsive,
	size,
	textEllipsis,
	itemFocus
} from 'components/styles';

const IMAGE_RATIO = 236 / 419;

export const TileContentWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const Figure = styled.figure`
	width: 100%;
	height: 100%;
	margin: 0;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 0.6rem;
	background: no-repeat white url(${placeholder}) center/50%;
	margin-top: 2px;
	box-shadow: 0px 2px 6px -3px rgba(66, 68, 90, 1);
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};
`;

export const Image = styled.img`
	height: 100%;
	width: ${({ isImageUrl }) => (isImageUrl ? '100%' : 'auto')};
	object-fit: cover;
	object-position: center;
`;

export const Figcaption = styled.figcaption`
	display: none;
`;

export const TileTitle = styled.h3`
	font-size: ${({ theme }) =>
		theme.fontSize.productItemsGrid.tileTitle.mobile}rem;
	line-height: 1.4rem;
	color: ${({ theme }) => theme.productItemsGrid.tileTitle};
	${textEllipsis};
	margin-top: 0.4rem;
	${customMinResponsive(
		size.medium,
		css`
			margin-top: 0.8rem;
			font-size: ${({ theme }) =>
				theme.fontSize.productItemsGrid.tileTitle.desktop}rem;
			line-height: 2.8rem;
		`
	)}
`;

export const TileDescription = styled.p`
	font-size: ${({ theme }) =>
		theme.fontSize.productItemsGrid.tileDescription.mobile}rem;
	line-height: 0.6rem;
	text-transform: uppercase;
	color: ${({ theme }) => theme.productItemsGrid.tileDescription};
	margin-top: 0.5rem;
	${customMinResponsive(
		size.medium,
		css`
			margin-top: 0;
			font-size: ${({ theme }) =>
				theme.fontSize.productItemsGrid.tileDescription.desktop}rem;
			line-height: 2.8rem;
		`
	)}
`;

export const StyledLink = styled(Link)`
	position: relative;
	display: block;
	width: 100%;
	padding-top: calc(${IMAGE_RATIO} * 100%); //to keep image ratio in css grid
	&:focus {
		outline: none;

		figure {
			${itemFocus()}
		}
	}
`;
