import React from 'react';
import { bool } from 'prop-types';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { deleteVideoSession } from 'store/actions';
import { useLocation } from 'react-router-dom';

// Import helpres
import { PLAYER_SELECTORS } from 'helpers/variables';
import { getProductType } from 'helpers/products';
import { fetchMovieResources } from 'components/elements/organisms/player_vod/PlayerWrapper/helpers';

// Import components
import { Button, PlayerElements as Player } from 'components/elements';

const playerSelector = PLAYER_SELECTORS.VOD;

const PlayerSeriresControllers = ({ isEpisodeAvaiable }) => {
	const store = useStore();
	const { prev, next } = useSelector((state) => state.movieDetails.episodes);
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const type = getProductType(pathname);

	const handlePlayEpisode = (episode) => async () => {
		// Delete video live session
		// Dispatch an deleteVideoSession action in player folder
		await deleteVideoSession({ type: playerSelector })(
			dispatch,
			store.getState
		);
		const id = episode.uuid;
		// Fetch movie resources
		await fetchMovieResources({ id, type, dispatch });
	};
	return (
		<>
			<Button
				disabled={!prev}
				onClick={handlePlayEpisode(prev)}
				modifiers={['icon', 'playerIconHover']}
			>
				<Player.IconPrevious />
			</Button>

			<Button
				disabled={!next || !isEpisodeAvaiable}
				onClick={handlePlayEpisode(next)}
				modifiers={['icon', 'playerIconHover']}
			>
				<Player.IconNext />
			</Button>
		</>
	);
};

PlayerSeriresControllers.propTypes = {
	isEpisodeAvaiable: bool.isRequired
};

export default PlayerSeriresControllers;
