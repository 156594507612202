import React from 'react';
import { object } from 'prop-types';

// Import styles
import { HeaderRow } from 'components/elements/molecules/table/styles';

// Import components
import { TableHeader } from '.';

const TableHeaderRow = ({ headerGroup }) => (
	<HeaderRow
		className="blueonline-table__head-row blueonline-table__row"
		{...headerGroup.getHeaderGroupProps()}
	>
		{headerGroup.headers.map((column) => (
			<TableHeader key={column.id} column={column} />
		))}
	</HeaderRow>
);

TableHeaderRow.propTypes = {
	headerGroup: object.isRequired
};

export default TableHeaderRow;
