import React from 'react';
import { useSelector } from 'react-redux';

// Import styles
import { Wrapper } from './styles';
import { WatermarkImage } from 'components/elements';

// Import helpers
import { WATERMARK_URL } from 'helpers/variables';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const Watermark = () => {
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	const { useWatermark, floatMode, isFullScreen } = usePlayerVodContext();
	const { isFloatMode } = floatMode;

	const isFloatModeActive = isFloatMode && !isFullScreen;

	if (!useWatermark) return null;

	return (
		<Wrapper isFloatMode={isFloatModeActive}>
			<WatermarkImage isContrast={isContrast} src={WATERMARK_URL} />
		</Wrapper>
	);
};

export default Watermark;
