import React from 'react';
import styled from 'styled-components';

// Import utilities
import { CountdownRPC } from 'components/utilities';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

const Timer = () => {
	const { isPaused, movieEndMargin, currentTime, duration } =
		usePlayerVodContext();
	const [time, setTime] = React.useState(movieEndMargin);

	React.useEffect(() => {
		const leftTime = duration - currentTime;
		leftTime < movieEndMargin ? setTime(leftTime) : setTime(movieEndMargin);
		// eslint-disable-next-line
	}, [duration, currentTime, movieEndMargin]);

	return (
		<CountdownRPC time={time} isPaused={isPaused}>
			{({ counter }) => <Time>{counter.toFixed(0)}s.</Time>}
		</CountdownRPC>
	);
};

const Time = styled.span`
	color: ${({ theme }) => theme.white};
`;

export default Timer;
