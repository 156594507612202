import * as types from 'store/actions/types';

const INITIAL_STATE = {
	data: [],
	continueProgress: null,
	loading: false,
	error: false
};

export default function(state = INITIAL_STATE, action = {}) {
	switch (action.type) {
		// ******* FETCH CONTINUE_WATCHING MOVIES *******
		case types.CONTINUE_WATCHING_LOADING:
			return {
				...state,
				loading: true,
				error: true
			};
		case types.CONTINUE_WATCHING_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				continueProgress: action.payload.time,
				loading: false,
				error: false
			};
		case types.CONTINUE_WATCHING_ERROR:
			return {
				...state,
				loading: false,
				error: true
			};

		// ******* CLEAR CONTINUE WATCHING *******
		case types.CLEAR_CONTINUE_WATCHING:
			return { ...INITIAL_STATE };

		default:
			return state;
	}
}
