import React from 'react';
import { object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import componets
import {
	BannerContent,
	Title,
	Description
} from 'components/elements/molecules/slider_banners/styles';

// Import styles
import { BannerWrapper } from './styles';

// Import utilities

const Info = ({ currentProduct, type }) => {
	const {
		i18n: { language }
	} = useTranslation();

	const translations = currentProduct?.translations?.[language];
	const title = translations?.title || currentProduct?.title;
	const short_desc = translations?.short_desc || currentProduct?.short_desc;

	return (
		<BannerWrapper modifiers={type}>
			<BannerContent modifiers={type}>
				<Title tabIndex={-1}>{title}</Title>
				{short_desc && <Description tabIndex={-1}>{short_desc}</Description>}
			</BannerContent>
		</BannerWrapper>
	);
};

Info.propTypes = {
	currentProduct: object.isRequired,
	type: string.isRequired
};

export default Info;
