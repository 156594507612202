import { useQuery, useQueryClient } from 'react-query';

// Import helpers
import { fetchAgreementsByType } from './helpers';

const useFetchAgreements = ({
	queryKey,
	agreementsToFetch = [],
	useCache = false
}) => {
	const queryClient = useQueryClient();
	const fetchResources = async () => {
		const agreementsCache = queryClient.getQueryData(queryKey);

		const handleFetchAgreementsByType = (type) => fetchAgreementsByType(type);

		if (agreementsCache?.length && useCache) {
			return agreementsCache;
		}

		return Promise.all(agreementsToFetch.map(handleFetchAgreementsByType)).then(
			(response) => response.map(({ data }) => data).flat()
		);
	};

	const { isError, isLoading, data } = useQuery({
		queryKey,
		queryFn: fetchResources
	});

	const agreements = data ?? [];

	return {
		agreements,
		isAgreementsError: isError,
		isAgreementsLoading: isLoading
	};
};

export default useFetchAgreements;
