import { getTime } from 'date-fns';
import { groupBy } from 'lodash';

export const remindersSorting = ({ reminders }) => {
	// group reminders by 'program_start'
	const remindersByStartTime = groupBy(reminders, 'program_start');

	// convert grouped remiders object to array
	// to get [[], [], []]
	const remindersByStartTimeArray = Object.values(remindersByStartTime);

	// sort grouped reminders arrays by program title
	const sortedByTitle = remindersByStartTimeArray.map((array) =>
		[...array].sort((prev, next) => prev.title.localeCompare(next.title))
	);

	// sort by grouped arrays first element program_start value
	const sortedByStartTime = [...sortedByTitle].sort(
		(prev, next) =>
			getTime(prev[0].program_start) - getTime(next[0].program_start)
	);

	return sortedByStartTime.flat();
};
