import styled from 'styled-components';
import { MenuList, MenuItem } from '@reach/menu-button';

// Import styles
import { transitionMain } from 'components/styles';

export const OptionsList = styled(MenuList)`
	border: ${({ theme }) =>
		`1px solid ${theme.reminders.menu.border} !important`};
	background: ${({ theme }) => theme.reminders.menu.bg} !important;
	border-radius: ${({ theme }) => theme.reminders.menu.roundSize}rem;
	width: ${({ width }) => width}px;
	padding: 0 !important;

	[data-selected] {
		${transitionMain}
		color: ${({ theme }) => theme.reminders.menu.colorHover} !important;
	}
`;

export const OptionItem = styled(MenuItem)`
	background: ${({ theme }) => theme.reminders.menu.bg} !important;
	height: 4.5rem !important;
	font-size: ${({ theme }) => theme.fontSize.remindersMenu}rem !important;
	color: ${({ theme }) => theme.reminders.menu.color} !important;
	font-weight: ${({ theme }) => theme.font.normal} !important;
	display: flex !important;
	justify-content: flex-start;
	align-items: center;

	&:first-of-type {
		border-top-right-radius: 1px;
		border-top-left-radius: 1px;
	}

	&:not(&:last-of-type) {
		border-bottom: ${({ theme }) => `1px solid ${theme.reminders.menu.border}`};
	}

	&:last-of-type {
		border-bottom-right-radius: 1px;
		border-bottom-left-radius: 1px;
	}
`;
