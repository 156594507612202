import * as types from 'store/actions/types';
import services from 'services/services';
import i18n from 'i18next';
import Cookie from 'js-cookie';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import { createQueryParams } from 'helpers';

// Import variables
import { COOKIES_AFFILIATE_CLIENT } from 'helpers/variables';

// Import api
import { getProductPurchaseApi } from 'helpers/api';

// ******************** SUBMIT PRODUCT PURCHASE ********************
export const submitProductPurchase =
	({
		pin = '',
		productId,
		priceId,
		promotion = null,
		paymentMethod,
		user_payment_data_id = null,
		gateway_type = null,
		update_subscription,
		need_invoice = false,
		client = null,
		transaction_id = null,
		sms_code = null
	}) =>
	async (dispatch) => {
		try {
			// Dispatch an loading action
			dispatch({
				type: types.PURCHASE_LOADING,
				payload: true
			});

			const url = getProductPurchaseApi({ paymentMethod, productId, priceId });

			const query = createQueryParams({
				language: i18n.language,
				aff_code: Cookie.get(COOKIES_AFFILIATE_CLIENT),
				promotion_id: promotion?.id,
				context: 'out'
			});

			const body = {
				need_invoice,
				client,
				update_subscription,
				user_payment_data_id,
				transaction_id,
				sms_code,
				data: {
					pin,
					code: promotion?.voucherCode ?? '',
					gateway_type
				}
			};

			const { data } = await services.post(`${url}${query}`, body);

			// Dispatch an action
			dispatch({
				type: types.PURCHASE_SUCCESS,
				payload: { data, paymentMethod }
			});
		} catch (error) {
			if (error.response) {
				const { codeErrorMessage } = getErrorCodeResults(error);

				// Dispatch an error action
				error.response.data.message = codeErrorMessage;
			}

			// Dispatch an error action
			dispatch({
				type: types.PURCHASE_ERROR,
				payload: error
			});
		}
	};

export const clearPurchase = (dispatch) =>
	dispatch({ type: types.PURCHASE_CLEAR });
