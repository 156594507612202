import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isLoading: false,
	isSuccess: false,
	registerError: {
		message: '',
		name: '',
		isError: false,
		errorCode: null,
		status: 200
	},
	registerAgreements: {
		data: [],
		isLoading: true,
		isError: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Register user *******************
			case types.REGISTER_USER_LOADING:
				draft.isLoading = true;
				draft.isError = false;
				draft.isSuccess = false;
				draft.registerError = INITIAL_STATE.registerError;
				break;

			case types.REGISTER_USER_SUCCESS:
				draft.isLoading = false;
				draft.isSuccess = true;
				break;

			case types.REGISTER_USER_ERROR:
				draft.isLoading = false;
				draft.isSucces = false;
				draft.registerError.isError = true;
				draft.registerError.name = action.payload.data.name || '';
				draft.registerError.message = action.payload.data.message || '';
				draft.registerError.status = action.payload.status;
				break;

			// ******************* Fetch register agreements *******************
			case types.FETCH_REGISTER_AGREEMENTS_LOADING:
				draft.registerAgreements.isLoading = true;
				draft.registerAgreements.isError = false;
				draft.registerAgreements.data = INITIAL_STATE.registerAgreements.data;
				break;

			case types.FETCH_REGISTER_AGREEMENTS_SUCCESS:
				draft.registerAgreements.isLoading = false;
				action.payload.map((agreement) => (agreement.checked = false));
				draft.registerAgreements.data = action.payload;
				break;

			case types.FETCH_REGISTER_AGREEMENTS_ERROR:
				draft.registerAgreements.isLoading = false;
				draft.registerAgreements.isError = true;
				break;

			case types.CLEAR_REGISTER_AGREEMENTS:
				draft.registerAgreements = INITIAL_STATE.registerAgreements;
				break;

			// ******************* Clear register data *******************
			case types.CLEAR_REGISTER_DATA:
				return { ...INITIAL_STATE };

			default:
				return state;
		}
	});
