// -----------------  FETCH EPG -----------------
export const FETCH_EPG_PROGRAMS_LOADING = 'FETCH_EPG_PROGRAMS_LOADING';
export const FETCH_EPG_PROGRAMS_SUCCESS = 'FETCH_EPG_PROGRAMS_SUCCESS';
export const FETCH_EPG_PROGRAMS_ERROR = 'FETCH_EPG_PROGRAMS_ERROR';

// -----------------  FETCH EPG -----------------
export const SET_EPG_CHANNELS = 'SET_EPG_CHANNELS';

// -----------------  CLEAR EPG -----------------
export const CLEAR_EPG_PROGRAMS = 'CLEAR_EPG_PROGRAMS';
