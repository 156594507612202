import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';

// Import actions
import { triggerReminder } from 'helpers/triggerReminder';
// import helpers
import { menuItems, getMinutesFromNow } from './helpers';
import { PRODUCTS_TYPES } from 'helpers/variables';
import { getProductType } from 'helpers/products';

const { PROGRAM, LIVE_EVENT } = PRODUCTS_TYPES;

export function RemindersMenu({ programId, startDate }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const minutesLeft = getMinutesFromNow(startDate);

	const productType =
		getProductType(pathname) === 'live' ? LIVE_EVENT : PROGRAM;

	const handleMenuItemClick = (minutes) => {
		triggerReminder({
			productUuid: programId,
			offset: minutes,
			productType,
			startDate
		})(dispatch);
	};

	return (
		<Menu>
			{menuItems.map(({ id, minutes }) =>
				minutesLeft && minutesLeft > minutes ? (
					<Menu.Item key={id} onClick={() => handleMenuItemClick(minutes)}>
						{t(`common_reminders_list_${minutes}_before`)}
					</Menu.Item>
				) : null
			)}
		</Menu>
	);
}

RemindersMenu.propTypes = {
	programId: string.isRequired,
	startDate: string.isRequired
};
