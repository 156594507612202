import React, { useRef, useEffect } from 'react';
import { bool } from 'prop-types';
// Import styled components
import { Wrapper, MenusContainer, Container, MobileWrapper } from './styles';
// Import components
import Hamburger from '../hamburger';
import NavSearchForm from '../nav_search/NavSerachForm';
import MobileMenu from './MobileMenu';
import PortalLogo from '../portal_logo';
import SearchButton from '../nav_toolbar/SearchButton';
import { MobileNavTemplate } from './styles';
// Import logic
import { useMobileNav } from './useMobileNav';

const MobileNav = ({ intersection }) => {
	// block body scrolling if mobile menu is open
	const {
		isMenuOpen,
		isSearchingOpen,
		toggleMenu,
		toggleSearching,
		closeMenu,
		closeSearching
	} = useMobileNav();

	const refMenusContainer = useRef(null);

	const handleToggle = (e) => {
		if (refMenusContainer.current === e.target) {
			toggleMenu();
		}
	};

	useEffect(() => {
		closeMenu();
		closeSearching();
		// eslint-disable-next-line
	}, [intersection]);

	return (
		<MobileWrapper intersection={intersection}>
			<MobileNavTemplate>
				<Wrapper>
					{isSearchingOpen ? (
						<NavSearchForm
							toggle={toggleSearching}
							isMobileView
							autoFocus={true}
						/>
					) : (
						<>
							<PortalLogo />
							<Container>
								<SearchButton toggle={toggleSearching} />
								<Hamburger isOpen={isMenuOpen} toggle={toggleMenu} />
							</Container>
						</>
					)}
				</Wrapper>
			</MobileNavTemplate>
			{isMenuOpen && (
				<MenusContainer ref={refMenusContainer} onClick={handleToggle}>
					<MobileMenu toggle={toggleMenu} />
				</MenusContainer>
			)}
			{isSearchingOpen && (
				<MenusContainer
					ref={refMenusContainer}
					onClick={toggleSearching}
				></MenusContainer>
			)}
		</MobileWrapper>
	);
};

MobileNav.propTypes = {
	intersection: bool
};

export default MobileNav;
