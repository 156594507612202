import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavToolbar } from './useNavToolbar';

// Import components
import NavDropdown from './nav_dropdown/NavDropdown';
import Favourites from './favourites/Favourites';
import Reminders from './reminders/Reminders';
import SearchButton from './SearchButton';
import LanguageDropDownDesktop from './LanguageDropDownDesktop/LanguageDropDownDesktop';

// Import styles
import {
	NavToolbarStyled,
	Item,
	StyleLangueageItem,
	StyledButton
} from './styles';

const NavToolbar = ({ toggle }) => {
	const { t } = useTranslation();

	const { isLogged, loginClick } = useNavToolbar();

	return (
		<NavToolbarStyled>
			<StyleLangueageItem>
				<LanguageDropDownDesktop />
			</StyleLangueageItem>
			<Item>
				<SearchButton toggle={toggle} />
			</Item>

			<Item>
				<Favourites />
			</Item>

			<Item>
				<Reminders />
			</Item>

			<Item>
				{isLogged ? (
					<NavDropdown />
				) : (
					<StyledButton onClick={loginClick} modifiers={['default']}>
						{t('common_login_btn_submit')}
					</StyledButton>
				)}
			</Item>
		</NavToolbarStyled>
	);
};

NavToolbar.propTypes = {
	toggle: PropTypes.func.isRequired
};

export default NavToolbar;
