// ******************** FREE VOD PACKETS LIST ********************
export const FETCH_PACKETS_FREE_VOD_SUCCESS = 'FETCH_PACKETS_FREE_VOD_SUCCESS';
export const FETCH_PACKETS_FREE_VOD_ERROR = 'FETCH_PACKETS_FREE_VOD_ERROR';

// ******************** PACKETS LIST ********************
export const FETCH_PACKETS_LOADING = 'FETCH_PACKETS_LOADING';
export const FETCH_PACKETS_SUCCESS = 'FETCH_PACKETS_SUCCESS';
export const FETCH_PACKETS_ERROR = 'FETCH_PACKETS_ERROR';

// ******************** PACKET DETAILS ********************
export const FETCH_PACKET_LOADING = 'FETCH_PACKET_LOADING';
export const FETCH_PACKET_SUCCESS = 'FETCH_PACKET_SUCCESS';
export const FETCH_PACKET_ERROR = 'FETCH_PACKET_ERROR';
