import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const useAuth = () => {
	const history = useHistory();
	const isAuth = useSelector((state) => state.auth.isLogged);

	const mainPath = `/tv/${APP_CORE_PATHS.LOGIN}`;
	const loginPath = `/${APP_CORE_PATHS.LOGIN}`;
	const handleRedirectToLogin = () => history.push(loginPath);
	const handleRedirectToMain = () => history.push(mainPath);

	return {
		isAuth,
		redirectToLogin: handleRedirectToLogin,
		redirectToMain: handleRedirectToMain,
		redirectToMainPath: mainPath,
		redirectToLoginPath: loginPath
	};
};

export default useAuth;
