import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

// HELPERS
import { INITIAL_STATE } from './helpers';

// Import reducer
import reducer from './store/reducer';

// Import hooks
import { useInfo } from './hooks';

export const InfoContext = createContext(null);

const InfoProvider = ({ children, subtype }) => {
	// state reducer
	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

	const infoData = useInfo({ state, dispatch, subtype });

	return (
		<InfoContext.Provider
			value={{
				...state,
				...infoData
			}}
		>
			{children}
		</InfoContext.Provider>
	);
};

InfoProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	subtype: PropTypes.string.isRequired
};

export default InfoProvider;
