import styled, { css, keyframes } from 'styled-components';
import { Menu } from 'antd';
import { size, customMinResponsive } from 'components/styles';
import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from 'helpers/variables';

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const StyledMenu = styled(Menu)`
	&.ant-menu {
		background-color: ${({ theme }) => theme.body.nav.menuMobileBgColor};
	}
	&.ant-menu-inline,
	.ant-menu-vertical,
	.ant-menu-vertical-left {
		border-right: none;
	}
	position: absolute;
	right: 0;
	height: calc(100vh - ${HEADER_HEIGHT_MOBILE}px);
	overflow-x: hidden;
	padding-bottom: 85px;

	${customMinResponsive(
		size.medium,
		css`
			width: 50%;
			max-width: 500px;
			height: calc(100vh - ${HEADER_HEIGHT_DESKTOP}px);
			margin-left: auto;
			animation: ${slideInFromRight} 0.2s ease-out;
		`
	)}
`;
