import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// Import styles
import { flex, spin, spinReverse } from 'components/styles';

const Loader = ({
	width = 15,
	height = 15,
	position = 'fixed',
	background = true,
	zIndex = 1200,
	wrapperHeight = '100%',
	wrapperWidth = '100%',
	top = 0,
	...rest
}) => {
	return (
		<LoaderWrapper
			zIndex={zIndex}
			background={background}
			position={position}
			wrapperHeight={wrapperHeight}
			wrapperWidth={wrapperWidth}
			top={top}
			data-testid="loader"
			{...rest}
		>
			<LoaderItem width={width} height={height} />
		</LoaderWrapper>
	);
};

const base = css`
	border: 3px solid transparent;
	border-radius: 50%;
`;

const LoaderWrapper = styled.div`
	position: ${({ position }) => position};
	top: ${({ top }) => top};
	left: 0;
	width: ${({ wrapperWidth }) => wrapperWidth};
	height: ${({ wrapperHeight }) => wrapperHeight};
	z-index: ${({ zIndex }) => zIndex};
	transition: opacity 0.4s ease-in-out;
	background-color: ${({ background }) =>
		background ? ({ theme }) => theme.loaders.loader.loaderBg : 'transparent'};
	${flex()}
`;
const LoaderItem = styled.div`
	display: block;
	width: ${({ width }) => `${width}rem`};
	height: ${({ height }) => `${height}rem`};
	position: relative;
	animation: ${spin} 1.7s linear infinite;
	${base};
	border-top-color: ${({ theme }) => theme.loaders.loader.colorStart};

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		top: 5px;
		left: 5px;
		right: 5px;
		bottom: 5px;
		${base}
		border-top-color:${({ theme }) => theme.loaders.loader.colorMiddle};
		animation: ${spinReverse} 0.6s linear infinite;
	}
	&:after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 15px;
		left: 15px;
		right: 15px;
		bottom: 15px;
		${base}
		border-top-color: ${({ theme }) => theme.loaders.loader.colorEnd};
		animation: ${spin} 1s linear infinite;
	}
`;

Loader.propTypes = {
	position: PropTypes.string,
	background: PropTypes.bool,
	zIndex: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	wrapperHeight: PropTypes.string,
	wrapperWidth: PropTypes.string
};

export default Loader;
