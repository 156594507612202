import React from 'react';
import ReactDOM from 'react-dom';

// Import translations
import './i18n';

// Import fonts
import './assets/fonts/fonts';

// Import vendors
import './vendors/vendor_styles';

// Import root component
import Root from './Root';

if (process.env.NODE_ENV !== 'development') {
	// eslint-disable-next-line
	console.log = () => {};
}

ReactDOM.render(<Root />, document.getElementById('root'));

if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.register('/firebase-messaging-sw.js')
		.then(function (registration) {
			// eslint-disable-next-line
			console.log('Registration successful, scope is:', registration.scope);
		})
		.catch(function (err) {
			// eslint-disable-next-line
			console.log('Service worker registration failed, error:', err);
		});
}
