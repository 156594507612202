import React from 'react';
import { bool } from 'prop-types';

// Import utilities
import { usePip } from 'components/utilities';

const PlayerPip = React.memo(
	({ isReady }) => {
		usePip({ isReady });
		return null;
	},
	(prev, next) => prev.isReady === next.isReady
);

PlayerPip.propTypes = {
	isReady: bool
};

export default PlayerPip;
