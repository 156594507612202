import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { scheduleRecording, fetchRecordings } from 'store/actions';
import styled from 'styled-components';

// Import icons
import { ReactComponent as IconRec } from 'assets/icons/ic_rec.svg';

// Import components
import { Button } from 'components/elements';

const ProgramRecordings = ({
	programId,
	scheduleRecording,
	fetchRecordings,
	selectedDevice,
	stbDevices,
	recordings,
	isDetails
}) => {
	const deviceForRecording = selectedDevice || stbDevices[0].StbId;

	const handleScheduleRecordings = async () => {
		await scheduleRecording(programId, deviceForRecording, () =>
			fetchRecordings(deviceForRecording)
		);
	};

	// gets reminder object for its program
	const isProgramRecorded = recordings.find(
		({ programUuid }) => programUuid === programId
	);

	const iconDimensions = '25px';

	return isProgramRecorded ? (
		<ButtonRecord
			padding="0"
			isProgramRecorded={isProgramRecorded}
			isDetails={isDetails}
		>
			<IconRec width={iconDimensions} height={iconDimensions} />
		</ButtonRecord>
	) : (
		<ButtonRecord
			padding="0"
			isProgramRecorded={isProgramRecorded}
			onClick={handleScheduleRecordings}
			isDetails={isDetails}
		>
			<IconRec width={iconDimensions} height={iconDimensions} />
		</ButtonRecord>
	);
};

const ButtonRecord = styled(Button)`
	svg {
		path {
			fill: ${({ theme, isProgramRecorded }) =>
				isProgramRecorded
					? theme.popover.iconActive
					: ({ isDetails }) => (isDetails ? theme.gray : theme.popover.icon)};
		}
		path.recording_dot {
			fill: ${({ theme, isProgramRecorded }) =>
				isProgramRecorded
					? theme.red
					: ({ isDetails }) => (isDetails ? theme.gray : theme.popover.icon)};
		}
	}
`;

ProgramRecordings.defaultProps = {
	isDetails: false
};

ProgramRecordings.propTypes = {
	programId: PropTypes.string.isRequired,
	scheduleRecording: PropTypes.func.isRequired,
	fetchRecordings: PropTypes.func.isRequired,
	selectedDevice: PropTypes.string,
	stbDevices: PropTypes.array.isRequired,
	recordings: PropTypes.array.isRequired,
	isDetails: PropTypes.bool.isRequired
};

const mapStatetoProps = ({
	devices: { selectedDevice, data },
	recordings
}) => ({
	selectedDevice,
	stbDevices: data,
	recordings: recordings.data
});

export default connect(
	mapStatetoProps,
	{ scheduleRecording, fetchRecordings }
)(ProgramRecordings);
