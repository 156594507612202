import React from 'react';
import { string, number, func, object, bool } from 'prop-types';

// Import components
import { AuthModal, CommonButton } from 'components/elements';

// Import logic
import usePlayButton from './usePlayButton';

const PlayButton = ({ width, redirect, ...restProps }) => {
	const { linkRedirect, buttonText, isAvailableToPlay, renderAs } =
		usePlayButton(restProps);

	return (
		<AuthModal>
			<CommonButton
				disabled={restProps.disabled || !isAvailableToPlay}
				isPrime={true}
				as={renderAs}
				onClick={redirect}
				width={width}
				to={linkRedirect}
				isDisabled={!isAvailableToPlay}
			>
				{buttonText}
			</CommonButton>
		</AuthModal>
	);
};

PlayButton.defaultProps = {
	width: 144,
	subtype: '',
	uuid: '',
	redirect: () => {},
	product: {}
};

PlayButton.propTypes = {
	uuid: string,
	width: number,
	subtype: string,
	redirect: func,
	product: object.isRequired,
	disabled: bool
};

export default PlayButton;
