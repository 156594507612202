import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Import components
import { InputRadio } from 'components/elements';

const Radio = ({ disabled, margin, ...field }) => {
	return (
		<Wrapper margin={margin}>
			<InputRadio {...field} disabled={disabled} />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	margin: ${({ margin }) => margin};
	&:last-child {
		margin: 0 0 1.5rem 3rem;
	}
`;

Radio.defaultProps = {
	id: null,
	label: '',
	disabled: false,
	margin: '0 0 1.5rem'
};

Radio.propTypes = {
	field: PropTypes.object.isRequired,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label: PropTypes.string,
	disabled: PropTypes.bool,
	margin: PropTypes.string
};

export default Radio;
