// -----------------  FETCH PLAYER CONFIGURATION -----------------
export const FETCH_PLAYER_CONFIG_LOADING = 'FETCH_PLAYER_CONFIG_LOADING';
export const FETCH_PLAYER_CONFIG_SUCCESS = 'FETCH_PLAYER_CONFIG_SUCCESS';
export const FETCH_PLAYER_CONFIG_ERROR = 'FETCH_PLAYER_CONFIG_ERROR';

// -----------------  DELETE PLAYER VIDEO SESSION  -----------------
export const PLAYER_DELETE_VIDEO_SESSION_LOADING =
	'PLAYER_DELETE_VIDEO_SESSION_LOADING';
export const PLAYER_DELETE_VIDEO_SESSION_SUCCESS =
	'PLAYER_DELETE_VIDEO_SESSION_SUCCESS';
export const PLAYER_DELETE_VIDEO_SESSION_ERROR =
	'PLAYER_DELETE_VIDEO_SESSION_ERROR';

// -----------------  UPDATE PLAYER VIDEO SESSION  -----------------
export const PLAYER_UPDATE_VIDEO_SESSION_SUCCESS =
	'PLAYER_UPDATE_VIDEO_SESSION_SUCCESS';

// -----------------  SET PLAYER PLAYBACK ERROR  -----------------
export const PLAYER_SET_PLAYBACK_ERROR = 'PLAYER_SET_PLAYBACK_ERROR';

// -----------------  PLAYER READY  -----------------
export const PLAYER_IS_READY = 'PLAYER_IS_READY';

//***********************************************************************/
// -----------------  FETCH PLAYER DETAILS CONFIGURATION -----------------
export const FETCH_PLAYER_DETAILS_CONFIG_LOADING =
	'FETCH_PLAYER_DETAILS_CONFIG_LOADING';
export const FETCH_PLAYER_DETAILS_CONFIG_SUCCESS =
	'FETCH_PLAYER_DETAILS_CONFIG_SUCCESS';
export const FETCH_PLAYER_DETAILS_CONFIG_ERROR =
	'FETCH_PLAYER_DETAILS_CONFIG_ERROR';

// -----------------  DELETE PLAYER VIDEO SESSION  -----------------
export const PLAYER_DETAILS_DELETE_VIDEO_SESSION_LOADING =
	'PLAYER_DETAILS_DELETE_VIDEO_SESSION_LOADING';
export const PLAYER_DETAILS_DELETE_VIDEO_SESSION_SUCCESS =
	'PLAYER_DETAILS_DELETE_VIDEO_SESSION_SUCCESS';
export const PLAYER_DETAILS_DELETE_VIDEO_SESSION_ERROR =
	'PLAYER_DETAILS_DELETE_VIDEO_SESSION_ERROR';

// -----------------  UPDATE PLAYER VIDEO SESSION  -----------------
export const PLAYER_DETAILS_UPDATE_VIDEO_SESSION_SUCCESS =
	'PLAYER_DETAILS_UPDATE_VIDEO_SESSION_SUCCESS';

// -----------------  PLAYER READY  -----------------
export const PLAYER_DETAILS_IS_READY = 'PLAYER_DETAILS_IS_READY';

// -----------------  SET PLAYER PLAYBACK ERROR  -----------------
export const PLAYER_DETAILS_SET_PLAYBACK_ERROR =
	'PLAYER_DETAILS_SET_PLAYBACK_ERROR';

//***********************************************************************/
// -----------------  FETCH PLAYER VOD PREVIEW CONFIGURATION -----------------
export const FETCH_PLAYER_VOD_PREVIEW_CONFIG_LOADING =
	'FETCH_PLAYER_VOD_PREVIEW_CONFIG_LOADING';
export const FETCH_PLAYER_VOD_PREVIEW_CONFIG_SUCCESS =
	'FETCH_PLAYER_VOD_PREVIEW_CONFIG_SUCCESS';
export const FETCH_PLAYER_VOD_PREVIEW_CONFIG_ERROR =
	'FETCH_PLAYER_VOD_PREVIEW_CONFIG_ERROR';

// -----------------  DELETE PLAYER VIDEO SESSION  -----------------
export const PLAYER_VOD_PREVIEW_DELETE_VIDEO_SESSION_LOADING =
	'PLAYER_VOD_PREVIEW_DELETE_VIDEO_SESSION_LOADING';
export const PLAYER_VOD_PREVIEW_DELETE_VIDEO_SESSION_SUCCESS =
	'PLAYER_VOD_PREVIEW_DELETE_VIDEO_SESSION_SUCCESS';
export const PLAYER_VOD_PREVIEW_DELETE_VIDEO_SESSION_ERROR =
	'PLAYER_VOD_PREVIEW_DELETE_VIDEO_SESSION_ERROR';

// -----------------  UPDATE PLAYER VIDEO SESSION  -----------------
export const PLAYER_VOD_PREVIEW_UPDATE_VIDEO_SESSION_SUCCESS =
	'PLAYER_VOD_PREVIEW_UPDATE_VIDEO_SESSION_SUCCESS';

// -----------------  PLAYER READY  -----------------
export const PLAYER_VOD_PREVIEW_IS_READY = 'PLAYER_VOD_PREVIEW_IS_READY';

// -----------------  SET PLAYER PLAYBACK ERROR  -----------------
export const PLAYER_VOD_PREVIEW_SET_PLAYBACK_ERROR =
	'PLAYER_DETAILS_SET_PLAYBACK_ERROR';

//***********************************************************************/
// -----------------  CLEAR_PLAYER PLAYBACK ERROR  -----------------
export const PLAYER_CLEAR_PLAYBACK_ERROR = 'PLAYER_CLEAR_PLAYBACK_ERROR';
export const PLAYER_DETAILS_CLEAR_PLAYBACK_ERROR =
	'PLAYER_DETAILS_CLEAR_PLAYBACK_ERROR';
export const PLAYER_VOD_PREVIEW_CLEAR_PLAYBACK_ERROR =
	'PLAYER_VOD_PREVIEW_CLEAR_PLAYBACK_ERROR';

// -----------------  SET PLAYER TV OPEN  -----------------
export const SET_PLAYER_TV_OPEN = 'SET_PLAYER_TV_OPEN';
