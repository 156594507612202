import React from 'react';
import { string } from 'prop-types';

// Import utilities
import { useProductTranslations } from 'components/utilities';

// Import styles
import { Wrapper, Item, Title, Info, Divider } from './styles';

const ItemDetails = ({ data }) => {
	const {
		product: { title, year, country, rating, genres }
	} = useProductTranslations({ data });

	const genre = genres?.length && genres[0].name;

	const hasAgeRating = typeof rating === 'number';

	return (
		<Wrapper className="hover-wrap">
			<Item className="hover-item">
				{!!title && <Title>{title}</Title>}

				{year && (
					<>
						<Info>{year}</Info>
						<Divider>|</Divider>
					</>
				)}

				{genre && (
					<>
						<Info>{genre}</Info>
						<Divider>|</Divider>
					</>
				)}

				{country && (
					<>
						<Info>{country}</Info>
						<Divider>|</Divider>
					</>
				)}

				{hasAgeRating && <Info>{rating}+</Info>}
			</Item>
		</Wrapper>
	);
};

ItemDetails.propTypes = {
	title: string,
	year: string,
	genres: string,
	rating: string,
	country: string
};

export default ItemDetails;
