import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Import components
import NavLinks from './NavLinks';
import NavToolbar from './nav_toolbar/NavToolbar';
import NavSearch from './nav_search/NavSearch';
import NavTemplate from './nav_template';
import { DesktopWrapper, StyledPortalLogo, WrapperElement } from './styles';
// Import utilities
import { ToggleRPC } from 'components/utilities';

const Nav = forwardRef((props, ref) => {
	const { menuLinks } = useSelector(({ menu }) => menu);

	return (
		<DesktopWrapper intersection={props?.intersection}>
			<ToggleRPC>
				{({ on, toggle }) => (
					<NavTemplate data-testid="nav">
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<StyledPortalLogo />
							{!on && <NavLinks menuNavLinks={menuLinks} />}
						</div>
						<WrapperElement style={{ display: 'flex' }} ref={ref}>
							{on && <NavSearch toggle={toggle} />}
							{!on && <NavToolbar toggle={toggle} />}
							{props?.intersection && <div style={{ width: 50 }} />}
						</WrapperElement>
					</NavTemplate>
				)}
			</ToggleRPC>
		</DesktopWrapper>
	);
});

Nav.propTypes = {
	intersection: PropTypes.bool
};

export default Nav;
