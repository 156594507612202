export const FRESH_MAIL_API = '/newsletter/subscriber/add';

export const FRESH_MAIL_API_OPTIONS = {
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Accept: 'application/json',
		Authorization: `Bearer ${process.env.REACT_APP_FRESH_MAIL_TOKEN}`
	}
};
