import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// Import icons

// Import components
import { ToolBox } from './styles';

// Import helpers
import { checkAppType } from 'helpers';
import { useTranslation } from 'react-i18next';
import RenderButtonType from './helpers/RenderButtonType';

const PopoverToolbox = ({
	reminderId,
	linkDetails,
	programId,
	isUpcoming = false,
	isCatchup = false,
	isNotEnded = false,
	startDate,
	showRemindersMenu = true
}) => {
	const { t } = useTranslation();

	const { data } = useSelector(({ devices }) => devices);
	const { isTvSmart } = checkAppType();

	const recordingConditions = isNotEnded && data.length > 0 && !isTvSmart;

	const showShareButton = false;

	const getButtonText = () => {
		if (isNotEnded && !isUpcoming) {
			return t('vod_buttons_play');
		}
		return t('vod_buttons_live_check_details');
	};

	return (
		<ToolBox>
			<RenderButtonType
				showRemindersMenu={showRemindersMenu}
				isUpcoming={isUpcoming}
				isNotEnded={isNotEnded}
				reminderId={reminderId}
				programId={programId}
				startDate={startDate}
				recordingConditions={recordingConditions}
				showShareButton={showShareButton}
				linkDetails={linkDetails}
				isCatchup={isCatchup}
				buttonText={getButtonText()}
			/>
		</ToolBox>
	);
};

PopoverToolbox.propTypes = {
	isUpcoming: PropTypes.bool.isRequired,
	programId: PropTypes.string.isRequired,
	linkDetails: PropTypes.shape({
		pathname: PropTypes.string,
		state: PropTypes.shape({ sCatchup: PropTypes.bool }),
		hash: PropTypes.string
	}),
	reminderId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
	isCatchup: PropTypes.bool,
	isNotEnded: PropTypes.bool,
	startDate: PropTypes.string,
	showRemindersMenu: PropTypes.bool
};

export default PopoverToolbox;
