import _ from 'lodash';

// Import helpers
import { convertDateToLocale } from 'helpers';

// ------------ DATE PROPERTIES ------------
const DATE_PROPERTIES = {
	CREATED_AT: 'created_at',
	UPDATED_AT: 'updated_at',
	LAST_LOGIN_DATE: 'last_login_date',
	LAST_LOGIN: 'last_login',
	SINCE: 'since',
	TILL: 'till',
	EXPIRES_AT: 'expires_at',
	PROGRAM_END: 'program_end',
	PROGRAM_START: 'program_start',
	AVAILABILITY_EXPIRATION_DATE: 'availability_expiration_date',
	EXPIRATION_DATE: 'expiration_date',
	NEXT_CHECKOUT_DATE: 'next_checkout_date'
};

const PROPERTIES_TO_CONVERT = ['data', 'episodes'];

const DATE_NAMES = Object.values(DATE_PROPERTIES);

const convertItemDates = (item) => {
	let itemData = { ...item };

	// apply date conversion for all properties listed in datesToConvert array
	// gives ability to seamlessly add or remove data keys that are needed for conversion
	DATE_NAMES.forEach((key) => {
		const date = item[key];
		if (date) {
			itemData[key] = convertDateToLocale(date);
		}
	});

	return itemData;
};

const checkIsObject = (value) => _.isObject(value) && !_.isArray(value);

const checkHasDates = (data) =>
	// eslint-disable-next-line
	checkIsObject(data) && DATE_NAMES.some((key) => data?.hasOwnProperty(key));

const convertPayloadData = (payload) => {
	const isArray = _.isArray(payload);
	const isObject = checkIsObject(payload);

	// if payload is array check if item is object with dates
	// then convert item dates or return item
	if (isArray) {
		return payload.map((item) =>
			checkHasDates(item) ? convertItemDates(item) : item
		);
	}

	// if payload is object check if has property 'data' or is object with dates
	if (isObject) {
		const payloadData = { ...payload };

		// if payload has property - run this function to convert data
		PROPERTIES_TO_CONVERT.forEach((key) => {
			const propertyData = payloadData[key];

			if (propertyData) {
				payloadData[key] = convertPayloadData(propertyData);
			}
		});

		// if payload has dates properties convert them
		if (checkHasDates(payloadData)) {
			return convertItemDates(payloadData);
		}

		return payloadData;
	}

	return payload;
};

export const convertApiResponse = ({ data, ...rest }) => {
	const convertedData = convertPayloadData(data);
	return { ...rest, data: convertedData };
};
