import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, object } from 'prop-types';

// Import components
import {
	Loader,
	Button,
	PlayerError,
	PlayerElements as Player,
	PlayerPip
} from 'components/elements';
import PlayerControllers from '../player_overlay/player_controllers/PlayerControllers';
import { PlayerStickyButtons } from 'components/elements/organisms/player/PlayerStickyButtons';
import { ButtonCloseFullScreen, IconPlayStyled } from './styles';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';

// Import variables
import {
	DEFAULT_PLAYER,
	THEO_PLAYER,
	PLAYER_SELECTORS
} from 'helpers/variables';

const isStickyControllerMode = false;

const { PLAYER } = PLAYER_SELECTORS;

const PlayerTvContent = ({ error, isFullScreen, isCatchup }) => {
	const { t } = useTranslation();
	const {
		isReady,
		isPaused,
		play,
		pause,
		setFullScreen: openPlayerFullScreen,
		isEnded,
		catchupRepeat,
		showLoader,
		isSiedebarVisible,
		isFocus
	} = usePlayerTvContext();

	// const { isFloatMode, closeFloatMode } = useFloatWidget();

	const isFloatModeScreen = false;

	const closeButtonCondition = !isSiedebarVisible && isFullScreen && isFocus;

	const isTheoPlayer = DEFAULT_PLAYER === THEO_PLAYER;

	const handleBigPlayButtonClick = (params) => {
		const { play, pause, isPaused, isEnded, catchupRepeat } = params;
		const videoPlayPause = () => (isPaused ? play() : pause());

		// repeat stream if catchup has ended
		// else play/pause stream
		isEnded ? catchupRepeat() : videoPlayPause();
	};

	const renderClosebutton = (close = () => {}) => (
		<ButtonCloseFullScreen
			onClick={close}
			isFocus={isFocus}
			modifiers={['icon', 'playerIconHover']}
			isFloatMode={isFloatModeScreen}
		>
			<Player.IconCloseThin isFloatMode={isFloatModeScreen} />
		</ButtonCloseFullScreen>
	);

	const renderPlayerControllers = () => (
		<PlayerControllers
			isCatchup={isCatchup}
			isFocus={isFocus}
			isFloatMode={isFloatModeScreen}
		/>
	);

	return (
		<>
			{showLoader && (
				<Loader zIndex={1} position="absolute" background={false} />
			)}

			{!isTheoPlayer && (
				<>
					{closeButtonCondition &&
						isCatchup &&
						renderClosebutton(openPlayerFullScreen)}
					{closeButtonCondition && renderClosebutton(openPlayerFullScreen)}

					{/* {isFloatModeScreen && renderClosebutton(closeFloatMode)} */}

					{isReady && (isFullScreen || isCatchup || !isStickyControllerMode) ? (
						renderPlayerControllers()
					) : (
						<PlayerStickyButtons>
							{renderPlayerControllers()}
						</PlayerStickyButtons>
					)}
				</>
			)}

			{isReady && isCatchup && isPaused && (
				<Button
					isCatchupControllers={false}
					modifiers={['icon', 'catchup', 'playerIconHover', 'playerPlay']}
					onClick={() =>
						handleBigPlayButtonClick({
							play,
							pause,
							isPaused,
							isEnded,
							catchupRepeat
						})
					}
					title={t('common_player_play_title')}
				>
					<IconPlayStyled
						aria-hidden="true"
						title={t('common_player_play_title')}
					/>
				</Button>
			)}

			<PlayerPip isReady={isReady} />

			{error?.isError && (
				<PlayerError error={error} playerType={PLAYER} isFloatMode={false} />
			)}
		</>
	);
};

PlayerTvContent.propTypes = {
	error: object.isRequired,
	setPlayerCustomError: func.isRequired,
	isFullScreen: bool.isRequired,
	isCatchup: bool.isRequired
};

export default PlayerTvContent;
