// Import api
import {
	GET_PACKET_PRODUCTS_LIST_API,
	GET_PASS_PRODUCTS_LIST_API
} from 'helpers/api';

// Import helpers
import { PRODUCTS_TYPES } from 'helpers/variables';

const { COLLECTION, PACKET, PASS } = PRODUCTS_TYPES;

export const getProductListApi = ({ subtype, id }) => {
	let apiUrl = '';

	switch (subtype) {
		case COLLECTION:
		case PACKET:
			apiUrl = GET_PACKET_PRODUCTS_LIST_API;
			break;

		case PASS:
			apiUrl = GET_PASS_PRODUCTS_LIST_API;
			break;

		default:
			break;
	}

	return apiUrl.replace('id', id);
};
