import styled from 'styled-components';

// Import styles
import { sectionBackground } from 'components/styles';

export const Root = styled.div`
	height: ${({ height }) => height}px;
	width: 100%;
	display: grid;
	grid-template-rows: ${({ header }) => `${header}px 1fr`};
	align-items: center;
	justify-items: center;
	grid-gap: ${({ gridGap }) => gridGap}px;

	${({ backgroundImage }) =>
		backgroundImage && sectionBackground(backgroundImage)}
`;

export const SliderContainer = styled.div`
	height: ${({ height }) => height}px;
	width: ${({ width }) => width}px;
	grid-row-start: 2;
	grid-row-end: 3;
	z-index: 2;
`;

export const ItemContainer = styled.div`
	display: flex;
	justify-content: center;
`;
