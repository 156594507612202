import React from 'react';
import { number, array, func, oneOfType, bool, string } from 'prop-types';
import Slider from 'react-slick';

// Components
import { CustomArrow } from './CustomArrow';
import { BlankItem } from './BlankItem';
import { Root, SliderContainer, ItemContainer } from './style';

// Logic
import { useLogic } from './useLogic';

export function Basic(props) {
	const {
		renderItemComponent,
		renderHeader,
		headerSliderGap,
		itemsToShow,
		backgroundImage = false
	} = props;
	const {
		containerRef,
		containerHeight,
		checkedHeaderHeight,
		containerWidth,
		itemAndSliderHeight,
		sliderWidth,
		itemWidth,
		processedItemsList
	} = useLogic(props);

	return (
		<Root
			ref={containerRef}
			height={containerHeight}
			header={checkedHeaderHeight}
			gridGap={headerSliderGap}
			backgroundImage={backgroundImage}
		>
			{!!containerWidth && (
				<>
					{!!renderHeader && renderHeader()}
					<SliderContainer height={itemAndSliderHeight} width={sliderWidth}>
						<Slider
							infinite={false}
							dots={false}
							rows={1}
							slidesToScroll={itemsToShow}
							slidesToShow={itemsToShow}
							initialSlide={0}
							nextArrow={<CustomArrow direction="right" />}
							prevArrow={<CustomArrow direction="left" />}
						>
							{processedItemsList.map((item, index) => (
								<div key={`${item.uuid || item}-${index}`}>
									<ItemContainer>
										{item === 'extender' ? (
											<BlankItem
												width={itemWidth}
												height={itemAndSliderHeight}
											/>
										) : (
											renderItemComponent({
												data: item,
												height: itemAndSliderHeight,
												width: itemWidth,
												index
											})
										)}
									</ItemContainer>
								</div>
							))}
						</Slider>
					</SliderContainer>
				</>
			)}
		</Root>
	);
}

Basic.propTypes = {
	itemResolutionWidth: number.isRequired,
	itemResolutionHeight: number.isRequired,
	itemsList: array.isRequired,
	spaceBetweenItems: number.isRequired,
	horizontalPadding: number,
	verticalPadding: number,
	headerHeight: number,
	itemsToShow: number.isRequired,
	headerSliderGap: number,
	renderItemComponent: func.isRequired,
	renderHeader: func,
	backgroundImage: oneOfType([string, bool])
};

Basic.defaultProps = {
	horizontalPadding: 40,
	verticalPadding: 24,
	headerHeight: 90,
	headerSliderGap: 0,
	backgroundImage: false
};
