import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Import components
import {
	AriaBaseModal,
	AriaModalContent,
	CommonButton
} from 'components/elements';
import { StyledLink } from './styles';

// Import variables
import { FOOTER_PATHS } from 'components/routes/paths';

const TransactionError = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const handleGoBack = () => history.goBack();

	const buttonText = t('common_modal_close_button_title');
	const title = t('payment_error_title');
	const description = (
		<>
			{t('payment_error_description')}
			<StyledLink to={FOOTER_PATHS.CONTACT}>
				{t('payment_error_description_contact_from')}
			</StyledLink>
		</>
	);

	return (
		<AriaBaseModal
			titleText={title}
			close={handleGoBack}
			isCloseButton={false}
			style={{ width: '47vw' }}
		>
			<AriaModalContent
				title={title}
				description={description}
				childrenDirection="column"
			>
				<CommonButton onClick={handleGoBack} title={buttonText}>
					{buttonText}
				</CommonButton>
			</AriaModalContent>
		</AriaBaseModal>
	);
};

export default TransactionError;
