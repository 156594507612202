import React from 'react';
import { string, func, bool, number } from 'prop-types';

// Import icons
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close_thin.svg';

// Import styles
import {
	Wrapper,
	SearchIconStyled,
	FormStyled,
	InputStyled,
	ButtonStyled
} from './styles';

const SearchBar = ({
	searchQuery,
	setSearchQuery,
	clearSearchQuery,
	placeholder,
	showClearButton,
	ariaLabel,
	searchIconWidth,
	searchIconHeight
}) => {
	// Prevent default form actions
	const handleOnSubmit = (event) => event.preventDefault();

	return (
		<Wrapper className="search_bar">
			<SearchIconStyled width={searchIconWidth} height={searchIconHeight} />
			<FormStyled onSubmit={handleOnSubmit} showClearButton={showClearButton}>
				<InputStyled
					name="search_query"
					type="text"
					id="search_query"
					placeholder={placeholder}
					onChange={setSearchQuery}
					value={searchQuery}
					aria-label={ariaLabel}
				/>
			</FormStyled>
			{showClearButton && (
				<ButtonStyled isVisible={searchQuery} onClick={clearSearchQuery}>
					<CloseIcon />
				</ButtonStyled>
			)}
		</Wrapper>
	);
};

SearchBar.defaultProps = {
	searchQuery: '',
	setSearchQuery: () => {},
	clearSearchQuery: () => {},
	placeholder: '',
	showClearButton: true,
	ariaLabel: '',
	searchIconWidth: 2.4,
	searchIconHeight: 2.4
};

SearchBar.propTypes = {
	searchQuery: string.isRequired,
	setSearchQuery: func.isRequired,
	clearSearchQuery: func.isRequired,
	placeholder: string,
	showClearButton: bool,
	ariaLabel: string.isRequired,
	searchIconWidth: number,
	searchIconHeight: number
};

export default SearchBar;
