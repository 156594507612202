import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	isPaymentLoading: false,
	isPaymentError: false,
	paymentErrorMessage: '',
	data: {}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Fetch payment init *******************
			case types.FETCH_PAYMENT_INIT_LOADING:
				draft.isPaymentLoading = true;
				return;

			case types.FETCH_PAYMENT_INIT_SUCCESS:
				draft.data = action.payload;
				draft.isPaymentLoading = false;
				return;

			case types.FETCH_PAYMENT_INIT_ERROR:
				draft.isPaymentError = true;
				draft.isPaymentLoading = false;
				draft.paymentErrorMessage = action.payload.response?.data?.message;
				return;

			// ******************* Clear payment *******************
			case types.PAYMENT_CLEAR:
				return INITIAL_STATE;

			default:
				return state;
		}
	});
