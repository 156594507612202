import styled from 'styled-components';

// Import icons
import { ReactComponent as IconTick } from 'assets/icons/ic_tick.svg';

const Box = styled.div`
	position: absolute;
	top: -1.6rem;
	right: -2.4rem;
	width: 6rem;
	height: 4rem;
	transform: rotate(45deg);
`;

const BgGradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(
		0deg,
		${({ theme: { slider } }) => slider.available.bgColorStart} 12%,
		${({ theme: { slider } }) => slider.available.bgColorEnd} 75%
	);
	opacity: 0.6;
	z-index: -1;
`;

const Icon = styled(IconTick)`
	position: absolute;
	bottom: 0.6rem;
	left: 2.7rem;
	height: 0.7rem;
	transform: rotate(-41deg);
`;

export { Box, BgGradient, Icon };
