import React from 'react';
import { useSelector } from 'react-redux';
import { array } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import variables
import { INAPPROPRIATE_VOD_CONTENT_TYPES } from 'helpers/variables';

// Import styled components
import {
	StyledIconContentRatingP,
	StyledIconContentRatingS,
	StyledIconContentRatingN,
	StyledIconContentRatingW,
	Wrapper
} from './styles';

const ContentRatingIcons = ({ contentRating }) => {
	const { t } = useTranslation();
	const { isContrast } = useSelector(
		({ wcag_accessibility }) => wcag_accessibility
	);

	const renderContentRating = (rating) => {
		switch (rating) {
			case INAPPROPRIATE_VOD_CONTENT_TYPES.VIOLENCE:
				return (
					<StyledIconContentRatingP
						isContrast={isContrast}
						aria-hidden="true"
						key={rating}
						title={t(`inappropriate_vod_content_type_violence`)}
					/>
				);
			case INAPPROPRIATE_VOD_CONTENT_TYPES.SEX:
				return (
					<StyledIconContentRatingS
						isContrast={isContrast}
						aria-hidden="true"
						key={rating}
						title={t(`inappropriate_vod_content_type_sex`)}
					/>
				);
			case INAPPROPRIATE_VOD_CONTENT_TYPES.DRUGS:
				return (
					<StyledIconContentRatingN
						isContrast={isContrast}
						aria-hidden="true"
						key={rating}
						title={t(`inappropriate_vod_content_type_drugs`)}
					/>
				);
			case INAPPROPRIATE_VOD_CONTENT_TYPES.VULGARISMS:
				return (
					<StyledIconContentRatingW
						isContrast={isContrast}
						aria-hidden="true"
						key={rating}
						title={t(`inappropriate_vod_content_type_vulgarisms`)}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<Wrapper>
			{contentRating.map((rating) => renderContentRating(rating))}
		</Wrapper>
	);
};

ContentRatingIcons.propTypes = {
	contentRating: array.isRequired
};

export default ContentRatingIcons;
