// API
export const GET_DOCUMENT_BY_ALIAS_API = 'documents/byAlias/type';

// Queries
export const QUERY_DOCUMENT_BY_ALIAS = 'QUERY_DOCUMENT_BY_ALIAS';

export const QUERY_COOKIES_DOCUMENTS = 'QUERY_COOKIES_DOCUMENTS';
export const QUERY_MARKETING_DOCUMENTS = 'QUERY_MARKETING_DOCUMENTS';
export const QUERY_KEY_CONTACT_FORM_DOCUMENTS =
	'QUERY_KEY_CONTACT_FORM_DOCUMENTS';
