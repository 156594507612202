import React from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

// Import components
import { Alert, Loader } from 'components/elements';
import Section from './Section';
import NoContentMessage from './NoContentMessage';

// Import styles
import { Wrapper } from './styles';

// Import utilities
import { useSectionsQuery } from 'components/utilities';

// Import templates
import { SectionsTemplate } from 'components/templates';

const Sections = ({ label }) => {
	const { t } = useTranslation();

	const { isLoading, sections, isError, isSections } = useSectionsQuery({
		label
	});

	return (
		<Wrapper>
			{isLoading && <Loader background={false} position="absolute" />}

			{isError && <Alert fixed={true} text={t('common_errors_connection')} />}

			{isSections && (
				<SectionsTemplate sections={sections} label={label}>
					{({ data, showContentMessage }) => (
						<>
							{showContentMessage && <NoContentMessage />}

							{data.map((section, index) => (
								<Section key={section?.id} section={section} index={index} />
							))}
						</>
					)}
				</SectionsTemplate>
			)}
		</Wrapper>
	);
};

Sections.defaultProps = {
	label: ''
};

Sections.propTypes = {
	label: string.isRequired
};

export default Sections;
