import React from 'react';
import { array, string } from 'prop-types';

// Import helpers
import LivePlaceholder from './video_banner_live/LivePlaceholder';
import { useAuth } from 'components/utilities';
import VideoBanners from './VideoBanners';

const VideoBannersContainer = ({ name, content, type }) => {
	const { isAuth } = useAuth();

	const isPublic = content[0]?.previews?.banner[0]?.is_public ?? false;

	if (!isPublic && !isAuth) {
		return <LivePlaceholder />;
	}
	return <VideoBanners name={name} type={type} content={content} />;
};

VideoBannersContainer.propTypes = {
	content: array.isRequired,
	type: string.isRequired,
	name: string.isRequired
};

export default VideoBannersContainer;
