// MOVIES LIST
export const MOVIES_LIST_LOADING = 'MOVIES_LIST_LOADING';
export const MOVIES_LIST_SUCCESS = 'MOVIES_LIST_SUCCESS';
export const MOVIES_LIST_ERROR = 'MOVIES_LIST_ERROR';
export const MOVIES_LIST_CLEAR = 'MOVIES_LIST_CLEAR';
export const MOVIES_LIST_CLEAR_DATA = 'MOVIES_LIST_CLEAR_DATA';

// FILTER
export const UPDATE_MOVIES_FILTER = 'UPDATE_MOVIES_FILTER';
export const UPDATE_SORTING_NAME = 'UPDATE_SORTING_NAME';
