import { useContext } from 'react';

// Import context
import { ProductPaymentContext } from './ProductPaymentProvider';

const useProductPaymentContext = () => {
	const context = useContext(ProductPaymentContext);
	return context;
};

export default useProductPaymentContext;
