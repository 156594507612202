import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import components
import { CommonButton } from 'components/elements';

const MoreInfoButton = ({ url, ...restProps }) => {
	const { t } = useTranslation();

	if (!url) return null;

	return (
		<CommonButton
			as="a"
			href={url}
			target="_blank"
			rel="noopener noreferrer"
			{...restProps}
		>
			{t('common_more_info_button')}
		</CommonButton>
	);
};

MoreInfoButton.propTypes = {
	url: string
};

export default MoreInfoButton;
