import React from 'react';

// Import components
import AppRoutesDesktop from './AppRoutesDesktop';
import Routes from './Routes';

// Import helpers
import { checkIsDesktopOnly } from 'helpers';

const AppRoutes = () => {
	const isDesktopOnly = checkIsDesktopOnly();

	return isDesktopOnly ? <AppRoutesDesktop /> : <Routes />;
};

export default AppRoutes;
