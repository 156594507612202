import React from 'react';
import PropTypes from 'prop-types';

// Import logic
import useNavSelect from './useNavSelect';

// Import components
import { Select } from 'antd';

const { Option } = Select;

const NavSelect = ({ path, links }) => {
	const { category, onChange } = useNavSelect(path);
	return (
		<Select onChange={onChange} value={category}>
			{links.map(({ id, name }) => (
				<Option key={id} value={id}>
					{name}
				</Option>
			))}
		</Select>
	);
};

NavSelect.propTypes = {
	links: PropTypes.array.isRequired,
	path: PropTypes.string,
};

export default NavSelect;
