// ----------------- FETCH FAVOURITES -----------------
export const FETCH_FAVOURITES_LOADING = 'FETCH_FAVOURITES_LOADING';
export const FETCH_FAVOURITES_SUCCESS = 'FETCH_FAVOURITES_SUCCESS';
export const FETCH_FAVOURITES_ERROR = 'FETCH_FAVOURITES_ERROR';

// ----------------- ADD FAVOURITES -----------------
export const ADD_FAVOURITE_LOADING = 'ADD_FAVOURITE_LOADING';
export const ADD_FAVOURITE_SUCCESS = 'ADD_FAVOURITE_SUCCESS'; //todo: not used
export const ADD_FAVOURITE_ERROR = 'ADD_FAVOURITE_ERROR';

// ----------------- DELETE FAVOURITES -----------------
export const DELETE_FAVOURITE_LOADING = 'DELETE_FAVOURITE_LOADING';
export const DELETE_FAVOURITE_SUCCESS = 'DELETE_FAVOURITE_SUCCESS';
export const DELETE_FAVOURITE_ERROR = 'DELETE_FAVOURITE_ERROR';
