import * as types from 'store/actions/types';
import produce from 'immer';

// Import helpers
import { AVAILABLE_FONT_SIZE } from 'helpers/wcag';

const { DEFAULT } = AVAILABLE_FONT_SIZE;

const INITIAL_STATE = {
	areAnimationsAvailable: true,
	isContrast: false,
	fontSize: DEFAULT
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.RESET_TO_DEFAULT_SETTINGS:
				return { ...INITIAL_STATE };

			case types.TOGGLE_APP_CONTRAST:
				draft.isContrast = !state.isContrast;
				return;

			case types.TOGGLE_ANIMATIONS_AVAILABILITY:
				draft.areAnimationsAvailable = !state.areAnimationsAvailable;
				return;

			case types.SET_FONT_SIZE:
				draft.fontSize = action.payload;
				return;

			default:
				return state;
		}
	});
