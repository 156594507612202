import styled from 'styled-components';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Button } from 'components/elements';

export const Wrapper = styled.div`
	${flex({ jc: 'flex-start' })};
	height: 7.5rem;
	padding: 0 ${({ isLogged }) => (isLogged ? '0' : '2.5rem')};
	background: ${({ theme }) => theme.body.nav.menuMobileBgColor};
	border-bottom: 1px solid ${({ theme }) => theme.line};
`;

export const LogInButton = styled(Button)`
	text-align: center;
	line-height: 1.9rem;
	width: 100%;
	padding: 1.1rem 0;
	text-transform: uppercase;
`;

export const LogOutButton = styled.button`
	font-size: ${({ theme }) => theme.fontSize.nav.menuMobileItem}rem;
	line-height: 2.4rem;
	font-weight: ${({ theme }) => theme.font.medium};
	color: ${({ theme }) => theme.body.nav.color};
	text-align: left;
	padding: 0 3rem;
	outline: none;
	border: none;
	width: 100%;
	height: 100%;
	background: transparent;
	cursor: pointer;
	text-transform: uppercase;
`;
