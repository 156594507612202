import React from 'react';
import PropTypes from 'prop-types';

// Import components
import { ModalForm } from 'components/elements';
import ParentalControlFields from './parental_control_fields/ParentalControlFields';
import AriaBaseModal from 'components/elements/organisms/aria_modal/AriaBaseModal';

const ParentalControlModal = ({
	toggle,
	title,
	isEnabled,
	initialValues,
	validation,
	on,
	submitAction
}) => {
	return (
		on && (
			<AriaBaseModal
				style={{ maxWidth: '600px' }}
				close={toggle}
				titleText={title}
				underlayClickExits={false}
			>
				<ModalForm
					title={title}
					initialValues={initialValues}
					submitAction={submitAction}
					type="parental_control"
					translation="parental_control_error_message"
					isOpen={on}
					closeModal={toggle}
					validation={validation}
					isEnabled={isEnabled}
				>
					{({ isSubmitting }) => (
						<ParentalControlFields
							isEnabled={isEnabled}
							isSubmitting={isSubmitting}
						/>
					)}
				</ModalForm>
			</AriaBaseModal>
		)
	);
};

ParentalControlModal.propTypes = {
	toggle: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	isEnabled: PropTypes.bool.isRequired,
	initialValues: PropTypes.object.isRequired,
	validation: PropTypes.func.isRequired,
	on: PropTypes.bool.isRequired,
	submitAction: PropTypes.func.isRequired
};

export default ParentalControlModal;
