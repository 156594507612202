import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { string, func, array } from 'prop-types';

// Import components
import { Loader } from 'components/elements';

// Import logic
import useSectionsTemplate from './useSectionsTemplate';

const SectionsTemplate = ({ sections, label, children }) => {
	const {
		fetchNextPage,
		hasNextPage,
		sectionsData,
		isLoading,
		isContentFetched,
		hasContent,
		showContentMessage
	} = useSectionsTemplate({
		sections,
		label
	});

	return (
		<>
			{isLoading && <Loader position="absolute" background={false} />}

			<InfiniteScroll
				pageStart={0}
				loadMore={fetchNextPage}
				hasMore={hasNextPage}
				loader={
					<Loader position="static" background={false} wrapperHeight="100vh" />
				}
				threshold={1500}
			>
				{children({
					data: sectionsData,
					isContentFetched,
					hasContent,
					showContentMessage
				})}
			</InfiniteScroll>
		</>
	);
};

SectionsTemplate.propTypes = {
	sections: array.isRequired,
	label: string.isRequired,
	children: func.isRequired
};

export default SectionsTemplate;
