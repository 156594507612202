import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { APP_CORE_PATHS } from 'components/routes/paths/app';
// Import components
import { Main } from './helpers/components';
import { RouteWithHelmet } from 'components/elements';

// Import routes
import { appRoutes } from './helpers/routes';

const ProtectedRoutes = () => {
	return (
		<Switch>
			<Redirect exact path="/" to={APP_CORE_PATHS.HOME_PAGE} />
			{appRoutes.map(({ path, ...route }) => (
				<RouteWithHelmet key={path} path={path} {...route} />
			))}
			<Main />
			<Redirect to={APP_CORE_PATHS.HOME_PAGE} />
		</Switch>
	);
};

export default ProtectedRoutes;
