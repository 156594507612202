// HELPERS FOR PROMOTIONS

import { isEmpty } from 'lodash';

// initial promotion item
const initialItem = {
	id: null,
	price: null,
	voucherCode: null,
	voucherMessage: ''
};

// get promotion available for user
const getAvailablePromotions = (promotions, userPromotions) => {
	return Object.keys(promotions)
		.filter((key) => userPromotions.includes(parseInt(key)))
		.reduce((obj, key) => {
			obj[key] = promotions[key];
			return obj;
		}, {});
};

// create array of promotions objects
const createPromotionsObjects = (promotions) =>
	Object.entries(promotions).map((item) => {
		return {
			id: item[0],
			price: item[1],
			voucherCode: null,
			voucherMessage: ''
		};
	});

// sort promotions by price and return first element
const getLowestPriceItem = (promotions) =>
	promotions.sort((a, b) => a.price - b.price)[0];

// get the most favorable promotion for user
const getPromotion = (
	regularPrice,
	productPromotions,
	userPromotions,
	isVoucher = false
) => {
	const availablePromotions = getAvailablePromotions(
		productPromotions,
		userPromotions
	);
	const promotionsArray = createPromotionsObjects(availablePromotions);
	const favorablePromotion = getLowestPriceItem(promotionsArray);

	const isPromotion =
		favorablePromotion && favorablePromotion.price < regularPrice;

	const promotionNotExist = isVoucher
		? { ...initialItem, voucherMessage: 'voucher_expensive' }
		: initialItem;

	if (isVoucher && isEmpty(availablePromotions)) {
		return { ...initialItem, voucherMessage: 'voucher_not_exist' };
	}

	return isPromotion ? favorablePromotion : promotionNotExist;
};

export { initialItem, getPromotion };
