import React from 'react';
import PropTypes from 'prop-types';

// Import utilities
import { ToggleRPC } from 'components/utilities';

// Import components
import { Loader, PlayerPip, BigPlayButton } from 'components/elements';
import usePlayerContent from '../usePlayerContent';
import PlayerControllers from 'components/elements/organisms/player_vod/PlayerWrapper/player_controllers/PlayerControllers';
import TopHeadline from 'components/elements/organisms/player_vod/PlayerWrapper/TopHeadline';
import Resume from 'components/elements/organisms/player_vod/PlayerWrapper/resume/Resume';
import NextEpisode from 'components/elements/organisms/player_vod/PlayerWrapper/next_episode/NextEpisode';
import Recommendations from 'components/elements/organisms/player_vod/PlayerWrapper/recommendations/Recommendations';
import Watermark from '../../Watermark';

// Import styles
import { BgGradient, Poster, PosterImg } from '../styles';

const ShakaPlayerContent = ({ isFocus, type }) => {
	const {
		buffering,
		isReady,
		error,
		continueProgress,
		isEpisode,
		isEpisodeAvaiable,
		handlePlayPause,
		isFullScreen,
		setFullScreen,
		setEndMargin,
		duration,
		updateTime,
		currentTimeProgress,
		isPreviewAvailable,
		showNextEpisode,
		showRecommendation,
		showContinueProgress,
		showBigPlayButton,
		floatMode,
		isAudio,
		posterUrl
	} = usePlayerContent({ type });

	const { isFloatMode } = floatMode;

	return (
		<>
			<BgGradient
				onDoubleClick={setFullScreen}
				onClick={handlePlayPause}
				isFocus={isFocus}
			/>
			{isAudio && posterUrl && (
				<Poster>
					<PosterImg src={posterUrl} alt="Audio poster" />{' '}
				</Poster>
			)}

			{buffering && (
				<Loader background={false} position="absolute" zIndex={1} />
			)}

			<TopHeadline isFocus={isFocus} isError={error.isError} />

			<Watermark />
			{isReady && (
				<PlayerControllers
					isEpisodeAvaiable={isEpisodeAvaiable}
					isEpisode={isEpisode}
					isFullScreen={isFullScreen}
					isFocus={isFocus}
					duration={duration}
					updateTime={updateTime}
					currentTimeProgress={currentTimeProgress}
					isPreviewAvailable={isPreviewAvailable}
				/>
			)}

			{showBigPlayButton && (
				<BigPlayButton
					isFloatMode={isFloatMode && !isFullScreen}
					modifiers={['icon', 'catchup', 'playerIconHover', 'playerPlay']}
					onClick={handlePlayPause}
				/>
			)}

			{showContinueProgress && (
				<ToggleRPC initial={true}>
					{({ on, toggle }) =>
						on && <Resume currentTime={continueProgress.time} toggle={toggle} />
					}
				</ToggleRPC>
			)}

			{showNextEpisode && (
				<ToggleRPC initial={true}>
					{({ on, toggle }) =>
						on && <NextEpisode setEndMargin={setEndMargin} toggle={toggle} />
					}
				</ToggleRPC>
			)}

			{showRecommendation && <Recommendations />}

			<PlayerPip isReady={isReady} />
		</>
	);
};

ShakaPlayerContent.propTypes = {
	type: PropTypes.string.isRequired,
	isFocus: PropTypes.bool.isRequired
};

export default ShakaPlayerContent;
