export const checkIsAgreementAccepted = ({ acceptedAgreements, id }) => {
	return acceptedAgreements.includes(id);
};

export const AGREEMENTS_TYPES = {
	COOKIES: 'COOKIES',
	LOGIN: 'LOGIN',
	MARKETING: 'MARKETING',
	PAYMENT: 'PAYMENT',
	INVOICE: 'INVOICE',
	REGISTRATION: 'REGISTRATION',
	RODO: 'RODO',
	SUBSCRIBER: 'SUBSCRIBER'
};

const { REGISTRATION, RODO, MARKETING, PAYMENT, COOKIES } = AGREEMENTS_TYPES;

export const REGISTER_AGREEMENTS = [RODO, REGISTRATION, MARKETING];

export const SUBSCRIBER_ALL_AGREEMENTS = [...REGISTER_AGREEMENTS];

export const PAYMENT_AGREEMENTS = [PAYMENT];

export const COOKIES_AGREEMENTS = [COOKIES];
