import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styled components
import { StyledNav } from './styles';

// Import helpers
import { TAB_NAV_LIST } from './helpers';

const TabNav = () => {
	const { t } = useTranslation();

	const renderLinks = TAB_NAV_LIST.map(({ href, text }) => (
		<a href={href} key={text}>
			{t(text)}
		</a>
	));

	return <StyledNav>{renderLinks}</StyledNav>;
};

export default TabNav;
