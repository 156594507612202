import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	data: [],
	selectedDevice: null,
	isLoaded: false,
	isError: false
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************** FETCH DVR DEVICES ********************
			case types.FETCH_DEVICES_LOADING:
				return { ...state, data: [], isLoaded: false, isError: false };

			case types.FETCH_DEVICES_SUCCESS:
				draft.isLoaded = true;
				draft.data = action.payload;
				return;

			case types.FETCH_DEVICES_ERROR:
				draft.isError = true;
				return;

			// ******************** SELECT DEVICES ********************
			case types.SELECT_DEVICE:
				draft.selectedDevice = action.payload;
				return;

			default:
				return state;
		}
	});
