import { useSelector } from 'react-redux';

// Import context
import useInfoContext from '../context/useInfoContext';

// Import utilities
import { useProductAvailability } from 'components/utilities';

// Import helpers
import { checkProductSubtype } from 'helpers';

const useInfoTabButtons = () => {
	const { redirect } = useInfoContext();

	// Prices from movieDetails
	const { data } = useSelector(({ movieDetails }) => movieDetails);
	const { uuid: productUuid, subtype, available_in, free } = data;

	const collectionMovies = useSelector(({ moviesList }) => moviesList.data);

	const isProductAvailable = useProductAvailability(productUuid, available_in);

	const {
		isSeries,
		isCollection,
		isLiveEvent,
		isVod,
		isPass,
		isPacket,
		isEpisode
	} = checkProductSubtype(subtype);

	// Check if movie's trailer is avaiable
	const isTrailerAvailable = data?.trailers?.length;

	const isCollectionWithMovies = isCollection && collectionMovies?.length;

	const materialUuid = isCollectionWithMovies
		? collectionMovies[0].uuid
		: productUuid;

	const showPlayButton =
		isCollectionWithMovies || isVod || isLiveEvent || isEpisode;

	const showFavouriteButton = !isPass && !isPacket;

	const showPacketsButton =
		!isProductAvailable &&
		!free &&
		(isCollection || isVod || isSeries || isLiveEvent);

	return {
		redirect,
		data,
		productUuid,
		subtype,
		isProductAvailable,
		isSeries,
		isLiveEvent,
		isPass,
		isTrailerAvailable,
		showPacketsButton,
		materialUuid,
		showPlayButton,
		showFavouriteButton
	};
};

export default useInfoTabButtons;
