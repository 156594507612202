import moment from 'moment';

export const calculateTimeLeft = (targetDate) => {
	const now = moment();
	const difference = moment(targetDate).diff(now);

	let timeLeft = {};

	if (difference > 0) {
		timeLeft = {
			days: Math.floor(difference / (1000 * 60 * 60 * 24)),
			hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
			minutes: Math.floor((difference / 1000 / 60) % 60),
			seconds: Math.floor((difference / 1000) % 60)
		};
	}

	return timeLeft;
};
