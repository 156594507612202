import styled from 'styled-components';

// Import components
import { Button } from 'components/elements';

export const ButtonStyled = styled(Button)`
	margin-left: auto;
`;

export const ButtonFullScreen = styled(Button)`
	margin-right: 3rem;
`;
