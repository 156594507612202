import React from 'react';
import { string, func } from 'prop-types';
import { LANGUAGE_OPTIONS } from 'helpers/languages';
import {
	StyledDropdownWrapper,
	StyledSelect,
	StyledFlag,
	StyledLanguageText
} from './styles';
import { useLanguages } from 'components/utilities/hooks/languages/useLanguages.js';

const LanguageDropDownDesktop = () => {
	const dropdownRender = (menu) => {
		return (
			<StyledDropdownWrapper>{React.cloneElement(menu)}</StyledDropdownWrapper>
		);
	};

	const { currentLanguage, defaultLanguage, handleLanguageChange } =
		useLanguages();

	return (
		<StyledSelect
			getPopupContainer={(trigger) => trigger.parentElement}
			dropdownClassName="language-dropdown"
			dropdownRender={dropdownRender}
			value={currentLanguage}
			onChange={handleLanguageChange}
			defaultValue={defaultLanguage}
		>
			{LANGUAGE_OPTIONS.map(({ code, flag }) => {
				const Flag = flag;
				const language = new Intl.DisplayNames(code, { type: 'language' })
					.of(code)
					.toUpperCase();

				return (
					<StyledSelect.Option key={code} value={code}>
						<StyledFlag>
							<Flag />
						</StyledFlag>
						<StyledLanguageText>{language}</StyledLanguageText>
					</StyledSelect.Option>
				);
			})}
		</StyledSelect>
	);
};

LanguageDropDownDesktop.propTypes = {
	changeLanguage: func.isRequired,
	language: string.isRequired
};

export default React.memo(LanguageDropDownDesktop);
