import services from 'services/services';
import * as types from 'store/actions/types';

export async function fetchGeolockPurchase(dispatch) {
	try {
		dispatch({ type: types.GEOLOCK_PURCHASE_LOADING });

		const url = `payments/country/isPurchaseAvailable`;
		const { data } = await services.get(url);

		dispatch({
			type: types.GEOLOCK_PURCHASE_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.GEOLOCK_PURCHASE_ERROR,
			payload: error.response.data
		});
	}
}
