import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteVideoSession } from 'store/actions';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import helpres
import {
	PLAYER_SELECTORS,
	PLAYER_CONTINUE_VIDEO_INERTIA_TIME
} from 'helpers/variables';
import { fetchMovieResources } from '../helpers';
import { getVideoEndStatus } from './helpers';
import { getProductType } from 'helpers';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

// Set player type
const playerSelector = PLAYER_SELECTORS.VOD;

const usePlayerContent = ({ type }) => {
	const { continueProgress } = useSelector((state) => state.continue_watching);
	// Get data from state
	const {
		data,
		episodes: { next }
	} = useSelector((state) => state.movieDetails);

	const subtype = data?.subtype;
	const isAudio = data?.is_audio;
	const images = data?.images;

	const { error } = useSelector((state) => state[type]);
	const [scrollYPosition, setScrollYPosition] = React.useState(0);

	const store = useStore();
	const dispatch = useDispatch();
	const history = useHistory();
	const { pathname } = useLocation();
	const productType = getProductType(pathname);

	// Get data from context
	const {
		isEnded,
		isReady,
		endMarginAction,
		endMarginActionCancel,
		buffering,
		play,
		pause,
		isPaused,
		isFullScreen,
		setFullScreen,
		setEndMargin,
		isPreview,
		closePreview,
		duration,
		updateTime,
		currentTimeProgress,
		isPreviewAvailable,
		floatMode
	} = usePlayerVodContext();

	// --------- Variables ----------------
	// Check if product is avaiable / purschased by user
	const isEpisodeAvaiable = next ? !!next.uuid : false;

	// check if product is an episode
	const isEpisode = subtype === 'episode' ? true : false;

	const isTheoPlayer = DEFAULT_PLAYER === THEO_PLAYER;

	const showNextEpisode =
		endMarginAction &&
		!endMarginActionCancel &&
		next &&
		isEpisode &&
		isEpisodeAvaiable;

	const showRecommendation = endMarginAction && !isPreview && !isEpisode;

	const showContinueProgress =
		isReady &&
		!isPreview &&
		continueProgress.time >= PLAYER_CONTINUE_VIDEO_INERTIA_TIME &&
		continueProgress.time <= duration - PLAYER_CONTINUE_VIDEO_INERTIA_TIME;

	const showBigPlayButton = isReady && isPaused;

	const { isFloatMode, closeFloatMode } = floatMode;

	const isPoster = images?.poster?.length > 0;
	const posterUrl = isPoster ? images.poster[0].url : '';

	// --------- Handlers ----------------
	const handleFetchResources = async () => {
		// Delete video live session
		// Dispatch an deleteVideoSession action in player folder
		await deleteVideoSession({ type: playerSelector })(
			dispatch,
			store.getState
		);
		// Fetch movie resources
		await fetchMovieResources({
			id: next.uuid,
			type: productType,
			dispatch
		});
	};

	const handlePlayPause = () => (isPaused ? play() : pause());

	const handleOnVideoEnd = () => {
		// Cehck if movie is ended, and product type is an episode and product is purschased by user
		const { isNextEpisode, isVideoEnded } = getVideoEndStatus({
			isEnded,
			isEpisode,
			isFloatMode,
			endMarginActionCancel,
			isEpisodeAvaiable
		});

		if (isEnded && isPreview) {
			closePreview();
		} else if (isEnded && isFloatMode) {
			closeFloatMode();
		} else if (isNextEpisode) {
			handleFetchResources();
		} else if (isVideoEnded) {
			history.goBack();
		}
	};

	// --------- Effects ----------------
	React.useEffect(() => {
		handleOnVideoEnd();
		// eslint-disable-next-line
	}, [isEnded, isFloatMode]);

	React.useEffect(() => {
		if (isFullScreen) {
			setScrollYPosition(window.scrollY);
		} else if (scrollYPosition) {
			window.scrollTo(0, scrollYPosition);
		}
		// eslint-disable-next-line
	}, [isFullScreen]);

	return {
		buffering,
		isReady,
		error,
		continueProgress,
		isEpisode,
		isEpisodeAvaiable,
		handlePlayPause,
		setFullScreen,
		isFullScreen,
		setEndMargin,
		duration,
		updateTime,
		currentTimeProgress,
		isPreviewAvailable,
		isTheoPlayer,
		showNextEpisode,
		showRecommendation,
		showContinueProgress,
		showBigPlayButton,
		floatMode,
		posterUrl,
		isAudio
	};
};

export default usePlayerContent;
