import * as types from './types';
import { fetchPlaylistData } from 'components/context/player/shakaPlayer';

export const setPlaylistData = (playlist) => async (dispatch) => {
	try {
		const payload = await fetchPlaylistData(playlist);

		dispatch({ type: types.SET_PLAYLIST_DATA, payload });
	} catch (e) {
		dispatch({ type: types.SET_IS_PLAYER_ERROR });
	}
};

export const setSessionIntervalTime = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SESSION_INTERVAL_TIME, payload });
};

export const setBuffering = (payload) => (dispatch) => {
	dispatch({ type: types.SET_BUFFERING, payload });
};

export const setIsReady = (payload) => (dispatch) => {
	dispatch({ type: types.SET_IS_READY, payload });
};

export const setIsPaused = (payload) => (dispatch) => {
	dispatch({ type: types.SET_IS_PAUSED, payload });
};

export const setIsEnded = (payload) => (dispatch) => {
	dispatch({ type: types.SET_IS_ENDED, payload });
};

export const setPlayerError = () => (dispatch) => {
	dispatch({ type: types.SET_IS_PLAYER_ERROR });
};
