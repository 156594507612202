export const CONTAINER_SCREEN_PERCENT_DESKTOP = 90.3;
export const CONTAINER_SCREEN_PERCENT_MOBILE = 65;

export const sliderSettings = {
	dots: true,
	infinite: false,
	speed: 500,
	swipe: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
	arrows: false,
	initialSlide: 0,
	centerMode: false,
	accessibility: false
};
