import styled from 'styled-components';

import { ProductType } from '../../common_styles';

export const StyledProductType = styled(ProductType)`
	text-transform: uppercase;
`;

export const Time = styled(ProductType)`
	&::before {
		content: '| ';
	}
`;
