import React from 'react';
import { func } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import variables
import { APP_CORE_PATHS } from 'components/routes/paths';

const { REGISTER, ADDITIONAL_INFO, DEFAULT_DOCUMENT, CONTACT } = APP_CORE_PATHS;

const ProtectedRegistrationCompleted = ({ component: Component }) => {
	const { isAuthenticated, isRegisterCompleted, isFirebaseRegisterCompleted } =
		useSelector(({ auth }) => auth);

	const allowedPaths = [`/${DEFAULT_DOCUMENT}`, `/${CONTACT}`];
	const checkPath = (path) => {
		return !allowedPaths.some((allowedPath) => path.includes(allowedPath));
	};

	return (
		<Route
			render={(props) => {
				return isAuthenticated &&
					!isRegisterCompleted &&
					!isFirebaseRegisterCompleted &&
					checkPath(props.location.pathname) ? (
					<Redirect to={`/${REGISTER}/${ADDITIONAL_INFO}`} />
				) : (
					<Component {...props} />
				);
			}}
		/>
	);
};

ProtectedRegistrationCompleted.propTypes = {
	component: func.isRequired,
	location: func.isRequired
};

export default ProtectedRegistrationCompleted;
