import { useRef, useEffect, useState } from 'react';
import { string } from 'prop-types';

// Import variables
import { SLIDERS_CONFIG, DEFAULT_SLIDER_CONFIG } from 'helpers/variables';

// Import helpers
import { getSlidesToShow } from './helpers';

// Import utilities
import { useViewportResize } from 'components/utilities';

const useSlideSize = ({ type }) => {
	// get ref to slider container
	const targetRef = useRef(null);

	// state
	const [containerWidth, setContainerWidth] = useState(0);
	const [slidesToShow, setSlidesToShow] = useState(0);
	const [isDesktop, setIsDesktop] = useState(false);

	const sliderConfig = SLIDERS_CONFIG[type] || DEFAULT_SLIDER_CONFIG;

	const { resolutionHeight, resolutionWidth, spaceBetweenSlides, responsive } =
		sliderConfig;

	// calculate single slide width depending on slides per page
	const slideWidth = containerWidth / slidesToShow - spaceBetweenSlides;

	// calculate single slide height depending on resolution from mockup
	const slideHeight = (slideWidth / resolutionWidth) * resolutionHeight;

	const handleResize = () => {
		// set container width
		targetRef.current && setContainerWidth(targetRef.current.offsetWidth);

		const { slidesToShow, isDesktop } = getSlidesToShow(responsive);

		setSlidesToShow(slidesToShow);
		setIsDesktop(isDesktop);
	};

	useViewportResize({ callback: handleResize });

	useEffect(() => {
		handleResize();
		// eslint-disable-next-line
	}, [targetRef]);

	return {
		targetRef,
		slideWidth,
		slideHeight,
		containerWidth,
		slidesToShow,
		spaceBetweenSlides,
		isDesktop
	};
};

useSlideSize.propTypes = {
	type: string.isRequired
};

export default useSlideSize;
