import { useRef, useEffect, useState } from 'react';

// Import utilities
import { useViewportResize } from 'components/utilities';

const useBannerLogic = () => {
	const containerRef = useRef(null);
	const [bannerHeight, setBannerHeight] = useState(0);

	// multiplication container width * 20.84% to get banner height
	// depending on the browser window width
	const handleSetBannerHeight = () => {
		if (containerRef.current) {
			const height = (containerRef.current.offsetWidth * 20.84) / 100;
			setBannerHeight(height);
		}
	};

	useViewportResize({ callback: handleSetBannerHeight })

	useEffect(() => {
		handleSetBannerHeight();
	}, [containerRef]);

	return { containerRef, bannerHeight };
};

export default useBannerLogic;
