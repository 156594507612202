import React from 'react';

const useScript = ({ url, id, isActive }) => {
	const existingScript = document.getElementById(id);

	const handleRemoveScript = () => document.body.removeChild(existingScript);

	React.useEffect(() => {
		if (!existingScript && isActive) {
			const script = document.createElement('script');
			script.src = url;
			script.id = id;
			document.body.appendChild(script);
		}

		return () => existingScript && handleRemoveScript();
		// eslint-disable-next-line
	}, [isActive]);
};

export default useScript;
