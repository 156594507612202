import ReactHtmlParser from 'react-html-parser';

export const removeHtmlTags = (content) => {
	const regex = /<[^>]*>/g;
	const text = content?.replace(regex, '');
	return ReactHtmlParser(text);
};

export const fontOptions = {
	family: 'Roboto',
	src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf'
};

export const LINK_SELECTOR = 'a[download]';

export const EVENTS = {
	FOCUS: 'focus',
	BLUR: 'blur'
};
