import * as types from 'store/actions/types';
import produce from 'immer';

import { STATUS_TYPES, TRANSACTION_STATUS_TYPES } from 'helpers/variables';

const INITIAL_STATE = {
	requestStatus: STATUS_TYPES.loading, //'idle' | 'loading' | 'success' | 'error',
	transactionStatus: TRANSACTION_STATUS_TYPES.pending, //'init' | 'pending' | 'succeed' | 'failed' - only cases for online payment status
	error: null,
	transactionData: {},
	confirmationStatus: TRANSACTION_STATUS_TYPES.pending
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Submit purchase *******************
			case types.TRANSACTION_FINALIZATION_LOADING:
				draft.requestStatus = STATUS_TYPES.loading;
				draft.transactionStatus = TRANSACTION_STATUS_TYPES.pending;
				draft.error = null;
				draft.transactionData = {};
				break;

			case types.TRANSACTION_FINALIZATION_SUCCESS:
				draft.requestStatus = STATUS_TYPES.success;

				// treat transaction init as pending for now - it may have different meaning in future
				let transactionStatus = action.payload.status;
				if (transactionStatus === TRANSACTION_STATUS_TYPES.init) {
					transactionStatus = TRANSACTION_STATUS_TYPES.pending;
				}

				draft.transactionStatus = transactionStatus;
				draft.transactionData = action.payload;
				draft.confirmationStatus = action.payload.confirmation_status;
				draft.error = null;
				break;

			case types.TRANSACTION_FINALIZATION_ERROR:
				draft.requestStatus = STATUS_TYPES.error;
				draft.transactionStatus = TRANSACTION_STATUS_TYPES.pending;
				draft.error = action.payload;
				draft.transactionData = {};
				break;

			default:
				return state;
		}
	});
