import React from 'react';
import { useTranslation } from 'react-i18next';
import whiteLogo from 'assets/images/white-logo.png';
// Import components
import FooterNav from './FooterNav';
import Copyrights from './Copyrights';

// Import styles
import {
	FooterStyled,
	Wrapper,
	FlexContainer,
	StyledLogo,
	NavWrapper,
	AppVersion
} from './styles';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

const { FOOTER } = SECTIONS_ID;

const Footer = () => {
	const { t } = useTranslation();

	return (
		<FooterStyled id={FOOTER}>
			<Wrapper>
				<FlexContainer>
					<NavWrapper>
						<StyledLogo
							aria-hidden="true"
							ariaLabel={t('aria_header_logo_link')}
							title={t('aria_header_logo_link')}
							src={whiteLogo}
							alt={t('common_portal_logo_title')}
						/>
						<FooterNav />
					</NavWrapper>
					<Copyrights />
				</FlexContainer>
			</Wrapper>
			<AppVersion>
				{t('common_app_version', { version: process.env.REACT_APP_VERSION })}
			</AppVersion>
		</FooterStyled>
	);
};

export default Footer;
