import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import components
import { Paragraph } from 'components/elements';

// Import styles
import {
	transitionMain,
	flex,
	customMinResponsive,
	size
} from 'components/styles';

export const SearchItemStyled = styled.li`
	height: 8.4rem;
	border-top: 1px solid ${({ theme }) => theme.line};
`;

export const ParagraphStyled = styled(Paragraph.Ellipsis)`
	width: 100%;
	color: ${({ theme }) => theme.search.dropdown.itemTitle};
	font-size: ${({ theme }) => theme.fontSize.xs}rem;
	font-weight: ${({ theme }) => theme.font.bold};
	${transitionMain}
	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.xs}rem;
		`
	)}
`;

export const ProductType = styled(Paragraph.Ellipsis)`
	color: ${({ theme }) => theme.search.dropdown.itemDescription};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
	font-weight: ${({ theme }) => theme.font.medium};
	width: 100%;

	&:first-letter {
		text-transform: capitalize;
	}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;
		`
	)}
`;

export const StyledLink = styled(Link)`
	&:link,
	&:visited {
		height: 100%;
		width: 100%;
		padding: 1.5rem 2.6rem;
		${flex({ fd: 'column', ai: 'flex-start' })}
		${transitionMain}
	}
	&:hover,
	&:active {
		${ParagraphStyled} {
			color: ${({ theme: { search } }) => search.dropdown.itemTitleHover};
		}
	}
`;
