import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { PlayerElements as Player } from 'components/elements';

// Import context
import { usePlayerTvContext } from 'components/context/player/player_tv';
import { StyledButton } from './styles';

const PlayController = () => {
	const { t } = useTranslation();
	const { play, pause, isPaused, isFocus } = usePlayerTvContext();

	return isPaused ? (
		<StyledButton
			modifiers={['icon', 'playerIconHover']}
			onClick={play}
			isFocus={isFocus}
			title={t('common_player_play_title')}
		>
			<Player.IconPlay
				title={t('common_player_play_title')}
				aria-hidden="true"
			/>
		</StyledButton>
	) : (
		<StyledButton
			modifiers={['icon', 'playerIconHover']}
			onClick={pause}
			isFocus={isFocus}
			title={t('common_player_pause_title')}
		>
			<Player.IconPause
				aria-hidden="true"
				title={t('common_player_pause_title')}
			/>
		</StyledButton>
	);
};

export default PlayController;
