import { getUserAgentInfo } from 'helpers/index';
import Cookies from 'js-cookie';

// Import helpers
import { COOKIE_UID, LOCALSTORAGE_USER } from 'helpers/variables';

export const getHeaders = () => {
	const uid = Cookies.get(COOKIE_UID);
	let user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER)) || null;
	const { browser, os } = getUserAgentInfo();
	const apiDevice = `${browser.name}; ${browser.major}; ${os.name}; ${os.version}; ${os.name}; ${os.version};`;

	let headers = {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'API-DeviceUID': uid,
		'API-Device': apiDevice
	};

	if (user) {
		headers['Authorization'] = `Bearer ${user.token}`;
	}

	return headers;
};
