import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Import actions
import { deleteReminder } from 'store/actions';

// import helpers
import { getTimeDifference } from './helpers';
import { getProductType } from 'helpers/products';

// Import utilities
import { useAuth } from 'components/utilities';

const useRemindersMenu = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { isAuth } = useAuth();

	const { data } = useSelector(({ reminders }) => reminders);

	// check path to switch between live and program
	const { pathname } = useLocation();
	const dataKey =
		getProductType(pathname) === 'live' ? 'movieDetails' : 'program_details';

	const { since: startDate } = useSelector((state) => state[dataKey].data);

	const { timeDifference, showRemindersButton } = getTimeDifference(startDate);

	// gets current product reminder
	const reminder = data.find(({ product_uuid }) => product_uuid === id);

	const reminderId = reminder?.id;

	const handleDeleteReminder = () => deleteReminder(reminderId)(dispatch);

	return {
		timeDifference,
		showRemindersButton,
		reminderId,
		startDate,
		deleteReminder: handleDeleteReminder,
		isAuth
	};
};

export default useRemindersMenu;
