import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const INPUT_MODIFIERS = {};
const baseInputStyles = css`
	width: 100%;
	height: 4rem;
	padding: 10px 12px;
	color: ${({ theme }) => theme.input.color};
	background-color: transparent;
	border: 1px solid ${({ theme }) => theme.input.borderColor};
	border-radius: ${({ theme }) => theme.input.roundSize}rem;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	font-size: 14px;
	line-height: 1.28;

	&:focus {
		outline: none;
		color: ${({ theme }) => theme.input.focusColor};
		border-color: ${({ theme }) => theme.input.focusBorderColor};
	}

	::-webkit-input-placeholder {
		color: ${({ theme }) => theme.input.color};
	}

	&.errors {
		border: 1px solid ${({ theme }) => theme.error.border};
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	${applyStyleModifiers(INPUT_MODIFIERS)};
`;

const Input = styled.input`
	${baseInputStyles}
`;

const Textarea = styled.textarea`
	${baseInputStyles}
`;

const Select = styled.select`
	${baseInputStyles}
`;

export { Input, Textarea, Select, baseInputStyles };
