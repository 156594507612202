import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styles
import { IconReminder } from 'components/elements/molecules/common_button/styles';

// Import components
import { AuthModal, CommonButton } from 'components/elements';
import Options from './Options';

// Import logic
import useRemindersMenu from './useRemindersMenu';

const RemindersMenu = () => {
	const { t } = useTranslation();

	const {
		timeDifference,
		showRemindersButton,
		reminderId,
		startDate,
		deleteReminder,
		isAuth
	} = useRemindersMenu();

	// eslint-disable-next-line
	const renderButton = ({ title, ...rest }) => (
		<CommonButton {...rest}>
			<IconReminder aria-hidden="true" /> {t(title)}
		</CommonButton>
	);

	if (!showRemindersButton) return null;

	if (!isAuth) {
		return (
			<AuthModal>
				{renderButton({ title: 'vod_buttons_live_add_reminder' })}
			</AuthModal>
		);
	}

	if (reminderId) {
		return renderButton({
			title: 'vod_buttons_live_remove_reminder',
			width: 300,
			onClick: deleteReminder
		});
	}

	return <Options startDate={startDate} timeDifference={timeDifference} />;
};

export default RemindersMenu;
