import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import services from 'services/services';

// Import api
import {
	GET_PRODUCT_PAYMENT_STATUS_API,
	QUERY_PRODUCT_PAYMENT_STATUS
} from 'helpers/api';

// Import variables
import { TRANSACTION_STATUS_TYPES } from 'helpers/variables';

// Import utilities
import { useAuth } from 'components/utilities';

const { pending } = TRANSACTION_STATUS_TYPES;

const useProductPaymentStatus = ({ uuid }) => {
	const [isPaymentPending, setIsPaymentPending] = useState(false);

	const { isLoading: geolockPurchaseLoading } = useSelector(
		({ geolockPurchase }) => geolockPurchase
	);

	const { isAuth } = useAuth();

	const queryKey = `${QUERY_PRODUCT_PAYMENT_STATUS}_${uuid}`;

	const handleFetchData = async () => {
		const url = GET_PRODUCT_PAYMENT_STATUS_API.replace(':id', uuid);

		return services.get(url);
	};

	const handleCheckPaymentStatus = ({ data }) => {
		const status = data?.status;
		const isPending = status === pending;
		setIsPaymentPending(isPending);
	};

	const isEnabled = isAuth && !geolockPurchaseLoading;

	const { isLoading, remove, isIdle } = useQuery({
		queryKey,
		queryFn: handleFetchData,
		onSuccess: handleCheckPaymentStatus,
		enabled: isEnabled
	});

	useEffect(() => {
		return () => remove();
		// eslint-disable-next-line
	}, []);

	return {
		isPaymentPending,
		isLoading: isAuth ? isIdle || isLoading : isLoading
	};
};
export default useProductPaymentStatus;
