import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
`;

export function BlankItem({ width, height }) {
	return <Root style={{ width, height }} />;
}
