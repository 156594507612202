import * as types from './types';
import produce from 'immer';

// Import helpers
import { SHAKA_PLAYER_ERRORS } from 'helpers/player';

const { SHAKA_PLAYER_ERROR_MESSAGE } = SHAKA_PLAYER_ERRORS;

export const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_PLAYLIST_DATA:
				draft.playlistData = action.payload;
				break;

			case types.SET_IS_PLAYER_ERROR:
				draft.isError = true;
				draft.errorMessage = SHAKA_PLAYER_ERROR_MESSAGE;
				break;

			case types.SET_SESSION_INTERVAL_TIME:
				draft.sessionIntervalTime = action.payload;
				break;

			case types.SET_BUFFERING:
				draft.buffering = action.payload;
				break;

			case types.SET_IS_READY:
				draft.isReady = action.payload;
				break;

			case types.SET_IS_PAUSED:
				draft.isPaused = action.payload;
				break;

			case types.SET_IS_ENDED:
				draft.isEnded = action.payload;
				break;

			default:
				return state;
		}
	});
