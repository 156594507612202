import React from 'react';

// Import heleprs
import { FLOAT_WIDGET } from 'helpers/variables';
import { callAllFns } from 'helpers';

// Import components
import useFloatWidget from './useFloatWidget';

export const FloatWrapper = ({ children, ...rest }) =>
	React.cloneElement(children, { id: FLOAT_WIDGET.WRAPPER, ...rest });

export const FloatElement = ({ children }) =>
	React.cloneElement(children, { id: FLOAT_WIDGET.ELEMENT });

export const FloatSpinner = ({ children }) =>
	React.cloneElement(children, { id: FLOAT_WIDGET.SPINNER });

export const FloatError = ({ children }) =>
	React.cloneElement(children, { id: FLOAT_WIDGET.ERROR });

export const FloatCloseButton = ({ children }) => {
	const { closeFloatMode } = useFloatWidget();

	return React.cloneElement(children, {
		onClick: callAllFns(() => closeFloatMode(), children.props.onClick)
	});
};
