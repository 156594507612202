import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { deleteReminder } from 'store/actions';

// Import helpers
import { createLinkParams, getDate } from 'helpers';
import { getProductTypeAlias } from 'helpers/products';

// Import variables
import { DATE_FORMAT } from 'helpers/variables';

const { DATE_WITH_TIME } = DATE_FORMAT;

const useReminder = (reminder) => {
	const dispatch = useDispatch();
	const { title, id, program_start, product_uuid, type } = reminder;

	const programStartTime = format(getDate(program_start), DATE_WITH_TIME);

	const link = createLinkParams({ id: product_uuid, type, title });

	const handleDeleteReminder = () => deleteReminder(id)(dispatch);

	const productAlias = getProductTypeAlias(reminder);

	return {
		deleteReminder: handleDeleteReminder,
		programStartTime,
		link,
		productAlias
	};
};

export default useReminder;
