import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'components/utilities/hooks/auth/useAuth.js';
// Import styled components
import { Nav, Item, StyledLink } from './styles';

// import helpers
import { FOOTER_NAV_LINKS } from './helpers';
import { isEmpty } from 'lodash';

const FooterNav = () => {
	const { t } = useTranslation();
	const { isAuth } = useAuth();

	const filteredAuthed = (navLink) => (navLink.auth ? isAuth : !navLink.auth);
	const filterEmpty = (navLink) => !isEmpty(navLink);
	const renderFooterNavLinks = FOOTER_NAV_LINKS.filter(filterEmpty)
		.filter(filteredAuthed)
		?.map(({ path, text, ariaLabel }) => (
			<Item key={text}>
				<StyledLink to={path} aria-label={t(ariaLabel)} title={t(text)}>
					{t(text)}
				</StyledLink>
			</Item>
		));

	return <Nav>{renderFooterNavLinks}</Nav>;
};

export default FooterNav;
