import { useMediaQuery } from 'react-responsive';

// Import helpers
import { getUserAgentInfo } from 'helpers';

// Import variables
import { MOBILE_DEVICES } from 'helpers/variables';

const useDeviceInfo = () => {
	const isTabletOrMobile = useMediaQuery({ query: MOBILE_DEVICES });

	const { isMobile } = getUserAgentInfo();

	const isMobileDevice = isMobile || isTabletOrMobile;

	return { isMobileDevice };
};

export default useDeviceInfo;
