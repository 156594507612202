import { isProgramLive } from 'helpers/epg';

export const getChannelSliderContent = ({ content, channels }) => {
	// we always take first channel to display his epg
	const currentChannelUuid = content[0]?.uuid;

	const currentChannel = channels.find(
		({ uuid }) => uuid === currentChannelUuid
	);

	// current live program
	const currentLiveProgramIndex = currentChannel?.epgProgrammes.findIndex(
		(program) => isProgramLive(program)
	);

	// we have to return only current programs
	const currentPrograms = currentChannel?.epgProgrammes.slice(
		currentLiveProgramIndex
	);

	return currentChannel ? currentPrograms : [];
};
