import * as types from 'store/actions/types';
import produce from 'immer';

const INITIAL_STATE = {
	data: {},
	recommendations: [],
	broadcasts: [],
	isLoaded: false,
	error: { isError: false, message: '', errorCode: '' }
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ******************* Fetch program detail recommendations *******************
			case types.FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_LOADING:
				return { ...INITIAL_STATE };

			case types.FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_SUCCESS:
				draft.recommendations = action.payload;
				return;

			case types.FETCH_PROGRAM_DETAILS_RECOMMENDATIONS_ERROR:
				draft.error.isError = true;
				return;

			// ******************* Fetch program details broadcasts *******************
			case types.FETCH_PROGRAM_DETAILS_BROADCASTS_LOADING:
				draft.data = {};
				draft.isLoaded = false;
				draft.error.isError = false;
				return;

			case types.FETCH_PROGRAM_DETAILS_BROADCASTS_SUCCESS:
				draft.broadcasts = action.payload;
				return;

			case types.FETCH_PROGRAM_DETAILS_BROADCASTS_ERROR:
				draft.error.isError = true;
				return;

			// ******************* Fetch program details *******************
			case types.FETCH_PROGRAM_DETAILS_LOADING:
				draft.data = {};
				draft.isLoaded = false;
				draft.error.isError = false;
				return;

			case types.FETCH_PROGRAM_DETAILS_SUCCESS:
				draft.isLoaded = true;
				draft.data = action.payload;
				return;

			case types.FETCH_PROGRAM_DETAILS_ERROR:
				draft.error.isError = true;
				draft.isLoaded = true;
				if (action.payload.message) {
					draft.error.message = action.payload.message;
					draft.error.errorCode = action.payload.errorCode;
				}
				return;

			// ******************* Clear program details *******************
			case types.CLEAR_PROGRAM_DETAILS:
				return { ...INITIAL_STATE };

			default:
				return state;
		}
	});
