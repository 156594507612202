import React from 'react';
import PropTypes from 'prop-types';

// Import utilities
import { ToggleRPC } from 'components/utilities';

// Import components
import { Loader, PlayerError, PlayerPip } from 'components/elements';
import usePlayerContent from '../usePlayerContent';
import Resume from 'components/elements/organisms/player_vod/PlayerWrapper/resume/Resume';
import NextEpisode from 'components/elements/organisms/player_vod/PlayerWrapper/next_episode/NextEpisode';
import Recommendations from 'components/elements/organisms/player_vod/PlayerWrapper/recommendations/Recommendations';

const TheoPlayerContent = ({ type }) => {
	const {
		buffering,
		isReady,
		error,
		continueProgress,
		setEndMargin,
		showNextEpisode,
		showRecommendation,
		showContinueProgress
	} = usePlayerContent({ type });

	return (
		<>
			{buffering && (
				<Loader background={false} position="absolute" zIndex={1} />
			)}

			{showContinueProgress && (
				<ToggleRPC initial={true}>
					{({ on, toggle }) =>
						on && <Resume currentTime={continueProgress.time} toggle={toggle} />
					}
				</ToggleRPC>
			)}

			{showNextEpisode && (
				<ToggleRPC initial={true}>
					{({ on, toggle }) =>
						on && <NextEpisode setEndMargin={setEndMargin} toggle={toggle} />
					}
				</ToggleRPC>
			)}

			{showRecommendation && <Recommendations />}

			<PlayerPip isReady={isReady} />

			{error.isError && <PlayerError error={error} />}
		</>
	);
};

TheoPlayerContent.propTypes = {
	type: PropTypes.string.isRequired
};

export default TheoPlayerContent;
