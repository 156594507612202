import React from 'react';
import { node, oneOfType, arrayOf } from 'prop-types';

// Import components
import useFloatWidget from './useFloatWidget';

// Context
export const FloatWidgetContext = React.createContext(null);

const FloatWidgetProvider = (props) => {
	const widgetProps = useFloatWidget();

	const { isPlayerOpen } = widgetProps;

	if (!isPlayerOpen) return null;

	return <FloatWidgetContext.Provider value={widgetProps} {...props} />;
};

FloatWidgetProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default FloatWidgetProvider;
