import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import services from 'services/services';

// Import utilities
import { useToggle, useAuth } from 'components/utilities';

// Import api
import {
	GET_SUBSCRIBER_DETAILS_API,
	QUERY_SUBSCRIBER_DETAILS
} from 'helpers/api';

// Import helpers
import { getModalText } from './helpers';

const usePaymentProtect = ({ redirectUrl }) => {
	const history = useHistory();
	const [isOpened, modalToggle] = useToggle();
	const { isAuth } = useAuth();

	const { authenticate } = useSelector(({ auth }) => auth);

	const { isError, errorCode } = useSelector(
		({ geolockPurchase }) => geolockPurchase.error
	);

	const { isLoading: isUserDetailsLoading, mutateAsync: fetchUserDetails } =
		useMutation({
			mutationFn: () => services.get(GET_SUBSCRIBER_DETAILS_API),
			mutationKey: QUERY_SUBSCRIBER_DETAILS,
			onSuccess: ({ data: { email_approved } }) =>
				email_approved ? history.push(redirectUrl) : modalToggle(),
			onError: modalToggle
		});

	const isGeolockError = isError && !!errorCode;
	const isEmailConfirmed = !!authenticate?.email_approved;

	const { title, description } = getModalText({
		isGeolockError,
		isEmailConfirmed
	});

	const handleProtect = (callback) => (event) => {
		if (isAuth) {
			if (isGeolockError || !isEmailConfirmed) {
				event.preventDefault();
				isGeolockError ? modalToggle() : fetchUserDetails();
			} else if (callback) {
				callback();
			}
		}
	};

	return {
		isOpened,
		modalToggle,
		title,
		description,
		isGeolockError,
		isEmailConfirmed,
		isUserDetailsLoading,
		protect: handleProtect
	};
};

export default usePaymentProtect;
