import {
	fetchPlayerConfig,
	fetchContinueWatchingData,
	fetchMovieEpisodesPrevNext,
	fetchMovieDetails
} from 'store/actions';
import {
	PLAYER_SELECTORS,
	QUERY_SUBTYPES,
	PLAYER_VIDEO_TYPES
} from 'helpers/variables';

export const getIsEpisode = (type) => type === QUERY_SUBTYPES.series;

const { LIVE_EVENT, VOD } = PLAYER_VIDEO_TYPES;
export const fetchMovieResources = async ({
	id,
	type,
	dispatch,
	isDetails,
	isAuth
}) => {
	const playerSelector = PLAYER_SELECTORS.VOD;
	const isEpisode = getIsEpisode(type);

	const videoType = type === 'live' ? LIVE_EVENT : VOD;

	if (isAuth) {
		// Dispatch an fetchContinueWatchingData action in continue watching folder
		await fetchContinueWatchingData(id)(dispatch);
		// Dispatch an fetchMovieEpisodesPrevNext action in movieDetails folder
		isEpisode && (await fetchMovieEpisodesPrevNext(id, dispatch));
	}

	// Dispatch an fetchMovieDetails action in movieDetails folder
	!isDetails && (await fetchMovieDetails(id, videoType, dispatch));

	// Dispatch an fetchPlayerConfig action in player folder
	await fetchPlayerConfig({
		productID: id,
		type: playerSelector,
		videoType
	})(dispatch);
};
