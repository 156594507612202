import React from 'react';
import { Link } from 'react-router-dom';
import { string, array, node, number } from 'prop-types';
// Import styles
import { Item } from './styles';
// Import helpers
import { getImage } from 'components/views/vod/helpers/getImage';
import { checkBannerUrl } from '../helpers';
import { PRODUCT_IMAGES_TYPES } from 'helpers/variables';
import BannerImage from '../BannerImage';
// Import variables
const { COVER } = PRODUCT_IMAGES_TYPES;

export const BannerItem = ({
	uuid,
	images,
	title,
	url,
	bannerHeight,
	children
}) => {
	const coverImage = getImage({ images, type: COVER });

	const { isExternalPath, urlPc } = checkBannerUrl(url);
	const BannerImageComponent = (
		<BannerImage
			coverImage={coverImage}
			title={title}
			bannerHeight={bannerHeight}
		/>
	);

	const content = (
		<>
			{BannerImageComponent}
			{children}
		</>
	);

	if (!url) {
		return <Item key={uuid}>{content}</Item>;
	}

	if (isExternalPath) {
		return (
			<Link to={`/${urlPc.splice(1).join('/')}`}>
				<Item key={uuid}>{content}</Item>
			</Link>
		);
	}

	return (
		<a href={url} target="_blank" rel="noopener noreferrer">
			<Item key={uuid}>{content}</Item>
		</a>
	);
};

BannerItem.propTypes = {
	uuid: string.isRequired,
	images: array.isRequired,
	title: string.isRequired,
	url: string,
	bannerHeight: number.isRequired,
	children: node
};

export default BannerItem;
