import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Import styles
import { fadeIn } from 'components/styles';

// Import components
import { Regular } from 'components/views/vod/components/sliders/Regular';

const Recommendations = () => {
	const { t } = useTranslation();
	const recommendations = useSelector(
		({ recommendations }) => recommendations.data
	);

	if (recommendations.length === 0) return null;

	return (
		<Wrapper>
			<Regular
				showAllMoviesButton={false}
				content={recommendations}
				name={t('player_recommendations_title')}
				id="recommendations"
				borderLine={true}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: absolute;
	bottom: 11rem;
	left: 0;
	width: 100%;
	z-index: 900;
	animation: ${fadeIn(0)} 0.5s ease-in-out forwards;
`;

export default Recommendations;
